import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core'
import { Platform } from '@ionic/angular'

import { DebounceTimeType, IsDOMElementVisible } from '@mediacoach-ui-library/global'

import { PlatformType } from '@core/models/models'
import { debounceTime, filter, tap } from 'rxjs/operators'
import { RouterEventsService } from '@core/services/router-events/router-events.service'
import { IonicPageVisibilityService } from '@core/services/ionic-page-visibility/ionic-page-visibility.service'

import { StatusBar } from '@capacitor/status-bar'

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements AfterViewInit, OnDestroy {
  @Input() title: string
  @Input() titleParams: { [key: string]: string }
  @Input() loading = false
  @Input() fullscreen = false
  @Input() overflowAuto = false
  @Input() dismissible = true
  @Input() extraClasses: string

  @Output() onClose = new EventEmitter()
  parent

  onWillLeave$ = this._ionicPageVisibilityService.onWillLeave$.pipe(
    debounceTime(DebounceTimeType.ForCrashes),
    filter(() => IsDOMElementVisible(this.el.nativeElement)),
    tap(() => this._appendToParent())
  )
  onDidEnter$ = this._ionicPageVisibilityService.onDidEnter$.pipe(
    debounceTime(DebounceTimeType.ForCrashes),
    filter(() => IsDOMElementVisible(this.el.nativeElement)),
    tap(() => this._appendToBody())
  )

  constructor(
    private el: ElementRef,
    private _platform: Platform,
    private _routerEventsService: RouterEventsService,
    private _ionicPageVisibilityService: IonicPageVisibilityService
  ) {}

  ngAfterViewInit() {
    if (this._platform.is(PlatformType.Android)) {
      StatusBar.hide().catch((error) => console.error(error))
    }
    this._appendToBody()
  }

  ngOnDestroy() {
    if (this._platform.is(PlatformType.Android)) {
      StatusBar.show().catch((error) => console.error(error))
    }
  }

  onClosed() {
    this.onClose.emit()
  }

  private _appendToBody() {
    this.parent = this.el.nativeElement.parentNode
    document.querySelector('ion-app').appendChild(this.el.nativeElement)
  }

  private _appendToParent() {
    this.parent.appendChild(this.el.nativeElement)
  }
}
