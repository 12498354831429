<div class="mcm-file-upload">
  <mcm-file-upload-base
    auto="true"
    [chooseLabel]="'MTR_COMMON_SELECT_FILE' | translate"
    [multiple]="false"
    [disabled]="disabled"
    [class.mcm-file-upload--disabled]="disabled"
    [class.mcm-file-upload--loading]="loading"
    [accept]="accept"
    [maxFileSize]="maxFileSize"
    [minFileSize]="minFileSize"
    [invalidMaxFileSizeMessageDetail]="invalidMaxFileSizeMessageDetail"
    [invalidMinFileSizeMessageDetail]="invalidMinFileSizeMessageDetail"
    [invalidFileTypeMessageDetail]="invalidFileTypeMessageDetail"
    (selectEvent)="onPreselect()"
    (uploadHandler)="onSelectFn($event)"
    #fileUpload>
    <mcui-avatar
      class="mcm-file-upload__image"
      [title]="avatarProps?.title"
      [text]="avatarProps?.text"
      [backgroundColor]="avatarProps?.color"
      [imageSource]="base64Value"
      placeholderIcon="camera" />
  </mcm-file-upload-base>
</div>
