export enum TextAnchor {
  Start = 'start',
  Middle = 'middle',
  End = 'end'
}

export interface Margin {
  top: number
  right: number
  bottom: number
  left: number
}

export interface Axis {
  x?: number
  y?: number
}

export interface SVGAttrs extends Axis {
  dy?: number | string
  x1?: number
  y1?: number
  x2?: number
  y2?: number
  cx?: number
  cy?: number
  r?: number
  textAnchor?: string
  transform?: string
  filter?: string
  fill?: string
  points?: string
  value?: string
}
