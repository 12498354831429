import * as signalR from '@microsoft/signalr'
import { HttpClient as HttpClientSignalR, HubConnection, LogLevel } from '@microsoft/signalr'
import { filter, map, shareReplay } from 'rxjs/operators'
import { environment } from '@env'
import { firstValueFrom, Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { CoreAuthService } from '@core/services/auth/core-auth-service'
import { AuthActions } from 'ionic-appauth/lib/auth-action'

export function SignalrFactory(_authService: CoreAuthService, _http: HttpClient): Observable<HubConnection> {
  let instance: HubConnection
  const options = {
    httpClient: <HttpClientSignalR>{
      getCookieString: (_url: string) => '',
      post: (url: any) =>
        firstValueFrom(_http.post(url, null)).then(
          (res) => ({
            statusCode: 200,
            statusText: null,
            content: JSON.stringify(res)
          }),
          (err) => err
        )
    },
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets
  }

  _authService.events$.pipe(filter((e) => e.action === AuthActions.SignOutSuccess)).subscribe(() => (instance = null))

  return _authService.token$.pipe(
    filter((t) => t?.isValid(0)),
    map((tokenResponse) => tokenResponse?.accessToken),
    map((token: string) => {
      if (!instance) {
        instance = new signalR.HubConnectionBuilder()
          .withUrl(environment.API.WEB_SOCKET(token), options)
          .configureLogging(environment.production ? LogLevel.Information : LogLevel.Debug)
          .build()
      }
      return instance
    }),
    shareReplay(1)
  )
}
