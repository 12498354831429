import { MetricAggregationState } from '@core/store/states/metric-aggregation.state'
import { Action, createReducer, on } from '@ngrx/store'
import {
  initializeMetricAggregationSuccess,
  purgeMetricAggregation,
  setAggregationMode,
  setIsMatch,
  setIsVsEnabled,
  setPlayerDemarcation
} from '@core/store/actions/metric-aggregation.actions'

const initialState: MetricAggregationState = {
  isVsEnabled: null,
  isMatch: null,
  context: null,
  aggregationMode: null,
  aggregationModes: null
}

const reducer = createReducer(
  initialState,
  on(
    initializeMetricAggregationSuccess,
    (state, { initialState: newState }): MetricAggregationState => ({ ...state, ...newState })
  ),
  on(setIsVsEnabled, (state, { isVsEnabled }): MetricAggregationState => ({ ...state, isVsEnabled })),
  on(setIsMatch, (state, { isMatch }): MetricAggregationState => ({ ...state, isMatch })),
  on(setAggregationMode, (state, { aggregationMode }): MetricAggregationState => ({ ...state, aggregationMode })),
  on(setPlayerDemarcation, (state, { demarcation }): MetricAggregationState => ({ ...state, demarcation })),
  on(purgeMetricAggregation, (state): MetricAggregationState => ({ ...initialState }))
)

export const metricAggregationReducer = (
  state: MetricAggregationState | undefined,
  action: Action
): MetricAggregationState => reducer(state, action)
