import { NgModule } from '@angular/core'
import { FACTORY_TOKEN } from './constants/signalr.constants'
import { SignalrFactory } from './factories/signalr.factory'
import { HttpClient } from '@angular/common/http'
import { SignalrService } from './services/signalr.service'
import { CoreAuthService } from '@core/services/auth/core-auth-service'

@NgModule({
  providers: [
    SignalrService,
    {
      provide: FACTORY_TOKEN,
      useFactory: SignalrFactory,
      deps: [CoreAuthService, HttpClient]
    }
  ]
})
export class SignalrModule {}
