import { Store } from '@ngrx/store'
import { inject, Injectable } from '@angular/core'
import { fetchTopic, setSocketStatus } from '@core/store/actions/socket.actions'
import { SignalrService } from '@core/websockets/signalr/services/signalr.service'
import { ROOM_RECEIVE_MESSAGE } from '@sockets/constants/socket.constants'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { map } from 'rxjs/operators'
import { HubConnectionState } from '@microsoft/signalr'
import { Observable } from 'rxjs'

@Injectable()
export class SocketService {
  private readonly _store: Store = inject(Store)
  private readonly _signal: SignalrService = inject(SignalrService)

  constructor() {
    this._signal.setupRoom(ROOM_RECEIVE_MESSAGE).pipe(takeUntilDestroyed()).subscribe()

    this._signal.message$
      .pipe(takeUntilDestroyed())
      .subscribe((socketMessage) => this._store.dispatch(fetchTopic({ socketMessage })))

    this._signal.statusChange$.pipe(takeUntilDestroyed()).subscribe(({ status }) => {
      this._store.dispatch(setSocketStatus({ status }))
    })
  }

  hub() {
    return this._signal.hub
  }

  isConnected(): Observable<boolean> {
    return this._signal.hub.pipe(map((hub) => hub.state === HubConnectionState.Connected))
  }

  connect() {
    return this._signal.startConnection()
  }

  disconnect() {
    return this._signal.stopConnection()
  }

  dispose(): void {
    this._signal.dispose()
  }
}
