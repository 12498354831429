import { Injectable } from '@angular/core'
import { Actions,  createEffect, ofType } from '@ngrx/effects'
import { concatLatestFrom } from '@ngrx/operators'
import { rankingActions } from '../actions/ranking.actions'
import { map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { RankingsApi } from '../../api/rankings.api'
import { requestErrorHandler } from '@shared/operators/request-error.operator'
import { ModalController } from '@ionic/angular'
import { Observable } from 'rxjs'
import { fromPromise } from 'rxjs/internal/observable/innerFrom'
import {
  selectAggregationContext,
  selectAggregationMode,
  selectMetricAggregationMetadata
} from '@core/store/selectors/metric-aggregation.selectors'
import { Action, Store } from '@ngrx/store'
import { MetricFinderKey } from '@core/models/models'
import { resolveTeamRankingRequestAdapter } from '../../adapters/team-ranking.adapter'
import { MetricAggregation } from '@core/enums/metric.enums'
import { RankedMetric, RankingRequest } from '../../models/ranking.model'
import { MetricAggregationContextMetadata } from '@core/models/metric.models'
import { RankingDisplayMode, RankingWarningMode } from '../../enums/ranking.enums'
import { RankingContainerComponent } from '../../ranking-container.component'
import { resolvePlayerRankingRequest } from '../../adapters/player-ranking.adapter'
import { resolveMatchRankingRequest } from '../../adapters/match-ranking.adapter'
import { WarningModalComponent } from '@shared/components'
import { RANKING_MATCH_PREVIOUS_STATES } from '@features/rankings/constants/rankings.constants'
import {
  selectMetricToRank,
  selectRankedMetricContext,
  selectRankedMetricDisplayMode
} from '@features/rankings/store/selectors/ranking.selectors'
import { DemarcationPipe } from '@shared/pipes/demarcation.pipe'
import { PLAYER_DEMARCATION_FALLBACK_KEY, PLAYER_DEMARCATION_KEYS, PLAYER_POSITION_KEYS } from '@core/constants/player.constants'


@Injectable()
export class RankingEffects {
  private _modal: HTMLIonModalElement

 fetchRankedMetric$ = createEffect(() => this._actions.pipe(
    ofType(rankingActions.fetchRanking),
    map(({payload}) => {
      if (!/previa/i.test(payload.value?.keyPath) && RANKING_MATCH_PREVIOUS_STATES.includes(payload?.valueContext?.state?.name)){
        return ({ warning: RankingWarningMode.NoMatch, payload: 'MTR_RANKINGS_NO_MATCH_DATA' })
      }
      return ({warning: null,  payload})
    }),
    map(({warning, payload})=> warning ? rankingActions.openWarningModal({body: payload as string}): rankingActions.performFetchRanking({payload})),
  ))

  performFetchRanking$ = createEffect(() => this._actions.pipe(
    ofType(rankingActions.performFetchRanking),
    concatLatestFrom(() => [
      this._store.select(selectAggregationMode),
      this._store.select(selectAggregationContext),
      this._store.select(selectMetricAggregationMetadata),
    ]),
    map(([
           {payload},
           mode,
           context,
           aggregationMetadata
         ]) =>  ({originalPayload: payload, resolvedPayload: this._resolvePayload(payload, mode, context, aggregationMetadata)})),
    tap(({resolvedPayload}) => {
      const { metricToRank, ...ctx } = resolvedPayload
      this._store.dispatch(rankingActions.setRankingContext({metricToRank, ...ctx}))
    }),
    switchMap(({originalPayload, resolvedPayload: payload}) => this._api.fetchRankedMetric(payload.metricToRank).pipe(
      map(({ ranking, matchDay}) => ({matchDay, ranking: ranking.map(this._mapRanking)})),
      mergeMap(({ranking, matchDay}) => [
        rankingActions.openModal({payload}),
        rankingActions.setSourceMetric({sourceMetric: originalPayload.value}),
        rankingActions.setRanking({ranking}),
        rankingActions.setRankingMatchDay({matchDay})
      ]),
      requestErrorHandler(),
    ))
  ))


  refreshRanking$ = createEffect(() => this._actions.pipe(
    ofType(rankingActions.refreshRanking),
    concatLatestFrom(() => [
      this._store.select(selectMetricToRank),
      this._store.select(selectRankedMetricContext),
      this._store.select(selectRankedMetricDisplayMode),
    ]),
    map(([{payload}, metricToRank, metricContext, displayMode]) => ({
      metricToRank: {...metricToRank, metricType: payload},
      metricContext,
      displayMode
    })),
    switchMap(({metricToRank, metricContext, displayMode}) => this._api.fetchRankedMetric(metricToRank).pipe(
      map(({ ranking, matchDay}) => ({matchDay, ranking: ranking.map(this._mapRanking)})),
      mergeMap(({ranking}) => [
        rankingActions.setRankingContext({metricToRank,metricContext, displayMode}),
        rankingActions.setRanking({ranking})
      ]),
      requestErrorHandler()
    )),
  ))

  openWarningModal$ = createEffect(() => this._actions.pipe(
    ofType(rankingActions.openWarningModal),
    switchMap(({body}) => this._createWarningModal({
      title: 'MTR_COMMON_WARNING',
      body,
      actionClose: rankingActions.closeModal
    })),
    switchMap(modal => {
      this._modal = modal
      return fromPromise(this._modal.present())
    }),
  ), {dispatch: false})

  openRankingsModal$ = createEffect(() => this._actions.pipe(
    ofType(rankingActions.openModal),
    switchMap((payload) => this._createRankingModal(payload).pipe(
      switchMap(modal => {
        this._modal = modal
        return fromPromise(this._modal.present())
      }),
    )),
  ), {dispatch: false})

  closeRankingsModal$ = createEffect(() => this._actions.pipe(
    ofType(rankingActions.closeModal),
    switchMap(() => fromPromise(this._modal?.dismiss())),
    map(() => rankingActions.purgeRanking())
  ))

  constructor(
    private readonly _actions: Actions,
    private readonly _api: RankingsApi,
    private readonly _store: Store,
    private readonly _modalController: ModalController,
    private readonly _demarcationPipe: DemarcationPipe<any>
  ) {
  }

  private _createWarningModal(
    componentProps: { title?: string; body?: string; icon?: string; [key: string]: any; actionClose: Action }
  ): Observable<HTMLIonModalElement> {
    return fromPromise(this._modalController.create({
      component: WarningModalComponent,
      componentProps,
      cssClass: 'mcm-rankings-warning',
      backdropDismiss: true
    }))
  }

  private _createRankingModal(componentProps: { [key: string]: any }): Observable<HTMLIonModalElement> {
    return fromPromise(this._modalController.create({
      component: RankingContainerComponent,
      componentProps,
      cssClass: 'mcm-rankings',
      backdropDismiss: true
    }))
  }

  private _resolvePayload(
    payload: any, mode: MetricAggregation, context: MetricFinderKey, contextMetadata: MetricAggregationContextMetadata
  ): { metricToRank: RankingRequest; metricContext: any; displayMode: RankingDisplayMode } {
    let metricToRank: RankingRequest
    let displayMode: RankingDisplayMode

    switch (context) {
      case MetricFinderKey.Team:
        metricToRank = resolveTeamRankingRequestAdapter(payload, mode, contextMetadata)
        displayMode = contextMetadata.isVsEnabled ? RankingDisplayMode.ComparedTeam : RankingDisplayMode.Team
        break
      case MetricFinderKey.Player:
        metricToRank = resolvePlayerRankingRequest(payload, mode, contextMetadata)
        displayMode = contextMetadata.isVsEnabled
          ? contextMetadata.isMatch
            ? RankingDisplayMode.MatchComparedPlayer
            : RankingDisplayMode.ComparedPlayer
          : contextMetadata.isMatch
            ? RankingDisplayMode.MatchPlayer
            : RankingDisplayMode.Player
        break
      case MetricFinderKey.Match:
        metricToRank = resolveMatchRankingRequest(payload, mode)
        displayMode = RankingDisplayMode.Match
        break
    }
    return {
      metricToRank,
      displayMode,
      metricContext: payload.valueContext
    }
  }

  private _mapRanking = (rank: RankedMetric) => {
    let returnRank = {...rank}

    if (returnRank.position) {
      const positionData = {
        playerPosition: returnRank.position,
        playerPositionKey: PLAYER_POSITION_KEYS[returnRank.position],
        genericPositionKey: returnRank.genericPositionKey,
        specificPositionKey: returnRank.specificPositionKey,
      }
      returnRank = {
        ...returnRank, position: this._demarcationPipe.transform(
          positionData,
          PLAYER_DEMARCATION_KEYS,
          PLAYER_DEMARCATION_FALLBACK_KEY,
          ' '
        )
      }
    }

    return returnRank
  }

}
