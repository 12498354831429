<div class="mcm-rankings-warning__header">
  <div class="mcm-rankings-warning__title">{{title | translate}}</div>
  <app-button
    class="mcm-rankings-warning__close"
    icon="close-outline"
    extraClasses="mcm-button--xxl"
    (click)="onClose()"></app-button>
</div>
<div class="mcm-rankings-warning__content">
  <div class="mcm-rankings-warning__content-body">{{body | translate}}</div>
</div>
