import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core'

import { DebounceTimeType, IsDOMElementVisible } from '@mediacoach-ui-library/global'

import { IonicPageVisibilityService } from '@core/services/ionic-page-visibility/ionic-page-visibility.service'
import { debounceTime, filter, tap } from 'rxjs/operators'

@Directive({
  selector: '[mcmAutofocus]'
})
export class AutofocusDirective implements OnInit, OnDestroy {
  @Input() isIonic = false
  private _autofocus: boolean

  constructor(
    private _el: ElementRef,
    private _ionicPageVisibilityService: IonicPageVisibilityService
  ) {}

  get autofocus() {
    return this._autofocus
  }

  @Input('mcmAutofocus') set autofocus(_autofocus) {
    this._autofocus = !!_autofocus
    this._toggleFocus()
  }

  ngOnInit() {
    this._ionicPageVisibilityService.onDidEnter$
      .pipe(
        debounceTime(DebounceTimeType.ForCrashes),
        filter(() => IsDOMElementVisible(this._el.nativeElement)),
        tap(() => this._toggleFocus())
      )
      .subscribe()
  }

  ngOnDestroy() {}

  private _toggleFocus() {
    const elem = this._el.nativeElement
    if (this._autofocus) {
      if (this.isIonic) {
        elem.setFocus()
      } else {
        ;(elem as HTMLInputElement).focus()
      }
    } else if (!this.isIonic) {
      ;(elem as HTMLInputElement).blur()
    }
  }
}
