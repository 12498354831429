import { InjectionToken, NgModule } from '@angular/core'
import { ActionReducer, ActionReducerMap, MetaReducer, StoreModule, UPDATE } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { ngRxDevToolsConfig } from '../../ngrx-devtools-config'
import { metricAggregationReducer } from './reducers/metric-aggregation.reducer'
import { StoreToken } from './enums/store-tokens.enum'
import { MetricAggregationEffects } from './effects/metric-aggregation.effects'
import { metricFinderReducer } from './reducers/metric-finder.reducer'
import { MatchMetricFinderEffects } from './effects/match-metric-finder.effects'
import { PlayerMetricFinderEffects } from './effects/player-metric-finder.effects'
import { TeamMetricFinderEffects } from './effects/team-metric-finder.effects'
import { ContextMenuEffects } from '@core/store/effects/context-menu.effects'
import { userReducer } from '@core/store/reducers/user.reducer'
import { seasonReducer } from '@core/store/reducers/season.reducer'
import { settingsReducer } from '@core/store/reducers/settings.reducer'
import { SettingsEffects } from '@core/store/effects/settings.effects'
import { MetricFinderEffects } from '@core/store/effects/metric-finder.effects'
import { AuthEffects } from '@core/store/effects/auth.effects'
import { ProfileEffects } from '@core/store/effects/profile.effects'
import { SeasonEffects } from '@core/store/effects/season.effects'
import { ErrorEffects } from '@core/store/effects/error.effects'
import { socketReducer } from '@core/store/reducers/socket.reducer'
import { SocketEffects } from '@core/store/effects/socket.effects'
import { RECOMPUTE } from '@ngrx/store-devtools'
import { signOutSuccess } from '@core/store/actions/auth.actions'
import { setTopic } from '@core/store/actions/socket.actions'

export const getReducers = (): ActionReducerMap<any> => ({
  [StoreToken.metricAggregation]: metricAggregationReducer,
  [StoreToken.metricFinder]: metricFinderReducer,
  [StoreToken.user]: userReducer,
  [StoreToken.season]: seasonReducer,
  [StoreToken.settings]: settingsReducer,
  [StoreToken.socket]: socketReducer
})

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>('mobile')

export const clearStateMetaReducer = (reducer: ActionReducer<any>): ActionReducer<any> =>
  function (state, action) {
    if (action.type === signOutSuccess.type) {
      state = {}
    }
    return reducer(state, action)
  }

export const debug = (reducer: ActionReducer<any>): ActionReducer<any> =>
  function (state, action) {
    if (![UPDATE, RECOMPUTE as string].includes(action.type) && action.type === setTopic.type) {
      //console.log('action', action)
    }
    return reducer(state, action)
  }
export const metaReducers: MetaReducer[] = [clearStateMetaReducer, debug]
const EFFECTS = [
  MetricFinderEffects,
  MetricAggregationEffects,
  MatchMetricFinderEffects,
  PlayerMetricFinderEffects,
  TeamMetricFinderEffects,
  ContextMenuEffects,
  SettingsEffects,
  ProfileEffects,
  AuthEffects,
  SeasonEffects,
  ErrorEffects,
  SocketEffects
]

@NgModule({
  imports: [StoreModule.forRoot(REDUCER_TOKEN, { metaReducers }), EffectsModule.forRoot(EFFECTS), ngRxDevToolsConfig],
  providers: [{ provide: REDUCER_TOKEN, useFactory: getReducers }]
})
export class RootStoreModule {}
