import { Component, EventEmitter, Input, Output } from '@angular/core'
import { COLUMNS, METRIC_MODE_MAP } from './constants/team-players-grid.constants'
import { PLAYER_PLACEHOLDER_IMAGE } from '@core/constants/asset-path'
import { MetricPage } from '@pages/metric/metric.page'
import { MetricCategory } from '@pages/metric/metric.models'
import { parsePlayerStats } from './utils/team-players-grid.utils'
import { TeamPlayer } from './models/team-players-grid.models'
import { Router } from '@angular/router'
import { MATCH_PLAYER_DETAIL_BASE_PATH, PLAYER_DETAIL_BASE_PATH } from '@pages/matches/constants/matches.constants'
import { MetricMode } from '@pages/matches/enums/matches.enum'
import { GroupWeight } from './enums/team-players-grid.enums'
import { FirebaseAnalyticsService } from '@core/services/analytics/firebase/firebase-analytics.service'
import {
  FirebaseAnalyticsCategory,
  FirebaseAnalyticsEvent,
  FirebaseAnalyticsParam
} from '@core/services/analytics/firebase/enums/firebase-analytics.enums'
import { debounce } from '../../decorators/debounce.decorator'
import { deepCloneArray } from '@core/utils/array.utils'
import { ButtonModifier, ButtonType, Team } from '@mediacoach-ui-library/global'
import { MetricAggregation } from '@core/enums/metric.enums'
import { BehaviorSubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { Column } from '@shared/components/table/table.models'
import { StaticOrientation } from '@core/enums/statics.enum'
import { parseStatics } from '@core/utils/statics.utils'
import { McmModalControllerService } from '@core/services/mcm-modal-controller/mcm-modal-controller.service'
import { AGGREGATED_METRIC_LABEL_MAP } from '@core/constants/metric-aggregation.constants'
import { PLAYER_DEMARCATION_FALLBACK_KEY, PLAYER_DEMARCATION_KEYS } from '@core/constants/player.constants'

@Component({
  selector: 'app-team-players-grid',
  templateUrl: './team-players-grid.component.html',
  styleUrls: ['./team-players-grid.component.scss']
})
export class TeamPlayersGridComponent {
  private readonly _aggregationMode$$ = new BehaviorSubject(MetricAggregation.AverageValues)

  private _matchId: string
  get matchId() {
    return this._matchId
  }

  @Input() set matchId(id: string) {
    this._matchId = id
    const _initialAggregation = id ? MetricAggregation.MatchValues : MetricAggregation.AverageValues
    this._aggregationMode$$.next(_initialAggregation)
    this.columns = this._mapColumns(METRIC_MODE_MAP[_initialAggregation])
  }

  private _lineupConfirmed: boolean

  @Input() team: Team & { competitionId: string; seasonId: string }
  @Input() loading: boolean
  @Input() lang: string

  @Output() playerSelect = new EventEmitter()
  @Output() tableScroll = new EventEmitter()

  columns: Column[] = this._mapColumns()
  rows: TeamPlayer[]
  showAggregationSelector: boolean

  readonly playerDemarcationKeys = PLAYER_DEMARCATION_KEYS
  readonly playerDemarcationFallbackKey = PLAYER_DEMARCATION_FALLBACK_KEY

  readonly playerPlaceholder = PLAYER_PLACEHOLDER_IMAGE
  readonly buttonType = ButtonType.Icon
  readonly buttonModifiers = [ButtonModifier.Small]

  readonly aggregationMode$ = this._aggregationMode$$.asObservable()
  readonly aggregationLabel$ = this.aggregationMode$.pipe(map((mode) => AGGREGATED_METRIC_LABEL_MAP[mode]))
  readonly metricMode$ = this.aggregationMode$.pipe(map((mode) => METRIC_MODE_MAP[mode]))

  constructor(
    private readonly _modalController: McmModalControllerService,
    private readonly _router: Router,
    private readonly _analytics: FirebaseAnalyticsService
  ) {
    this._aggregationMode$$.next(MetricAggregation.AverageValues)
  }

  @Input()
  set players(players) {
    if (players) {
      this._lineupConfirmed = players?.length > 0
      this.rows = parsePlayerStats(players)
      const firstSubstituteIndex = this.rows.findIndex((item) => !item.starter)
      const firstStarterIndex = this.rows.findIndex((item) => item.starter)
      if (firstSubstituteIndex !== -1 && firstStarterIndex !== -1) {
        this.rows.splice(firstSubstituteIndex, 0, {
          substituteSeparatorText: 'MTR_MATCH_DETAIL_SUBSTITUTES',
          groupWeight: GroupWeight.Separator
        })
      }
    }
  }

  async openMetricDialog(row, column, cell) {
    const modal = await this._modalController.create({
      component: MetricPage,
      componentProps: {
        data: {
          ...row,
          team: {
            name: this.team?.name,
            ...parseStatics(this.team?.statics, [StaticOrientation.Portrait])
          },
          metricLabel: (column.key || column.label).split('_').slice(0, 3).join('_'),
          metricValue: cell.value,
          metricUnit: cell.unit
        },
        type: MetricCategory.Player
      },
      cssClass: 'custom-modal custom-modal--player',
      backdropDismiss: false
    })
    return await modal.present()
  }

  trackByFn(index: number, player): string {
    return player.id
  }

  handlePlayerClick(player: TeamPlayer) {
    this.playerSelect.emit(player)
    this._navigate(player)
  }

  @debounce()
  private _navigate(player: TeamPlayer) {
    const { seasonId, competitionId, id: teamId } = this.team
    const url =
      this.matchId && this._lineupConfirmed
        ? MATCH_PLAYER_DETAIL_BASE_PATH(this.matchId, teamId, player.id)
        : PLAYER_DETAIL_BASE_PATH(seasonId, competitionId, teamId, player.id)

    this._analytics.logEvent(FirebaseAnalyticsEvent.clickPlayer, {
      [FirebaseAnalyticsParam.category]: FirebaseAnalyticsCategory.players,
      [FirebaseAnalyticsParam.playerId]: player.id,
      [FirebaseAnalyticsParam.seasonId]: seasonId,
      [FirebaseAnalyticsParam.competitionId]: competitionId,
      [FirebaseAnalyticsParam.teamId]: teamId
    })
    this._router.navigateByUrl(url)
  }

  onAggregationSelectorClose(data) {
    this.showAggregationSelector = false
    this._aggregationMode$$.next(data.id)
    this.columns = this._mapColumns(METRIC_MODE_MAP[data.id])
  }

  private _mapColumns(mode: MetricMode = MetricMode.Average) {
    return deepCloneArray(COLUMNS).map((c) => {
      if (c.sortableKey) {
        c.sortableKey = `${mode}.${c.sortableKey}`
      }
      return c
    })
  }
}
