export enum RankingMetricType {
  Average = 1,
  Accumulate,
  Match
}

export enum RankingDisplayMode {
  Team = 'team',
  ComparedTeam = 'comparedTeam',
  Player = 'player',
  ComparedPlayer = 'comparedPlayer',
  Match = 'match',
  MatchPlayer = 'matchPlayer',
  MatchComparedPlayer = 'matchComparedPlayer'
}

export enum RankingWarningMode {
  NoMatch = 'NoMatch',
  NoMetric = 'NoMetric',
  NoRank = 'NoRank',
  NoPercent = 'NoPercent'
}
