import { of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { requestErrorHandler } from '@shared/operators/request-error.operator'

export function catchObsError(fallback: any = null): MethodDecorator {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const original = descriptor.value
    descriptor.value = function (...args) {
      return original.apply(this, args).pipe(
        requestErrorHandler(),
        catchError(() => of(fallback))
      )
    }

    return descriptor
  }
}
