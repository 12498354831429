import { SeasonState } from '@core/store/states/season.state'
import { Action, createReducer, on } from '@ngrx/store'
import { setCurrentSeason } from '@core/store/actions/season.actions'

const initialState: SeasonState = {
  current: null
}

const reducer = createReducer(
  initialState,
  on(setCurrentSeason, (state: SeasonState, { current }): SeasonState => ({ ...state, current }))
)

export const seasonReducer = (state: SeasonState | undefined, action: Action) => reducer(state, action)
