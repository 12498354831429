import { ChangeDetectionStrategy, Component } from '@angular/core'
import { Store } from '@ngrx/store'
import {
  selectRankedMetricContext,
  selectRankedMetricDisplayMode,
  selectRankedMetricKey,
  selectRankingColumns,
  selectRankingHeader,
  selectRankingRows,
  selectShowFooter,
  selectWarningI18n
} from '@features/rankings/store/selectors/ranking.selectors'
import { rankingActions } from '@features/rankings/store/actions/ranking.actions'
import { RankedMetric } from '@features/rankings/models/ranking.model'
import { selectAggregationMode, selectMetricAggregationModes } from '@core/store/selectors/metric-aggregation.selectors'
import { SegmentModifier } from '@shared/components/segmented-control/segmented-control.models'
import { tap } from 'rxjs/operators'
import { MetricAggregation } from '@core/enums/metric.enums'
import { MetricAggregationModeSelectable } from '@core/models/metric.models'
import {
  RANKING_AGGREGATION_TO_METRIC_TYPE_MAP,
  RANKING_ROW_STYLES
} from '@features/rankings/constants/rankings.constants'

@Component({
  selector: 'mcm-ranking-container',
  templateUrl: './ranking-container.component.html',
  styleUrls: ['./ranking-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RankingContainerComponent {
  readonly rows$ = this._store.select(selectRankingRows)
  readonly warning$ = this._store.select(selectWarningI18n)
  readonly columns$ = this._store.select(selectRankingColumns)
  readonly metricKey$ = this._store.select(selectRankedMetricKey)
  readonly metricContext$ = this._store.select(selectRankedMetricContext)
  readonly displayMode$ = this._store.select(selectRankedMetricDisplayMode)
  readonly aggregationModes$ = this._store.select(selectMetricAggregationModes).pipe(
    tap(
      (modes) =>
        (this.modes = (modes || []).map((mode) => ({
          ...mode,
          label: mode.text,
          selected: false
        })))
    )
  )
  readonly aggregationMode$ = this._store
    .select(selectAggregationMode)
    .pipe(tap((mode) => (this.aggregationMode = mode)))
  readonly showFooter$ = this._store.select(selectShowFooter)

  readonly header$ = this._store.select(selectRankingHeader)

  readonly segmentModifiers = [SegmentModifier.SecondLevel, 'mcm-segmented-control--no-margin'] as SegmentModifier[]

  readonly rowStyles = RANKING_ROW_STYLES

  aggregationMode: MetricAggregation
  modes: MetricAggregationModeSelectable[]

  payload: { metricContext: { overrideAggregationMode: string } }

  constructor(private readonly _store: Store) {}

  close() {
    this._store.dispatch(rankingActions.closeModal())
  }

  trackByFn(index: number, rank: RankedMetric): string {
    return rank.id
  }

  onModeChange(value) {
    this._store.dispatch(rankingActions.refreshRanking({ payload: RANKING_AGGREGATION_TO_METRIC_TYPE_MAP[value] }))
  }
}
