<ng-container
  *ngIf="{
    mode: metricMode$ | async,
    label: aggregationLabel$ | async,
    aggregationMode: aggregationMode$ | async
} as data">
  <div class="mcm-team-players__middle-header-aggregation" (click)="showAggregationSelector = true" *ngIf="!loading">
    <div class="mcm-team-players__middle-header-aggregation-text">{{data?.label | translate}}</div>
    <div class="mcm-team-players__middle-header-aggregation-icon">
      <mc-button icon="refresh u-icon--xs" [buttonType]="buttonType" [buttonModifiers]="buttonModifiers"></mc-button>
    </div>
  </div>

  <mcm-table
    class="mcm-team-players__table"
    [columns]="columns"
    [rows]="rows"
    [loading]="loading"
    [cellTpl]="cellTpl"
    [sortable]="true"
    [trackByFn]="trackByFn.bind(this)"
    (tableScroll)="tableScroll.emit($event)">
    <ng-template #cellTpl let-row="row" let-column="column" let-isFirstColumn="isFirstColumn">
      <ng-container *ngIf="row">
        <!-- metric column -->
        <ng-template #metricBlock>
          <ng-container *ngIf="row.substituteSeparatorText; else metric">
            <div class="mcm-team-players__table-cell__metric--substitutes-separator"></div>
          </ng-container>
          <ng-template #metric>
            <div
              class="mcm-team-players__table-cell__metric"
              *mcmVar="(((row)[data?.mode] || {})[column.key || column.label] || {}) as cell"
              (click)="openMetricDialog(row, column, cell)">
              {{ cell.value === 0 ? '' : ((cell.value | number:'': lang) || '-') }}{{cell.value === 0 || null ? '' :
              ((cell.unit | translate) || '')}}
            </div>
          </ng-template>
        </ng-template>

        <!-- player column-->
        <ng-container *ngIf="isFirstColumn; else metricBlock">
          <ng-container *ngIf="row.substituteSeparatorText; else player">
            <div class="mcm-team-players__table-cell__player--substitutes-separator">
              {{ row.substituteSeparatorText | translate}}
            </div>
          </ng-container>
          <ng-template #player>
            <div class="mcm-team-players__table-cell__player" (click)="handlePlayerClick(row)">
              <img
                loading="lazy"
                class="mcm-team-players__table-cell__player-avatar"
                [appDefaultImage]="playerPlaceholder"
                [srcUrl]="row?.imgUrl"
                [alt]="row?.name"
                loading="lazy" />
              <div class="mcm-team-players__table-cell__player-text">
                <span class="mcm-team-players__table-cell__player-text--name">{{row?.name}}</span>
                <span
                  class="mcm-team-players__table-cell__player-text--position"
                  [innerHtml]="row?.shirtNumber +'. ' + (row | demarcation: playerDemarcationKeys : playerDemarcationFallbackKey : ' ': '<br>')">
                </span>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-template>
  </mcm-table>

  <app-modal
    *ngIf="showAggregationSelector"
    extraClasses="no-padding"
    [title]="'MTR_COMMON_AGGREGATION_METRICS_TITLE'"
    (onClose)="showAggregationSelector = false">
    <mcm-aggregation-selector
      [selection]="data?.aggregationMode"
      [inMatchContext]="!!matchId"
      (selectionChange)="onAggregationSelectorClose($event)"></mcm-aggregation-selector>
  </app-modal>
</ng-container>
