import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() text: string
  @Input() color: string
  @Input() icon: string
  @Input() iconSize: string
  @Input() disabled = false
  @Input() extraClasses: string
  @Input() isActive: boolean
  @Input() expand: 'block' | 'full'
  @Input() qaId: string
  @Output() onClick = new EventEmitter<boolean>()

  constructor() {}

  onHandleClick() {
    this.onClick.emit(true)
  }
}
