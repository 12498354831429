import { MCMTeam } from '@pages/team/models/team.models'
import { MetricAggregation } from '@core/enums/metric.enums'
import { MetricAggregationContextMetadata } from '@core/models/metric.models'
import {
  parseTeamMetricFinderToRankingRequest,
  parseTeamToRankingRequest
} from '@features/rankings/parsers/team-ranking.parser'
import {
  parseTeamComparisonMetricFinderToRankingRequest,
  parseTeamComparisonToRankingRequest
} from '@features/rankings/parsers/team-comparison-ranking.parser'
import { getMetricValueAndContext } from '@features/rankings/utils/ranking.utils'

export const resolveTeamRankingRequestAdapter = (
  payload,
  aggregationMode: MetricAggregation,
  { isVsEnabled }: MetricAggregationContextMetadata
) => {
  const { context, value, mode, metricFinder } = getMetricValueAndContext(payload, aggregationMode)

  return isVsEnabled
    ? metricFinder
      ? parseTeamComparisonMetricFinderToRankingRequest(context, value, mode)
      : parseTeamComparisonToRankingRequest(context, value, mode)
    : metricFinder
      ? parseTeamMetricFinderToRankingRequest(context, value, mode)
      : parseTeamToRankingRequest(context as MCMTeam, value, mode)
}
