import { Directive, ElementRef, HostBinding, OnDestroy, Renderer2 } from '@angular/core'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mcm-video-markers'
})
export class TooltipHelperDirective implements OnDestroy {
  @HostBinding('class.tooltip') isTooltipShown

  constructor(
    private readonly _host: ElementRef,
    private readonly _renderer: Renderer2
  ) {
    // In order to prevent odd glitches on hover, we have to handle mouse events via renderer
    this._renderer.listen(this._element, 'mouseover', () => this._handleSeekBarTooltips(false))
    this._renderer.listen(this._element, 'mouseout', () => this._handleSeekBarTooltips(true))
  }

  private get _element(): HTMLElement {
    return this._host ? this._host.nativeElement : undefined
  }

  private get _nativeTooltip(): HTMLElement {
    return this._host ? this._host.nativeElement.parentElement.querySelector('.vjs-mouse-display') : undefined
  }

  ngOnDestroy(): void {}

  private _handleSeekBarTooltips(hide: boolean): void {
    if (this._nativeTooltip) {
      this._renderer.setStyle(this._nativeTooltip, 'opacity', +hide)
    }
  }
}
