import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core'
import { combineLatest, Observable, Subject } from 'rxjs'
import { AdvancedControlAction, Marker } from './models/video-markers.models'
import { VideoComponent } from '@shared/components/video-container/components/video/video.component'
import { parseMarkers } from '@shared/components/video-container/utils/video-markers.utils'
import { filter, tap } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { VideoAnalyticsEvent } from '@shared/components/video-container/models/video.models'

@UntilDestroy()
@Component({
  selector: 'mcm-video-container',
  templateUrl: './video-container.component.html',
  styleUrls: ['./video-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoContainerComponent {
  private _time$$ = new Subject<number>()
  private _rawMarkers$$ = new Subject<Marker[]>()
  private _markers$$ = new Subject<Marker[]>()
  private _totalTime = 0
  private _url: string

  get url() {
    return this._url
  }

  @Input() set markers(_markers) {
    this._rawMarkers$$.next(_markers)
  }
  @Input() set source(source: string) {
    const _cleanUrl = (this._url || '_').replace(/^.*&n=/, '')
    const _source = (source || '').replace(/^.*&n=/, '')

    if (_cleanUrl !== _source) {
      this._url = source
    }
  }

  @Input() withCredentials: boolean
  @Input() streamTitle: string
  @Input() debug: boolean
  @Input() formatTimeFn: (...args: unknown[]) => unknown
  @Input() showAdvancedControls: boolean
  @Input() hostElement: Element
  @Input() lang: string

  @Output() fullscreenChange = new EventEmitter<boolean>()
  @Output() playChange = new EventEmitter<boolean>()
  @Output() videoError = new EventEmitter<unknown>()
  @Output() playerReady = new EventEmitter<unknown>()
  @Output() analytics = new EventEmitter<VideoAnalyticsEvent>()

  @ViewChild('videoMarkers', { read: ElementRef }) set markersElement(element: ElementRef) {
    if (element) {
      this.videoMarkersElement = element
      this._cdr.detectChanges()
    }
  }

  @ViewChild(VideoComponent) videoPlayer: VideoComponent

  markersInjected: boolean
  videoMarkersElement: ElementRef
  markers$: Observable<Marker[]> = this._markers$$.asObservable()
  time$: Observable<number> = this._time$$.asObservable().pipe(tap((time) => (this._totalTime = time)))

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _translate: TranslateService
  ) {
    combineLatest([
      this._time$$.asObservable().pipe(filter((t) => t !== undefined && t !== Infinity)),
      this._rawMarkers$$.asObservable()
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([time, markers]) => this._handleMarkers(time, markers))
  }

  private _handleMarkers(time, markList: Marker[]): void {
    const _markers = parseMarkers(markList, time, this._translate)
    this._markers$$.next(_markers)
  }

  getCurrentTime(): number {
    return this.videoPlayer.getCurrentTime()
  }

  isFullScreen(): boolean {
    return this.videoPlayer.isFullscreen()
  }

  setPlayerTime(time: number): void {
    this.videoPlayer.setPlayerTime(time)
  }

  sendAnalytics(params: VideoAnalyticsEvent): void {
    this.analytics.emit(params)
  }

  seekToLiveEdge(): void {
    this.videoPlayer.seekToLiveEdge()
  }

  onMove({ direction, length }: AdvancedControlAction): void {
    this.videoPlayer?.playFrom(direction === 'back' ? -length : length)
  }

  onMarker(marker: Partial<Marker>): void {
    if (marker?.time <= this._totalTime && this.videoPlayer) {
      this.videoPlayer.playAt(marker.time)
    }
  }

  onTimeUpdate(time: number): void {
    this._time$$.next(time)
  }

  onError(error): void {
    this.videoError.emit(error)
  }
}
