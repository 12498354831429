import { parseStatic, parseStatics } from '@core/utils/statics.utils'
import { StaticOrientation } from '@mediacoach-ui-library/global'
import { PlayerSummary } from '@pages/player-detail/models/player-detail.models'

export const mapPlayer = (player: PlayerSummary) => ({
  ...player,
  playerId: player.playerId || player.id,
  portraitLogo: parseStatic(player.statics, StaticOrientation.Portrait),
  metricPlayerName: `${player.shirtNumber}. ${player.formattedName}`,
  state: {},
  matchdayNumber: null,
  ...parseTeams(player),
  team: player.homeTeam ? getTeam(player.homeTeam, player.awayTeam, player.teamId) : player.team
})

export const mapComparisonResult = ({ playerA, playerB, ...metrics }, payload?) => ({
  ...metrics,
  playerA: { ...mapPlayer(playerA), ...(payload ? { ...payload.playerA } : {}) },
  playerB: { ...mapPlayer(playerB), ...(payload ? { ...payload.playerB } : {}) }
})

const parseTeams = (player) => ({
  ...((player.awayTeam && { awayTeam: parseTeamStatics(player.awayTeam) }) || {}),
  ...((player.homeTeam && { homeTeam: parseTeamStatics(player.homeTeam) }) || {})
})

const parseTeamStatics = (team) => ({
  ...team,
  ...parseStatics(team.statics, [StaticOrientation.Portrait])
})

const getTeam = (homeTeam, awayTeam, teamId) =>
  homeTeam.teamId === teamId ? parseTeamStatics(homeTeam) : parseTeamStatics(awayTeam)
