import { parseStatic } from '@core/utils/statics.utils'
import { StaticOrientation } from '@core/enums/statics.enum'
import { MetricDetailConfig, MetricDetailType } from '../models/metric-detail.models'
import { ListType } from '@mediacoach-ui-library/global'
import {
  GROUPS_ITEM_KEYS,
  METRIC_DETAIL_AVERAGE_BY_POSITION,
  METRIC_ITEM_LEFT_RIGHT_MAP
} from '@features/metric-detail/constants/metric-detail.constants'
import { getLeftRightValue } from '@core/utils/team.utils'
import { MetricAggregation } from '@core/enums/metric.enums'
import { isObjectUnique, objectKeys, safeObjectData } from '@core/utils/object.utils'
import { MetricFinderKey } from '@core/models/models'

export const getTeamMetricHeader = (team: any, ops = {}) => ({
  img: parseStatic(team?.statics, StaticOrientation.Portrait),
  title: team?.shortName,
  ...ops
})

export const getMetricDetailMatchConfig = (data: any): MetricDetailConfig => {
  const {
    holder,
    metrics: { metricCategories, metricsOverview, metricsPrevious }
  } = data
  const { homeTeam, awayTeam } = holder || ({} as any)
  return {
    header: {
      leftContent: getTeamMetricHeader(homeTeam),
      rightContent: getTeamMetricHeader(awayTeam)
    },
    type: MetricDetailType.Teams,
    metrics: {
      metricCategories,
      metricsOverview,
      metricsPrevious
    }
  }
}

export const getMetricDetailTeamConfig = (data: any): MetricDetailConfig => {
  const { holder, metrics, isVsEnable, isoTypeLogo } = data || { metrics: {} }
  const {
    portraitLogo,
    logoTeamA,
    abbreviation,
    shortName,
    abbreviationTeamA,
    nameTeamA,
    abbreviationTeamB,
    nameTeamB,
    logoTeamB
  } = holder || ({} as any)

  return {
    type: isVsEnable ? MetricDetailType.Teams : MetricDetailType.TeamCompetition,
    header: {
      leftContent: {
        img: portraitLogo || logoTeamA,
        title: abbreviation || shortName || abbreviationTeamA || nameTeamA
      },
      rightContent: {
        img: abbreviationTeamB || nameTeamB ? logoTeamB : isoTypeLogo,
        title: abbreviationTeamB || nameTeamB ? abbreviationTeamB || nameTeamB : isoTypeLogo
      }
    },
    metrics: {
      metricCategories: metrics?.metricCategories,
      metricsOverview: metrics?.metricsOverview
    },
    extraData: {
      portraitLogo: portraitLogo || logoTeamA,
      formattedName: abbreviation || shortName || abbreviationTeamA || nameTeamA,
      isTeam: true
    }
  }
}

export const getMetricDetailPlayerConfig = (data: any): MetricDetailConfig => {
  const { metrics, holder, isMatch, isVsEnable, demarcation, isoTypeLogo } = data
  const {
    playerA,
    playerB,
    portraitLogo,
    metricPlayerName,
    formattedName,
    playerPosition,
    playerPositionKey,
    genericPositionKey,
    specificPositionKey,
    shirtNumber,
    team
  } = holder || ({} as any)

  return {
    type:
      isMatch && !isVsEnable
        ? MetricDetailType.Player
        : isVsEnable
          ? MetricDetailType.Players
          : MetricDetailType.PlayerCompetition,
    header: {
      leftContent: {
        img: portraitLogo || playerA?.portraitLogo,
        title: metricPlayerName || playerA?.metricPlayerName,
        isPlayer: true
      },
      rightContent: {
        img: playerB?.portraitLogo || isoTypeLogo,
        title: playerB?.metricPlayerName || isoTypeLogo,
        isPlayer: !!isMatch || !!playerB?.metricPlayerName
      }
    },
    metrics,
    extraData: {
      portraitLogo,
      demarcation,
      formattedName,
      playerPosition,
      playerPositionKey,
      genericPositionKey,
      specificPositionKey,
      shirtNumber,
      team,
      isPlayer: true
    }
  }
}

export const getMetricDetailConfigByContext = (context: MetricFinderKey) =>
  ({
    [MetricFinderKey.Match]: getMetricDetailMatchConfig,
    [MetricFinderKey.Team]: getMetricDetailTeamConfig,
    [MetricFinderKey.Player]: getMetricDetailPlayerConfig
  })[context]

const addToArray = (item: any) => (item ? [item] : [])

export const parseMetricDetail = (data: MetricDetailConfig) => {
  const { header, type, metrics, extraData } = data
  const { metricsPrevious, metricsOverview, metricCategories } = (metrics || {}) as any
  const metricsToParse = [...(metricCategories || []), ...addToArray(metricsPrevious), ...addToArray(metricsOverview)]
  const groupsItems = metricsToParse.reduce(
    (results, { groups, key: groupKey }) => [
      ...results,
      ...groups.reduce(
        (rGroups: any, group: any) => [
          ...rGroups,
          {
            ...GROUPS_ITEM_KEYS.reduce(
              (rObj, key) => ({
                ...rObj,
                [key]: (group[key] || [])
                  .map((groupItemValue: any) =>
                    objectKeys(groupItemValue).reduce(
                      (rValues, keyValue) => ({
                        ...rValues,
                        ...(METRIC_ITEM_LEFT_RIGHT_MAP[keyValue]
                          ? {
                              [METRIC_ITEM_LEFT_RIGHT_MAP[keyValue]]: groupItemValue[keyValue]
                            }
                          : { [keyValue]: groupItemValue[keyValue] })
                      }),
                      {}
                    )
                  )
                  .map((item: any) => ({
                    keyPath: `${groupKey}.${safeObjectData(item, 'key', '')}`,
                    text: safeObjectData(item, 'key', ''),
                    key: safeObjectData(item, 'key', ''),
                    ...getLeftRightValue(item, ['left', 'right'])
                  }))
              }),
              {}
            )
          }
        ],
        []
      )
    ],
    []
  )

  return {
    type,
    extraData,
    listData: processGroupsItems(groupsItems, header, extraData?.demarcation)
  }
}

export const processGroupsItems = (inputGroups = [], header: any, position: string) => {
  const outputObj = {}

  const processValues = (values: any, type: string) => {
    values.forEach((value: any) => {
      const keyPath = value.keyPath
      const group = outputObj[keyPath] || {
        type: ListType.Metrics,
        header,
        groups: []
      }

      let groupObj = group.groups[group.groups.length - 1]

      if (!groupObj) {
        groupObj = {
          items: []
        }
        group.groups.push(groupObj)
      }

      const itemToPush = { ...value, keyPath, text: type }

      if (isObjectUnique(itemToPush, groupObj.items, (a: any, b: any) => JSON.stringify(a) === JSON.stringify(b))) {
        groupObj.items.push(itemToPush)
      }

      outputObj[keyPath] = group
    })
  }

  inputGroups.forEach((group) => {
    processValues(group[MetricAggregation.MatchValues], 'MTR_COMMON_MATCH_VALUE')
    processValues(
      group[MetricAggregation.AverageValues],
      position ? METRIC_DETAIL_AVERAGE_BY_POSITION[position] : 'MTR_COMMON_AVERAGE'
    )
    processValues(group[MetricAggregation.AccumulatedValues], 'MTR_COMMON_ACCUMULATED')
  })

  return outputObj
}

export const parsePlayerItems = (items: any) => [
  { text: 'MTR_COMMON_MATCH_VALUE', value: items[0]?.homeValue, unit: items[0]?.homeUnit },
  ...items.map(({ text, awayValue, awayUnit }) => ({ text, value: awayValue, unit: awayUnit }))
]

export const parseEntityWithCompetitionItems = (items: any) => [
  { text: 'MTR_COMMON_AVERAGE', value: items[0]?.homeValue, unit: items[0]?.homeUnit },
  ...items.map(({ text, awayValue, awayUnit }, i: number) => ({
    text: !i ? 'MTR_COMMON_AVERAGE_COMPETITION' : text,
    value: awayValue,
    unit: awayUnit
  }))
]

export const parseItemsByType = (type: MetricDetailType) =>
  ({
    [MetricDetailType.Player]: parsePlayerItems,
    [MetricDetailType.PlayerCompetition]: parseEntityWithCompetitionItems,
    [MetricDetailType.TeamCompetition]: parseEntityWithCompetitionItems
  })[type]

export const parseDetailConfigByType = (detailConfig: any) => {
  const { type, data } = detailConfig
  const items = data?.groups[0]?.items
  const parseFn = parseItemsByType(type)
  return parseFn ? { ...detailConfig, data: parseFn(items) } : detailConfig
}
