<app-list
  [data]="listData"
  [itemContext]="itemContext"
  [isCollapsible]="isCollapsible"
  [aggregationHeader]="aggregationHeader"
  (aggregationModeClick)="showAggregationSelector = true"
  [longPressActive]="true"
  [isVsEnabled]="isVsEnabled"
  [showAggregationIcon]="showAggregationIcon"
  [contextMenuItems]="CONTEXT_MENU_METRIC_ITEMS"></app-list>

<app-modal
  *ngIf="showAggregationSelector"
  extraClasses="no-padding"
  [title]="'MTR_COMMON_AGGREGATION_METRICS_TITLE'"
  (onClose)="showAggregationSelector = false">
  <mcm-aggregation-selector
    [selection]="aggregationMode"
    [inMatchContext]="inMatchContext"
    [againstCompetition]="againstCompetition"
    (selectionChange)="onAggregationSelectorClose($event)"></mcm-aggregation-selector>
</app-modal>
