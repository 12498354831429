import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core'

@Directive({
  selector: '[mcmVideoResizeHandler]'
})
export class VideoResizeHandlerDirective {
  private get _element() {
    return this._host?.nativeElement
  }

  private get _minHeight() {
    return `${(window.innerWidth * 9) / 16 || 0}px`
  }

  @HostListener('window:resize')
  onResize() {
    this._renderer.setStyle(this._element, 'minHeight', this._minHeight)
  }

  constructor(
    private readonly _host: ElementRef,
    private readonly _renderer: Renderer2
  ) {
    this.onResize()
  }
}
