export enum FirebaseAnalyticsCategory {
  navigation = 'navigation_mobile',
  settings = 'settings_mobile',
  matches = 'matches_section_mobile',
  players = 'players_section_mobile',
  comparisons = 'comparisons_mobile',
  auth = 'auth_mobile',
  streaming = 'streaming_mobile',
  search = 'search_mobile'
}

export enum FirebaseAnalyticsRouteTitle {
  matchDetail = 'Match detail',
  matchDetailMetricFinder = 'Match detail | Metric Finder',
  matches = 'Matches',
  matchesCalendar = 'Matches | Calendar',
  matchesStandings = 'Matches | Standings',
  logout = 'Logout',
  login = 'Login',
  mainHome = 'Main | Home',
  mainMatches = 'Main | Matches',
  teamsComparison = 'Teams | Comparison',
  teamsMetricFinder = 'Teams | Metric Finder',
  players = 'Players',
  playersComparison = 'Players | Comparison',
  playersMetricFinder = 'Players | Metric Finder',
  more = 'Main | More',
  moreMetrics = 'More | Metrics',
  moreSuite = 'More | Suite',
  moreInfo = 'More | Info',
  moreProfile = 'More | Profile',
  moreTeamSelect = 'More | Team Select',
  moreLegal = 'More | Legal',
  moreNotifications = 'More | Notifications'
}

export enum FirebaseAnalyticsParam {
  category = 'event_category',
  version = 'version',
  environment = 'environment',
  favTeamId = 'favourite_team_id',
  favCompetitionId = 'favourite_competition_id',
  favTeamName = 'favourite_team_name',
  customerId = 'customer_id',
  customerName = 'customer_name',
  matchId = 'match_id',
  matchName = 'match_name',
  teamId = 'team_id',
  playerId = 'player_id',
  seasonId = 'season_id',
  competitionId = 'competition_id',
  vsSeasonIdA = 'season_id_A',
  vsCompetitionIdA = 'competition_id_A',
  vsTeamIdA = 'team_id_A',
  vsPlayerIdA = 'player_id_A',
  vsSeasonIdB = 'season_id_B',
  vsCompetitionIdB = 'competition_id_B',
  vsTeamIdB = 'team_id_B',
  vsPlayerIdB = 'player_id_B',
  position = 'player_position_comparison',
  searchText = 'search_text',
  menuItemId = 'menu_item_id',
  pagePath = 'page_path',
  pageTitle = 'page_title',
  pageReferrer = 'page_referrer',
  pageReferrerTitle = 'page_referrer_title',
  streamTitle = 'stream_title',
  matchDayId = 'standings_filter_match_day_id',
  teamCondition = 'standings_filter_home_away',
  avgChecked = 'match_avg_checked',
  notificationId = 'notification_id',
  notificationEnabled = 'notification_notificationEnabled'
}

export enum FirebaseAnalyticsEvent {
  pageView = 'page_view',
  changeCompetition = 'change_competition',
  changeTeam = 'change_team',
  searchMetric = 'search_metric',
  searchPlayer = 'search_player',
  logout = 'logout',
  login = 'login',
  editMetrics = 'edit_metrics',
  liveMatch = 'click_match_live',
  nextMatch = 'click_match_next',
  prevMatch = 'click_match_previous',
  menuItem = 'click_menu_item',
  standingFilters = 'click_standings_filters',
  switchAvg = 'click_switch_avg',
  clickPlayer = 'click_player',
  clickMatch = 'click_match',
  notificationSetting = 'click_notification_setting',
  play = 'play',
  pause = 'pause'
}
