export enum TitleSize {
  XXS = 'xxs',
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  XL = 'xl'
}

export enum TitleAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right'
}

export enum TitleWeight {
  Light = 'lighter',
  Normal = 'normal',
  Bold = 'bold'
}
