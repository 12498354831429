import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { NavController } from '@ionic/angular'

import { Observable } from 'rxjs'
import { delay, map, take } from 'rxjs/operators'

import { RouteUrl } from '@core/models/models'
import { trimUrl } from '../utils/main.utils'
import { CoreAuthService } from '@core/services/auth/core-auth-service'
import { Store } from '@ngrx/store'
import { isPagePermitted } from '@core/store/selectors/auth.selector'
import { authenticatedSuccess, signIn } from '@core/store/actions/auth.actions'
import { DebounceTimeType } from '@mediacoach-ui-library/global'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private readonly _ionicAuth: CoreAuthService,
    private readonly _navCtrl: NavController,
    private readonly _store: Store
  ) {}

  canActivate(): Observable<boolean> {
    return this._ionicAuth.isAuthenticated$.pipe(
      delay(DebounceTimeType.Long),
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          this._store.dispatch(signIn())
        } else {
          this._store.dispatch(authenticatedSuccess())
        }
        return isAuthenticated
      }),
      take(1)
    )
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._store.pipe(isPagePermitted(state.url)).pipe(
      delay(DebounceTimeType.Long),
      map((isPermitted) => {
        if (trimUrl(state.url) !== RouteUrl.NotPermitted && !isPermitted) {
          void this._navCtrl.navigateForward([RouteUrl.NotPermitted])
        }
        return true
      })
    )
  }
}
