// @ts-ignore
import { VideoJsPlayerOptions } from 'video.js'
import { VIDEOJS_LOCALES } from './video-locale.constants'
import { VideoMimeType } from '@shared/components/video-container/enums/video.enum'
import { Capacitor } from '@capacitor/core'

export const MIME_TYPE_BY_FORMAT = {
  [VideoMimeType.m3u8]: 'application/x-mpegURL',
  [VideoMimeType.mp4]: 'video/mp4',
  [VideoMimeType.mov]: 'video/mp4',
  [VideoMimeType.m4v]: 'video/mp4',
  [VideoMimeType.m4a]: 'audio/mp4',
  [VideoMimeType.opus]: 'video/ogg',
  [VideoMimeType.ogv]: 'video/ogg',
  [VideoMimeType.oga]: 'video/ogg',
  [VideoMimeType.mkv]: 'video/x-matroska',
  [VideoMimeType.mp3]: 'audio/mpeg',
  [VideoMimeType.aac]: 'audio/aac',
  [VideoMimeType.caf]: 'audio/x-caf',
  [VideoMimeType.flac]: 'audio/flac',
  [VideoMimeType.wav]: 'audio/wav',
  [VideoMimeType.mpd]: 'application/dash+xml'
}

export const VIDEOJS_SETTINGS: VideoJsPlayerOptions = {
  controlBar: {
    pictureInPictureToggle: Capacitor.getPlatform() !== 'android'
  },
  controls: true,
  preload: 'auto',
  liveui: true,
  fluid: true,
  inactivityTimeout: 0,
  html5: {
    nativeTextTracks: false,
    vhs: {
      enableLowInitialPlaylist: true,
      fastQualityChange: true,
      overrideNative: true
    }
  },
  languages: VIDEOJS_LOCALES
}

export const DEFAULT_STEP_TIME = 15
export const REFRESH_TIME_UPDATE = 2000
export const DIFF_TIME = 15

export const KEY_TIME_MAP = {
  alt: 60,
  shift: 30,
  default: 15
}

export const MEDIA_ERR_NETWORK = 2
