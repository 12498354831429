import { createAction, props } from '@ngrx/store'
import { TeamSummary, ViewType } from '@pages/team/models/team.models'
import { McmPlayer } from '@core/models/dto/player.dto'
import { ApiMetric } from '@core/models/metric.models'

export const fetchTeamSummary = createAction('[Team] Fetch team summary')
export const setTeamSummary = createAction('[Team] Set team summary', props<{ summary: TeamSummary }>())

export const fetchTeamData = createAction('[Team] Fetch team data', props<{ summary: TeamSummary }>())

export const fetchTeamPlayers = createAction('[Team] Fetch team players', props<{ summary: TeamSummary }>())
export const setTeamPlayers = createAction('[Team] Set team players', props<{ players: McmPlayer[] }>())

export const fetchTeamMetrics = createAction('[Team] Fetch team metrics', props<{ summary: TeamSummary }>())
export const setTeamMetrics = createAction('[Team] Set team metrics', props<{ metrics: ApiMetric }>())

export const setTeamSelectedView = createAction('[Team] set team selected view', props<{ selectedView: ViewType }>())
export const setTeamMenu = createAction('[Team] set team menu', props<{ menu: any }>())
export const setTeamSelectedMenu = createAction('[Team] set team selected menu', props<{ selectedMenu: string }>())
export const setTeamSelectedComparedMenu = createAction(
  '[Team] set team selected compared menu',
  props<{ selectedComparedMenu: string }>()
)

export const compareTeams = createAction(
  '[Team] Compare teams',
  props<{ seasonIdB: string; competitionIdB: string; teamIdB: string }>()
)
export const setTeamB = createAction('[Team] Set team B', props<{ teamB: TeamSummary }>())

export const setTeamPlayersViewLoader = createAction(
  '[Team] set players view loader',
  props<{ playersViewLoader: boolean }>()
)

export const purgeTeamData = createAction('[Team] purge team data')

export const reloadTeamData = createAction('[Team] reload data')
