import { SegmentOption } from '../segmented-control/segmented-control.models'

export const parseHeatMapData = (data): number[] => (data ? data.split('').map((el) => parseFloat(el)) : [])

export const HEAT_MAP_DEFAULT_OPTIONS: SegmentOption[] = [
  {
    id: 'global',
    label: 'MTR_HEAT_MAP_GLOBAL'
  },
  {
    id: 'offensive',
    label: 'MTR_HEAT_MAP_OFFENSIVE'
  },
  {
    id: 'defensive',
    label: 'MTR_HEAT_MAP_DEFENSIVE'
  }
]
