import { createAction, props } from '@ngrx/store'
import { ComparedPlayersData, PlayerSummary } from '@pages/player-detail/models/player-detail.models'

export const fetchPlayerMetricsFinderData = createAction('[MetricFinder] fetch player metrics data')

export const fetchFavTeamPlayerMetrics = createAction(
  '[MetricFinder] fetch fav player metrics',
  props<{
    playerId: string
    position: string
  }>()
)
export const fetchPlayerMetrics = createAction(
  '[MetricFinder] fetch player metrics',
  props<{
    player: PlayerSummary
  }>()
)
export const fetchPlayerComparisonMetrics = createAction(
  '[MetricFinder] fetch player comparison metrics',
  props<{
    players: ComparedPlayersData
  }>()
)
export const fetchMatchPlayerMetrics = createAction(
  '[MetricFinder] fetch match player metrics',
  props<{
    matchId: string
    teamId: string
    playerId: string
    position: string
  }>()
)
export const fetchMatchPlayerComparisonMetrics = createAction(
  '[MetricFinder] fetch match player comparison metrics',
  props<{
    matchId: string
    comparison: ComparedPlayersData
    position: string
  }>()
)
