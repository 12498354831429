import { Team } from '@mediacoach-ui-library/global'
import { McmTeam } from '@core/models/dto/team.dto'
import { Season } from '@core/models/dto/season.dto'
import { Competition } from '@core/models/dto/competition.dto'
import { ApiStandings } from '@core/models/team.models'
import { McmTeamMatch } from '@core/models/dto/match.dto'

export interface MCMTeam extends Team {
  seasonId: string
  competitionId: string
}

export interface TeamHolder extends Team {
  portraitLogo?: string
  abbreviation?: string
  logoTeamA?: string
  abbreviationTeamA?: string
  nameTeamA?: string
  logoTeamB?: string
  abbreviationTeamB?: string
  nameTeamB?: string
  metricsOverview?: any
  chartMetricResults?: any
  metricCategories?: any
  lastMatch?: any
  nextMatch?: any
  currentMatch?: any

  competition?: any
}

export enum ModalType {
  FavTeam = 'FavTeam',
  Vs = 'VS'
}

export enum ViewType {
  Teams = 'teams',
  Players = 'players'
}

export enum TeamTabs {
  Overview = 'MTR_METRICCATEGORY_RESUMEN'
}

export interface ComparedTeam {
  seasonId?: string
  competitionId: string
  id: string
}

export interface ComparedTeamsData {
  teamA: ComparedTeam
  teamB: ComparedTeam
}

export interface FormattedMetricsConfig {
  metricsProperty?: { key: string; groups: any[] }
  left?: { title: string; img: string; isPlayer?: boolean; subtitle?: string }
  right?: { title: string; img: string; isPlayer?: boolean; subtitle?: string }
  leftContent?: { title: string; img: string; isPlayer?: boolean; subtitle?: string }
  rightContent?: { title: string; img: string; isPlayer?: boolean; subtitle?: string }
  fromMatch?: boolean
  isSwitchable?: boolean
  switcherValues?: { id: string; text: string }[]
}

export interface TeamSummary extends McmTeam {
  season?: Season
  competition?: Competition
  standings: TeamStanding
  lastMatch: McmTeamMatch
  nextMatch: McmTeamMatch
  currentMatch?: McmTeamMatch
  refreshMetrics?: boolean
}

export type TeamStanding = ApiStandings
