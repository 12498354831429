import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { MatchItemType } from '../match-item/match-item.model'

@Component({
  selector: 'mcm-match-card-block',
  templateUrl: './match-card-block.component.html',
  styleUrls: ['./match-card-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatchCardBlockComponent {
  @Input() match: any
  @Input() title: string
  @Input() analyticsClickEvent
  @Input() extraParams
  @Input() isLiveCard

  @Output() matchCardClick = new EventEmitter<any>()

  readonly matchItemType = MatchItemType
}
