import { NgModule } from '@angular/core'
import { SharedModule } from '@shared/shared.module'
import { RankingsApi } from './api/rankings.api'
import { StoreModule } from '@ngrx/store'
import { RANKINGS_FEATURE_TOKEN } from './constants/rankings.constants'
import { rankingsReducer } from './store/reducers/ranking.reducer'
import { EffectsModule } from '@ngrx/effects'
import { RankingEffects } from './store/effects/ranking.effects'
import { RankingContainerComponent } from './ranking-container.component'
import { RankingHeaderComponent } from '@features/rankings/components/ranking-header/ranking-header.component'

@NgModule({
  declarations: [RankingContainerComponent, RankingHeaderComponent],
  exports: [RankingContainerComponent],
  imports: [
    StoreModule.forFeature(RANKINGS_FEATURE_TOKEN, rankingsReducer),
    EffectsModule.forFeature([RankingEffects]),
    SharedModule
  ],
  providers: [RankingsApi]
})
export class RankingsModule {}
