import { TEAMS } from '@mediacoach-ui-library/global'
import { MetricCategory } from '@pages/metric/metric.models'

export const getLeftRightValue = (mainObj, ops) =>
  ops.reduce(
    (obj, key, i) => ({
      ...obj,
      [`${TEAMS[i]}Value`]: (mainObj[key] || {}).value,
      [`${TEAMS[i]}Label`]: (mainObj[key] || {}).label,
      [`${TEAMS[i]}Unit`]: (mainObj[key] || {}).unit,
      [`${TEAMS[i]}Decorated`]: (mainObj[key] || {}).isDecorated
    }),
    {}
  )

export const mapSpiderChartConfig = ({
  competition,
  chartMetrics,
  team,
  playerPosition,
  playerPositionKey,
  genericPositionKey,
  specificPositionKey,
  type
}: {
  [key: string]: any
}) => ({
  list: {
    type: chartMetrics.metrics.type,
    header: chartMetrics.metrics.header,
    groups: []
  },
  chart: {
    type: type || MetricCategory.Team,
    data: chartMetrics.metrics,
    competition,
    team,
    playerPosition,
    playerPositionKey,
    genericPositionKey,
    specificPositionKey
  }
})
