import { ColumnStyle } from './table.models'

export const DEFAULT_HEADER_HEIGHT = '50px'
export const DEFAULT_ROW_HEIGHT = '49px'

export const DEFAULT_STICKY_ROW_STYLE: Partial<CSSStyleDeclaration> = {
  display: 'flex',
  background: 'white',
  boxShadow: 'inset 0 -1px 0 0 var(--mcm-color__neutral--grey--20)',
  minWidth: '45px',
  justifyContent: 'center'
}

export const DEFAULT_STICKY_COLUMN_STYLE: ColumnStyle = {
  styles: { borderRight: '1px solid' },
  width: '135px'
}
