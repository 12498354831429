import { Observable, Subscription } from 'rxjs'
import { PopoverController } from '@ionic/angular'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core'
import { ContextMenuContentComponent } from './components/context-menu-content/context-menu-content.component'

@Component({
  selector: 'mcm-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit, OnDestroy {
  private eventSubscription: Subscription

  popover: HTMLIonPopoverElement
  @Input() triggerOpen: Observable<any>
  @Output() menuContextDismiss = new EventEmitter()
  @ViewChild('template', { static: true }) template: TemplateRef<any>

  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit() {
    this.eventSubscription = this.triggerOpen.subscribe(async (event) => await this.present(this.template, event))
  }

  ngOnDestroy() {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe()
      this.eventSubscription = null
    }
  }

  async present(child, event) {
    this.popover = await this.popoverCtrl.create({
      component: ContextMenuContentComponent,
      componentProps: {
        template: child
      },
      translucent: true,
      side: 'top',
      alignment: 'end',
      event
    })
    this.popover.onDidDismiss().then(() => this.menuContextDismiss.emit())
    return await this.popover.present()
  }
}
