import { ApplicationMenuModels } from '@mediacoach-ui-library/global'

export interface Permission {
  regex: RegExp
  deepLength: number
  isDefaultRoute: boolean
}

export interface UserProfile {
  id: string
  name: string
  surname: string
  fullName: string
  language: string
  email: string
  humanizedRoles: string
  permissions: Permission[]
  cachedDateTime?: string
  userId?: string
  favouriteCompetitionId: string
  favouriteTeamId: string
  favourites: {
    competitionId: string
    teamId: string
  }
  profiles: ApplicationMenuModels[]
  customerTeamTranslationKey?: string
  photoPath?: string

  // new analytics fields
  favouriteTeamName?: string
  customerId?: string
  customerName?: string // already received
  color?: string
  avatarPath?: string
  avatar?: string
  clubTeamLogoUrl?: string
}

export enum AuthError {
  UserDisallowed = 'user_disallowed_for_client',
  InvalidGrant = 'invalid_grant',
  InvalidRequest = 'invalid_request'
}
