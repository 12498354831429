import { Injectable } from '@angular/core'
import { AppTrackingStatus, AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency'
import { Capacitor } from '@capacitor/core'

@Injectable({ providedIn: 'root' })
export class AppTrackingTransparencyService {
  async isGranted(): Promise<boolean> {
    if (Capacitor.getPlatform() === 'ios') {
      let status = await this.getStatus()
      if (status !== 'denied' && status !== 'authorized') {
        status = await this._requestPermission()
      }

      if (status !== 'authorized') {
        console.log('[ATT] permission NOT granted!')
        return Promise.resolve(false)
      }
      return Promise.resolve(true)
    }
    return Promise.resolve(true)
  }

  async getStatus(): Promise<AppTrackingStatus> {
    if (Capacitor.getPlatform() === 'ios') {
      const { status } = await AppTrackingTransparency.getStatus()
      console.log('[ATT] status:', status)
      return status
    }
    return 'authorized'
  }

  private async _requestPermission(): Promise<AppTrackingStatus> {
    console.log('[ATT] requesting permission')
    const { status } = await AppTrackingTransparency.requestPermission()
    console.log('[ATT] requested permission result:', status)
    return status
  }
}
