import { createAction, props } from '@ngrx/store'
import { IAuthAction } from 'ionic-appauth/lib/auth-action'

export const authenticatedSuccess = createAction('[Authenticated] Load initial data (profile)')
export const onAuthEventChange = createAction(
  '[AUTH] On auth event change',
  props<{
    event: IAuthAction
  }>()
)
export const authorizationCallback = createAction('[AUTH] Authorization callback', props<{ url: string }>())
export const endSessionCallback = createAction('[AUTH] End session callback', props<{ navigateUrl?: string }>())
export const signIn = createAction('[AUTH] SignIn')
export const signInSuccess = createAction('[AUTH] SignIn success')
export const signInFailed = createAction('[AUTH] SignIn failed', props<{ error: any }>())
export const signOut = createAction('[AUTH] SignOut', (state?: string) => ({ state }))
export const signOutSuccess = createAction('[AUTH] SignOut success')
export const signOutFailed = createAction('[AUTH] SignOut failed', props<{ error: any }>())
