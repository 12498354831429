import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ITUNES_LOOKUP_BASEURL } from '../constants/updater.constants'

@Injectable({ providedIn: 'root' })
export class UpdaterApi {
  constructor(private readonly _http: HttpClient) {}

  getIOSBundleIdMetadata(bundleId: string) {
    return this._http.get(`${ITUNES_LOOKUP_BASEURL}${bundleId}&date=${new Date().getTime()}`)
  }
}
