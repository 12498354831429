import { RankedMetric, RankingHeader, RankingRequest } from '@features/rankings/models/ranking.model'
import { MetricAggregation } from '@core/enums/metric.enums'
import { RANKING_AGGREGATION_TO_METRIC_TYPE_MAP } from '@features/rankings/constants/rankings.constants'
import { parseCommonRankingRow } from '@features/rankings/utils/ranking.utils'
import { PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'
import { LA_LIGA_LIST_NAME_CAPITALIZED } from '@core/constants/constants'

export const parsePlayerToRankingRequest = (context: any, value: any, mode: MetricAggregation): RankingRequest => ({
  seasonId: context.seasonId,
  competitionId: context.competitionId,
  metricName: value.key,
  metricType: RANKING_AGGREGATION_TO_METRIC_TYPE_MAP[mode],
  players: [{ playerId: context.id, teamId: context.teamId }]
})

export const parsePlayerMatchToRankingRequest = (
  context: any,
  value: any,
  mode: MetricAggregation
): RankingRequest => ({
  seasonId: context.seasonId,
  competitionId: context.competitionId,
  metricName: value.key,
  metricType: RANKING_AGGREGATION_TO_METRIC_TYPE_MAP[mode],
  players: [{ playerId: context.playerId, teamId: context.team.teamId }],
  matchId: context.id
})

export const parsePlayerRankingRows = (rankedMetric: RankedMetric[], context): RankedMetric[] => {
  const selectedValues = (rankedMetric || []).filter((m) => m.isSelected)

  return (rankedMetric || []).map((m) => {
    const { metric } = parseCommonRankingRow(m, context)
    const _id = context.playerA?.playerId || context.playerId || context.id
    return {
      ...metric,
      imagePlaceholder: PLACEHOLDER_IMAGES.PLAYER,
      teamPlaceholder: PLACEHOLDER_IMAGES.TEAM,
      position: metric.isCompetition ? '-' : m.position,
      styleClass: [
        'mcm-ranking__row',
        !metric.isCompetition ? 'avatar' : null,
        m.isSelected ? 'selected' : null,
        m.id === _id ? 'home' : null,
        selectedValues.length > 1 && m.id === selectedValues[0].id ? 'first-selected' : null,
        selectedValues.length > 1 && m.id === selectedValues[1].id ? 'last-selected' : null
      ]
        .filter(Boolean)
        .join(' ')
    }
  })
}

export const parsePlayerRankingHeader = (metricKey: string, metricContext: any, matchDay: number): RankingHeader => ({
  title: metricKey,
  imageA: metricContext.portraitLogo,
  textA: metricContext.metricPlayerName,
  imageAStyleClass: 'mcm-rankings__avatar',
  imageB: metricContext?.competition?.isoTypeLogo,
  textB: LA_LIGA_LIST_NAME_CAPITALIZED,
  placeholderA: PLACEHOLDER_IMAGES.PLAYER,
  placeholderB: PLACEHOLDER_IMAGES.PLAYER,
  matchDay
})

export const parseMatchPlayerRankingHeader = (
  metricKey: string,
  metricContext: any,
  matchDay: number
): RankingHeader => ({
  title: metricKey,
  imageA: metricContext.portraitLogo,
  textA: metricContext.metricPlayerName,
  imageAStyleClass: 'mcm-rankings__avatar',
  textB: 'MTR_TITLE_MATCH',
  placeholderA: PLACEHOLDER_IMAGES.PLAYER,
  matchDay
})
