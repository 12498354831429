import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'
import { TranslateService } from '@ngx-translate/core'
import { FileUploadType } from '@shared/components/file-upload/enums/file-upload.enums'
import { AvatarProps } from '@shared/components/file-upload/models/file-upload.models'
import { bytesToSize, readFile } from './utils/file-upload.utils'
import { MIN_FILE_SIZE } from './constants/file-upload.constants'
import { FileUploadBaseComponent } from '@shared/components'

@UntilDestroy()
@Component({
  selector: 'mcm-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  #translate = inject(TranslateService)

  private _onChange: (data) => void
  private _onTouch: (data) => void
  private _fileLimit
  private _acceptTypes = ['jpeg', 'jpg', 'png']
  private _maxFileSize = 5120 * 1024
  private _minFileSize = MIN_FILE_SIZE

  @ViewChild('fileUpload', { static: true }) public fileUpload: FileUploadBaseComponent

  get fileLimit() {
    return this._fileLimit
  }

  @Input() set fileLimit(_fileLimit) {
    this._fileLimit = _fileLimit
    this.invalidFileLimitMessageDetail = this.#translate.instant('MTR_COMMON_ERROR_UPLOAD_LIMIT', {
      limit: _fileLimit
    })
  }

  get acceptTypes() {
    return this._acceptTypes
  }

  @Input() set acceptTypes(_acceptTypes) {
    this._acceptTypes = _acceptTypes
    this.invalidFileTypeMessageDetail = this.#translate.instant('MTR_COMMON_ERROR_UPLOAD_TYPE', {
      types: (_acceptTypes || []).join(', ')
    })
  }

  get maxFileSize() {
    return this._maxFileSize
  }

  @Input() set maxFileSize(_maxFileSize) {
    this._maxFileSize = _maxFileSize
    this.invalidMaxFileSizeMessageDetail = this.#translate.instant('MTR_COMMON_ERROR_UPLOAD_SIZE', {
      size: bytesToSize(_maxFileSize)
    })
  }

  get minFileSize() {
    return this._minFileSize
  }

  @Input() set minFileSize(_minFileSize) {
    this._minFileSize = _minFileSize
    this.invalidMinFileSizeMessageDetail = this.#translate.instant(
      'MTR_COMMON_ERROR_UPLOAD_MIN_SIZE',
      { minSize: bytesToSize(_minFileSize) }
    )
  }

  @Input() accept = 'image/jpeg'
  @Input() base64Value: string
  @Input() blobToType: 'readAsDataURL' | 'readAsText' = 'readAsDataURL'
  @Input() loading = false
  @Input() formControlOriginalFile = false
  @Input() avatarProps?: AvatarProps
  @Input() set disable(ds: boolean) {
    this.setDisabledState(ds)
  }

  @Output() errorsEvent = new EventEmitter<any>()
  @Output() filesSelectedEvent = new EventEmitter()

  disabled = false
  invalidMaxFileSizeMessageDetail: string
  invalidMinFileSizeMessageDetail: string
  invalidFileTypeMessageDetail: string
  invalidFileLimitMessageDetail: string
  AVATAR_PLACEHOLDER_IMAGE = PLACEHOLDER_IMAGES.AVATAR
  fileUploadType = FileUploadType

  private async _applyOnChange(files) {
    if (this.formControlOriginalFile && this._onChange) {
      this._onChange(files)
    } else {
      this.base64Value = (await readFile(files[0], this.blobToType).toPromise()) as any
      if (this._onChange) {
        this._onChange(this.base64Value)
      }
    }
  }

  ngOnInit() {
    this.#translate
      .stream(
        [
          'MTR_COMMON_ERROR_UPLOAD_SIZE',
          'MTR_COMMON_ERROR_UPLOAD_TYPE',
          'MTR_COMMON_ERROR_UPLOAD_LIMIT',
          'MTR_COMMON_ERROR_UPLOAD_MIN_SIZE',
        ],
        {
          types: (this.acceptTypes || []).join(', '),
          size: bytesToSize(this.maxFileSize),
          limit: this.fileLimit,
          minSize: bytesToSize(MIN_FILE_SIZE),
        },
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        ({
          MTR_COMMON_ERROR_UPLOAD_SIZE,
          MTR_COMMON_ERROR_UPLOAD_TYPE,
          MTR_COMMON_ERROR_UPLOAD_LIMIT,
          MTR_COMMON_ERROR_UPLOAD_MIN_SIZE,
        }) => {
          this.invalidMaxFileSizeMessageDetail = MTR_COMMON_ERROR_UPLOAD_SIZE
          this.invalidFileTypeMessageDetail = MTR_COMMON_ERROR_UPLOAD_TYPE
          this.invalidFileLimitMessageDetail = MTR_COMMON_ERROR_UPLOAD_LIMIT
          this.invalidMinFileSizeMessageDetail = MTR_COMMON_ERROR_UPLOAD_MIN_SIZE
        },
      )
  }

  ngAfterViewInit() {
    this.fileUpload
      .getBlockableElement()
      .querySelector('input[type="file"]')
      .setAttribute('title', '')
  }

  onPreselect() {
    this.errorsEvent.next(this.fileUpload.msgs.map(({ detail }) => detail))
  }

  registerOnChange(fn: any): void {
    this._onChange = fn
  }

  async onSelectFn(e) {
    if (!!this._fileLimit && e.files.length === this._fileLimit) {
      this.disabled = true
    } else if (!!this._fileLimit && e.files.length > this._fileLimit) {
      this.disabled = true
      e.files.splice(this._fileLimit, e.files.length - this._fileLimit)
      this.errorsEvent.next([this.invalidFileLimitMessageDetail])
    } else {
      this.disabled = false
      this.errorsEvent.next([])
    }

    this.filesSelectedEvent.next(e.files)

    await this._applyOnChange(e.files)
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  writeValue(value: any): void {
    this.base64Value = value
    if (!value) {
      this.fileUpload.clear()
    }
  }

  async removeFile(file: File, uploader: FileUploadBaseComponent) {
    const index = uploader.files.indexOf(file)
    uploader.remove(new Event('remove'), index)
    await this.onSelectFn({ files: uploader.files })
  }
}
