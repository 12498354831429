import { Injectable } from '@angular/core'
import { ModalController, ModalOptions, Platform } from '@ionic/angular'
import { Observable } from 'rxjs'
import { PlatformType } from '@core/models/models'
import { StatusBar } from '@capacitor/status-bar'
import { fromPromise } from 'rxjs/internal/observable/innerFrom'

@Injectable({
  providedIn: 'root'
})
export class McmModalControllerService extends ModalController {
  constructor(private _platform: Platform) {
    super()
  }

  create(opts: ModalOptions): Promise<HTMLIonModalElement> {
    this._hideStatusBar()
    return super.create(opts)
  }

  dismiss(data?: any, role?: string, id?: string): Promise<boolean> {
    if (this._platform.is(PlatformType.Android)) {
      StatusBar.show().catch((error) => console.error(error))
    }
    return super.dismiss(data, role, id)
  }

  createObs(opts: ModalOptions): Observable<HTMLIonModalElement> {
    this._hideStatusBar()
    return fromPromise(this.create(opts))
  }

  private _hideStatusBar() {
    if (this._platform.is(PlatformType.Android)) {
      StatusBar.hide().catch((error) => console.error(error))
    }
  }
}
