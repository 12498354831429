<div class="mcm-layout__block">
  <app-list [data]="aggregationModes" (onItemSelected)="checkSelection($event)" [customTemplate]="itemTpl"></app-list>
  <ng-template #itemTpl let-item>
    <div [class.selected]="item.id === selection">
      <app-title
        [text]="item.text"
        [alignment]="TitleAlignment.Center"
        [size]="TitleSize.SM"
        [weight]="TitleWeight.Bold"></app-title>
    </div>
  </ng-template>
</div>
<div class="mcm-layout__block mcm-player-selection__footer">
  <app-button
    text="MTR_COMMON_SELECT"
    extraClasses="mcm-button--gradient mcm-button--block"
    [disabled]="!selection || isDisabled"
    (onClick)="onSelected(selection)">
  </app-button>
</div>
