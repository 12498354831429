<div
  [mcmAnalyticsClickEvent]="analyticsClickEvent"
  [extraParams]="extraParams"
  [matchParam]="match"
  (click)="matchCardClick.emit(match)">
  <div class="mcm-layout__block-header">
    <app-title [text]="title"></app-title>
    <i class="mcm-match-card-block__icon mcm-match-card-block__icon--action u-icon--dart-right-squared"></i>
  </div>
  <div [class.mcm-match-card-block__live]="isLiveCard">
    <app-match-item [match]="match" [type]="matchItemType.FullListed"></app-match-item>
  </div>
</div>
