import { Directive, HostListener, Input } from '@angular/core'
import { FirebaseAnalyticsService } from '@core/services/analytics/firebase/firebase-analytics.service'
import {
  FirebaseAnalyticsEvent,
  FirebaseAnalyticsParam
} from '@core/services/analytics/firebase/enums/firebase-analytics.enums'
import { DEFAULT_PARAMS_PER_EVENT } from '@core/services/analytics/firebase/constants/firebase-analytics.constants'
import { IMatch, Season } from '@mediacoach-ui-library/global'

@Directive({
  selector: '[mcmAnalyticsClickEvent]'
})
export class AnalyticsClickEventDirective {
  @Input('mcmAnalyticsClickEvent') eventName: FirebaseAnalyticsEvent
  @Input() extraParams: { key: FirebaseAnalyticsParam; value: string }[]
  @Input() matchParam: IMatch & { season: Season }

  constructor(private readonly _analytics: FirebaseAnalyticsService) {}

  @HostListener('click')
  onClick() {
    let extra = (this.extraParams || []).reduce(
      (obj, param) => ({
        ...obj,
        [param.key]: param.value
      }),
      {}
    )
    const { name, params } = DEFAULT_PARAMS_PER_EVENT[this.eventName]
    if (this.matchParam) {
      extra = {
        ...extra,
        [FirebaseAnalyticsParam.matchName]: [
          this.matchParam?.competition?.officialName,
          `T${this.matchParam?.season?.officialName.replace(/\s-\s\d{2}/, '-')}`,
          `J${this.matchParam?.matchdayNumber}`,
          `${this.matchParam?.homeTeam.abbreviation}-${this.matchParam?.awayTeam.abbreviation}`
        ].join(' | ')
      }
    }
    this._analytics.logEvent(name, { ...params, ...extra })
  }
}
