import { createFeatureSelector, createSelector } from '@ngrx/store'
import { Params } from '@angular/router'
import { MergedRoute, MergedRouteReducerState } from '@core/ngrx-router/models/ngrx-router.models'
import { ROUTER_FEATURE_TOKEN } from '@core/ngrx-router/constants/ngrx-router.constants'

export const selectRouterReducerState = createFeatureSelector<MergedRouteReducerState>(ROUTER_FEATURE_TOKEN)

export const selectMergedRoute = createSelector(
  selectRouterReducerState,
  (mergedRouteState): MergedRoute => mergedRouteState?.state
)

export const selectMergedRouteParams = createSelector(selectMergedRoute, (mergedRoute): Params => mergedRoute?.params)

export const selectMergedRouteData = createSelector(selectMergedRoute, (mergedRoute): Params => mergedRoute?.data)

export const selectMergedRouteUrl = createSelector(selectMergedRoute, (mergedRoute): string => mergedRoute?.url)

export const selectMergedRouteQueryParams = createSelector(
  selectMergedRoute,
  (mergedRoute): Params => mergedRoute?.queryParams
)
