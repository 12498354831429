import { MetricAggregation } from '@core/enums/metric.enums'
import { RankingHeader, RankingRequest } from '@features/rankings/models/ranking.model'
import { RANKING_AGGREGATION_TO_METRIC_TYPE_MAP } from '@features/rankings/constants/rankings.constants'
import { PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'

export const parseTeamComparisonToRankingRequest = (
  context: any,
  value: any,
  mode: MetricAggregation
): RankingRequest => ({
  seasonId: context.teamA.seasonId,
  competitionId: context.teamA.competitionId,
  metricName: value.key,
  metricType: RANKING_AGGREGATION_TO_METRIC_TYPE_MAP[mode],
  teams: [context.teamA.id, context.teamB.id]
})

export const parseTeamComparisonMetricFinderToRankingRequest = (
  context,
  value,
  mode: MetricAggregation
): RankingRequest => ({
  seasonId: context.teamA.seasonId,
  competitionId: context.teamA.competitionId,
  metricName: value.key,
  metricType: RANKING_AGGREGATION_TO_METRIC_TYPE_MAP[mode],
  teams: [context.teamA.id, context.teamB.id]
})

export const parseTeamComparisonRankingHeader = (
  metricKey: string,
  metricContext: any,
  matchDay: number
): RankingHeader => ({
  title: metricKey,
  imageA: metricContext.logoTeamA,
  textA: metricContext.nameTeamA,
  imageB: metricContext.logoTeamB,
  textB: metricContext.nameTeamB,
  placeholderA: PLACEHOLDER_IMAGES.TEAM,
  placeholderB: PLACEHOLDER_IMAGES.TEAM,
  matchDay
})
