import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'

import { MetricFinderKey, RouteUrl } from '@core/models/models'
import { AuthGuard } from '@core/guards/auth.guard'
import { LoginGuard } from '@core/guards/login.guard'
import { UpdateVersionGuard } from '@core/guards/update-version.guard'
import { UserDisallowedGuard } from '@core/guards/user-disallowed.guard'
import { ErrorType } from '@mediacoach-ui-library/global'
import { GTMCategories } from '@core/enums/gtm.enums'
import { FirebaseAnalyticsRouteTitle } from '@core/services/analytics/firebase/enums/firebase-analytics.enums'

const routes: Routes = [
  {
    path: '',
    redirectTo: RouteUrl.Login,
    pathMatch: 'full'
  },
  {
    path: RouteUrl.Auth,
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthPageModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard, UpdateVersionGuard],
    children: [
      {
        path: RouteUrl.Main,
        loadChildren: () => import('./pages/main/main.module').then((m) => m.MainPageModule)
      },
      {
        path: 'compare/seasons/:seasonId/competitions/:vsCompetitionId/teams',
        children: [
          {
            path: '',
            redirectTo: `/${RouteUrl.Home}`,
            pathMatch: 'full'
          },
          {
            path: ':vsTeamId',
            loadChildren: () => import('./pages/team/team.module').then((m) => m.TeamPageModule),
            data: {
              title: FirebaseAnalyticsRouteTitle.teamsComparison,
              gtmCategory: GTMCategories.TeamCompared,
              aggregationContext: MetricFinderKey.Team
            }
          }
        ]
      },
      {
        path: RouteUrl.Players,
        loadChildren: () => import('./pages/player-detail/player-detail.module').then((m) => m.PlayerDetailPageModule)
      },
      {
        path: 'seasons/:seasonId/competitions/:competitionId/teams/:teamId/players',
        loadChildren: () => import('./pages/player-detail/player-detail.module').then((m) => m.PlayerDetailPageModule),
        data: {
          gtmCategory: GTMCategories.Player
        }
      },
      {
        path: RouteUrl.Matches,
        loadChildren: () => import('./pages/matches/matches.module').then((m) => m.MatchesPageModule)
      },
      {
        path: RouteUrl.More,
        loadChildren: () => import('./pages/more/more.module').then((m) => m.MorePageModule)
      },
      {
        path: RouteUrl.NotPermitted,
        loadChildren: () =>
          import('./pages/error-feedback/error-feedback.module').then((m) => m.ErrorFeedbackPageModule),
        data: { errorType: ErrorType.Forbidden }
      },
      {
        path: RouteUrl.NetworkTimeout,
        loadChildren: () =>
          import('./pages/error-feedback/error-feedback.module').then((m) => m.ErrorFeedbackPageModule),
        data: { errorType: ErrorType.Custom, message: 'MTR_NETWORK_TIMEOUT_ERROR' }
      },
      {
        path: RouteUrl.Notifications,
        loadChildren: () => import('./pages/notifications/notifications.module').then((m) => m.NotificationsPageModule)
      }
    ]
  },
  {
    path: RouteUrl.UpdateVersion,
    canActivate: [UpdateVersionGuard],
    loadChildren: () => import('./pages/error-feedback/error-feedback.module').then((m) => m.ErrorFeedbackPageModule),
    data: { errorType: ErrorType.UpdateVersion }
  },
  {
    path: RouteUrl.UserDisallowed,
    canActivate: [UserDisallowedGuard],
    loadChildren: () => import('./pages/error-feedback/error-feedback.module').then((m) => m.ErrorFeedbackPageModule),
    data: { errorType: ErrorType.UserDisallowed }
  },
  {
    path: RouteUrl.Concurrency,
    loadChildren: () => import('./pages/error-feedback/error-feedback.module').then((m) => m.ErrorFeedbackPageModule),
    data: { errorType: ErrorType.UserConcurrence }
  },
  {
    path: RouteUrl.Login,
    canActivate: [LoginGuard],
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule)
  },
  {
    path: '**',
    loadChildren: () => import('./pages/error-feedback/error-feedback.module').then((m) => m.ErrorFeedbackPageModule),
    data: { errorType: ErrorType.NotFound }
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
