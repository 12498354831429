import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  Output,
  Renderer2
} from '@angular/core'

import { Subject, timer } from 'rxjs'
import { map, switchMap, take, tap } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { debounce } from '@shared/decorators/debounce.decorator'

const MIN_HEADER_HEIGHT_DEFAULT = 96

@UntilDestroy()
@Directive({
  selector: '[mcmHeaderCollapse]',
  exportAs: 'mcmHeaderCollapse'
})
export class HeaderCollapseDirective {
  @Input() minHeight = MIN_HEADER_HEIGHT_DEFAULT
  @Output() onScroll = new EventEmitter()
  @HostBinding('style.overflow') overflow = 'hidden'
  @HostBinding('class.is-header-collapse') isHeaderCollapse = true
  scrollElem: HTMLElement

  private _isCollapsed: boolean
  private _hasScrolled: boolean
  private _height: number
  private _content
  private _headerOriginalHeight: number
  private _paddingTop$$ = new Subject<void>()
  private _maxHeight = 0

  constructor(
    private _el: ElementRef,
    private _ref: ChangeDetectorRef,
    private readonly _renderer: Renderer2,
    private readonly _ngZone: NgZone
  ) {
    this._renderer.setStyle(this._el.nativeElement, 'transition', 'height ease 0.3s')
  }

  @HostBinding('class.is-collapsed') get isCollapsed() {
    return this._isCollapsed
  }

  @HostBinding('class.has-scrolled') get hasScrolled() {
    return this._hasScrolled
  }

  @HostBinding('style.height.px') get height() {
    return this._height
  }

  @HostBinding('style.min-height.px') get minHeightFn() {
    return this.minHeight
  }

  get maxScrollHeight() {
    return this._el?.nativeElement.scrollHeight
  }

  get offsetHeight() {
    return this._el?.nativeElement.offsetHeight
  }

  get content() {
    return this._content
  }

  @Input('mcmHeaderCollapse') set content(_content) {
    this._setContentConfig(_content)
  }

  @Input() set loading(loading) {
    if (!loading) {
      this._ngZone.onMicrotaskEmpty.pipe(take(1)).subscribe(() => {
        this._headerOriginalHeight = this._el.nativeElement.getBoundingClientRect().height
        this._maxHeight = Math.max(this._headerOriginalHeight, this._maxHeight)
        this._paddingTop$$.next()
      })
    }
  }

  expand() {
    this._isCollapsed = false
    this._hasScrolled = false
    this._height = Math.max(this._headerOriginalHeight, this._maxHeight)
    this._content.el.style.cssText = `--padding-top: ${Math.max(this._headerOriginalHeight, this._maxHeight)}px`
  }

  collapse() {
    this._isCollapsed = true
    this._hasScrolled = false
    this._height = this.minHeight
    this._content.el.style.cssText = `--padding-top: ${this.minHeight}px`
  }

  private _setContentConfig(_content) {
    let isFirst = false
    if (!this._content && _content) {
      this._content = _content

      this._content.getScrollElement().then((scrollElem) => (this.scrollElem = scrollElem))
      // FIXME if ios has the scrolling bug
      // this._content.forceOverscroll = false
      this._content.scrollEvents = true
      this._content.ionScroll
        .pipe(
          map(({ detail }) => detail.scrollTop),
          tap((scrollTop: number) => {
            this.onScroll.emit(scrollTop)

            this._isCollapsed = this.maxScrollHeight && scrollTop >= this.maxScrollHeight - this.minHeight
            this._hasScrolled = scrollTop && !this.isCollapsed
            this._height = scrollTop
              ? this.maxScrollHeight && this.scrollElem && Math.max(0, this.maxScrollHeight - scrollTop)
              : null
          }),
          tap(() => {
            if (!isFirst) {
              isFirst = true
              this._ref.detectChanges()
              this._content.el.style.cssText = `--padding-top: ${Math.max(this.offsetHeight, this._maxHeight)}px`
            }
          }),
          untilDestroyed(this)
        )
        .subscribe()

      this._paddingTop$$
        .pipe(
          take(1),
          switchMap(() => timer(100)),
          tap(() => {
            this._content.el.style.cssText = `--padding-top: ${Math.max(this.offsetHeight, this._maxHeight)}px`
          }),
          untilDestroyed(this)
        )
        .subscribe()
    }
  }

  @debounce(0)
  private _updatePaddingTop() {
    this._ref.detectChanges()
    this._content.el.style.cssText = `--padding-top: ${Math.max(this.offsetHeight, this._maxHeight)}px`
  }
}
