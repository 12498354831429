import { deepEqual } from '@core/utils/object.utils'

export const arrEqual = (x: any, y: any) => {
  if (x instanceof Array && y instanceof Array) {
    if (x.length !== y.length) {
      return false
    }
    for (let i = 0; i < x.length; i++) {
      if (!arrEqual(x[i], y[i])) {
        return false
      }
    }
    return true
  } else if (typeof x === 'object' && typeof y === 'object') {
    return deepEqual(x, y)
  } else {
    return x === y
  }
}

export const deepCloneArray = <T = unknown>(array: T[] = []): T[] => {
  const clone = []
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < array.length; i++) {
    clone.push({ ...array[i] })
  }
  return clone
}
