import { Injectable } from '@angular/core'

import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { fetchCurrentSeason } from '@core/store/actions/season.actions'

@Injectable()
export class SeasonResolver {
  constructor(private readonly _store: Store) {}

  resolve(): Observable<void> | Promise<void> | void {
    this._store.dispatch(fetchCurrentSeason())
    return of(null)
  }
}
