import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-competition-selector',
  templateUrl: './competition-selector.component.html',
  styleUrls: ['./competition-selector.component.scss']
})
export class CompetitionSelectorComponent {
  @Input() competition
  @Input() teamId
  @Input() qaId
  @Output() onCompetitionSelect = new EventEmitter()

  showModal = false

  onTeamSelected(event) {
    this.onCompetitionSelect.emit(event)
    this.showModal = false
  }
}
