import { Injectable } from '@angular/core'
import { UpdaterApi } from '@core/services/updater/api/updater.api'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  fetchIOsBundleMetadata,
  notifyAndRedirectToAppStore,
  setIOSBundleMetadata
} from '@core/services/updater/store/actions/updater.actions'
import { filter, map, mergeMap, switchMap } from 'rxjs/operators'
import { IOSBundleMetadata } from '@core/services/updater/models/updater.models'
import { Action } from '@ngrx/store'
import { isNewerVersion } from '@core/services/updater/utils/updater.utils'
import { TranslateService } from '@ngx-translate/core'
import { APP_STORE_DEEPLINK_BASEURL, UPDATER_DIALOG_I18N_KEYS } from '@core/services/updater/constants/updater.constants'
import { from } from 'rxjs'
import { Dialog } from '@capacitor/dialog'
import { AppLauncher } from '@capacitor/app-launcher'
import { App } from '@capacitor/app'
import { environment } from '@env'

@Injectable()
export class UpdaterEffects {

  fetchIOsBundleMetadata$ = createEffect(() =>
    { return this._actions$.pipe(
      ofType(fetchIOsBundleMetadata),
      switchMap(({bundleId}) => from(App.getInfo()).pipe(map(info => ({info, bundleId})))),
      switchMap(({bundleId, info}) => this._api.getIOSBundleIdMetadata(bundleId || environment.bundleId || info.id).pipe(
        map((response: any) => {
          try {
            const metadata = response?.results[0]
            return {
              fileSizeBytes: metadata.fileSizeBytes,
              trackViewUrl: metadata.trackViewUrl,
              releaseDate: metadata.releaseDate,
              bundleId: metadata.bundleId,
              currentVersionReleaseDate: metadata.currentVersionReleaseDate,
              trackId: metadata.trackId,
              version: metadata.version,
            } as IOSBundleMetadata
          }catch (e){
            console.warn(`BundleID ${bundleId} not found`, e)
          }
        }),
        filter(metadata => !!metadata),
        map(metadata => ({metadata, currentVersion: info.version}))
      )),
      mergeMap(({metadata: ios, currentVersion}) => {
        const actions: Action[] = [setIOSBundleMetadata({ios})]
        if(isNewerVersion(ios.version, currentVersion)){
          actions.push(notifyAndRedirectToAppStore({ios}))
        }
        return actions
      })
    ) }
  )

  notifyAndRedirectToAppStore$ = createEffect(() => { return this._actions$.pipe(
    ofType(notifyAndRedirectToAppStore),
    switchMap(({ios}) => this._translate.get(UPDATER_DIALOG_I18N_KEYS).pipe(
      map(i18n => Object.values(i18n)),
      switchMap(([title, message, buttonTitle]: [string, string, string]) => from(Dialog.alert({title, message, buttonTitle}))),
      switchMap(() => from(AppLauncher.openUrl({url: `${APP_STORE_DEEPLINK_BASEURL}${ios.trackId}`})))
    )),

  ) }, {dispatch: false})

  constructor(
    private readonly _api: UpdaterApi,
    private readonly _translate: TranslateService,
    private readonly _actions$: Actions) {
  }

}
