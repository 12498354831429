<div class="w-spider-chart">
  <svg
    height="100%"
    width="100%"
    preserveAspectRatio="none"
    [attr.viewBox]="'0 0 ' + width + ' ' + height"
    #spiderChartSvg>
    <defs>
      <filter [id]="id + '--shadow'" x="-20%" y="-20%" width="200%" height="200%" style="x: -11%">
        <feDropShadow
          dx="1"
          dy="1"
          stdDeviation="1"
          flood-color="#000"
          style="flood-color: rgba(0, 0, 0, 0.2)"></feDropShadow>
      </filter>
      <linearGradient
        *ngFor="let category of [0, 1]"
        spreadMethod="pad"
        [id]="id + '--grad-' + category"
        x1="0%"
        y1="0%"
        x2="0%"
        y2="100%">
        <stop offset="0%"></stop>
        <stop offset="100%"></stop>
      </linearGradient>
    </defs>
    <g *ngIf="!loading && params" [attr.transform]="'translate(' + params.margin.left + ',' + params.margin.top + ')'">
      <g class="w-spider-chart__helper-line-groups">
        <ng-container *ngFor="let levelLines of helperLineData">
          <g *ngFor="let line of levelLines" class="w-spider-chart__helper-line-group">
            <line
              class="w-spider-chart__helper-line"
              [attr.x1]="line.x1"
              [attr.y1]="line.y1"
              [attr.x2]="line.x2"
              [attr.y2]="line.y2"
              [attr.transform]="line.transform"></line>
          </g>
        </ng-container>
      </g>
      <g class="w-spider-chart__axis-group">
        <g *ngFor="let key of axisKeys; let i = index" class="w-spider-chart__axis" fill="none">
          <ng-container *ngIf="axisData[i] as axis">
            <line
              class="w-spider-chart__axis--line"
              [attr.x1]="axis.x1"
              [attr.y1]="axis.y1"
              [attr.x2]="axis.x2"
              [attr.y2]="axis.y2"></line>
            <circle
              class="w-spider-chart__axis--circle"
              [attr.r]="axis.r"
              [attr.cx]="axis.x2"
              [attr.cy]="axis.y2"
              (click)="openMetricModal(key)"></circle>
          </ng-container>
        </g>
      </g>
      <g
        class="w-spider-chart__key-groups"
        [attr.transform]="'translate(' + -this.params.keyTranslation.x + ',' + -this.params.keyTranslation.y + ')'">
        <ng-container *ngFor="let key of axisKeys; let i = index">
          <text
            *ngIf="(keyAlias[key] || key) | translate as translatedText"
            class="w-spider-chart__key-group"
            [attr.dy]="keyData[key].dy"
            [attr.x]="keyData[key].x"
            [attr.y]="keyData[key].y"
            [attr.text-anchor]="keyData[key].textAnchor"
            [attr.data-key]="key"
            [attr.data-translated]="translatedText"
            (click)="openMetricModal(key)"
            #keyText></text>
        </ng-container>
      </g>
      <ng-container *ngIf="!isDataEmpty">
        <g class="w-spider-chart__polygon-group">
          <polygon
            *ngFor="let p of polygonMatrix; let i = index"
            class="w-spider-chart__polygon"
            [attr.filter]="polygonData[i].filter"
            [attr.fill]="polygonData[i].fill"
            [attr.points]="polygonData[i].points"
            #polygon></polygon>
        </g>
        <g class="w-spider-chart__polygon-point-groups">
          <g
            *ngFor="let polygon of polygonMatrix; let i = index"
            class="w-spider-chart__polygon-group"
            [attr.data-index]="i">
            <circle
              *ngFor="let points of polygon"
              class="w-spider-chart__polygon-point"
              r="2"
              [attr.cx]="points.coords.x"
              [attr.cy]="points.coords.y"></circle>
          </g>
        </g>
      </ng-container>
      <g *ngIf="!params.hideLegend" class="w-spider-chart__legend-group">
        <ng-container *ngFor="let l of legendData; let i = index">
          <text
            *ngIf="i != 0 || params.showZero"
            class="w-spider-chart__legend"
            [attr.x]="legendData[i].x"
            [attr.y]="legendData[i].y"
            [attr.transform]="legendData[i].transform">
            {{legendData[i].value}}
          </text>
        </ng-container>
      </g>
    </g>
  </svg>
  <span *ngIf="isDataEmpty" class="w-spider-chart__no-data">{{'MTR_LABEL_DATA_UNAVAILABLE' | translate}}</span>
</div>
<ng-template [ngIf]="slideOnPageVisible$ | async"></ng-template>
