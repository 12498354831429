import { ListType } from '@mediacoach-ui-library/global'

import { PlayerPositionType } from './player-selection.model'
import { PlayerPosition } from '@core/enums/player.enums'
import { PLAYER_POSITION_KEYS } from '@core/constants/player.constants'
import { objectKeys } from '@core/utils/object.utils'

const getPlayerPositionList = (onlyFieldPlayers = false) => ({
  type: ListType.Simple,
  groups: [
    {
      items: objectKeys(PLAYER_POSITION_KEYS)
        .filter((playerKey) => !onlyFieldPlayers || playerKey !== PlayerPosition.Goalkeeper)
        .map((key) => ({ id: key, text: PLAYER_POSITION_KEYS[key], isSelectable: true }))
    }
  ]
})

export const PLAYER_POSITION_MAP = {
  [PlayerPositionType.FieldPlayer]: getPlayerPositionList(true),
  [PlayerPositionType.All]: getPlayerPositionList()
}
