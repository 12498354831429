import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Injectable,
  Input,
  Optional,
  Output,
  QueryList,
  Self
} from '@angular/core'
import { Router } from '@angular/router'

import { HeaderCollapseDirective, TemplateDirective } from '../../directives'
import { RouterEventsService } from '@core/services/router-events/router-events.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class HeaderComponent implements AfterContentInit {
  @Input() title: string
  @Input() loading = false
  @Input() showToolbar = true
  @Input() showBackButton = true
  @Input() disableNavigateBack = false
  @Input() extraClasses: string
  @Input() titleParams: { [key: string]: string }
  @Input() navigateBackUrl: string

  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>

  @Output() navigationBack = new EventEmitter()

  titleTemplate
  startButtonsTemplate
  endButtonsTemplate
  contentTemplate

  constructor(
    @Optional() @Self() private readonly _collapseDirective: HeaderCollapseDirective,
    private _router: Router,
    public routerEventsService: RouterEventsService
  ) {}

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'title':
          this.titleTemplate = item.template
          break
        case 'start-buttons':
          this.startButtonsTemplate = item.template
          break
        case 'end-buttons':
          this.endButtonsTemplate = item.template
          break
        case 'content':
          this.contentTemplate = item.template
          break
      }
    })
  }

  expand() {
    this._collapseDirective?.expand()
  }

  collapse() {
    this._collapseDirective.collapse()
  }

  isCollapsed() {
    return this._collapseDirective?.isCollapsed
  }

  onBackNavigation() {
    this.navigationBack.emit()
    if (this.navigateBackUrl) {
      this._router.navigate([this.navigateBackUrl])
    } else {
      this.routerEventsService.goBack()
    }
  }
}
