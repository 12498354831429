import { Action, createReducer, on } from '@ngrx/store'
import { SettingsState } from '@core/store/states/settings.state'
import { setSelectedCompetitionIdSetting, setSelectedMatchDayIdSetting } from '@core/store/actions/settings.actions'

const initialState: SettingsState = {
  selectedCompetitionId: null,
  selectedMatchDayId: null
}

const reducer = createReducer(
  initialState,
  on(
    setSelectedCompetitionIdSetting,
    (state: SettingsState, { selectedCompetitionId }): SettingsState => ({
      ...state,
      selectedCompetitionId
    })
  ),
  on(
    setSelectedMatchDayIdSetting,
    (state: SettingsState, { selectedMatchDayId }): SettingsState => ({
      ...state,
      selectedMatchDayId
    })
  )
)

export const settingsReducer = (state: SettingsState | undefined, action: Action) => reducer(state, action)
