import { createAction, props } from '@ngrx/store'
import { MetricAggregation } from '@core/enums/metric.enums'
import { MetricAggregationHeader, MetricAggregationModeSelectable } from '@core/models/metric.models'
import { MetricAggregationState } from '@core/store/states/metric-aggregation.state'
import { MetricFinderKey } from '@core/models/models'
import { PlayerPosition } from '@core/enums/player.enums'

export const initializeMetricAggregation = createAction(
  '[Metric Aggregation] Initialize',
  props<{
    isVsEnabled?: boolean
    isMatch?: boolean
    demarcation?: PlayerPosition
    context?: MetricFinderKey
    aggregationMode?: MetricAggregation
    aggregationHeader?: MetricAggregationHeader
    aggregationModes?: MetricAggregationModeSelectable[]
  }>()
)

export const initializeMetricAggregationSuccess = createAction(
  '[Metric Aggregation] Initialize success',
  props<{
    initialState: MetricAggregationState
  }>()
)

export const setIsVsEnabled = createAction('[Metric Aggregation] Set isVsEnabled', props<{ isVsEnabled: boolean }>())

export const setIsMatch = createAction('[Metric Aggregation] Set isMatch', props<{ isMatch: boolean }>())

export const setAggregationMode = createAction(
  '[Metric Aggregation] Set aggregation mode',
  props<{
    aggregationMode: MetricAggregation
  }>()
)

export const setPlayerDemarcation = createAction(
  '[Metric Aggregation] set player demarcation',
  props<{ demarcation: PlayerPosition }>()
)

export const purgeMetricAggregation = createAction('[Metric Aggregation] purge metric aggregation')
