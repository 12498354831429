import { Component, EventEmitter, Input, Output } from '@angular/core'
import { SegmentModifier, SegmentOption } from './segmented-control.models'

@Component({
  selector: 'app-segmented-control',
  templateUrl: './segmented-control.component.html',
  styleUrls: ['./segmented-control.component.scss']
})
export class SegmentedControlComponent {
  @Input() disabled = false
  @Input() autoSelect = true
  @Input() modifiers: SegmentModifier[]

  @Output() onChange = new EventEmitter<any>()

  private _value: string
  private _options: SegmentOption[]

  get value() {
    return this._value
  }
  get options() {
    return this._options
  }

  @Input() set value(_value: string) {
    this._value = _value && String(_value)
  }

  @Input() set options(_options) {
    this._options = _options
    if ((_options || []).length && this.autoSelect) {
      this.value = this._value ? this._value : (this.options.find(({ selected }) => selected) || this.options[0]).id
    }
  }

  onSegmentValueChanged({ detail: { value } }) {
    this.value = value
    this.options.forEach((option) => (option.selected = option.id === value))
    this.onChange.emit(value)
  }
}
