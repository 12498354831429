<mcm-loader [loading]="loading || !rows || !columns">
  <div
    #scrollable
    *ngIf="!loading && rows && columns"
    (scroll)="onScroll($event)"
    [gridTpl]="{columns: columns, rows: rows, headerHeight: headerHeight, rowHeight: rowHeight}"
    class="mcm-table mcm-table--scrollable">
    <ng-container *ngIf="drawRowHolder">
      <div
        *rxFor="let row of rows;trackBy:trackByFn; index as rIdx; first as isFirstRow; last as isLastRow; odd as isOdd; even as isEven"
        class="mcm-table__row-holder"
        [ngClass]="row.styleClass"
        [class.odd]="isOdd"
        [class.even]="isEven"
        [style.grid-row-start]="2+rIdx"
        [style.grid-row-end]="2+rIdx + 1"
        [style.grid-column-start]="1"
        [style.grid-column-end]="1+ columns.length"></div>
    </ng-container>
    <ng-container *rxFor="let column of columns; index as cIdx; first as isFirstColumn; last as isLastColumn">
      <div
        class="mcm-table__header"
        [ngClass]="column.styleClass"
        [ngStyle]="column.styles"
        [class.default]="!headerTpl"
        [class.sticky]="stickyHeader"
        [class.top]="stickyHeader"
        [class.left]="stickyHeader && isFirstColumn"
        [class.on-top]="stickyHeader && isFirstColumn"
        [style.grid-row-start]="1"
        [style.gridColumn]="1+cIdx"
        (click)="sort(cIdx);">
        <ng-container *ngIf="headerTpl; else defaultHeaderTpl">
          <ng-container
            [ngTemplateOutlet]="headerTpl"
            [ngTemplateOutletContext]="{column: column, index: cIdx, isFirstColumn: isFirstColumn, isLastColumn: isLastColumn}"></ng-container>
        </ng-container>
        <ng-template #defaultHeaderTpl>
          <div class="mcm-table__header-text">{{column.label | translate}}</div>
        </ng-template>

        <span
          class="mcm-table__header-sort"
          [ngClass]="column.sortDirection"
          *ngIf="column.sortFn || column.sortable || sortable"></span>
      </div>

      <div
        class="mcm-table__row"
        [ngStyle]="rowStyles || defaultRowStyles"
        *rxFor="let row of rows;trackBy:trackByFn; index as rIdx; first as isFirstRow; last as isLastRow; odd as isOdd; even as isEven"
        [style.grid-row]="2+rIdx"
        [style.grid-column]="1+cIdx"
        [class.sticky]="column.sticky"
        [class.left]="column.sticky"
        [class.odd]="isOdd"
        [class.even]="isEven"
        [class.last-column]="isLastColumn"
        [class.first-column]="isFirstColumn"
        [ngClass]="row.styleClass"
        (click)="cellClick.emit({row: row, column: column, rowIdx: rIdx, columnIdx: cIdx})">
        <ng-container *ngIf="cellTpl; else defaultRowTpl">
          <ng-container
            [ngTemplateOutlet]="cellTpl"
            [ngTemplateOutletContext]="{
                        row: row, rowIndex: rIdx, isFirstRow: isFirstRow, isLastRow: isLastRow ,
                        column: column, columnIndex: cIdx, isFirstColumn: isFirstColumn, isLastColumn: isLastColumn,
                        isOdd: isOdd, isEven: isEven
                      }">
          </ng-container>
        </ng-container>
        <ng-template #defaultRowTpl>
          <div>{{row[column.key]}}</div>
        </ng-template>
      </div>
    </ng-container>
  </div>
</mcm-loader>
