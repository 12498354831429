import { MetricFinderState } from '@core/store/states/metric-finder.state'
import { Action, createReducer, on } from '@ngrx/store'
import { purgeMetrics, setMetricHolder, setMetrics } from '@core/store/actions/metric-finder.actions'

export const initialState: MetricFinderState = {
  metrics: null,
  metricHolder: null
}

const reducer = createReducer(
  initialState,
  on(setMetrics, (state, { metrics }): MetricFinderState => ({ ...state, metrics })),
  on(setMetricHolder, (state, { metricHolder }): MetricFinderState => ({ ...state, metricHolder })),
  on(purgeMetrics, (): MetricFinderState => ({ ...initialState }))
)

export const metricFinderReducer = (state: MetricFinderState | undefined, action: Action): MetricFinderState =>
  reducer(state, action)
