<app-header
  class="mcm-metric__header"
  [ngClass]="{
              'mcm-metric__header--player': type === MetricCategory.Player,
              'mcm-metric__header--team': type === MetricCategory.Team
            }"
  [showToolbar]="false">
  <ng-template mcmTemplate="content">
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="MetricCategory.Player">
        <ng-container *ngTemplateOutlet="playerHeader; context: { $implicit: data }"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="MetricCategory.Team">
        <ng-container *ngTemplateOutlet="teamHeader; context: { $implicit: data }"></ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
</app-header>

<ion-content>
  <div class="mcm-metric__data" [class.isDual]="isDual">
    <span class="mcm-metric__data-key">{{data.metricLabel | translate}}</span>
    <ng-container *ngIf="!isDual else dualMode;">
      <ng-container *ngIf="(language$ | async) as language">
        <span class="mcm-metric__data-value"
          >{{((data.metricValue | number:'': language) || '-') + ((data.metricUnit | translate) || '')}}</span
        >
      </ng-container>
    </ng-container>
    <div class="mcm-metric__data-button">
      <app-button text="MTR_COMMON_CLOSE" expand="full" extraClasses="mcm-button--gradient" (onClick)="onAccept()">
      </app-button>
    </div>
  </div>
</ion-content>

<ng-template #dualMode>
  <app-list [data]="data?.dualData"></app-list>
</ng-template>

<ng-template #playerHeader let-data>
  <div class="mcm-metric__header-content">
    <img
      loading="lazy"
      class="mcm-metric__header-content-logo"
      slot="start"
      [appDefaultImage]="PLAYER_PLACEHOLDER_IMAGE"
      [srcUrl]="data.imgUrl"
      [alt]="data.name" />
    <div class="mcm-metric__header-content-info">
      <span class="mcm-metric-info__name"> {{data.nameWithShirtNumber}} </span>
      <span class="mcm-metric-info__position">
        {{(data | demarcation: playerDemarcationKeys : playerDemarcationFallbackKey : ' ')}}
      </span>
      <div class="mcm-metric-info__team">
        <img
          loading="lazy"
          [appDefaultImage]="SHIELD_PLACEHOLDER_IMAGE"
          [srcUrl]="data.team.portraitLogo"
          [alt]="data.team.name" />
        <span class="mcm-metric-info__team-name"> {{data.team.name}} </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #teamHeader let-data>
  <div class="mcm-metric__header-content">
    <img
      loading="lazy"
      class="mcm-metric__header-content-logo"
      slot="start"
      [appDefaultImage]="SHIELD_PLACEHOLDER_IMAGE"
      [srcUrl]="data?.imgUrl"
      [alt]="data?.name" />
    <div class="mcm-metric__header-content-info">
      <span class="mcm-metric-info__name"> {{data?.name}} </span>
      <div class="mcm-metric-info__competition" *ngIf="data?.competition?.id">
        <img
          loading="lazy"
          appDefaultImage
          [srcUrl]="data?.competition?.landscapeLogoWhite"
          [alt]="data?.competition" />
      </div>
    </div>
  </div>
</ng-template>
