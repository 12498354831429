import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import {
  authenticatedSuccess,
  authorizationCallback,
  endSessionCallback,
  onAuthEventChange,
  signIn,
  signInFailed,
  signInSuccess,
  signOut,
  signOutFailed,
  signOutSuccess
} from '@core/store/actions/auth.actions'
import { debounceTime, delay, filter, map, switchMap, tap } from 'rxjs/operators'
import { AuthActions, IAuthAction } from 'ionic-appauth/lib/auth-action'
import { NavController } from '@ionic/angular'
import { RouteUrl } from '@core/models/models'
import { fetchUserProfile } from '@core/store/actions/profile.actions'
import { CoreAuthService } from '@core/services/auth/core-auth-service'
import { fromPromise } from 'rxjs/internal/observable/innerFrom'

const ACTIONS_WHITE_LIST = [AuthActions.SignInSuccess, AuthActions.SignInFailed, AuthActions.SignOutSuccess, AuthActions.SignOutFailed]

@Injectable()
export class AuthEffects {

  authenticatedSuccess$ = createEffect(
    () => this._actions$.pipe(
      ofType(authenticatedSuccess),
      delay(0),
      switchMap(() => [fetchUserProfile()])
    )
  )

  onAuthEventChange$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(onAuthEventChange),
        filter(({event}) => ACTIONS_WHITE_LIST.includes(event.action as AuthActions)),
        map(({event}) => this._getActionByEventAction(event))
      )
  )

  signIn$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(signIn),
        switchMap(() => fromPromise(this._ionicAuth.signIn()))
      ),
    {dispatch: false}
  )

  signOut$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(signOut),
        switchMap(( { state }) => fromPromise(this._ionicAuth.signOut(state)))
      ),
    {dispatch: false}
  )

  authorizationCallback$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(authorizationCallback),
        tap(({ url }) => this._ionicAuth.authorizationCallback(url))
      ),
    {dispatch: false}
  )

  endSessionCallback$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(endSessionCallback),
        debounceTime(200),
        tap(({ navigateUrl }) => {
          this._ionicAuth.endSessionCallback()
          if (navigateUrl && navigateUrl.indexOf('concurrency') !== -1) {
            void this._navController.navigateRoot(RouteUrl.Concurrency)
          } else if (navigateUrl && navigateUrl.indexOf('login') !== -1) {
            void this._navController.navigateRoot(RouteUrl.Login)
          }
        })
      ),
    {dispatch: false}
  )

  signInSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(signInSuccess),
        switchMap(() => this._navController.navigateRoot(RouteUrl.Home))
      ),
    {dispatch: false}
  )

  signOutSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(signOutSuccess),
        tap(() => {
          console.log('destroyInstance websocket connection')

        })
      ),
    { dispatch: false }
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
    private readonly _ionicAuth: CoreAuthService,
    private readonly _navController: NavController) {
  }

  private _getActionByEventAction(event: IAuthAction) {
    return {
      [AuthActions.SignInSuccess]: signInSuccess(),
      [AuthActions.SignInFailed]: signInFailed({ error: event.error}),
      [AuthActions.SignOutSuccess]: signOutSuccess(),
      [AuthActions.SignOutFailed]: signOutFailed({ error: event.error})
    }[event.action]
  }
}
