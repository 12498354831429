import { Platform } from '@ionic/angular'
import * as packageInfo from '../../../../package.json'

import { PlatformType, RouteUrl } from '../models/models'

export const PLATFORMS = Object.values(PlatformType)
export const getTeamProp = (team) => team + 'Team'

export const checkPlatform = (platform: Platform): PlatformType => PLATFORMS.find((type) => platform.is(type))

// FIXME Check if better to use regex
export const URL_SAVE_BLACKLIST = [
  RouteUrl.Login,
  RouteUrl.AuthMain,
  RouteUrl.NotPermitted,
  RouteUrl.UpdateVersion,
  RouteUrl.UserDisallowed
].map((page) => new RegExp(`^${page}$`))

// eslint-disable-next-line @typescript-eslint/dot-notation
export const APP_VERSION = packageInfo['default']?.version

export const LA_LIGA_LIST_NAME_CAPITALIZED = 'LaLiga'
