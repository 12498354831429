export const getLocalDate = (utc, lang: string) => {
  const date = new Date(utc)

  return {
    weekday: date.toLocaleDateString(lang, { weekday: 'long' }),
    hour: date.toLocaleTimeString(),
    date
  }
}

export const urlDeepLength = (url) => url?.split('/')?.length
export const trimUrl = (url) => url?.replace(/^\//, '')
export const urlNoQueryParams = (url) => url && url.split('?')[0]

export const REFRESH_TIME = {
  PER_MIN: 60,
  FIVE_MIN: 60 * 5,
  PER_DAY: 24 * 60 * 60
}

export const throttle = (callback: (...args) => void, delay: number): ((...args) => void) => {
  let lastCalled = 0
  return (...args) => {
    const now = new Date().getTime()
    if (now - lastCalled < delay) {
      return
    }
    lastCalled = now
    return callback(args)
  }
}

export const trackByData = (index, item) => item.id
