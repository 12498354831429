import { selectProfile } from '@core/store/selectors/user.selectors'
import { of, pipe, takeWhile } from 'rxjs'
import { select } from '@ngrx/store'
import { catchError, distinctUntilChanged, filter, map } from 'rxjs/operators'
import { trimUrl, urlDeepLength } from '@core/utils/main.utils'
import { DeepEqual } from '@mediacoach-ui-library/global'

export const isPagePermitted = (page: string) =>
  pipe(
    select(selectProfile),
    distinctUntilChanged(DeepEqual),
    takeWhile((userProfile) => !userProfile, true),
    filter((userProfile) => !!userProfile),
    map((userProfile) => {
      const pageToCheck = trimUrl(page).split('?')[0]
      return userProfile?.permissions?.some(
        ({ deepLength, regex }) => regex.test(pageToCheck) && deepLength === urlDeepLength(pageToCheck)
      )
    }),
    catchError(() => of(false))
  )
