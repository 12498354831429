import { StaticOrientation } from '@core/enums/statics.enum'
import { STATIC_ORIENTATION_MAP } from '@core/constants/statics.constants'
import { ApiStatic } from '@core/models/assets.models'

export const parseStatic = (statics, orientation: StaticOrientation) =>
  ((statics || []).find((staticAsset) => staticAsset.orientation === orientation) || {}).url

export const parseStatics = (statics, orientations: StaticOrientation[]) =>
  orientations.reduce(
    (obj, orientation) => ({
      ...obj,
      [STATIC_ORIENTATION_MAP[orientation] || STATIC_ORIENTATION_MAP.default]: parseStatic(statics, orientation)
    }),
    {}
  )

export const getStaticItem = (statics: ApiStatic[], predicate: (staticItem: ApiStatic) => boolean) =>
  statics.find((s) => predicate(s))?.url
