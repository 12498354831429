import { ListHeader } from '@mediacoach-ui-library/global'

export interface MetricDetailConfig {
  header: ListHeader
  type: MetricDetailType
  metrics?: any
  metricCategories?: any[]
  metricsOverview?: any[]
  metricsPrevious?: any[]
  extraData?: any
}

export enum MetricDetailType {
  Teams = 'teams',
  TeamCompetition = 'teamCompetition',
  Player = 'player',
  Players = 'players',
  PlayerCompetition = 'playerCompetition'
}
