import { IonicPageState } from './ionic-page.models'
import { IonicPageVisibilityService } from '../../services/ionic-page-visibility/ionic-page-visibility.service'

export class IonicPage {
  isCurrentPage = true
  constructor(protected _ionicPageVisibilityService: IonicPageVisibilityService) {}

  ionViewWillEnter() {
    this._ionicPageVisibilityService.pageVisibility$.next({
      state: IonicPageState.WillEnter,
      component: this
    })
  }

  ionViewDidEnter() {
    this._ionicPageVisibilityService.pageVisibility$.next({
      state: IonicPageState.DidEnter,
      component: this
    })
  }

  ionViewWillLeave() {
    this._ionicPageVisibilityService.pageVisibility$.next({
      state: IonicPageState.WillLeave,
      component: this
    })
  }

  ionViewDidLeave() {
    this._ionicPageVisibilityService.pageVisibility$.next({
      state: IonicPageState.DidLeave,
      component: this
    })
  }
}
