import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { COMMON_AGGREGATION_MODES, MATCH_CONTEXT_AGGREGATION_MODES } from '@core/constants/metric-aggregation.constants'
import { List, ListComponent, ListType } from '@mediacoach-ui-library/global'
import { TitleAlignment, TitleSize, TitleWeight } from '@shared/components/title/title.constants'

@Component({
  selector: 'mcm-aggregation-selector',
  templateUrl: './aggregation-selector.component.html',
  styleUrls: ['./aggregation-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AggregationSelectorComponent implements OnInit {
  private _initialSelection = null
  @ViewChild(ListComponent, { static: false }) list: ListComponent
  aggregationModes: List<any>

  @Input() inMatchContext: boolean
  @Input() againstCompetition: boolean
  @Input() selection = null
  @Output() selectionChange = new EventEmitter()

  isDisabled = true

  ngOnInit() {
    this._initialSelection = this.selection
    this.aggregationModes = this._buildCustomSelectableSimpleList(
      this.inMatchContext ? MATCH_CONTEXT_AGGREGATION_MODES : COMMON_AGGREGATION_MODES,
      this.againstCompetition,
      this.selection
    )
  }

  checkSelection(item) {
    this.selection = item
    this.isDisabled = this._initialSelection === item.id
  }

  onSelected(item) {
    this.selectionChange.emit(item)
  }

  private _buildCustomSelectableSimpleList = (list: any[], useAltText: boolean, selection: boolean) => ({
    type: ListType.Simple,
    groups: [
      {
        items: list.map((item) => ({
          ...item,
          active: item.id === selection,
          isSelectable: true,
          text: item.alt && useAltText ? item.alt : item.text
        }))
      }
    ]
  })

  protected readonly TitleAlignment = TitleAlignment
  protected readonly TitleWeight = TitleWeight
  protected readonly TitleSize = TitleSize
}
