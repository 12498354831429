import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core'

import { Subject, switchMap } from 'rxjs'
import { map, take, tap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { selectCompetitions, selectCurrentSeasonCompetitionAndTeamIds } from '@core/store/selectors/season.selector'
import { ModalType } from '@pages/team/models/team.models'
import { Team } from '@mediacoach-ui-library/global'
import { TeamApi } from '@core/requests/api/team/team.api'

@Component({
  selector: 'app-team-selection',
  templateUrl: './team-selection.component.html',
  styleUrls: ['./team-selection.component.scss']
})
export class TeamSelectionComponent implements AfterViewInit {
  private _teamId: string
  private _competitionId: string
  private _fetchTeamsSubject = new Subject<string>()

  @Input() onlyCompetition = false
  @Input() preselected = true
  @Input() mode: ModalType
  @Output() onTeamSelect = new EventEmitter()

  selectedTeam: Partial<Team>
  selectedCompetitionId: string

  competitions$ = this._store.pipe(selectCompetitions).pipe(
    map((competitions) =>
      competitions.map((competition, index) => ({
        id: competition.id,
        selected: this.selectedCompetitionId ? competition.id === this.selectedCompetitionId : !index,
        image: competition?.landscapeLogo
      }))
    ),
    tap((competitions) => (this.competitionId = competitions.find(({ selected }) => selected).id))
  )
  teams$ = this._fetchTeamsSubject.asObservable().pipe(
    switchMap((_competitionId) =>
      this._store.pipe(selectCurrentSeasonCompetitionAndTeamIds()).pipe(
        take(1),
        map(({ competitionId, seasonId }) => ({ competitionId: _competitionId || competitionId, seasonId }))
      )
    ),
    switchMap(({ seasonId, competitionId }) =>
      this._teamApi
        .fetchTeams(seasonId, competitionId)
        .pipe(map(({ teams }) => teams.map((team) => ({ ...team, seasonId, competitionId }))))
    )
  )

  constructor(
    private _teamApi: TeamApi,
    private readonly _store: Store
  ) {}

  ngAfterViewInit() {
    this._fetchTeamsSubject.next(this.competitionId)
  }

  get isFavTeamMode() {
    return this.mode === ModalType.FavTeam
  }

  get teamId() {
    return this._teamId
  }

  get competitionId() {
    return this._competitionId
  }

  @Input() set teamId(_teamId) {
    this._teamId = _teamId
    if (this.preselected) {
      this.selectedTeam = { id: _teamId }
    }
  }

  @Input() set competitionId(_competitionId) {
    this._competitionId = _competitionId
    this.selectedCompetitionId = _competitionId
  }

  onCompetitionChanged(id: string) {
    if (this.selectedCompetitionId !== id) {
      this._fetchTeamsSubject.next(id)
      this.selectedCompetitionId = id
      this.selectedTeam = this.selectedCompetitionId === this.competitionId ? { id: this.teamId } : null
    }
  }

  onTeamClick(team: Team) {
    if (team.id !== this.teamId) {
      this.selectedTeam = team
    }
  }

  onTeamSelected() {
    this.onTeamSelect.emit({
      competitionId: this.selectedCompetitionId,
      ...this.selectedTeam
    })
  }
}
