import { NgModule, Optional, Self } from '@angular/core'
import { routerReducer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { Router } from '@angular/router'
import { environment } from '@env'
import { ROUTER_CONFIG, ROUTER_FEATURE_TOKEN } from './constants/ngrx-router.constants'
import { NgrxRouterEffects } from './state/effects/ngrx-router.effects'
import { MergedRouterStateSerializer } from '@core/ngrx-router/serializers/merged-route.serializer'

@NgModule({
  imports: [
    StoreModule.forFeature(ROUTER_FEATURE_TOKEN, routerReducer),
    EffectsModule.forFeature([NgrxRouterEffects]),
    StoreRouterConnectingModule.forRoot(ROUTER_CONFIG)
  ],
  exports: [StoreModule, StoreRouterConnectingModule],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: MergedRouterStateSerializer
    }
  ]
})
export class NgrxRouterStoreModule {
  constructor(@Self() @Optional() router: Router) {
    if (!environment.production) {
      if (router) {
        console.log('All good, NgrxRouterStoreModule 🎉')
      } else {
        console.error('NgrxRouterStoreModule must be imported in the same same level as RouterModule')
      }
    }
  }
}
