import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'mcm-content-menu-item',
  templateUrl: './context-menu-item.component.html',
  styleUrls: ['./context-menu-item.component.scss']
})
export class ContextMenuItemComponent {
  @Input() icon: string
  @Input() iconSize: string
  @Output() onClick = new EventEmitter<boolean>()

  constructor() {}

  onHandleClick() {
    this.onClick.emit(true)
  }
}
