import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { TokenInterceptor } from '@core/interceptors/token.interceptor'
import { AlertsInterceptor } from '@core/interceptors/alerts.interceptor'
import { ErrorInterceptor } from '@core/interceptors/error-interceptor.service'

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AlertsInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  }
]
