import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@env'
import { ApiPlayer, ApiPlayerMatch } from '@core/models/player.models'
import { ApiMetric } from '@core/models/metric.models'
import { catchObsError } from '@shared/decorators/catch-obs-error.decorator'
import { Observable } from 'rxjs'
import { ComparedPlayersData, PlayerSummary } from '@pages/player-detail/models/player-detail.models'

@Injectable()
export class PlayerApi {
  constructor(private readonly _http: HttpClient) {}

  @catchObsError()
  fetchPlayer(id: string, seasonId: string, competitionId: string, teamId: string): Observable<ApiPlayer> {
    return this._http.get<ApiPlayer>(environment.API.PLAYERS(seasonId, competitionId, teamId, id))
  }

  @catchObsError()
  fetchMatchPlayer(matchId: string, teamId: string, id: string, playerPosition: string) {
    return this._http.get<ApiPlayerMatch>(
      environment.API.MATCH_PLAYER_BY_ID(matchId, teamId, id),
      playerPosition
        ? {
            params: { playerPosition }
          }
        : {}
    )
  }

  @catchObsError()
  compare(comparedPlayersData: ComparedPlayersData) {
    return this._http.post<any>(environment.API.PLAYERS_COMPARE, comparedPlayersData)
  }

  @catchObsError()
  compareInMatch(matchId: string, comparedPlayersData: ComparedPlayersData): Observable<any> {
    return this._http.post<any>(environment.API.MATCH_PLAYERS_COMPARE(matchId), comparedPlayersData)
  }

  @catchObsError()
  fetchPlayerMetrics(
    seasonId: string,
    competitionId: string,
    teamId: string,
    id: string,
    playerPosition: string
  ): Observable<ApiMetric> {
    return this._http.get<ApiMetric>(
      environment.API.PLAYERS_METRIC_RESULTS(seasonId, competitionId, teamId, id),
      playerPosition
        ? {
            params: { playerPosition }
          }
        : {}
    )
  }

  @catchObsError()
  searchPlayer(searchParams: string, competitionId: string) {
    return this._http.get<PlayerSummary[]>(environment.API.PLAYERS_SEARCH, {
      params: { value: searchParams, competitionId }
    })
  }
}
