import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core'

@Directive({
  selector: '[mcmLoading]'
})
export class LoadingDirective implements OnInit {
  @Input() mcmLoading = false
  private _loadingElem = this.renderer.createElement('div')

  constructor(
    private _el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this._el.nativeElement.appendChild(this._loadingElem)
  }
}
