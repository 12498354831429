import { Component, EventEmitter, Input, Output } from '@angular/core'

import { DebounceTimeType, ListType } from '@mediacoach-ui-library/global'

import { BehaviorSubject, Observable, of } from 'rxjs'
import { debounceTime, map, switchMap, tap } from 'rxjs/operators'

import { PlayerPositionType, SelectionType } from './player-selection.model'
import { PLAYER_POSITION_MAP } from './player-selection.constants'
import { FirebaseAnalyticsService } from '@core/services/analytics/firebase/firebase-analytics.service'
import {
  FirebaseAnalyticsCategory,
  FirebaseAnalyticsEvent,
  FirebaseAnalyticsParam
} from '@core/services/analytics/firebase/enums/firebase-analytics.enums'
import { Action, Store } from '@ngrx/store'
import { playerDetailActions } from '@pages/player-detail/store/actions/player-detail.actions'
import { PlayerApi } from '@core/requests/api/player/player.api'
import { mapAndSortSearchPlayers } from '@shared/components/player-selection/player-selection.utils'
import { PlayerSummary } from '@pages/player-detail/models/player-detail.models'

const MIN_CHAR_TO_FILTER = 3

@Component({
  selector: 'mcm-player-selection',
  templateUrl: './player-selection.component.html',
  styleUrls: ['./player-selection.component.scss']
})
export class PlayerSelectionComponent {
  @Input() selectedPlayer: PlayerSummary
  @Input() selectionMode = SelectionType.PlayerSelection
  @Input() title: string
  @Input() titleParams: { [key: string]: string }
  @Input() actionClose: () => Action
  @Input() handleComparison: ({ data, modalContext }) => Action

  @Input() set showAllOptions(_showAllOptions: boolean) {
    this.positionPlayerType = _showAllOptions ? PlayerPositionType.All : PlayerPositionType.FieldPlayer
  }

  searchFilter$ = new BehaviorSubject<string>(null)

  filteredPlayers$: Observable<any>
  positionPlayerType: PlayerPositionType

  searching = false
  selection = null
  SelectionType = SelectionType
  PLAYER_POSITION_MAP = PLAYER_POSITION_MAP

  @Output() onSelection = new EventEmitter()

  private _previousValue: any

  constructor(
    private readonly _analytics: FirebaseAnalyticsService,
    private readonly _api: PlayerApi,
    private readonly _store: Store
  ) {}

  getFilteredPlayers$() {
    return (
      this.filteredPlayers$ ||
      (this.filteredPlayers$ = this.searchFilter$.pipe(
        tap(
          (value) =>
            (this.searching =
              value &&
              (value.length >= MIN_CHAR_TO_FILTER ||
                (this._previousValue && value.length < this._previousValue.length)))
        ),
        debounceTime(DebounceTimeType.InputChanges * 10),
        tap((searchText) => this._logToAnalytics(searchText)),
        switchMap((searchTextParams) =>
          !(!searchTextParams || searchTextParams.length < MIN_CHAR_TO_FILTER)
            ? this._api.searchPlayer(searchTextParams, this.selectedPlayer?.competitionId).pipe(
                map((players) => players.filter((player) => player.id !== (this.selectedPlayer || {}).id)),
                map((players) => ({
                  type: ListType.Players,
                  groups: [
                    {
                      items: mapAndSortSearchPlayers(players)
                    }
                  ]
                }))
              )
            : of({})
        ),
        tap(() => (this.searching = false))
      ))
    )
  }

  onSearch({ target }) {
    this.searchFilter$.next(target.value)
    this._previousValue = target.value
  }

  onSelected(selection: PlayerSummary) {
    this.onSelection.emit(selection)
    if (this.handleComparison) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.selectionMode === SelectionType.PlayerSelection &&
        this._store.dispatch(playerDetailActions.setToComparePlayer({ toComparePlayer: selection }))
      this._store.dispatch(
        this.handleComparison({
          data: selection,
          modalContext: this.selectionMode
        })
      )
    }
  }

  onClosed() {
    this._store.dispatch(this.actionClose())
  }

  private _logToAnalytics(searchText: string): void {
    if (!(!searchText || searchText.length < MIN_CHAR_TO_FILTER)) {
      this._analytics.logEvent(FirebaseAnalyticsEvent.searchPlayer, {
        [FirebaseAnalyticsParam.category]: FirebaseAnalyticsCategory.search,
        [FirebaseAnalyticsParam.searchText]: searchText
      })
    }
  }
}
