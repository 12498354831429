/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { DebounceTimeType, TranslatorService } from '@mediacoach-ui-library/global'

import { Subject } from 'rxjs'
import { debounceTime, tap } from 'rxjs/operators'

import { TableDataMode, TableMode } from './stat-table.models'
import { PLAYER_PLACEHOLDER_IMAGE } from '@core/constants/asset-path'
import { arrEqual } from '@core/utils/array.utils'
import { trackByData } from '@core/utils/main.utils'

@Component({
  selector: 'app-stat-table',
  templateUrl: './stat-table.component.html',
  styleUrls: ['./stat-table.component.scss']
})
export class StatTableComponent implements OnInit {
  private _hasInit = false
  private _switcherChecked = false
  private _data
  private _data$ = new Subject()

  dataDebouncer$ = this._data$.pipe(
    tap(() => (this.loading = true)),
    debounceTime(DebounceTimeType.Long * 5),
    tap((_data) => {
      this.loading = false
      this._data = _data
    })
  )

  get data() {
    return this._data
  }
  @Input() set data(_data) {
    if (!arrEqual(this._data, _data)) {
      if (this._hasInit) {
        this._data$.next(_data)
      } else {
        this._data = _data
      }
    }
  }

  @Input() props: string[]
  @Input() loading = false
  @Input() fields: string[]
  @Input() tableMode: TableMode
  @Input() tableDataMode: TableDataMode
  @Input() isSwitchable: boolean
  @Input() switcherValues: { id: string; text: string }[]
  @Input() switcherDisabled: boolean
  @Input() switcherDisableLabelIds: string[] = []
  @Input() widerCells: string[] = []

  get switcherChecked() {
    return this._switcherChecked
  }
  @Input() set switcherChecked(_switcherChecked) {
    if (this._switcherChecked !== _switcherChecked) {
      this._switcherChecked = _switcherChecked
      this.switcherCheckedChange.next(this._switcherChecked)
    }
  }
  @Output() switcherCheckedChange = new EventEmitter<boolean>()

  language$ = this._translatorService.onLangChange

  TrackByData = trackByData
  TableMode = TableMode
  PLAYER_PLACEHOLDER_IMAGE = PLAYER_PLACEHOLDER_IMAGE

  constructor(private _translatorService: TranslatorService) {}

  ngOnInit() {
    this._hasInit = true
  }

  onSwitchChange({ checked: value }) {
    this.switcherChecked = value
    this.tableDataMode = !value ? TableDataMode.Match : TableDataMode.Average
  }
}
