import { RankingHeader, RankingRequest } from '@features/rankings/models/ranking.model'
import { MetricAggregation } from '@core/enums/metric.enums'
import { RANKING_AGGREGATION_TO_METRIC_TYPE_MAP } from '@features/rankings/constants/rankings.constants'
import { PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'

export const parsePlayerComparisonToRankingRequest = (
  context: any,
  value: any,
  mode: MetricAggregation
): RankingRequest => ({
  seasonId: context?.playerA?.seasonId,
  competitionId: context?.playerA?.competitionId,
  metricName: value.key,
  metricType: RANKING_AGGREGATION_TO_METRIC_TYPE_MAP[mode],
  players: [
    { playerId: context?.playerA.id, teamId: context?.playerA.teamId },
    { playerId: context?.playerB.id, teamId: context?.playerB.teamId }
  ]
})

export const parsePlayerComparisonRankingHeader = (
  metricKey: string,
  metricContext: any,
  matchDay: number
): RankingHeader => ({
  title: metricKey,
  imageA: metricContext?.playerA?.portraitLogo,
  textA: metricContext.playerA?.metricPlayerName,
  imageAStyleClass: 'mcm-rankings__avatar',
  imageB: metricContext?.playerB?.portraitLogo,
  textB: metricContext.playerB?.metricPlayerName,
  imageBStyleClass: 'mcm-rankings__avatar',
  placeholderA: PLACEHOLDER_IMAGES.PLAYER,
  placeholderB: PLACEHOLDER_IMAGES.PLAYER,
  matchDay
})

export const parsePlayerMatchComparisonToRankingRequest = (
  context: any,
  value: any,
  mode: MetricAggregation
): RankingRequest => ({
  ...parsePlayerComparisonToRankingRequest(context, value, mode),
  players: [
    { playerId: context?.playerA.playerId, teamId: context?.playerA.teamId },
    { playerId: context?.playerB.playerId, teamId: context?.playerB.teamId }
  ],
  matchId: context?.playerA?.matchId
})
