import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Pipe({
  name: 'multipleTranslate'
})
export class MultipleTranslatePipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {}
  transform(value: string, splitSymbol = ', ', joinSymbol = ', '): string {
    if (value) {
      return value
        .split(splitSymbol)
        .map((v) => this.translate.instant(v))
        .join(joinSymbol)
    }
    return value
  }
}
