<mcm-loader [loading]="loading">
  <ion-header class="mcm-header" [ngClass]="extraClasses">
    <ion-toolbar *ngIf="showToolbar">
      <div class="mcm-header__title">
        <h2 *ngIf="title">
          <span>{{title | translate: titleParams}}</span>
        </h2>
        <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
      </div>
      <ion-buttons slot="start">
        <app-button
          *ngIf="showBackButton && !routerEventsService.isDefaultRoute"
          class="mcm-header__close-button"
          icon="arrow-left-rounded"
          [disabled]="disableNavigateBack"
          (onClick)="onBackNavigation()"></app-button>
        <ng-container *ngTemplateOutlet="startButtonsTemplate"></ng-container>
      </ion-buttons>
      <ion-buttons slot="end">
        <ng-container *ngTemplateOutlet="endButtonsTemplate"></ng-container>
      </ion-buttons>
    </ion-toolbar>
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </ion-header>
</mcm-loader>
