import { MIME_TYPE_BY_FORMAT } from '../constants/video.constants'
import { VideoMimeType } from '../enums/video.enum'

export function getMimeTypeByUrl(url: string): string {
  return MIME_TYPE_BY_FORMAT[url.replace(/.+\.(\w+)\??.*/, '$1')] || MIME_TYPE_BY_FORMAT[VideoMimeType.mp4]
}

export const isAzureStream = (url: string): boolean => /\/manifest\(format=.*\)$/i.test(url)

export const getAzureStreamExtension = (url: string): string => url.replace(/.*format=/i, '').replace(/-.*/, '')

const getExtension = (path) => {
  const url = path.replace(/\?.*$/, '')
  return url.substring(url.lastIndexOf('.') + 1)
}

export const getMimetype = function (src = '') {
  const ext = isAzureStream(src) ? getAzureStreamExtension(src) : getExtension(src)
  const mimetype = MIME_TYPE_BY_FORMAT[ext.toLowerCase()]

  return mimetype || ''
}

// Copied from Video.js version 7.6.5
export function videoJsFormatTime(timeInSeconds, guide, noHours?) {
  let isNegative = timeInSeconds < 0
  timeInSeconds = Math.abs(timeInSeconds)
  let seconds: number | string = Math.floor(timeInSeconds % 60)
  let minutes: number | string = noHours ? Math.floor(timeInSeconds / 60) : Math.floor((timeInSeconds / 60) % 60)
  let hours: number | string = noHours ? 0 : Math.floor(timeInSeconds / 3600)
  const guideMinutes = Math.floor((guide / 60) % 60)
  const guideHours = Math.floor(guide / 3600) // handle invalid times

  if (seconds === 0 && minutes === 0 && hours === 0) {
    isNegative = false
  }
  if (isNaN(seconds) || timeInSeconds === Infinity) {
    // '-' is false for all relational operators (e.g. <, >=) so this setting
    // will add the minimum number of fields specified by the guide
    hours = minutes = seconds = '-'
  } // Check if we need to show hours

  // Always show at least one digit of minutes.
  hours = noHours ? '' : (hours as number) > 0 || guideHours > 0 ? hours + ':' : '' // If hours are showing, we may need to add a leading zero.

  // Check if leading zero is need for seconds
  minutes = (!noHours && (hours || guideMinutes >= 10) && (minutes as number) < 10 ? '0' + minutes : minutes) + ':'

  seconds = (seconds as number) < 10 ? '0' + seconds : seconds
  return (isNegative ? '-' : '') + hours + minutes + seconds
}

export function updateTimeWithFormat(formatTime) {
  return function (seekBarRect, seekBarPoint, time, cb) {
    this.requestNamedAnimationFrame('TimeTooltip#updateTime', function () {
      let content
      const duration = this.player_.duration()

      if (this.player_.liveTracker && this.player_.liveTracker.isLive()) {
        const liveWindow = this.player_.liveTracker.liveWindow()
        const secondsBehind = seekBarPoint * liveWindow
        content = formatTime(secondsBehind, liveWindow)
      } else {
        content = formatTime(time, duration)
      }

      this.update(seekBarRect, seekBarPoint, content)

      if (cb) {
        cb()
      }
    })
  }
}
