import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { RankedMetric, RankingRequest } from '../models/ranking.model'
import { Observable } from 'rxjs'
import { environment } from '@env'

@Injectable()
export class RankingsApi {
  constructor(private readonly _http: HttpClient) {}

  fetchRankedMetric(rankingRequest: RankingRequest): Observable<{ ranking: RankedMetric[]; matchDay: number }> {
    return this._http.post<{ ranking: RankedMetric[]; matchDay: number }>(environment.API.RANKINGS, rankingRequest)
  }
}
