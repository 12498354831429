import {NgModule, NgZone} from '@angular/core'
import {HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http'

import {Browser} from 'ionic-appauth'
import {Platform} from '@ionic/angular'
import {Requestor, StorageBackend} from '@openid/appauth'
import {CapacitorBrowser, CapacitorSecureStorage} from 'ionic-appauth/lib/capacitor'

import {TranslatorService} from '@mediacoach-ui-library/global'

import {SharedModule} from '@shared/shared.module'
import {BackEndTranslatorService} from './services/translator/back-end-translator.service'
import {httpInterceptorProviders} from '@core/interceptors'
import {RootStoreModule} from '@core/store/store.module'
import {NgrxRouterStoreModule} from '@core/ngrx-router/ngrx-router.module'
import {RankingsModule} from '@features/rankings/rankings.module'
import {MetricDetailModule} from '@features/metric-detail/metric-detail.module'
import {SeasonApi} from '@core/requests/api/season/season.api'
import {SeasonResolver} from '@core/resolvers/season.resolver'
import {TeamApi} from '@core/requests/api/team/team.api'
import {PlayerApi} from '@core/requests/api/player/player.api'
import {httpFactory} from '@core/factories/http.factory'
import {authFactory} from '@core/factories/auth.factory'
import {Store} from '@ngrx/store'
import {ProfileApi} from '@core/requests/api/profile/profile.api'
import {SocketsModule} from '@sockets/sockets.module'
import {SharedMatchApi} from '@core/requests/api/match/shared-match.api'
import {CoreAuthService} from '@core/services/auth/core-auth-service'

const CORE_FEATURES = [MetricDetailModule, RankingsModule]

const RESOLVERS = [SeasonResolver]
const API_SERVICES = [SeasonApi, TeamApi, PlayerApi, ProfileApi, SharedMatchApi]

@NgModule({
  declarations: [],
  exports: [],
  imports: [SharedModule, SocketsModule, RootStoreModule, NgrxRouterStoreModule, ...CORE_FEATURES],
  providers: [
    TranslatorService,
    BackEndTranslatorService,
    httpInterceptorProviders,
    {
      provide: StorageBackend,
      useClass: CapacitorSecureStorage
    },
    {
      provide: Requestor,
      useFactory: httpFactory,
      deps: [Platform, HttpClient]
    },
    {
      provide: Browser,
      useClass: CapacitorBrowser
    },
    {
      provide: CoreAuthService,
      useFactory: authFactory,
      deps: [Platform, NgZone, Browser, StorageBackend, Requestor, Store]
    },
    ...API_SERVICES,
    ...RESOLVERS,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule {
}
