import { Injectable } from '@angular/core'

import { NavController } from '@ionic/angular'

import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { RouteUrl } from '@core/models/models'
import { Store } from '@ngrx/store'
import { goToPage } from '@core/ngrx-router/state/actions/ngrx-router.actions'
import { selectShouldUpdateVersion } from '@core/store/selectors/socket.selectors'

@Injectable({
  providedIn: 'root'
})
export class UpdateVersionGuard {
  _shouldUpdate$ = this._store.pipe(selectShouldUpdateVersion)

  constructor(
    private _navCtrl: NavController,
    private _store: Store
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this._shouldUpdate$.pipe(
      tap((shouldUpdate) => !shouldUpdate && this._store.dispatch(goToPage({}))),
      map(() => true)
    )
  }

  canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
    return this._shouldUpdate$.pipe(
      tap((shouldUpdate) => shouldUpdate && this._navCtrl.navigateRoot(RouteUrl.UpdateVersion)),
      map(() => true)
    )
  }
}
