/* eslint-disable @typescript-eslint/naming-convention */
import { Permission } from './auth.models'
import { RouteUrl } from '../../models/models'
import { urlDeepLength } from '../../utils/main.utils'
import { IAuthConfig } from 'ionic-appauth'

export const STORAGE_LAST_URL_KEY = 'mm__last-url'

const COMMON_PERMISSIONS = [RouteUrl.Login]

// IMPORTANT routes should follow order following angular strategy (specific routes first, variable routes last)
const COMMON_AUTH_PERMISSIONS = [
  RouteUrl.Main,
  RouteUrl.MainSub,
  RouteUrl.Profile,
  RouteUrl.AuthLogout,
  RouteUrl.HomeMetricFinder,
  RouteUrl.More,
  RouteUrl.TeamSelect,
  RouteUrl.Notifications,
  RouteUrl.NotificationDetails,
  RouteUrl.Metrics,
  RouteUrl.MCSuite,
  RouteUrl.Info,
  RouteUrl.Legal,
  RouteUrl.MatchStandings,
  RouteUrl.MatchCalendar,
  RouteUrl.MatchDetail,
  RouteUrl.MatchDetailMetricFinder,
  RouteUrl.MatchPlayerDetail,
  RouteUrl.MatchPlayerDetailMetricFinder,
  RouteUrl.MetricDetail,
  RouteUrl.TeamCompareDetail,
  RouteUrl.TeamCompareDetailMetricFinder,
  RouteUrl.PlayerDetail,
  RouteUrl.PlayerDetailMetricFinder,
  RouteUrl.PlayerCompareDetail,
  RouteUrl.MatchPlayerCompareDetail,
  RouteUrl.MatchPlayerCompareDetailMetricFinder,
  RouteUrl.PlayerCompareDetailMetricFinder,
  RouteUrl.NetworkTimeout
]

export const getPermissions = (): Permission[] =>
  Array.from(new Set([...COMMON_AUTH_PERMISSIONS, ...COMMON_PERMISSIONS])).map((_page) => ({
    regex: new RegExp(`((?!${_page}/))${_page}$`),
    deepLength: urlDeepLength(_page),
    isDefaultRoute: _page === RouteUrl.MainSub
  }))

export const getIDSSettingConfig = (settings): IAuthConfig => ({
  server_host: settings.AUTHORITY_URL,
  client_id: settings.CLIENT_ID,
  redirect_url: settings.REDIRECT_URI,
  end_session_redirect_url: settings.END_SESSION_REDIRECT_URI,
  scopes: settings.SCOPES,
  pkce: true
})
