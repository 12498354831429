import { parseStatics } from '@core/utils/statics.utils'
import { StaticOrientation } from '@core/enums/statics.enum'
import { McmMatch } from '@core/models/match.models'
import { MatchState } from '@mediacoach-ui-library/global'

export const mapMatch = (match) => ({
  ...match,
  homeTeam: mapTeamMatch(match.homeTeam, match),
  awayTeam: mapTeamMatch(match.awayTeam, match),
  isLive: !!(match?.state && (match?.state?.minute || match?.state?.name === MatchState.LineupReady))
})

export const mapTeamMatch = (team, match) => ({
  id: team.teamId,
  ...team,
  name: team.shortName || team.name,
  ...parseStatics(team.statics, [StaticOrientation.Portrait]),
  seasonId: match.seasonId,
  competitionId: match.competitionId
})

export const sortByMatchdayDescDateDesc = (items: McmMatch[]) => {
  return [...items].sort((a, b) => {
    if (a.matchdayNumber !== b.matchdayNumber) {
      return -(a.matchdayNumber - b.matchdayNumber)
    }

    if (a?.schedule?.translated?.date !== b?.schedule?.translated?.date) {
      return -(a?.schedule?.translated?.date?.valueOf() - b?.schedule?.translated?.date?.valueOf())
    }
  })
}

export function updateMatchWithLiveMatch(match: McmMatch, liveMatch: McmMatch): McmMatch {
  return {
    ...match,
    homeTeam: { ...match.homeTeam, ...liveMatch.homeTeam },
    awayTeam: { ...match.awayTeam, ...liveMatch.awayTeam },
    state: liveMatch.state,
    isLive: liveMatch.isLive
  }
}

export const getMatchIdByLocation = (): string => location.pathname.replace('/matches/', '').split('/')[0]
