import { Component, Input } from '@angular/core'
import { MatchItemType as MatchItemTypeLibrary, MatchState, TranslatorService } from '@mediacoach-ui-library/global'

import { parseMatch } from './match-item.constants'
import { IMatch, MatchItemType } from './match-item.model'
import { SHIELD_PLACEHOLDER_IMAGE } from '@core/constants/asset-path'
import { objectKeys } from '@core/utils/object.utils'

@Component({
  selector: 'app-match-item',
  templateUrl: './match-item.component.html',
  styleUrls: ['./match-item.component.scss']
})
export class MatchItemComponent {
  @Input() type: MatchItemType = MatchItemType.Full
  @Input() isToday = false

  MatchState = MatchState
  MatchItemType = MatchItemType
  MatchItemTypeLibrary = MatchItemTypeLibrary
  FullTypes = [MatchItemType.Full, MatchItemType.FullListed]

  SHIELD_PLACEHOLDER_IMAGE = SHIELD_PLACEHOLDER_IMAGE

  private _match: IMatch
  private readonly liveStatesBlackList = [MatchState.LineupReady, MatchState.HalfTime]

  constructor(private translatorService: TranslatorService) {}

  get match(): IMatch {
    return this._match
  }
  @Input() set match(_match: IMatch) {
    if (!!_match && !!objectKeys(_match).length) {
      this._match = parseMatch(_match, this.translatorService.getCurrentLang())
    }
  }

  get showLiveIndicator() {
    return this._match?.isLive && !this.liveStatesBlackList.includes(this._match?.state?.name)
  }
}
