import { TeamPlayer } from '../models/team-players-grid.models'
import { StaticOrientation } from '@core/enums/statics.enum'
import { SORTED_POSITIONS } from '@core/constants/player.constants'
import { mapPlayerMetric } from '@pages/matches/constants/matches.constants'
import { GroupWeight } from '../enums/team-players-grid.enums'
import { safeObjectData } from '@core/utils/object.utils'
import { parseStatic } from '@core/utils/statics.utils'

export function parseBooleanToNumber(value: boolean): number {
  return value ? 1 : 0
}

export function parsePlayerStats(players: any[]): TeamPlayer[] {
  return (players || []).map((player, index) => {
    const name = safeObjectData(player, 'formattedName', '-')
    const shirtNumber = safeObjectData(player, 'shirtNumber', '-')
    const position = safeObjectData(player, 'playerPositionKey', '-')

    return {
      id: player.id,
      order: index,
      name,
      shirtNumber,
      playerPosition: player?.playerPosition,
      playerPositionKey: player?.playerPositionKey,
      genericPositionKey: player?.genericPositionKey,
      specificPositionKey: player?.specificPositionKey,
      position,
      positionOrder: SORTED_POSITIONS.indexOf(position),
      imgUrl: parseStatic(safeObjectData(player, 'statics', []), StaticOrientation.Portrait),
      starter: player.starter,
      groupWeight: player.starter ? GroupWeight.Starter : GroupWeight.Substitute,
      starterNumeric: parseBooleanToNumber(player.starter),
      nameWithShirtNumber: `${shirtNumber}. ${name}`,
      ...mapPlayerMetric(player)
    }
  })
}
