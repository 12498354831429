<div class="mcm-match-item__container mcm-match-item__container--{{type}}">
  <ng-container *ngIf="FullTypes.includes(type)">
    <ng-container *ngTemplateOutlet="liveMatchDay"></ng-container>
  </ng-container>
  <div class="mcm-match-item__result-container">
    <div class="mcm-match-item__live-match-day" *ngIf="type === MatchItemType.Compressed">
      <ng-container *ngTemplateOutlet="liveMatchDay"></ng-container>
    </div>
    <div class="mcm-match-item__result">
      <mc-match-item
        *ngIf="match"
        [match]="match"
        [type]="type === MatchItemType.Compressed ? MatchItemTypeLibrary.Reduced : null"
        [hasMediaItemContainer]="true"
        [scoreSeparator]="''">
      </mc-match-item>
      <ng-container *ngIf="type === MatchItemType.Compressed">
        <div class="mcm-match-item__state-live">
          <ng-container *ngTemplateOutlet="state"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="type === MatchItemType.FullListed">
    <ng-container *ngTemplateOutlet="fullListedBottom"></ng-container>
    <ng-container *ngTemplateOutlet="matchDate"></ng-container>
  </ng-container>

  <ng-container *ngIf="type === MatchItemType.Full">
    <ng-container *ngTemplateOutlet="state"></ng-container>
    <ng-container *ngTemplateOutlet="matchDate"></ng-container>
  </ng-container>

  <ng-container *ngIf="type === MatchItemType.Listed">
    <div class="mcm-match-item__state-live">
      <ng-container *ngTemplateOutlet="state"></ng-container>
      <ng-container *ngTemplateOutlet="live"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="matchDate"></ng-container>
  </ng-container>
</div>

<ng-template #live>
  <div
    class="mcm-match-item__live"
    *ngIf="match?.isLive && match?.state?.name && match?.state.name !== MatchState.LineupReady">
    <div class="mcm-match-item__live-indicator"></div>
    <span>{{'MTR_COMMON_MATCH_LIVE' | translate}}</span>
  </div>
  <div
    class="mcm-match-item__live-empty"
    *ngIf="match?.isLive && match?.state?.name && match?.state.name === MatchState.LineupReady"></div>
</ng-template>

<ng-template #state>
  <div
    class="mcm-match-item__state"
    [ngClass]="{'not-absolute': type === MatchItemType.Listed && !match?.isLive, 'to-be-confirm': match?.schedule?.toBeConfirmed}">
    <ng-container *ngIf="match?.matchdayNumber">
      <mc-match-state *ngIf="!match?.state.minute" [matchState]="match?.state"></mc-match-state>
    </ng-container>
    <span class="mcm-match-item__state-time" *ngIf="match?.state.minute"
      >{{ 'MTR_COMMON_MATCH_MINUTE'| translate }} {{match?.state.minuteDescription}}</span
    >
  </div>
</ng-template>

<ng-template #liveMatchDay>
  <ng-container *ngIf="type === MatchItemType.Full">
    <ng-container *ngTemplateOutlet="live"></ng-container>
  </ng-container>
  <div class="mcm-match-item__match-day">
    <img
      loading="lazy"
      *ngIf="type === MatchItemType.FullListed && match?.competition?.landscapeLogo"
      class="mcm-match-item__competition-image"
      [srcUrl]="match?.competition?.landscapeLogo"
      [appDefaultImage]="SHIELD_PLACEHOLDER_IMAGE"
      [alt]="match?.competition?.officialName" />
    <span
      >{{(match?.matchdayNumber ? ('MTR_COMMON_MATCH_MATCHDAY_N' | translate: {matchDay: match?.matchdayNumber}) :
      '')}}</span
    >
  </div>
</ng-template>

<ng-template #fullListedBottom>
  <div class="mcm-match-item__state">
    <ng-container *ngIf="match?.state?.name && !showLiveIndicator">
      <mc-match-state *ngIf="!match?.state.minute" [matchState]="match?.state"></mc-match-state>
    </ng-container>
  </div>
  <div class="mcm-match-item__live-container">
    <div class="mcm-match-item__live" *ngIf="showLiveIndicator">
      <div class="mcm-match-item__live-indicator"></div>
      <span>{{'MTR_COMMON_MATCH_LIVE' | translate}}</span>
    </div>
    <div class="mcm-match-item__live-empty" *ngIf="showLiveIndicator"></div>
    <span class="mcm-match-item__live-time" *ngIf="match?.state.minute"
      >{{ 'MTR_COMMON_MATCH_MINUTE'| translate }} {{match?.state.minuteDescription}}</span
    >
  </div>
</ng-template>

<ng-template #todayDate>
  <span> {{'MTR_COMMON_TODAY_AT' | translate}} {{ match?.schedule?.translated?.date | localizedDate: 'HH:mm'}} </span>
</ng-template>

<ng-template #matchDate>
  <div class="mcm-match-item__date-container" *ngIf="!match?.isLive && !match?.schedule?.toBeConfirmed">
    <ng-container *ngIf="!isToday; else todayDate">
      <ng-container *ngIf="type !== MatchItemType.Full; else notInlineDate">
        <span class="mcm-match-item__date">
          <ng-container *ngIf="type === MatchItemType.Listed">
            {{(match?.matchdayNumber ? ('MTR_COMMON_MATCH_MATCHDAY_SHORT_N' | translate: {matchDay:
            match?.matchdayNumber}) + ' |' : '')}}
          </ng-container>
          {{ match?.schedule?.toBeConfirmed ? (('MTR_COMMON_MATCH_STATE_TOBEDEFINED' | translate)) :
          match?.schedule?.translated?.date | localizedDate:'EEEE'}}
        </span>
        <span> {{ match?.schedule?.translated?.date | localizedDate: 'dd/MM/y - HH:mm'}} </span>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #notInlineDate>
  <div class="mcm-match-item__date-part">
    <span> {{ match?.schedule?.translated?.date | localizedDate: 'dd/MM/y'}} </span>
  </div>
  <div class="mcm-match-item__hour-part">
    <span> {{ match?.schedule?.translated?.date | localizedDate: 'HH:mm' }} </span>
  </div>
</ng-template>
