import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@env'
import { catchObsError } from '@shared/decorators/catch-obs-error.decorator'
import { ApiMatch, ApiMatchDayItem } from '@core/models/match.models'

@Injectable()
export class SharedMatchApi {
  constructor(private readonly _http: HttpClient) {}

  @catchObsError()
  fetchMatch(matchId: string) {
    return this._http.get<ApiMatch>(environment.API.MATCHES(matchId))
  }

  fetchMatchesBetweenDates(seasonId: string, competitionId: string, dateFrom: string, dateTo: string) {
    return this._http.get<ApiMatchDayItem[]>(
      environment.API.MATCHES_IN_DATES(seasonId, competitionId, dateFrom, dateTo)
    )
  }
}
