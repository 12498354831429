import { SpiderChartDefaultParams } from './spider-chart.models'

export const DEFAULT_PARAMS: SpiderChartDefaultParams = {
  radius: 50,
  margin: { top: 15, right: 50, bottom: 15, left: 50 },
  textAnchorThreshold: 0.25,
  keyPadding: 8,
  keyTranslation: {
    x: 0,
    y: 6 * 1.25 // Roughly => {{key's font-size}} * 1.25
  },
  levelMaxNum: 7,
  normalize: true,
  edgeMarginRatio: 1,
  showZero: false,
  hideLegend: true
}

export const DEFAULT_KEY_ALIAS = {
  MTR_METRIC_PLAYERGOALSRECEIVED: 'MTR_METRIC_PLAYERGOALSRECEIVED_ALIAS',
  MTR_METRIC_PLAYERSHOTSRECEIVED: 'MTR_METRIC_PLAYERSHOTSRECEIVED_ALIAS'
}
