import { createAction, props } from '@ngrx/store'
import { Season } from '@core/models/dto/season.dto'
import { Competition } from '@core/models/dto/competition.dto'

export const fetchTeamMetricsFinderData = createAction('[MetricFinder] fetch team metrics data')

export const fetchTeamHolder = createAction(
  '[MetricFinder] fetch team holder',
  props<{ season: Season; competition: Competition; teamId: string }>()
)
export const fetchTeamMetrics = createAction(
  '[MetricFinder] fetch team metrics',
  props<{ seasonId: string; competitionId: string; teamId: string }>()
)

export const fetchFavTeamMetrics = createAction('[MetricFinder] fetch fav team metrics')
export const fetchFavTeamComparisonMetrics = createAction(
  '[MetricFinder] fetch fav team comparison metrics',
  props<{ seasonIdB: string; competitionIdB: string; teamIdB: string }>()
)

export const fetchTeamComparisonMetrics = createAction(
  '[MetricFinder] fetch team comparison metrics',
  props<{
    toCompare: any
  }>()
)
