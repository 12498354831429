import { MetricAggregation } from '@core/enums/metric.enums'
import { MetricAggregationContextMetadata } from '@core/models/metric.models'
import { RankingRequest } from '@features/rankings/models/ranking.model'
import {
  parsePlayerComparisonToRankingRequest,
  parsePlayerMatchComparisonToRankingRequest
} from '@features/rankings/parsers/player-comparison-ranking.parser'
import {
  parsePlayerMatchToRankingRequest,
  parsePlayerToRankingRequest
} from '@features/rankings/parsers/player-ranking.parser'
import { getMetricValueAndContext } from '@features/rankings/utils/ranking.utils'

export const resolvePlayerRankingRequest = (
  payload,
  aggregationMode: MetricAggregation,
  { isVsEnabled, isMatch }: MetricAggregationContextMetadata
): RankingRequest => {
  const { context, value, mode, metricFinder } = getMetricValueAndContext(payload, aggregationMode)

  if (isVsEnabled && isMatch) {
    return parsePlayerMatchComparisonToRankingRequest(context, value, mode)
  } else if (isVsEnabled && !isMatch) {
    return parsePlayerComparisonToRankingRequest(context, value, mode)
  } else if (!isVsEnabled && isMatch) {
    return parsePlayerMatchToRankingRequest(context, value, mode)
  } else {
    return parsePlayerToRankingRequest(context, value, mode)
  }
}
