import { RootState } from '@core/store/states/root.state'
import { createSelector, select } from '@ngrx/store'
import { pipe } from 'rxjs'
import { filter, map } from 'rxjs/operators'

export const selectMetricDetail = (state: RootState) => state.metricDetail

export const selectMetricDetailKey = createSelector(selectMetricDetail, (state) => state.key)

export const selectMetricDetailKeyPath = createSelector(selectMetricDetail, (state) => state.keyPath)

export const selectMetricDetailMetrics = createSelector(selectMetricDetail, (state) => state.metrics)

export const selectMetricDetailConfig = createSelector(selectMetricDetail, (state) => state.config)

export const selectMetricDetailParsedConfig = pipe(
  select(
    createSelector(
      selectMetricDetailKeyPath,
      selectMetricDetailMetrics,
      selectMetricDetailConfig,
      (keyPath, metrics, config) => ({ keyPath, metrics, config })
    )
  ),
  filter(({ keyPath, metrics }) => !!keyPath && !!metrics),
  map(({ keyPath, metrics, config }) => ({ ...config, data: metrics[keyPath] }))
)
