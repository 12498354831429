import { NgModule } from '@angular/core'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { UPDATER_FEATURE_TOKEN } from './constants/updater.constants'
import { updaterReducer } from './store/reducers/updater.reducer'
import { UpdaterEffects } from './store/effects/updater.effects'
import { IosUpdaterService } from '@core/services/updater/services/ios-updater.service'

@NgModule({
  imports: [StoreModule.forFeature(UPDATER_FEATURE_TOKEN, updaterReducer), EffectsModule.forFeature([UpdaterEffects])],
  providers: [IosUpdaterService]
})
export class UpdaterModule {}
