import {
  FirebaseAnalyticsCategory,
  FirebaseAnalyticsEvent,
  FirebaseAnalyticsParam
} from '../enums/firebase-analytics.enums'

export const USER_PROPERTIES = {
  appVersion: FirebaseAnalyticsParam.version,
  appEnvironment: FirebaseAnalyticsParam.environment,
  userTeamId: FirebaseAnalyticsParam.favTeamId,
  userTeamName: FirebaseAnalyticsParam.favTeamName,
  userCompetitionId: FirebaseAnalyticsParam.favCompetitionId,
  customerId: FirebaseAnalyticsParam.customerId,
  customerName: FirebaseAnalyticsParam.customerName
}

export const PARAM_MAP = {
  matchId: FirebaseAnalyticsParam.matchId,
  teamId: FirebaseAnalyticsParam.teamId,
  id: FirebaseAnalyticsParam.playerId,
  seasonId: FirebaseAnalyticsParam.seasonId,
  competitionId: FirebaseAnalyticsParam.competitionId,
  vsSeasonId: FirebaseAnalyticsParam.seasonId,
  vsCompetitionId: FirebaseAnalyticsParam.vsCompetitionIdB,
  vsTeamId: FirebaseAnalyticsParam.vsTeamIdB,
  vsPlayerId: FirebaseAnalyticsParam.vsPlayerIdB
}

export const QUERY_MAP = {
  position: FirebaseAnalyticsParam.position
}

export const DEFAULT_PARAMS_PER_EVENT = {
  [FirebaseAnalyticsEvent.editMetrics]: {
    name: FirebaseAnalyticsEvent.editMetrics,
    params: { [FirebaseAnalyticsParam.category]: FirebaseAnalyticsCategory.settings }
  },
  [FirebaseAnalyticsEvent.login]: {
    name: FirebaseAnalyticsEvent.login,
    params: { [FirebaseAnalyticsParam.category]: FirebaseAnalyticsCategory.auth }
  },
  [FirebaseAnalyticsEvent.liveMatch]: {
    name: FirebaseAnalyticsEvent.liveMatch,
    params: { [FirebaseAnalyticsParam.category]: FirebaseAnalyticsCategory.matches }
  },
  [FirebaseAnalyticsEvent.nextMatch]: {
    name: FirebaseAnalyticsEvent.nextMatch,
    params: { [FirebaseAnalyticsParam.category]: FirebaseAnalyticsCategory.matches }
  },
  [FirebaseAnalyticsEvent.prevMatch]: {
    name: FirebaseAnalyticsEvent.prevMatch,
    params: { [FirebaseAnalyticsParam.category]: FirebaseAnalyticsCategory.matches }
  }
}
