export enum TableMode {
  Standings = 'standings',
  Players = 'players'
}

export enum TableDataMode {
  Total = 'totalRecord',
  Away = 'awayRecord',
  Home = 'homeRecord',
  Match = 'matchMetricResult',
  Average = 'averageMetricResult',
  Accumulated = 'accumulatedMetricResult'
}
