import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@env'
import { ComparedPlayersData } from '@pages/player-detail/models/player-detail.models'

@Injectable({ providedIn: 'root' })
export class MetricsApi {
  constructor(private readonly _http: HttpClient) {}

  fetchPlayerMetrics(
    seasonId: string,
    competitionId: string,
    teamId: string,
    playerId: string,
    playerPosition: string
  ) {
    return this._http.get<any>(
      environment.API.PLAYERS_METRIC_RESULTS(seasonId, competitionId, teamId, playerId),
      playerPosition
        ? {
            params: { playerPosition }
          }
        : {}
    )
  }

  fetchPlayerComparisonMetrics(playerComparison) {
    return this._http.post<any>(environment.API.PLAYERS_COMPARE, playerComparison)
  }

  fetchMatchPlayerComparisonMetrics(matchId: string, comparison: ComparedPlayersData, playerPosition: string) {
    return this._http.post<any>(environment.API.MATCH_PLAYERS_COMPARE(matchId), {
      ...comparison,
      PlayerPosition: playerPosition
    })
  }

  fetchMatchMetrics(matchId: string) {
    return this._http.get<any>(environment.API.MATCH_TEAM_METRICS(matchId))
  }

  fetchMatchPlayerMetrics(matchId: string, teamId: string, playerId: string, playerPosition: string) {
    return this._http.get<any>(
      `${environment.API.MATCH_PLAYER_BY_ID(matchId, teamId, playerId)}`,
      playerPosition
        ? {
            params: { playerPosition }
          }
        : {}
    )
  }
}
