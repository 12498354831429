<app-header
  class="mcm-player-comparison__header"
  extraClasses="mcm-header--modal"
  [title]="title"
  [titleParams]="titleParams"
  [showBackButton]="false">
  <ng-template mcmTemplate="end-buttons">
    <app-button icon="close-outline" extraClasses="mcm-button--xxl" (onClick)="onClosed()"></app-button>
  </ng-template>
</app-header>
<div class="mcm-player-comparison">
  <app-segmented-control
    [options]="teamSegmentOptions"
    [value]="selectedTeam"
    [modifiers]="[SegmentModifier.FirstLevel, SegmentModifier.Inline]"
    (onChange)="onTeamChange($event)">
  </app-segmented-control>
  <div class="mcm-player-comparison__list">
    <app-list
      [data]="teams[selectedTeam] | excludePlayer: selectedPlayer.playerId"
      (onItemSelected)="selection = $event"></app-list>
  </div>

  <div class="mcm-player-comparison__footer">
    <app-button
      text="MTR_COMMON_SELECT"
      extraClasses="mcm-button--gradient mcm-button--block mcm-button--footer"
      [disabled]="!selection"
      (onClick)="onPlayerSelected({playerA: selectedPlayer, playerB: selection})">
    </app-button>
  </div>
</div>
