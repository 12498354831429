import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { CommonModule } from '@angular/common'
import { ContextMenuComponent } from './context-menu.component'
import { ContextMenuItemComponent } from './components/context-menu-item/context-menu-item.component'
import { ContextMenuContentComponent } from './components/context-menu-content/context-menu-content.component'

@NgModule({
  imports: [IonicModule, CommonModule],
  declarations: [ContextMenuComponent, ContextMenuContentComponent, ContextMenuItemComponent],
  exports: [ContextMenuComponent, ContextMenuContentComponent, ContextMenuItemComponent]
})
export class ContextMenuModule {}
