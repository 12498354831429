export const PLAYER_EVENTS = [
  'waiting',
  'volumechange',
  'usingnativecontrols',
  'usingcustomcontrols',
  'userinactive',
  'useractive',
  // 'timeupdate',
  'texttrackchange',
  'textdata',
  'tap',
  'suspend',
  'stalled',
  'sourceset',
  'seeking',
  'seeked',
  'resize',
  'ready',
  'ratechange',
  // 'progress',
  'posterchange',
  'pluginsetup',
  'playing',
  'playerresize',
  'playbackrateschange',
  'play',
  'pause',
  'loadstart',
  'loadedmetadata',
  'loadeddata',
  'leavepictureinpicture',
  'languagechange',
  'fullscreenchange',
  'exitFullWindow',
  'error',
  'enterpictureinpicture',
  'enterFullWindow',
  'ended',
  'emptied',
  'durationchange',
  'dispose',
  'controlsenabled',
  'controlsdisabled',
  'componentresize',
  'canplaythrough',
  'canplay',
  'beforepluginsetup',
  'abort',
  'beforepluginsetup',
  'pluginsetup'
]
