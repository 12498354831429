import { Component, Input, TemplateRef } from '@angular/core'

@Component({
  selector: 'mcm-context-menu-content',
  templateUrl: './context-menu-content.component.html',
  styleUrls: ['./context-menu-content.component.scss']
})
export class ContextMenuContentComponent {
  @Input() template: TemplateRef<any>
}
