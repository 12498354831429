import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'firstLetterUppercase'
})
export class FirstLetterUppercasePipe implements PipeTransform {
  constructor() {}

  transform(value: string, args?: any[]): string {
    return value && value.charAt(0).toUpperCase() + value.slice(1)
  }
}
