export const roundNumber = (n: number, digits = 0) => Number(n.toFixed(digits))

export const inRange = (value, max, min = 0) => Math.max(min, Math.min(value, max))

export function exists(value: number | undefined): boolean {
  return value !== undefined && value !== null
}

export function round(num: number, decimalPlaces = 0) {
  const p = Math.pow(10, decimalPlaces)
  const n = num * p * (1 + Number.EPSILON)
  return Math.round(n) / p
}

export function isDecimal(num: number): boolean {
  return num - Math.floor(num) !== 0
}

export function roundJustDecimals(num: number, decimalPlaces = 2): number {
  return isDecimal(num) ? round(num, decimalPlaces) : num
}
