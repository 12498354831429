import { AuthActionBuilder, AuthService, Browser } from 'ionic-appauth'
import { Requestor, StorageBackend, RevokeTokenRequest } from '@openid/appauth'
import { __awaiter } from 'tslib'
import { RevokeTokenRequestJson } from '@openid/appauth/src/revoke_token_request'
import { Injectable } from '@angular/core'
const TOKEN_RESPONSE_KEY = 'token_response'
@Injectable({
  providedIn: 'root'
})
export class CoreAuthService extends AuthService {
  constructor(browser?: Browser, storage?: StorageBackend, requestor?: Requestor) {
    super(browser, storage, requestor)
  }

  override requestTokenRevoke() {
    return __awaiter(this, void 0, void 0, function* () {
      const revokeAccessJson: RevokeTokenRequestJson = {
        token: this._tokenSubject.value.accessToken,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        token_type_hint: 'access_token',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        client_id: this.authConfig.client_id
      }
      yield this.tokenHandler.performRevokeTokenRequest(
        yield this.configuration,
        new RevokeTokenRequest(revokeAccessJson)
      )
      yield this.storage.removeItem(TOKEN_RESPONSE_KEY)
      this.notifyActionListers(AuthActionBuilder.RevokeTokensSuccess())
    })
  }

  override signOut(state?: string, revokeTokens?: boolean) {
    return __awaiter(this, void 0, void 0, function* () {
      if (revokeTokens) {
        yield this.revokeTokens()
      }
      if (yield this.storage.getItem(TOKEN_RESPONSE_KEY)) {
        yield this.storage.removeItem(TOKEN_RESPONSE_KEY)
      }
      if ((yield this.configuration).endSessionEndpoint) {
        yield this.performEndSessionRequest(state).catch((response) => {
          this.notifyActionListers(AuthActionBuilder.SignOutFailed(response))
        })
      }
    })
  }
}
