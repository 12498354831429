import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core'

import {
  CommonListItem,
  List,
  ListComponent,
  ListType,
  MetricListItem,
  PlayerListItem,
  SimpleListItem,
  TranslatorService
} from '@mediacoach-ui-library/global'

import { Subject } from 'rxjs'
import { environment } from '@env'
import { TemplateDirective } from '../../directives'
import { DEFAULT_CONTEXT_MENU_ITEMS } from './list-wrapper.constants'
import { IMatch, MatchItemType } from '../match-item/match-item.model'
import { TitleAlignment, TitleSize, TitleWeight } from '../title/title.constants'
import { Store } from '@ngrx/store'
import { clickContextMenuItem } from '@core/store/actions/context-menu.actions'

@Component({
  selector: 'app-list',
  templateUrl: './list-wrapper.component.html',
  styleUrls: ['./list-wrapper.component.scss']
})
export class ListWrapperComponent {
  @Input() limit = 7
  @Input() isCollapsible = false
  @Input() showTeamMarks = false
  @Input() showAggregationIcon: boolean
  @Input() aggregationHeader: { text?: string; icon?: string }
  @Input() customTemplate: TemplateRef<any>
  @Input() profileItemTemplate: TemplateRef<any>
  @Input() longPressActive = false
  @Input() contextMenuItems: { action: string }[] = [...DEFAULT_CONTEXT_MENU_ITEMS]
  @Input() itemContext: any
  @Input() isVsEnabled = false

  @Input() set data(_data) {
    this._data = _data
    if (_data) {
      this.setTemplate(_data.type)
    }
  }

  @Input() set listToggle(_listToggle) {
    if (this._listToggle !== _listToggle) {
      this._listToggle = _listToggle
      this.listToggleChange.next(this._listToggle)
    }
  }

  @Output() listToggleChange = new EventEmitter<any>()
  @Output() onClick = new EventEmitter<any>()
  @Output() onItemSelected = new EventEmitter<any>()
  @Output() onToggleChange = new EventEmitter<boolean>()
  @Output() aggregationModeClick = new EventEmitter<any>()
  @Output() onContextMenuItemClick = new EventEmitter<any>()

  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>
  @ViewChild('mcList', { static: true }) mcList: ListComponent
  @ViewChild('playerItem', { static: true }) playerItem: TemplateRef<any>
  @ViewChild('metricItem', { static: true }) metricItem: TemplateRef<any>
  @ViewChild('commonItem', { static: true }) commonItem: TemplateRef<any>
  @ViewChild('matchItem', { static: true }) matchItem: TemplateRef<any>
  @ViewChild('simpleItem', { static: true }) simpleItem: TemplateRef<any>

  itemTemplate: TemplateRef<any>
  language$ = this._translatorService.onLangChange
  triggerOpenContextMenu: Subject<any> = new Subject<any>()

  ListType = ListType
  TitleSize = TitleSize
  TitleAlignment = TitleAlignment
  TitleWeight = TitleWeight
  MatchItemType = MatchItemType

  private _data: List<CommonListItem | MetricListItem | PlayerListItem | SimpleListItem | IMatch | any>
  private _listToggle: boolean
  private _currentValue: any

  constructor(
    private _translatorService: TranslatorService,
    private _store: Store
  ) {}

  private setTemplate(type: ListType) {
    switch (type) {
      case ListType.Common:
        this.itemTemplate = this.commonItem
        break
      case ListType.MatchItem:
        this.itemTemplate = this.matchItem
        break
      case ListType.Metrics:
        this.itemTemplate = this.metricItem
        break
      case ListType.Players:
        this.itemTemplate = this.playerItem
        break
      case ListType.Simple:
        this.itemTemplate = this.simpleItem
        break
    }
  }

  get data() {
    return this._data
  }

  get listToggle() {
    return this._listToggle
  }

  resetShowMore() {
    this.mcList.resetShowMore()
  }

  onSwitchChange({ checked }) {
    this.listToggle = checked
    this.onToggleChange.emit(checked)
  }

  openContextMenu(item: any, target: any) {
    if (environment.enableContextMenu) {
      this._currentValue = item
      const event = new Event('long-press')
      this.triggerOpenContextMenu.next({ ...event, target })
    }
  }

  clickContextMenuItem(menuItem: any) {
    this._store.dispatch(clickContextMenuItem({ menuItem, value: this._currentValue, valueContext: this.itemContext }))
    this.onContextMenuItemClick.emit({ menuItem, value: this._currentValue })
    this._currentValue = null
  }
}
