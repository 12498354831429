import { MetricListItem } from '@mediacoach-ui-library/global'

export enum MetricCategory {
  Player = 'player',
  Team = 'team'
}

export interface IPlayer {
  id: string
  name: string
  shirtNumber: number
  playerPosition?: string
  playerPositionKey?: string
  genericPositionKey?: string
  specificPositionKey?: string
  imgUrl: string
  team: any
  metricLabel: string
  metricValue: number
  metricUnit?: string
}

export interface ITeam {
  id: string
  name: string
  imgUrl: string
  competition: any
  metricLabel: string
  metricValue: number
  metricUnit: string
}

export interface Dual {
  type?: string
  header?: {
    leftContent: {
      img: string
      title?: string
      subtitle?: string
      isPlayer?: boolean
      value?: number
    }
    rightContent: {
      img: string
      title?: string
      subtitle?: string
      isPlayer?: boolean
      value?: number
    }
  }
  groups?: {
    items: MetricListItem[]
  }
}

export interface IPlayerDual extends IPlayer {
  dualData: Dual
}

export interface ITeamDual extends IPlayer {
  dualData: Dual
}
