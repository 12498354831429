<div
  class="mcm-video-container"
  *ngIf="{
        markers: markers$ | async,
        totalTime: time$ | async
     } as data">
  <mcm-video
    *ngIf="url; else notAvailable"
    [debug]="debug"
    [mcmAttachMarkers]="videoMarkersElement"
    [source]="url"
    [streamTitle]="streamTitle"
    [lang]="lang"
    [withCredentials]="withCredentials"
    [formatTimeFn]="formatTimeFn"
    (injected)="markersInjected = true"
    (analytics)="sendAnalytics($event)"
    (time)="onTimeUpdate($event)"
    (playerReady)="playerReady.emit()"
    (playChange)="playChange.emit($event)"
    (fullscreenChange)="fullscreenChange.emit($event)"
    (videoError)="onError($event)"></mcm-video>
  <ng-template #notAvailable>
    <mcm-video-unavailable></mcm-video-unavailable>
  </ng-template>

  <mcm-video-markers
    #videoMarkers
    *ngIf="data?.totalTime"
    [hidden]="!markersInjected"
    [markers]="data?.markers"
    (marker)="onMarker($event)"></mcm-video-markers>
</div>
