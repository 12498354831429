export const UPDATER_FEATURE_TOKEN = 'updater'

export const ITUNES_LOOKUP_BASEURL = 'https://itunes.apple.com/lookup?country=ES&bundleId='
export const APP_STORE_DEEPLINK_BASEURL = 'itms-apps://itunes.apple.com/app/id'

export const UPDATER_DIALOG_I18N_KEYS = [
  'MTR_ERROR_FB_TITLE_UPDATE_VERSION',
  'MTR_ERROR_FB_SUBTITLE_UPDATE_VERSION',
  'MTR_COMMON_UPDATE'
]
