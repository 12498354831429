<app-header
  class="mcm-ranking__header"
  extraClasses="mcm-header--modal"
  title="{{'MTR_RANKING' | translate}} {{header?.title | translate}}"
  [showBackButton]="false">
  <ng-template mcmTemplate="content">
    <div class="mcm-ranking-container__header">
      <img
        loading="lazy"
        class="mcm-ranking-container__header-img"
        [ngClass]="header?.imageAStyleClass"
        [srcUrl]="header?.imageA"
        alt=""
        [appDefaultImage]="header?.placeholderA" />
      <span class="mcm-ranking-container__header-text">{{header?.textA}}</span>
      <span class="mcm-ranking-container__header-versus">{{'MTR_COMMON_VS' | translate}}</span>
      <img
        loading="lazy"
        *ngIf="header?.imageB"
        class="mcm-ranking-container__header-img"
        [ngClass]="header?.imageBStyleClass"
        [srcUrl]="header?.imageB"
        alt=""
        [appDefaultImage]="header?.placeholderB" />
      <span class="mcm-ranking-container__header-text">{{header?.textB | translate}}</span>
      <span class="mcm-ranking-container__header-match-day"
        >{{('MTR_COMMON_MATCH_MATCHDAY_N' | translate : {matchDay: header?.matchDay})}}</span
      >
    </div>
  </ng-template>
  <ng-template mcmTemplate="end-buttons">
    <app-button icon="close-outline" extraClasses="mcm-button--xxl" (onClick)="closeModal.emit()"></app-button>
  </ng-template>
</app-header>
