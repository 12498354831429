import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'

import { UntilDestroy } from '@ngneat/until-destroy'
import { Message } from './interfaces/message'
import { DomSanitizer } from '@angular/platform-browser'
import { latinize } from '@mediacoach-ui-library/global'

@UntilDestroy()
@Component({
  selector: 'mcm-file-upload-base',
  templateUrl: './file-upload-base.component.html',
  styleUrls: ['./file-upload-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FileUploadBaseComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() multiple: boolean
  @Input() accept: string
  @Input() disabled: boolean
  @Input() auto: boolean
  @Input() maxFileSize: number
  @Input() minFileSize: number
  @Input() invalidFileSizeMessageSummary = '{0}: Invalid file size, '
  @Input() invalidMaxFileSizeMessageDetail = 'maximum upload size is {0}.'
  @Input() invalidMinFileSizeMessageDetail = 'minimum upload size is {0}.'
  @Input() invalidFileTypeMessageSummary = '{0}: Invalid file type, '
  @Input() invalidFileTypeMessageDetail = 'allowed file types: {0}.'
  @Input() invalidFileLimitMessageDetail = 'limit is {0} at most.'
  @Input() invalidFileLimitMessageSummary = 'Maximum number of files exceeded, '
  @Input() previewWidth = 50
  @Input() chooseLabel: string
  @Input() fileLimit: number
  @Output() clearEvent: EventEmitter<any> = new EventEmitter()
  @Output() removeEvent: EventEmitter<any> = new EventEmitter()
  @Output() selectEvent: EventEmitter<any> = new EventEmitter()
  @Output() uploadHandler: EventEmitter<any> = new EventEmitter()
  @ViewChild('fileInput') fileInput: ElementRef
  @ViewChild('content') content: ElementRef

  @Input() set files(files) {
    this._files = []

    for (const file of files) {
      this._pushFile(file)
    }
  }

  get files(): File[] {
    return this._files
  }

  public _files: File[] = []
  public dragHighlight: boolean
  public msgs: Message[]
  public uploadedFileCount = 0

  focus: boolean
  duplicateIEEvent: boolean // flag to recognize duplicate onchange event for file input

  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2,
    private _sanitizer: DomSanitizer,
    private _zone: NgZone,
    private _cd: ChangeDetectorRef,
  ) {}

  private _pushFile(file) {
    if (this._validate(file)) {
      if (this.isImage(file)) {
        file.objectURL = this._sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file))
      }

      this.files.push(file)
    }
  }

  private _isFileTypeValid(file: File): boolean {
    const acceptableTypes = this.accept.split(',').map((type) => type.trim())
    for (const type of acceptableTypes) {
      const acceptable = this._isWildcard(type)
        ? this._getTypeClass(file.type) === this._getTypeClass(type)
        : file.type === type || this._getFileExtension(file).toLowerCase() === type.toLowerCase()

      if (acceptable) {
        return true
      }
    }
    return false
  }

  private _isFileSelected(file: File): boolean {
    for (const sFile of this.files) {
      if (sFile.name + sFile.type + sFile.size === file.name + file.type + file.size) {
        return true
      }
    }
    return false
  }

  private _sanitizeFileName(file: File): File {
    const sanitizedFileName = latinize(file.name).replace(/\s/g, '_')
    return new File([file], sanitizedFileName, {
      type: file.type,
      lastModified: file.lastModified,
    })
  }

  private _checkFileLimit() {
    if (this.isFileLimitExceeded()) {
      this.msgs.push({
        severity: 'error',
        summary: this.invalidFileLimitMessageSummary.replace('{0}', this.fileLimit.toString()),
        detail: this.invalidFileLimitMessageDetail.replace('{0}', this.fileLimit.toString()),
      })
    }
  }

  private _clearInputElement() {
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = ''
    }
  }

  private _clearIEInput() {
    if (this.fileInput && this.fileInput.nativeElement) {
      this.duplicateIEEvent = true //IE11 fix to prevent onFileChange trigger again
      this.fileInput.nativeElement.value = ''
    }
  }

  private _isIE11() {
    return !!window['MSInputMethodContext'] && !!document['documentMode']
  }

  private _validate(file: File): boolean {
    if (this.accept && !this._isFileTypeValid(file)) {
      this.msgs.push({
        severity: 'error',
        summary: this.invalidFileTypeMessageSummary?.replace('{0}', file.name),
        detail: this.invalidFileTypeMessageDetail?.replace('{0}', this.accept),
      })
      return false
    }

    if (this.minFileSize && file.size <= this.minFileSize) {
      this.msgs.push({
        severity: 'error',
        summary: this.invalidFileSizeMessageSummary?.replace('{0}', file.name),
        detail: this.invalidMinFileSizeMessageDetail?.replace(
          '{0}',
          this.formatSize(this.minFileSize),
        ),
      })
      return false
    }

    if (this.maxFileSize && file.size > this.maxFileSize) {
      this.msgs.push({
        severity: 'error',
        summary: this.invalidFileSizeMessageSummary?.replace('{0}', file.name),
        detail: this.invalidMaxFileSizeMessageDetail?.replace(
          '{0}',
          this.formatSize(this.maxFileSize),
        ),
      })
      return false
    }

    return true
  }

  private _getTypeClass(fileType: string): string {
    return fileType.substring(0, fileType.indexOf('/'))
  }

  private _isWildcard(fileType: string): boolean {
    return fileType.indexOf('*') !== -1
  }

  private _getFileExtension(file: File): string {
    return '.' + file.name.split('.').pop()
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this._zone.runOutsideAngular(() => {
      if (this.content) {
        this.content.nativeElement.addEventListener('dragover', this.onDragOver.bind(this))
      }
    })
  }

  choose() {
    this.fileInput.nativeElement.click()
  }

  onFileSelect(event) {
    if (event.type !== 'drop' && this._isIE11() && this.duplicateIEEvent) {
      this.duplicateIEEvent = false
      return
    }

    this.msgs = []
    if (!this.multiple) {
      this.files = []
    }

    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files
    for (const file of files) {
      const sanitizedFile = this._sanitizeFileName(file)
      if (!this._isFileSelected(sanitizedFile)) {
        this._pushFile(sanitizedFile)
      }
    }

    this.selectEvent.emit({
      originalEvent: event,
      files,
      currentFiles: this.files,
    })

    if (this.fileLimit) {
      this._checkFileLimit()
    }

    if (this.hasFiles() && this.auto && !this.isFileLimitExceeded()) {
      this.upload()
    }

    if (event.type !== 'drop' && this._isIE11()) {
      this._clearIEInput()
    } else {
      this._clearInputElement()
    }
  }

  isImage(file: File): boolean {
    return /^image\//.test(file.type)
  }

  upload() {
    if (this.fileLimit) {
      this.uploadedFileCount += this.files.length
    }

    this.uploadHandler.emit({
      files: this.files,
    })

    this._cd.markForCheck()
  }

  clear() {
    this.files = []
    this.clearEvent.emit()
    this._clearInputElement()
    this._cd.markForCheck()
  }

  remove(event: Event, index: number) {
    this._clearInputElement()
    this.removeEvent.emit({ originalEvent: event, file: this.files[index] })
    this.files.splice(index, 1)
  }

  isFileLimitExceeded() {
    if (
      this.fileLimit &&
      this.fileLimit <= this.files.length + this.uploadedFileCount &&
      this.focus
    ) {
      this.focus = false
    }

    return this.fileLimit && this.fileLimit < this.files.length + this.uploadedFileCount
  }

  isChooseDisabled() {
    return this.fileLimit && this.fileLimit <= this.files.length + this.uploadedFileCount
  }

  hasFiles(): boolean {
    return this.files && this.files.length > 0
  }

  onDragEnter(e) {
    if (!this.disabled) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  onDragOver(e) {
    if (!this.disabled) {
      this._renderer.addClass(this.content.nativeElement, 'root-fileupload-highlight')
      this.dragHighlight = true
      e.stopPropagation()
      e.preventDefault()
    }
  }

  onDragLeave(e) {
    if (!this.disabled) {
      this._renderer.removeClass(this.content.nativeElement, 'root-fileupload-highlight')
    }
  }

  onDrop(event) {
    if (!this.disabled) {
      this._renderer.removeClass(this.content.nativeElement, 'root-fileupload-highlight')
      event.stopPropagation()
      event.preventDefault()

      const files = event.dataTransfer ? event.dataTransfer.files : event.target.files
      const allowDrop = this.multiple || (files && files.length === 1)

      if (allowDrop) {
        this.onFileSelect(event)
      }
    }
  }

  onFocus() {
    this.focus = true
  }

  onBlur() {
    this.focus = false
  }

  formatSize(bytes) {
    if (bytes === 0) {
      return '0 B'
    }
    const k = 1000
    const dm = 3
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  getBlockableElement(): HTMLElement {
    return this._el.nativeElement.children[0]
  }

  get chooseButtonLabel(): string {
    return this.chooseLabel || ''
  }

  ngOnDestroy() {
    if (this.content && this.content.nativeElement) {
      this.content.nativeElement.removeEventListener('dragover', this.onDragOver)
    }
  }
}
