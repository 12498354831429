import { RankingState } from '../states/ranking.state'
import { Action, createReducer, on } from '@ngrx/store'
import { rankingActions } from '../actions/ranking.actions'

const initialState: RankingState = {
  ranking: null,
  sourceMetric: null,
  metricToRank: null,
  metricContext: null,
  displayMode: null,
  matchDay: null
}

const reducer = createReducer(
  initialState,
  on(rankingActions.setRanking, (state, { ranking }): RankingState => ({ ...state, ranking })),
  on(
    rankingActions.setRankingContext,
    (state, { metricToRank, metricContext, displayMode }): RankingState => ({
      ...state,
      metricToRank,
      metricContext,
      displayMode
    })
  ),
  on(rankingActions.setRankingMatchDay, (state, { matchDay }): RankingState => ({ ...state, matchDay })),
  on(rankingActions.setSourceMetric, (state, { sourceMetric }): RankingState => ({ ...state, sourceMetric })),
  on(rankingActions.purgeRanking, (state): RankingState => ({ ...initialState }))
)

export const rankingsReducer = (state: RankingState | undefined, action: Action): RankingState => reducer(state, action)
