import { Injectable } from '@angular/core'
import { Actions,  createEffect, ofType } from '@ngrx/effects'
import { concatLatestFrom } from '@ngrx/operators'
import { map, mergeMap, switchMap } from 'rxjs/operators'
import { Store } from '@ngrx/store'

import {
  selectAggregationContext,
  selectIsMatch,
  selectIsVSEnabled,
  selectPlayerDemarcation
} from '@core/store/selectors/metric-aggregation.selectors'
import { getMetricDetailConfigByContext, parseMetricDetail } from '../../utils/metric-detail.utils'
import { MetricDetailComponent } from '../../components/metric-detail/metric-detail.component'
import { Observable } from 'rxjs'
import { fromPromise } from 'rxjs/internal/observable/innerFrom'
import { metricDetailActions } from '@features/metric-detail/store/actions/metric-detail.actions'
import { McmModalControllerService } from '@core/services/mcm-modal-controller/mcm-modal-controller.service'
import { selectCurrentCompetition } from '@core/store/selectors/season.selector'

@Injectable()
export class MetricDetailEffects {

  private _modal: HTMLIonModalElement

  purgeMetricDetail$ = createEffect(() => this._actions.pipe(
    ofType(metricDetailActions.manageConfig),
    map(() => metricDetailActions.purgeMetricDetail())),
  )

  manageMetricDetailConfig$ = createEffect(() => this._actions.pipe(
    ofType(metricDetailActions.manageConfig),
    concatLatestFrom(() => [
      this._store.select(selectAggregationContext),
      this._store.select(selectIsMatch),
      this._store.select(selectIsVSEnabled),
      this._store.select(selectPlayerDemarcation),
      this._store.pipe(selectCurrentCompetition)
    ]),
    map(([{ payload }, context, isMatch, isVsEnable, demarcation, competition]) =>
      this._manageMetricDetail(payload, context, isMatch, isVsEnable, demarcation, competition)),
    mergeMap(({ type, listData, extraData}) => [
      metricDetailActions.setMetrics({metrics: listData}),
      metricDetailActions.setConfig({config: { type, extraData}})
    ]))
  )

  setMetricDetailKeyAndKeyPath$ = createEffect(() => this._actions.pipe(
    ofType(metricDetailActions.openModal),
    map(({ payload: {value: { key, keyPath}} }) => ({key, keyPath})),
    map(({ key, keyPath}) => metricDetailActions.setKeyAndKeyPath({ key, keyPath}))
  ))

  openMetricDetailModal$ = createEffect(() => this._actions.pipe(
    ofType(metricDetailActions.openModal),
    switchMap(() => this._createMetricDetailModal()),
    switchMap(modal => {
      this._modal = modal
      return fromPromise(this._modal.present())
    }),
  ), { dispatch: false})

  closeMetricDetailModal$ = createEffect(() => this._actions.pipe(
    ofType(metricDetailActions.closeModal),
    switchMap(() => fromPromise(this._modal?.dismiss())),
    map(() => metricDetailActions.setKeyAndKeyPath({ key: null, keyPath: null}))
  ))

  constructor(
    private readonly _actions: Actions,
    private readonly _store: Store,
    private _modalController: McmModalControllerService) {
  }

  private _createMetricDetailModal(): Observable<HTMLIonModalElement> {
    return this._modalController.createObs({
      component: MetricDetailComponent,
      cssClass: 'mcm-metric-detail__info-modal',
      backdropDismiss: true
    })
  }

  private _manageMetricDetail(payload, context, isMatch, isVsEnable, demarcation, competition) {
    const metricDetailInfoConfigFn = getMetricDetailConfigByContext(context)
    const config = metricDetailInfoConfigFn({...payload, isMatch, isVsEnable, demarcation, isoTypeLogo: competition.isoTypeLogo})
    return parseMetricDetail(config)
  }
}
