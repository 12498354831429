import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core'

import {
  ConnectionCoordinateType,
  FieldDiagramModifier,
  FieldDirection,
  IFieldDiagramData,
  TeamType
} from '@mediacoach-ui-library/global'

import { debounceTime, map } from 'rxjs/operators'

import { FIELD_DIAGRAM_DEFAULT_OPTIONS } from './field-diagram.constants'
import { SegmentModifier, SegmentOption } from '../segmented-control/segmented-control.models'

@Component({
  selector: 'app-field-diagram',
  templateUrl: './field-diagram.component.html',
  styleUrls: ['./field-diagram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldDiagramComponent {
  @Input() loading = false
  @Input() showPopUp
  @Input() title
  @Input() options: SegmentOption[] = [...FIELD_DIAGRAM_DEFAULT_OPTIONS]
  @Input() segmentValue = FIELD_DIAGRAM_DEFAULT_OPTIONS[0].id
  @Input() type: TeamType = TeamType.Home
  @Input() resetWhenClickOutside = true
  @Input() isColored: boolean
  @Input() playerPopUpTemplate: TemplateRef<any>
  @Input() arrowDirection: FieldDirection = FieldDirection.Right
  @Input() connectionCoordinateType: ConnectionCoordinateType
  @Input() selectedPlayerNumber = null
  @Input() keepPlayerSelected = false

  @Output() onChange: EventEmitter<string> = new EventEmitter<string>()
  @Output() onPlayerChange: EventEmitter<number | string> = new EventEmitter<number | string>()

  selectedPlayer$ = this.onPlayerChange.pipe(
    debounceTime(0),
    map((player) => !!player)
  )
  FieldDiagramModifier = FieldDiagramModifier
  SegmentModifier = SegmentModifier

  private _data: IFieldDiagramData

  constructor(private _ref: ChangeDetectorRef) {}

  get data(): IFieldDiagramData {
    return this._data
  }
  @Input() set data(_data: IFieldDiagramData) {
    this._data = _data
    this.onPlayerChange.emit(null)
    this._ref.markForCheck()
  }
}
