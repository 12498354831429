<ion-backdrop [visible]="true"></ion-backdrop>
<div
  class="mcm-modal"
  [class.mcm-modal--fullscreen]="fullscreen"
  [class.mcm-modal--overflow-auto]="overflowAuto"
  [ngClass]="extraClasses">
  <section class="mcm-modal__main">
    <app-header
      class="mcm-metric-detail-page__header"
      extraClasses="mcm-header--modal"
      [title]="title"
      [titleParams]="titleParams"
      [showBackButton]="false">
      <ng-template mcmTemplate="end-buttons">
        <app-button
          *ngIf="dismissible"
          icon="close-outline"
          extraClasses="mcm-button--xxl"
          (onClick)="onClosed()"></app-button>
      </ng-template>
    </app-header>

    <mcm-loader [loading]="loading">
      <div class="mcm-modal__main__body">
        <ng-content></ng-content>
      </div>
    </mcm-loader>
  </section>
  <ng-template [ngIf]="onWillLeave$ | async"></ng-template>
  <ng-template [ngIf]="onDidEnter$ | async"></ng-template>
</div>
