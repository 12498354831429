import { MetricAggregation } from '@core/enums/metric.enums'
import { RankingMetricType } from '@features/rankings/enums/ranking.enums'
import { Column } from '@shared/components/table/table.models'
import { MatchState } from '@mediacoach-ui-library/global'
import { DEFAULT_STICKY_ROW_STYLE } from '@shared/components/table/table.constants'

export const RANKINGS_FEATURE_TOKEN = 'rankings'

export const RANKING_AGGREGATION_TO_METRIC_TYPE_MAP = {
  [MetricAggregation.AverageValues]: RankingMetricType.Average,
  [MetricAggregation.AccumulatedValues]: RankingMetricType.Accumulate,
  [MetricAggregation.MatchValues]: RankingMetricType.Match
}
export const RANKING_METRIC_TYPE_TO_AGGREGATION_MAP = {
  [RankingMetricType.Average]: MetricAggregation.AverageValues,
  [RankingMetricType.Accumulate]: MetricAggregation.AccumulatedValues,
  [RankingMetricType.Match]: MetricAggregation.MatchValues
}

export const RANKING_TEAM_COLUMNS: Column[] = [
  {
    key: 'rank',
    styleClass: 'mcm-ranking-container__content-header first-column',
    label: 'MTR_TITLE_POSITION_SHORT',
    width: '80px'
  },
  {
    key: 'name',
    styleClass: 'mcm-ranking-container__content-header',
    label: 'MTR_TITLE_TEAM'
  }
]

export const RANKING_PLAYER_COLUMNS: Column[] = [
  {
    key: 'rank',
    styleClass: 'mcm-ranking-container__content-header first-column',
    label: 'MTR_TITLE_POSITION_SHORT',
    width: '60px'
  },
  {
    key: 'name',
    styleClass: 'mcm-ranking-container__content-header',
    label: 'MTR_TITLE_PLAYER'
  },
  {
    key: 'teamPhotoUrl',
    styleClass: 'mcm-ranking-container__content-header centered',
    label: 'MTR_TITLE_TEAM',
    width: '60px'
  },
  {
    key: 'position',
    styleClass: 'mcm-ranking-container__content-header',
    label: 'MTR_TITLE_POSITION',
    width: '75px'
  }
]

export const RANKING_MATCH_PREVIOUS_STATES = [
  MatchState.Pending,
  MatchState.Delayed,
  MatchState.LineupReady,
  MatchState.Postponed
]

export const RANKING_ROW_STYLES: Partial<CSSStyleDeclaration> = {
  ...DEFAULT_STICKY_ROW_STYLE,
  background: 'transparent',
  boxShadow: 'none'
}
