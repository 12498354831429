import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

import { IonicModule } from '@ionic/angular'

import { MetricPageRoutingModule } from './metric-routing.module'

import { MetricPage } from './metric.page'
import { SharedModule } from '@shared/shared.module'

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, MetricPageRoutingModule, SharedModule],
  declarations: [MetricPage]
})
export class MetricModule {}
