import {inject, Injectable} from '@angular/core'
import {Actions, createEffect, ofType} from '@ngrx/effects'
import {concatLatestFrom} from '@ngrx/operators'
import {filter, map, switchMap} from 'rxjs/operators'
import {Topics} from '@sockets/models/socket.model'
import {Topic} from '@sockets/enums/socket.enum'
import {Store} from '@ngrx/store'
import {SocketService} from '@sockets/services/socket.service'
import {
  connectSocket,
  disconnectSocket,
  fetchTopic,
  reconnectSocket,
  setSocketStatus,
  setTopic
} from '@core/store/actions/socket.actions'
import {selectTopic} from '@core/store/selectors/socket.selectors'
import {HubConnection, HubConnectionState} from "@microsoft/signalr";
import {of} from "rxjs";

@Injectable()
export class SocketEffects {
  private readonly _actions$: Actions = inject(Actions)
  private readonly _socket: SocketService = inject(SocketService)
  private readonly _store: Store = inject(Store)


  connectSocket$ = createEffect(
    () => this._actions$.pipe(
      ofType(connectSocket),
      switchMap(() => this._socket.connect()),
      map(({state: status}: HubConnection) => setSocketStatus({status}))
    )
  )

  disconnectSocket$ = createEffect(() => this._actions$.pipe(
      ofType(disconnectSocket),
      switchMap(() => this._socket.disconnect()),
      map(({state: status}: HubConnection) => setSocketStatus({status}))
    )
  )

  reconnectSocket$ = createEffect(() => this._actions$.pipe(
    ofType(reconnectSocket),
    switchMap(() => this._socket.hub()),

    switchMap((hub) => {
      return (hub.state === HubConnectionState.Connected)
        ? this._socket.disconnect()
        : of(hub)
    }),
    filter((hub) => hub.state !== HubConnectionState.Connected),
    switchMap(() => this._socket.connect()),
    map(({state: status}: HubConnection) => setSocketStatus({status}))
  ))

  getTopic$ = createEffect(
    () => this._actions$.pipe(
      ofType(fetchTopic),
      map(({socketMessage}) => {
        let msg: string
        try {
          msg = JSON.parse(socketMessage.message)
        } catch {
          msg = JSON.parse(`"${socketMessage.message}"`)
        }
        return {
          socketMessage: {topic: socketMessage.topic, message: msg} as { topic: string, message: unknown },
          topic: {[socketMessage.topic]: msg} as Topics
        }
      }),
      concatLatestFrom(() => this._store.select(selectTopic(Topic.MatchIdWithNewAssets))),
      switchMap(([{socketMessage, topic}, currentMatches]) => {
        const actions = [setTopic({topic: topic})]
        if (socketMessage.topic === Topic.LiveMatches) {
          const msg = (socketMessage.message || []) as { id: string }[]
          actions.push(
            setTopic({topic: {[Topic.IsLive]: msg.length > 0}}),
            setTopic({
              topic: {
                [Topic.MatchIdWithNewAssets]:
                  Array.from(new Set([...msg.map(m => m.id), ...(currentMatches || []) as string[]]))
              }
            })
          )
        } else if (socketMessage.topic === Topic.UpdateVersion) {
          const msg = (socketMessage.message || {}) as { buildVersion: string; forceUpdateVersion: string }
          actions.push(
            setTopic({topic: {[Topic.ForceUpdateVersion]: JSON.parse(msg.forceUpdateVersion)}}),
            setTopic({topic: {[Topic.BuildVersion]: msg.buildVersion}}),
          )
        }
        return actions
      }),
    )
  )


}
