import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { MetricPage } from './metric.page'

const routes: Routes = [
  {
    path: '',
    component: MetricPage
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MetricPageRoutingModule {}
