import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { setMetricHolder, setMetrics } from '@core/store/actions/metric-finder.actions'
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { MetricsApi } from '@core/services/metric-finder/metrics.api'
import { Store } from '@ngrx/store'
import { throwError } from 'rxjs'
import { fetchMatchMetrics, initializeMatchMetrics } from '@core/store/actions/match-metric-finder.actions'
import { initializeMetricAggregation } from '@core/store/actions/metric-aggregation.actions'
import { SharedMatchApi } from '@core/requests/api/match/shared-match.api'
import { mapMatch } from '@core/utils/match.utils'

@Injectable()
export class MatchMetricFinderEffects {

  initializeMatchMetrics$ = createEffect(() => this._actions$.pipe(
    ofType(initializeMatchMetrics),
    mergeMap(({matchId, context})=> [
      initializeMetricAggregation({isVsEnabled: true, isMatch: true, context}),
      fetchMatchMetrics({matchId})
    ])
  ))

  fetchMatchMetrics$ = createEffect(() => this._actions$.pipe(
    ofType(fetchMatchMetrics),
    switchMap(({matchId}) => this._sharedMatchApi.fetchMatch(matchId).pipe(
      map((match) => mapMatch(match)),
      tap((match) => this._store.dispatch(setMetricHolder({metricHolder: match}))),
      switchMap(() => this._api.fetchMatchMetrics(matchId)),
      catchError(err => {
        console.error(err)
        return throwError(() => err)
      })
    )),
    map(metrics => setMetrics({metrics})),
  ))


  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
    private readonly _sharedMatchApi: SharedMatchApi,
    private readonly _api: MetricsApi) {
  }
}
