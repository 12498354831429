import { MetricAggregation } from '@core/enums/metric.enums'
import { MetricAggregationModeSelectable } from '@core/models/metric.models'

export const COMMON_AGGREGATION_MODES: MetricAggregationModeSelectable[] = [
  { id: MetricAggregation.AccumulatedValues, text: 'MTR_COMMON_ACCUMULATED' },
  { id: MetricAggregation.AverageValues, text: 'MTR_COMMON_AVERAGE', alt: 'MTR_COMMON_AVERAGE_COMPETITION' }
]
export const MATCH_CONTEXT_AGGREGATION_MODES: MetricAggregationModeSelectable[] = [
  ...COMMON_AGGREGATION_MODES,
  { id: MetricAggregation.MatchValues, text: 'MTR_COMMON_MATCH_VALUE' }
]

export const AGGREGATED_METRIC_LABEL_MAP = {
  [MetricAggregation.AverageValues]: 'MTR_COMMON_AVERAGE',
  [MetricAggregation.AccumulatedValues]: 'MTR_COMMON_ACCUMULATED',
  [MetricAggregation.MatchValues]: 'MTR_COMMON_MATCH_VALUE'
}

export const AGGREGATED_METRIC_VALUE_SUFFIX_MAP = {
  [MetricAggregation.AverageValues]: 'AverageMetricResult',
  [MetricAggregation.AccumulatedValues]: 'AccumulatedMetricResult',
  [MetricAggregation.MatchValues]: 'MatchMetricResult'
}

export const DEFAULT_AVERAGE_METRIC_AGGREGATION_HEADER = {
  text: AGGREGATED_METRIC_LABEL_MAP[MetricAggregation.AverageValues]
}
export const DEFAULT_ACCUMULATED_METRIC_AGGREGATION_HEADER = {
  text: AGGREGATED_METRIC_LABEL_MAP[MetricAggregation.AccumulatedValues]
}
export const DEFAULT_MATCH_VALUE_METRIC_AGGREGATION_HEADER = {
  text: AGGREGATED_METRIC_LABEL_MAP[MetricAggregation.MatchValues]
}
