import { Location } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'

import { Observable, timer } from 'rxjs'
import { debounce, filter, map, pairwise, startWith, tap } from 'rxjs/operators'

import { FlattenDeep, List, ListType } from '@mediacoach-ui-library/global'

import { TitleSize } from '../title/title.constants'
import { ToastService } from '@core/services/toast/toast.service'
import { deepEqual, toDictionary } from '@core/utils/object.utils'
import { MenuService } from '@core/services/menu/menu.service'

interface MetricItem {
  key: string
  name: string
  isSelected: boolean
}

@Component({
  selector: 'app-metric-list',
  templateUrl: './metric-list.component.html',
  styleUrls: ['./metric-list.component.scss']
})
export class MetricListComponent {
  @Input() limit: number
  @Output() onChange = new EventEmitter()
  @Output() isSelecting = new EventEmitter<boolean>()

  selectedMetrics
  preselectionCount = 0
  form: UntypedFormGroup
  formChanges$: Observable<any>

  TitleSize = TitleSize

  private _metricCategories: {
    groups: {
      key: string
      name: string
      metrics: MetricItem[]
    }[]
    label: string
    metricListConfig?: List<MetricItem>
  }[]
  private _metricsObj: { [key: string]: MetricItem }
  private _metrics: MetricItem[]

  constructor(
    private fb: UntypedFormBuilder,
    private _toastService: ToastService,
    private _location: Location,
    private readonly _menuService: MenuService
  ) {}

  get metricCategories() {
    return this._metricCategories
  }
  @Input() set metricCategories(_metricCategories) {
    this._metricCategories = (_metricCategories || []).map((category) => ({
      ...category,
      metricListConfig: {
        type: ListType.Custom,
        groups: category.groups.map((group) => ({
          items: group.metrics,
          title: group.name
        }))
      }
    }))
    this._metrics = FlattenDeep(this.metricCategories.map(({ groups }) => groups.map(({ metrics }) => metrics)))
    this._metricsObj = toDictionary(this._metrics, 'key')
    this._createForm()
    this._menuService.updateAutoHeight()
  }

  getPreselectionCount(metrics = this.form.value) {
    this.preselectionCount = Object.values(metrics).filter((value) => value).length
  }

  onClick({ key }) {
    const formControl = this.form.get(key)
    if (formControl.value || this.preselectionCount < this.limit) {
      formControl.setValue(!formControl.value)
      formControl.markAsDirty()
    } else if (this.preselectionCount >= this.limit) {
      this._toastService.create({
        color: 'danger',
        message: 'MTR_MORE_MAX_FAV_METRICS_WARNING',
        messageParams: { limit: this.limit }
      })
    }
  }

  private _createForm() {
    this.form = this.fb.group(
      this._metrics.reduce(
        (obj, metric) => ({
          ...obj,
          [metric.key]: metric.isSelected
        }),
        {}
      )
    )

    this._saveMetrics()

    this.formChanges$ = this.form.valueChanges.pipe(
      tap(() => {
        this.isSelecting.next(true)
        this.getPreselectionCount()
      }),
      debounce(() => timer(deepEqual(this.selectedMetrics, this.form.value) ? 0 : 3000)),
      startWith(() => this.form.value),
      pairwise(),
      tap(() => {
        this.isSelecting.next(false)
      }),
      map(([a, b]) =>
        Object.entries(this.form.controls)
          .filter(([key, formControl]) => formControl.dirty && a[key] !== b[key])
          .map(([key, formControl]) => ({
            ...this._metricsObj[key],
            isSelected: formControl.value
          }))
      ),
      filter((metrics) => metrics.length > 0),
      tap((metrics) => {
        this.onChange.next(metrics)
        this._saveMetrics()
      })
    )

    this.getPreselectionCount()
  }

  private _saveMetrics() {
    this.selectedMetrics = this.form.value
  }
}
