<div class="mcm-header-player" [ngClass]="fromMatch ? 'mcm-header-player--match' : 'mcm-header-player--home'">
  <img
    loading="lazy"
    [srcUrl]="player?.portraitLogo"
    [appDefaultImage]="PLAYER_PLACEHOLDER_IMAGE"
    class="mcm-header-player__image"
    [alt]="player?.metricPlayerName" />
  <div class="mcm-header-player__body">
    <h1 class="mcm-header-player__body__name">{{ player?.metricPlayerName || '&nbsp;' }}</h1>
    <div class="mcm-header-player__body__position">
      {{ (player | demarcation: playerDemarcationKeys : playerDemarcationFallbackKey : ' ') || '&nbsp;' }}
    </div>
    <div *ngIf="!fromMatch" class="mcm-header-player__body__team">
      <img
        loading="lazy"
        [srcUrl]="player?.team?.portraitLogo"
        [appDefaultImage]="SHIELD_PLACEHOLDER_IMAGE"
        class="mcm-header-player__body__team__logo" />
      {{ player?.team?.name }}
    </div>
  </div>
  <ul class="mcm-header-player__stats">
    <li *ngFor="let stat of (fromMatch ? STATS.MATCH : STATS.NO_MATCH)" class="mcm-header-player__stats__item">
      <span class="mcm-header-player__stats__item__label">{{ stat.key | translate }}</span>
      <span class="mcm-header-player__stats__item__value">
        {{ (player && player[stat.valueKey] !== null) ? ((player[stat.valueKey] | translate) + ' ' + ((stat.unit |
        translate) || '')) : '-' }}
      </span>
    </li>
    <li *ngFor="let stat of (fromMatch ? player?.headerMetricResults : [])" class="mcm-header-player__stats__item">
      <span class="mcm-header-player__stats__item__label">{{ stat.key | translate }}</span>
      <span class="mcm-header-player__stats__item__value">
        {{ stat.value !== null && (stat.value >= 0) ? ((stat.value | translate) + ' ' +
        ((STATS_WITH_UNITS_DICTIONARY[stat.key] || '') | translate)) : '-' }}
      </span>
    </li>
  </ul>
</div>
<img
  loading="lazy"
  *ngIf="!fromMatch"
  [srcUrl]="player?.team?.portraitLogo"
  [appDefaultImage]="SHIELD_PLACEHOLDER_IMAGE"
  class="mcm-header-player-bg mcm-header-player-bg--single" />
<img
  loading="lazy"
  *ngIf="fromMatch"
  [srcUrl]="player?.homeTeam?.portraitLogo"
  [appDefaultImage]="SHIELD_PLACEHOLDER_IMAGE"
  class="mcm-header-player-bg mcm-header-player-bg--left" />
<img
  loading="lazy"
  *ngIf="fromMatch"
  [srcUrl]="player?.awayTeam?.portraitLogo"
  [appDefaultImage]="SHIELD_PLACEHOLDER_IMAGE"
  class="mcm-header-player-bg mcm-header-player-bg--right" />
