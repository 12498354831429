import { Component, Input, OnInit } from '@angular/core'
import { TeamBadge } from './team-badge.models'
import { SHIELD_PLACEHOLDER_IMAGE } from '@core/constants/asset-path'

@Component({
  selector: 'app-team-badge',
  templateUrl: './team-badge.component.html',
  styleUrls: ['./team-badge.component.scss']
})
export class TeamBadgeComponent implements OnInit {
  @Input() team: TeamBadge

  readonly teamPlaceholder: string = SHIELD_PLACEHOLDER_IMAGE

  constructor() {}

  ngOnInit() {}
}
