import { Injectable, NgZone } from '@angular/core'
import { FCM } from '@capacitor-community/fcm'
import { Capacitor } from '@capacitor/core'
import { Router } from '@angular/router'
import { PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications'
import { LocalNotifications } from '@capacitor/local-notifications'
import { PreferencesService } from '@core/services/preferences/preferences.service'
import { Preference } from '@core/services/preferences/preferences.enum'

/* eslint-disable no-console */

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _userId: string
  private _initialized: boolean

  constructor(
    private readonly _router: Router,
    private readonly _zone: NgZone,
    private readonly _preferences: PreferencesService
  ) {}

  async initialize(userId: string) {
    if (Capacitor.getPlatform() !== 'web') {
      if (!this._initialized) {
        console.log('[PUSH] Initializing notification service')
        this._initialized = true
        await FCM.setAutoInit({ enabled: true })
        await this._handleDefaultNotificationState(Preference.NotificationLivePro)
        await this._setupListeners()
        await this._registerNotifications()
      }
      if (userId !== this._userId) {
        console.log('[PUSH] Registering user:', userId)
        this._userId = userId
        await this.handleSubscriptionToUserIdTopic(
          await this._preferences.getPreference(Preference.NotificationLivePro)
        )
      }
    }
  }

  async handleSubscriptionToUserIdTopic(enable: boolean) {
    if (Capacitor.getPlatform() !== 'web') {
      if (enable) {
        FCM.subscribeTo({ topic: this._userId })
          .then(() => console.log('[PUSH] Subscribed to Topic successfully', this._userId))
          .catch(() => console.log('[PUSH] Unable to subscribe to topic', this._userId))
      } else {
        FCM.unsubscribeFrom({ topic: this._userId })
          .then(() => console.log('[PUSH] UnSubscribed to Topic successfully', this._userId))
          .catch(() => console.log('[PUSH] Unable to Unsubscribe to topic', this._userId))
      }
    }
  }

  private async _handleDefaultNotificationState(...prefKeys: Preference[]) {
    for (const prefKey of prefKeys) {
      const enabled = await this._preferences.getPreference(prefKey)
      // enable LivePRO notifications by default
      if (enabled !== false) {
        await this._preferences.setPreference(prefKey, true)
      }
    }
  }

  private async _setupListeners() {
    await PushNotifications.addListener('registration', async (token) => {
      try {
        const fcmToken = await (Capacitor.getPlatform() === 'ios'
          ? FCM.getToken()
          : Promise.resolve({ token: token.value }))
        console.info('[PUSH] Registration token', fcmToken.token)
      } catch (e) {
        console.error('[PUSH] ERROR: unable to get FCM token', e)
      }
    })
    await PushNotifications.addListener('registrationError', (err) =>
      console.error('[PUSH] Registration error: ', err.error)
    )

    await PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
      console.log('[PUSH] Notification received: ', JSON.stringify(notification))
      if (Capacitor.getPlatform() === 'android') {
        await this._notify(notification)
      }
    })
    await PushNotifications.addListener('pushNotificationActionPerformed', ({ notification }) =>
      this._handleNotificationAction(notification?.data)
    )

    await LocalNotifications.addListener('localNotificationActionPerformed', ({ notification }) =>
      this._handleNotificationAction(notification?.extra)
    )
    console.log('[PUSH] Notification listeners ready')
  }

  private async _registerNotifications() {
    try {
      let pushStatus = await PushNotifications.checkPermissions()
      let locStatus = await LocalNotifications.checkPermissions()
      console.log('[PUSH] PushNotification permissions status', pushStatus.receive)
      console.log('[PUSH] LocalNotification permissions status', locStatus.display)

      if (pushStatus.receive !== 'denied' && pushStatus.receive !== 'granted') {
        pushStatus = await PushNotifications.requestPermissions()
      }
      if (locStatus.display !== 'denied' && locStatus.display !== 'granted') {
        locStatus = await LocalNotifications.requestPermissions()
      }

      if (pushStatus.receive !== 'granted' || locStatus.display !== 'granted') {
        throw new Error('[PUSH] User denied permissions!')
      }

      console.log('[PUSH] Registering device to firebase')
      await PushNotifications.register()
    } catch (e) {
      console.error('[PUSH] Unable to grant permissions', e)
    }
  }

  private async _notify(notification: PushNotificationSchema) {
    console.log('[PUSH] Local notification', JSON.stringify(notification))
    try {
      await LocalNotifications.schedule({
        notifications: [
          {
            title: `${notification.title || ''}`,
            body: notification.body || '',
            // eslint-disable-next-line no-bitwise
            id: Capacitor.getPlatform() === 'ios' ? new Date().getTime() : (Math.random() * 2 ** 32) | 0,
            extra: notification.data,
            smallIcon: 'push_icon',
            largeIcon: 'ic_launcher',
            largeBody: notification.body || ''
          }
        ]
      })
    } catch (e) {
      console.error('[PUSH] ERROR! Unable to schedule a local notification', e)
    }
  }

  private async _handleNotificationAction(action: any) {
    console.log('[PUSH] Notification action performed: ', action)
    if (action?.matchId) {
      await this._router.navigate(['/matches', action?.matchId])
    }
  }
}

/* eslint-enable no-console */
