import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'excludePlayer'
})
export class ExcludePlayerPipe implements PipeTransform {
  transform(playerList: any, playerId: string, idKey = 'id'): any {
    if (playerList && playerId) {
      return {
        ...playerList,
        groups: [{ items: playerList.groups[0].items.filter((p) => p[idKey] !== playerId) }]
      }
    }
    return playerList
  }
}
