import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { switchMap, take, tap } from 'rxjs/operators'
import { unauthorizedError } from '@core/store/actions/error.actions'
import { Store } from '@ngrx/store'
import { signOut } from '@core/store/actions/auth.actions'
import { CoreAuthService } from '@core/services/auth/core-auth-service'
import { Platform } from '@ionic/angular'
import { environment } from '@env'

@Injectable()
export class ErrorEffects {
  unauthorizedError$ = createEffect(() =>
    this._actions$.pipe(
      ofType(unauthorizedError),
      switchMap(() => this._ionicAuth.token$.pipe(take(1))),
      tap((tokenResponse) => {
        if (tokenResponse?.isValid(0)) {
          const IDENTITY = environment.IDENTITY_SERVER(this._platform.is('capacitor'))
          this._store.dispatch(signOut(IDENTITY.END_SESSION_REDIRECT_CONCURRENCY_URI))
        } else {
          this._store.dispatch(signOut())
        }
      })
    ), { dispatch: false }
  )

  constructor(
    private readonly _store: Store,
    private readonly _actions$: Actions,
    private readonly _platform: Platform,
    private readonly _ionicAuth: CoreAuthService,
  ) {}
}
