import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { filter } from 'rxjs/operators'
import { IonicPageState } from '../../components/ionic-page/ionic-page.models'

@Injectable({
  providedIn: 'root'
})
export class IonicPageVisibilityService {
  pageVisibility$ = new Subject<{ state: IonicPageState; component: any }>()
  onWillEnter$ = this.pageVisibility$.pipe(filter(({ state }) => state === IonicPageState.WillEnter))
  onDidEnter$ = this.pageVisibility$.pipe(filter(({ state }) => state === IonicPageState.DidEnter))
  onWillLeave$ = this.pageVisibility$.pipe(filter(({ state }) => state === IonicPageState.WillLeave))
  onDidLeave$ = this.pageVisibility$.pipe(filter(({ state }) => state === IonicPageState.DidLeave))

  constructor() {}
}
