import { Component, Input } from '@angular/core'

import { FIELDS } from './team-state.constants'
import { Store } from '@ngrx/store'
import { selectCurrentCompetition } from '@core/store/selectors/season.selector'

@Component({
  selector: 'app-team-state',
  templateUrl: './team-state.component.html',
  styleUrls: ['./team-state.component.scss']
})
export class TeamStateComponent {
  @Input() stats: any
  readonly currentCompetition$ = this._store.pipe(selectCurrentCompetition)

  FIELDS = FIELDS

  constructor(private readonly _store: Store) {}
}
