import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  @Input() matches: any
  @Input() teamId: string
  @Input() minDate: Date
  @Input() maxDate: Date

  @Output() onMonthChange = new EventEmitter()
  @Output() onSelect = new EventEmitter()

  value

  // FIXME CREATE DICTIONARY FOR THIS AND REFRESH IT ONLY WHEN NEW MATCHES ARE PASSED
  dayHasMatches(day) {
    const dayMatches = (this.matches || []).find(function (today) {
      return today.date.getDate() === day
    })
    return !!dayMatches && dayMatches
  }

  favTeamHasMatch(day) {
    const dayMatches = this.dayHasMatches(day)

    if (dayMatches) {
      // Is team playing home or away
      const myMatch = dayMatches.matches.find(
        (match) => match.homeTeam.teamId === this.teamId || match.awayTeam.teamId === this.teamId
      )
      if (myMatch !== undefined) {
        return { home: myMatch.homeTeam.statics[0].url, away: myMatch.awayTeam.statics[0].url }
      }
      return false
    }
    return false
  }

  onMonthChanged($event) {
    this.onMonthChange.emit($event)
  }

  onSelected($event) {
    this.onSelect.emit($event)
  }
}
