import { Injectable } from '@angular/core'
import { Actions,  createEffect, ofType } from '@ngrx/effects'
import { concatLatestFrom } from '@ngrx/operators'
import { map } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { setMetrics } from '@core/store/actions/metric-finder.actions'
import { selectMetricHolder } from '@core/store/selectors/metric-finder.selectors'
import { metricDetailActions } from '@features/metric-detail/store/actions/metric-detail.actions'

@Injectable()
export class MetricFinderEffects {

  manageMetricFinderMetricDetailConfig = createEffect(() => this._actions$.pipe(
    ofType(setMetrics),
    concatLatestFrom(() => this._store.select(selectMetricHolder)),
    map(([ { metrics }, holder]) => ({ metrics, holder })),
    map((payload) => metricDetailActions.manageConfig({ payload }))
  ))

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store) {
  }
}
