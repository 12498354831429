<mcm-loader [loading]="!data">
  <mc-list
    #mcList
    [data]="data"
    [limit]="limit"
    [isCollapsible]="isCollapsible"
    [showTeamMarks]="showTeamMarks"
    [itemTemplate]="customTemplate || itemTemplate"
    [middleHeaderTemplate]="headerToggle"
    [showAggregationIcon]="showAggregationIcon"
    [aggregationHeader]="aggregationHeader"
    [ngClass]="{'is-vs-enabled': isVsEnabled}"
    (onClick)="onClick.emit($event)"
    (onItemSelected)="onItemSelected.emit($event)"
    (aggregationModeClick)="aggregationModeClick.emit($event)">
  </mc-list>
</mcm-loader>

<ng-template #playerItem let-item>
  <div
    mcmLongPress
    [active]="longPressActive"
    (longPress)="openContextMenu(item, playerLongPressContainer)"
    #playerLongPressContainer>
    <mc-player-list-item [data]="item"> </mc-player-list-item>
  </div>
</ng-template>

<ng-template #metricItem let-item>
  <div
    mcmLongPress
    [active]="longPressActive"
    (longPress)="openContextMenu(item, metricLongPressContainer)"
    #metricLongPressContainer>
    <mc-metric-item [metric]="item" [gradientAccent]="true"></mc-metric-item>
  </div>
</ng-template>

<ng-template #commonItem let-item>
  @switch (item.isProfileItem) { @case (true) {
  <ng-container [ngTemplateOutlet]="profileItemTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
  } @default {
  <div
    mcmLongPress
    [active]="longPressActive"
    (longPress)="openContextMenu(item, commonLongPressContainer)"
    #commonLongPressContainer>
    <mc-common-list-item [data]="item"></mc-common-list-item>
  </div>
  } }
</ng-template>

<ng-template #matchItem let-item>
  <div
    mcmLongPress
    [active]="longPressActive"
    (longPress)="openContextMenu(item, matchLongPressContainer)"
    #matchLongPressContainer>
    <app-match-item [match]="item" [type]="MatchItemType.Listed" [isToday]="item.isToday"></app-match-item>
    <i class="mcm-list__icon mcm-list__icon--action u-icon--dart-right-squared"></i>
  </div>
</ng-template>

<ng-template #simpleItem let-item>
  <div
    mcmLongPress
    [active]="longPressActive"
    (longPress)="openContextMenu(item, simpleLongPressContainer)"
    #simpleLongPressContainer>
    <app-title
      [text]="item.text"
      [alignment]="TitleAlignment.Center"
      [size]="TitleSize.SM"
      [weight]="TitleWeight.Bold"></app-title>
  </div>
</ng-template>

<ng-template #headerToggle let-header>
  <mc-input-switch
    *ngIf="header.isSwitchable"
    [isDoubleMode]="true"
    [label]="header.switcherValues"
    [ngModel]="listToggle"
    (onChange)="onSwitchChange($event)"></mc-input-switch>
</ng-template>

<mcm-context-menu *ngIf="contextMenuItems.length" [triggerOpen]="triggerOpenContextMenu.asObservable()">
  <mcm-content-menu-item *ngFor="let item of contextMenuItems" (onClick)="clickContextMenuItem(item)">
    {{item?.action | translate}}
  </mcm-content-menu-item>
</mcm-context-menu>
