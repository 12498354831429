import { Injectable } from '@angular/core'

import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { PARAM_MAP, QUERY_MAP } from './constants/firebase-analytics.constants'
import { ActivatedRoute, ActivatedRouteSnapshot, Event, NavigationEnd, Router, RouterEvent } from '@angular/router'
import { filter, map, switchMap, take, tap } from 'rxjs/operators'
import { FirebaseEvent } from './models/firebase-analytics.models'
import { TranslatorService } from '@mediacoach-ui-library/global'
import {
  FirebaseAnalyticsCategory,
  FirebaseAnalyticsEvent,
  FirebaseAnalyticsParam
} from './enums/firebase-analytics.enums'
import { UserProfile } from '../../auth/auth.models'
import { BackEndTranslatorService } from '../../translator/back-end-translator.service'
import { RouteUrl } from '@core/models/models'
import { initFirebase, mapRouteParams, updateProperties } from './utils/firebase-analytics.utils'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { fromPromise } from 'rxjs/internal/observable/innerFrom'
import { Store } from '@ngrx/store'
import { selectProfile } from '@core/store/selectors/user.selectors'

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class FirebaseAnalyticsService {
  private _user: UserProfile
  private _currentTitle: string
  private _previousTitle: string
  private _currentUrl: string
  private _attGranted: boolean

  constructor(
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _store: Store,
    private readonly _translator: TranslatorService,
    private readonly _backEndTranslatorService: BackEndTranslatorService
  ) {
    initFirebase().then(() => {
      this._router.events
        .pipe(
          filter((e: Event | RouterEvent) => e instanceof NavigationEnd),
          switchMap((event) =>
            this._store.select(selectProfile).pipe(
              filter((user) => !!user),
              take(1),
              map((user) => ({ user, event: event as NavigationEnd, snapshot: this._getCurrentRouteSnapshot() }))
            )
          ),
          tap(({ event, snapshot, user }) => this._updateCurrentValues(event.url, snapshot, user)),
          map(({ snapshot, event }) => this._getRouteParams(event.url, snapshot)),
          switchMap((params) => fromPromise(this._logPageView(params))),
          untilDestroyed(this)
        )
        .subscribe()
    })
  }

  registerUser(attGranted: boolean) {
    this._attGranted = attGranted
    this._store
      .select(selectProfile)
      .pipe(untilDestroyed(this))
      .subscribe((user) => this.setUserProperties(user))
  }

  async setUserProperties(user: UserProfile): Promise<unknown> {
    this._user = user
    return updateProperties(user, this._attGranted)
  }

  logEvent(name: string, params: FirebaseEvent): void {
    updateProperties(this._user, this._attGranted)

    const _params = {
      ...this._getRouteParams(),
      ...params,
      ...(params[FirebaseAnalyticsParam.pageReferrerTitle]
        ? { [FirebaseAnalyticsParam.pageReferrerTitle]: params[FirebaseAnalyticsParam.pageReferrerTitle] }
        : {}), // only applies if there is a router transition involved, should be the previous page URL
      [FirebaseAnalyticsParam.pageTitle]: params.page_title || this._currentTitle // Human readable page title
    }

    FirebaseAnalytics.logEvent({
      name,
      params: _params
    })
  }

  private _logPageView(params: { [key: string]: string }): Promise<unknown> {
    return FirebaseAnalytics.logEvent({
      name: FirebaseAnalyticsEvent.pageView,
      params: {
        [FirebaseAnalyticsParam.category]: FirebaseAnalyticsCategory.navigation,
        [FirebaseAnalyticsParam.pageTitle]: this._currentTitle,
        [FirebaseAnalyticsParam.pageReferrerTitle]: this._previousTitle,
        ...params
      }
    })
  }

  private _updateCurrentValues(url: string, snapshot: ActivatedRouteSnapshot, user: UserProfile) {
    this._user = user
    this._previousTitle = this._currentTitle
    this._currentUrl = url
    this._currentTitle = `${snapshot.data.title}${
      snapshot.data.concatParam ? ' | ' + snapshot.params[snapshot.data.concatParam] : ''
    }`
  }

  private _getTeamIdParam(url: string): { [k: string]: string } {
    return new RegExp(RouteUrl.Main).test(url)
      ? {
          [FirebaseAnalyticsParam.favTeamId as string]: this._user?.favouriteTeamId,
          [FirebaseAnalyticsParam.favCompetitionId as string]: this._user?.favouriteCompetitionId
        }
      : {}
  }

  private _getRouteParams(url?: string, snapshot?: ActivatedRouteSnapshot): { [k: string]: string } {
    const _snapshot = snapshot || this._getCurrentRouteSnapshot()
    return {
      ...this._getTeamIdParam(url || this._currentUrl),
      ...this._parseParamMap(_snapshot.params, PARAM_MAP, /compare/.test(this._router.url)),
      ...this._parseParamMap(_snapshot.queryParams, QUERY_MAP)
    }
  }

  private _getCurrentRouteSnapshot(): ActivatedRouteSnapshot {
    let child = this._route.firstChild
    while (child.firstChild) {
      child = child.firstChild
    }
    return child.snapshot
  }

  private _parseParamMap(
    params: { [key: string]: string },
    paramMap: { [key: string]: string },
    isComparison?: boolean
  ): { [key: string]: string } {
    const _paramMap = mapRouteParams(params, paramMap, isComparison)

    if (isComparison) {
      _paramMap.team_id_A = _paramMap.team_id_A || this._user?.favouriteTeamId
      _paramMap.competition_id_A = _paramMap.competition_id_A || this._user?.favouriteCompetitionId
    }

    return _paramMap
  }
}
