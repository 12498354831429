import { createAction, props } from '@ngrx/store'
import { MetricFinderKey } from '@core/models/models'

export const initializeMatchMetrics = createAction(
  '[MetricFinder] initialize match metrics',
  props<{
    matchId: string
    context: MetricFinderKey
  }>()
)
export const fetchMatchMetrics = createAction('[MetricFinder] fetch match metrics', props<{ matchId: string }>())
