<ng-container
  *ngIf="{
  rows: rows$ | loading | async,
  columns: columns$ | async,
  metricKey: metricKey$ | async,
  metricContext: metricContext$ | async,
  displayMode: displayMode$ | async,
  aggregationModes: aggregationModes$ | async,
  aggregationMode: aggregationMode$ | async,
  warning: warning$ | async,
  header: header$ | async,
  showFooter: showFooter$ | async
} as ranking">
  <div class="mcm-ranking-container">
    <mcm-ranking-header [header]="ranking?.header" (closeModal)="close()"></mcm-ranking-header>
    <div class="mcm-ranking-container__selector">
      <app-segmented-control
        [options]="modes"
        [value]="payload?.metricContext?.overrideAggregationMode || aggregationMode"
        [modifiers]="segmentModifiers"
        (onChange)="onModeChange($event)">
      </app-segmented-control>
    </div>
    <ng-container *ngIf="ranking?.rows?.value?.length >=2; else warning">
      <div class="mcm-ranking-container__content">
        <mcm-table
          headerHeight="31px"
          rowHeight="40px"
          [drawRowHolder]="true"
          [headerTpl]="headerTpl"
          [rows]="ranking.rows.value"
          [loading]="ranking.rows.loading"
          [rowStyles]="rowStyles"
          [columns]="ranking.columns"
          [cellTpl]="cellTpl"
          [sortable]="true"
          [trackByFn]="trackByFn.bind(this)">
          <ng-template #headerTpl let-column="column" let-isFirstColumn="isFirstColumn">
            <div class="mcm-ranking-container__content-header" [class.first-column]="isFirstColumn">
              {{column.label | translate}}
            </div>
          </ng-template>
          <ng-template #cellTpl let-row="row" let-column="column">
            <ng-container *ngIf="column.key === 'name'">
              <img
                loading="lazy"
                class="mcm-ranking-container__content-image rounded"
                [srcUrl]="row?.photoUrl"
                [appDefaultImage]="row.imagePlaceholder"
                alt="" />
              <div class="mcm-ranking-container__content-name" [class.low-involvement]="row.lowInvolvement">
                {{row[column.key] | translate | invalidValueHandler }}
              </div>
            </ng-container>

            <ng-container *ngIf="column.key !== 'teamPhotoUrl' && column.key !== 'name'">
              {{row[column.key] | translate | invalidValueHandler }}</ng-container
            >

            <div
              class="mcm-ranking-container__content-image centered"
              [class.team]="!row?.isCompetition"
              *ngIf="column.key === 'teamPhotoUrl'">
              <img
                loading="lazy"
                *ngIf="!row?.isCompetition"
                class="mcm-ranking-container__content-image"
                [srcUrl]="row?.teamPhotoUrl"
                [appDefaultImage]="row.teamPlaceholder"
                alt="" />
              <ng-container *ngIf="row?.isCompetition">-</ng-container>
            </div>
          </ng-template>
        </mcm-table>
      </div>
      <div class="mcm-ranking-container__footer" *ngIf="ranking?.showFooter">
        * {{'MTR_RANKING_FOOTER' | translate}}
      </div>
    </ng-container>
    <ng-template #warning>
      <div
        class="mcm-ranking-container__warning"
        *mcmVar="ranking.warning.param ? {aggregation: (ranking.warning.param | translate)} : '' as param">
        <ion-icon name="information-circle-outline" size="large" color="primary"></ion-icon>
        <p>
          {{ ranking.warning.param ? (ranking.warning.text | translate: param) : ranking.warning.text | translate }}
        </p>
      </div>
    </ng-template>
  </div>
</ng-container>
