import { RootState } from '@core/store/states/root.state'
import { createSelector } from '@ngrx/store'
import {
  hasNoRankingAvailableAtAll,
  hasPercentageRankingOnAccumulatedMode,
  hasRankingAvailableWhenIsMultiEntity,
  hasRankingAvailableWhenIsSingleEntity,
  resolveRankingColumns,
  resolveRankingHeader,
  resolveRankingRows
} from '@features/rankings/utils/ranking.utils'
import {
  RANKING_MATCH_PREVIOUS_STATES,
  RANKING_METRIC_TYPE_TO_AGGREGATION_MAP
} from '@features/rankings/constants/rankings.constants'
import { MetricAggregation } from '@core/enums/metric.enums'
import { RankingWarningMode } from '@features/rankings/enums/ranking.enums'
import {
  selectAggregationContext,
  selectMetricAggregationMetadata
} from '@core/store/selectors/metric-aggregation.selectors'
import { AGGREGATED_METRIC_LABEL_MAP } from '@core/constants/metric-aggregation.constants'
import { MetricFinderKey } from '@core/models/models'
import { selectCurrentSeasonAndCompetition } from '@core/store/selectors/season.selector'

export const selectRankings = (state: RootState) => state.rankings

export const selectRanking = createSelector(selectRankings, (state) => state.ranking)
export const selectSourceMetric = createSelector(selectRankings, (state) => state.sourceMetric)

export const selectMetricToRank = createSelector(selectRankings, (state) => state.metricToRank)

export const selectRankingAggregation = createSelector(selectMetricToRank, (metricToRank) =>
  metricToRank ? RANKING_METRIC_TYPE_TO_AGGREGATION_MAP[metricToRank.metricType] : MetricAggregation.AccumulatedValues
)

export const selectRankedMetricKey = createSelector(selectMetricToRank, (metricToRank) => metricToRank?.metricName)

export const selectRankedMetricContext = createSelector(selectRankings, (state) => state.metricContext)

export const selectRankingMatchDay = createSelector(selectRankings, (state) => state.matchDay)

export const selectRankedMetricDisplayMode = createSelector(selectRankings, (state) => state.displayMode)

export const selectRankingHeader = createSelector(
  selectRankingMatchDay,
  selectRankedMetricDisplayMode,
  selectRankedMetricContext,
  selectRankedMetricKey,
  selectCurrentSeasonAndCompetition,
  (matchDay, displayMode, metricContext, metricKey, { competition }) =>
    resolveRankingHeader(displayMode, { ...(metricContext?.holder ?? metricContext), competition }, metricKey, matchDay)
)

export const selectRankingColumns = createSelector(
  selectRankedMetricDisplayMode,
  selectRankingAggregation,
  selectRankedMetricContext,
  (displayMode, mode, context) => resolveRankingColumns(displayMode, context?.overrideAggregationMode ?? mode)
)

export const selectRankingRows = createSelector(
  selectRankedMetricDisplayMode,
  selectRanking,
  selectRankedMetricContext,
  selectCurrentSeasonAndCompetition,
  (displayMode, rankedMetric, metricContext, { competition }) =>
    resolveRankingRows(displayMode, rankedMetric, metricContext, competition)
)

export const selectRankingWarning = createSelector(
  selectSourceMetric,
  selectRankedMetricContext,
  selectRanking,
  selectRankingAggregation,
  selectAggregationContext,
  selectMetricAggregationMetadata,
  (source, metricContext, ranking, aggregation, aggregationContext, { isVsEnabled }) => {
    if (hasPercentageRankingOnAccumulatedMode(source, aggregation)) {
      return RankingWarningMode.NoPercent
    } else if (
      hasNoRankingAvailableAtAll(source) ||
      !hasRankingAvailableWhenIsSingleEntity(isVsEnabled, aggregationContext, aggregation, source) ||
      !hasRankingAvailableWhenIsMultiEntity(isVsEnabled, aggregationContext, aggregation, source)
    ) {
      return RankingWarningMode.NoRank
    } else if (!/previa/i.test(source?.keyPath) && RANKING_MATCH_PREVIOUS_STATES.includes(metricContext?.state?.name)) {
      return RankingWarningMode.NoMatch
    }
  }
)

export const selectWarningI18n = createSelector(selectRankingAggregation, selectRankingWarning, (aggregation, mode) => {
  let text: string
  let param: string
  switch (mode) {
    case RankingWarningMode.NoMatch:
      text = 'MTR_RANKINGS_NO_MATCH_DATA'
      break
    case RankingWarningMode.NoRank:
      text = 'MTR_RANKINGS_NO_DATA'
      param = AGGREGATED_METRIC_LABEL_MAP[aggregation]
      break
    case RankingWarningMode.NoPercent:
      text = 'MTR_RANKINGS_NO_DATA_PERC'
      param = AGGREGATED_METRIC_LABEL_MAP[aggregation]
      break
  }
  return { mode, text, param }
})

export const selectShowFooter = createSelector(
  selectAggregationContext,
  (context) => context === MetricFinderKey.Player
)
