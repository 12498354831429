import { Observable } from 'rxjs'

export const bytesToSize = (bytes: number): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) {
    return '0 Byte'
  }
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i]
}

export const readFile = (blob: Blob, readType: string, reader: FileReader = new FileReader()) =>
  new Observable((obs) => {
    if (!blob) {
      return
    }
    if (blob && !(blob instanceof Blob)) {
      obs.error(new Error('`blob` must be an instance of File or Blob.'))
      return
    }

    reader.onerror = (err) => obs.error(err)
    reader.onabort = (err) => obs.error(err)
    reader.onload = () => obs.next(reader.result)
    reader.onloadend = () => obs.complete()

    return reader[readType](blob)
  })
