import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { iif, Observable, switchMap } from 'rxjs'
import { environment } from '@env'
import { CoreAuthService } from '@core/services/auth/core-auth-service'
import { take } from 'rxjs/operators'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

const AUTH_DOMAINS = [environment.API.MAIN_PATH]

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private readonly _ionicAuth: CoreAuthService) {}

  private _handleTokenizedRequest(request: HttpRequest<any>, next: HttpHandler) {
    return this._ionicAuth.token$.pipe(
      takeUntilDestroyed(),
      take(1),
      switchMap((resp) =>
        next.handle(
          resp?.accessToken
            ? request.clone({
                setHeaders: {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  'Content-Type': 'application/json',
                  Authorization: `${environment.API_KEY_SECURE} ${resp.accessToken}`
                }
              })
            : request
        )
      )
    )
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return iif(
      () => AUTH_DOMAINS.some((domain) => request.url.indexOf(domain) === 0),
      this._handleTokenizedRequest(request, next),
      next.handle(request)
    )
  }
}
