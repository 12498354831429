<form [formGroup]="form">
  <app-menu [categories]="metricCategories">
    <ng-template mcmTemplate let-category>
      <app-list [data]="category.metricListConfig" [customTemplate]="metricItem" (onClick)="onClick($event)">
      </app-list>
    </ng-template>
  </app-menu>
</form>

<ng-template #metricItem let-item>
  <div class="mcm-metric-list__content">
    <app-title [text]="item.name" [size]="TitleSize.XS"></app-title>
    <i
      class="mcm-metric-list__icon"
      [ngClass]="'u-icon--' + (form.get(item.key).value ? 'star-rounded' : 'star-outlined')"></i>
  </div>
</ng-template>
<ng-template [ngIf]="formChanges$ | async"></ng-template>
