import * as StackTrace from 'stacktrace-js'
import { HttpErrorResponse } from '@angular/common/http'
import { ErrorBuilder } from './error.builder'
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics'
import { Device } from '@capacitor/device'

export async function recordClientException(error: Error): Promise<void> {
  if ((await Device.getInfo()).platform !== 'web') {
    let err = new ErrorBuilder(error)
      .setName('Non-fatal error')
      .setMessage(`[JS ERROR] ${error.toString()}`)
      .setCode(-0)

    try {
      const stacktrace = await StackTrace.fromError(error)
      err = err.setStackTrace(stacktrace)
    } catch (e) {
      console.warn(`[recordClientException] Non-fatal error: Unable to parse client-side exception! ${error}`)
    } finally {
      void FirebaseCrashlytics.recordException(err.buildAsRecordExceptionOptions())
    }
  }
}

export async function recordNetworkException(error: HttpErrorResponse): Promise<void> {
  if ((await Device.getInfo()).platform !== 'web') {
    let err = new ErrorBuilder(error)
      .setName('Network error')
      .setMessage(error.message || error.toString())
      .setCode(error?.status)

    try {
      const stacktrace = await StackTrace.fromError(error)
      err = err.setStackTrace(stacktrace)
    } catch (e) {
      console.error(`[recordNetworkException] Non-fatal error: Unable to parse network exception! ${e}`)
    } finally {
      void FirebaseCrashlytics.recordException(err.buildAsRecordExceptionOptions())
    }
  }
}
