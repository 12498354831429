import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { selectMergedRoute } from '@core/ngrx-router/state/selectors/ngrx-router.selectors'
import { Store } from '@ngrx/store'
import { filter, map, takeUntil, throttleTime } from 'rxjs/operators'
import { Capacitor } from '@capacitor/core'
import { PlatformType } from '@core/models/models'
import { fetchIOsBundleMetadata } from '@core/services/updater/store/actions/updater.actions'

@Injectable()
export class IosUpdaterService {
  private readonly _destroy$ = new Subject<void>()
  private readonly _currentRoute$ = this._store.select(selectMergedRoute)

  constructor(private readonly _store: Store) {}

  init() {
    this._currentRoute$
      .pipe(
        filter(() => Capacitor.getPlatform() === PlatformType.Ios),
        map((routeState) => routeState?.url),
        filter((url) => !/^\/auth/.test(url)),
        throttleTime(300),
        takeUntil(this._destroy$)
      )
      .subscribe(() => this._store.dispatch(fetchIOsBundleMetadata()))
  }

  destroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
}
