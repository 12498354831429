import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { debounceTime, map, take, tap } from 'rxjs/operators'

import { CoreAuthService } from '@core/services/auth/core-auth-service'
import { Store } from '@ngrx/store'
import { goToPage } from '@core/ngrx-router/state/actions/ngrx-router.actions'
import { DebounceTimeType } from '@mediacoach-ui-library/global'

@Injectable({
  providedIn: 'root'
})
export class LoginGuard {
  constructor(
    private _ionicAuth: CoreAuthService,
    private readonly _store: Store
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this._ionicAuth.token$.pipe(
      debounceTime(DebounceTimeType.Long * 3),
      tap((tokenResponse) => tokenResponse?.isValid(0) && this._store.dispatch(goToPage({}))),
      map((tokenResponse) => !tokenResponse?.isValid(0)),
      take(1)
    )
  }
}
