import { McmMatch } from '@core/models/match.models'
import { MetricAggregation } from '@core/enums/metric.enums'
import { RankingHeader, RankingRequest } from '@features/rankings/models/ranking.model'
import { RANKING_AGGREGATION_TO_METRIC_TYPE_MAP } from '@features/rankings/constants/rankings.constants'
import { PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'
import { parseStatic } from '@core/utils/statics.utils'
import { StaticOrientation } from '@core/enums/statics.enum'

export const parseMatchToRankingRequest = (context: McmMatch, value, mode: MetricAggregation): RankingRequest => ({
  seasonId: context.seasonId,
  competitionId: context.competitionId,
  metricName: value.key,
  metricType: RANKING_AGGREGATION_TO_METRIC_TYPE_MAP[mode],
  matchId: context.id,
  teams: [context?.homeTeam?.teamId, context?.awayTeam?.teamId]
})

export const parseMatchRankingHeader = (
  metricKey: string,
  metricContext: McmMatch,
  matchDay: number
): RankingHeader => ({
  title: metricKey,
  imageA: parseStatic(metricContext.homeTeam?.statics, StaticOrientation.Portrait),
  textA: metricContext.homeTeam?.shortName,
  imageB: parseStatic(metricContext.awayTeam?.statics, StaticOrientation.Portrait),
  textB: metricContext.awayTeam?.shortName,
  placeholderA: PLACEHOLDER_IMAGES.TEAM,
  placeholderB: PLACEHOLDER_IMAGES.TEAM,
  matchDay
})
