export enum GTMCategories {
  Matches = 'matches_mobile',
  MatchDetail = 'match_detail_mobile',
  Team = 'team_mobile',
  TeamCompared = 'team_compared_mobile',
  Player = 'player_mobile',
  PlayerCompared = 'player_compared_mobile',
  PlayerMatch = 'player_match_mobile',
  PlayerMatchCompared = 'player_compared_match_mobile',
  More = 'more_mobile',
  Menu = 'menu_mobile',
  Streaming = 'streaming_mobile',
  Metrics = 'metrics_mobile',
  Notifications = 'notificationc_mobile'
}
