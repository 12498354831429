import { parseStatic } from '@core/utils/statics.utils'
import { StaticOrientation } from '@core/enums/statics.enum'
import { SORTED_POSITIONS } from '@core/constants/player.constants'
import { PlayerSummary } from '@pages/player-detail/models/player-detail.models'

export const mapAndSortSearchPlayers = (players: PlayerSummary[]) =>
  players
    .map((player) => {
      const position = player.playerPositionKey ? player.playerPositionKey : '-'
      return {
        ...player,
        imgUrl: parseStatic(player.statics, StaticOrientation.Portrait),
        teamShield: parseStatic(player.teamStatics, StaticOrientation.Portrait),
        shirtNumber: player.shirtNumber ? player.shirtNumber : '-',
        name: player.formattedName ? player.formattedName : '-',
        position,
        positionOrder: SORTED_POSITIONS.indexOf(position),
        playerId: player.id,
        isSelectable: true
      }
    })
    .sort((a, b) => a.positionOrder - b.positionOrder || (a.shirtNumber as number) - (b.shirtNumber as number))
