import { Component, Input } from '@angular/core'
import { KitItem } from './models/kit-selector.models'

@Component({
  selector: 'mcm-kit-selector',
  templateUrl: './kit-selector.component.html',
  styleUrls: ['./kit-selector.component.scss']
})
export class KitSelectorComponent {
  @Input() data: KitItem[] = []
  @Input() isReverse = false

  selectedIndex = 0

  onKitSelectorSwitch(value) {
    this.selectedIndex = value
  }
}
