/* eslint-disable @typescript-eslint/member-ordering */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core'
import { Column } from './table.models'
import { SafeObjectData } from '@mediacoach-ui-library/global'
import { defaultTrackBy, getNewDirection } from './table.utils'
import { DEFAULT_STICKY_ROW_STYLE } from './table.constants'
import { Subject } from 'rxjs'
import { debounce } from '../../decorators/debounce.decorator'
import { exists } from '@core/utils/number.utils'
import { throttle } from '../../decorators/throttle.decorator'
import { deepCloneArray } from '@core/utils/array.utils'

@Component({
  selector: 'mcm-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent<T = unknown> {
  private _lastScroll = 0

  get rows(): T[] {
    return this._rows
  }

  @Input() set rows(_rows: T[]) {
    this._rows = _rows
    if (_rows) {
      this._rowsBackup = deepCloneArray<T>(_rows)
      this.resetColumnsSortDirection()
      this.setReadyState()
      this.lastColumnIndex = this._defaultSortableColumnIndex
    }
  }

  get columns(): Column[] {
    return this._columns
  }

  @Input() set columns(_columns: Column[]) {
    this._columns = _columns
    if (_columns) {
      this._columnsBackup = []
      _columns.forEach((c, i) => {
        this._columnsBackup.push({ ...c })
        if (c.sortDirection) {
          this._defaultSortableColumnIndex = i
          this.lastColumnIndex = i
        }
      })
    }
  }

  private _defaultSortableColumnIndex: number
  private _rows: T[]
  private _rowsBackup: T[]
  private _columns: Column[]
  private _columnsBackup: Column[]
  private _ready$$ = new Subject<ElementRef>()
  @ViewChild('scrollable') private _scrollable

  @Input() sortable: boolean
  @Input() headerHeight: string
  @Input() rowHeight: string
  @Input() drawRowHolder: boolean
  @Input() loading: boolean
  @Input() stickyHeader = true
  @Input() cellTpl: TemplateRef<unknown>
  @Input() headerTpl: TemplateRef<unknown>
  @Input() rowStyles: Partial<CSSStyleDeclaration>
  @Input() trackByFn: (...args) => unknown = defaultTrackBy
  @Output() cellClick = new EventEmitter()
  @Output() tableScroll = new EventEmitter()

  defaultRowStyles: Partial<CSSStyleDeclaration> = DEFAULT_STICKY_ROW_STYLE
  lastColumnIndex: number
  ready$ = this._ready$$.asObservable()

  constructor(private readonly _zn: NgZone) {}

  sort(index: number): void {
    this._zn.runOutsideAngular(() => {
      if (exists(this.lastColumnIndex) && this.lastColumnIndex !== index) {
        this.columns[this.lastColumnIndex].sortDirection = 'def'
      }
      this.columns[index].sortDirection = getNewDirection(this.columns[index])
      this.lastColumnIndex = index

      if (this.columns[index].sortFn) {
        this._rows = this.columns[index].sortDirection
          ? [...this._rowsBackup].sort((a, b) =>
              this.columns[index].sortFn(
                a,
                b,
                this.columns[index].sortDirection,
                this.columns[index].sortableKey,
                this.columns[index].key,
                this.columns[index].label
              )
            )
          : [...this._rowsBackup]
      } else if (this.sortable && this.columns[index].sortable !== false) {
        this._rows =
          this.columns[index].sortDirection && this.columns[index].sortDirection !== 'def'
            ? [...this._rowsBackup].sort((a, b) => {
                const _a =
                  +SafeObjectData(
                    a,
                    this.columns[index].sortableKey || this.columns[index].key || this.columns[index].label
                  ) || 0
                const _b =
                  +SafeObjectData(
                    b,
                    this.columns[index].sortableKey || this.columns[index].key || this.columns[index].label
                  ) || 0
                return this.columns[index].sortDirection === 'desc' ? _b - _a : _a - _b
              })
            : [...this._rowsBackup]
      }
    })
  }

  resetColumnsSortDirection(): void {
    this._zn.runOutsideAngular(() => {
      this._columns = deepCloneArray<Column>(this._columnsBackup)
    })
  }

  @debounce(0)
  setReadyState(): void {
    this._ready$$.next(this._scrollable)
  }

  @throttle(100)
  onScroll(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    const scroll = ev.target.scrollTop < 0 ? 0 : ev.target.scrollTop
    const direction = this._lastScroll < scroll ? 'down' : 'up'
    this._lastScroll = scroll
    this.tableScroll.emit({ event: ev, direction, isTopReached: ev.target.scrollTop <= 0 })
  }
}
