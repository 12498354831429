import { ComparedPlayersData } from '@pages/player-detail/models/player-detail.models'
import { Params } from '@angular/router'
import { parseStatic } from '@core/utils/statics.utils'
import { StaticOrientation } from '@mediacoach-ui-library/global'

export const mapComparedTeams = (teamsCompared) => ({
  ...teamsCompared,
  nameTeamA: teamsCompared?.teamA?.shortName || teamsCompared?.teamA?.name,
  nameTeamB: teamsCompared?.teamB?.shortName || teamsCompared?.teamB?.name,
  abbreviationTeamA: teamsCompared?.teamA?.abbreviation,
  abbreviationTeamB: teamsCompared?.teamB?.abbreviation,
  logoTeamA: parseStatic(teamsCompared?.teamA?.statics || [], StaticOrientation.Portrait),
  logoTeamB: parseStatic(teamsCompared?.teamB?.statics || [], StaticOrientation.Portrait)
})

export const preparePlayerComparison = (params: Params, position) => {
  const players: ComparedPlayersData = {
    playerA: {
      id: params.id,
      seasonId: params.seasonId || params.vsSeasonId,
      competitionId: params.competitionId || params.vsCompetitionId,
      teamId: params.teamId
    },
    playerB: {
      id: params.vsPlayerId,
      seasonId: params.vsSeasonId,
      competitionId: params.vsCompetitionId,
      teamId: params.vsTeamId
    },
    playerPosition: position
  }

  return { players, matchId: params.matchId }
}
