import { AssetStatic, MatchState, Schedule, State, Static, Team, TeamInfo } from '@mediacoach-ui-library/global'

interface IStatic extends Static {
  url: string
}

export interface ITeam extends Team {
  teamId: string
  abbreviation: string
  statics: AssetStatic[]
  score: number
  portraitLogo?: string
  manager: IManager
}

interface ITeamInfo extends TeamInfo {
  team: ITeam
}

interface ISchedule extends Schedule {
  translated: any
}

interface IState extends State {
  label?: string
  state?: string
  name: MatchState
  minuteDescription?: string
}

export interface IMatch {
  id: string
  schedule: ISchedule
  matchdayNumber: number
  state: IState
  homeTeam: ITeam
  awayTeam: ITeam
  home?: ITeamInfo
  away?: ITeamInfo
  isLive?: boolean
  competition?: {
    logo?: string
    officialName: string
    portraitLogoWhite?: string
    landscapeLogo?: string
    landscapeLogoWhite?: string
    isoTypeLogo?: string
  }
}

export enum MatchItemType {
  Full = 'full',
  Listed = 'listed',
  Compressed = 'compressed',
  FullListed = 'full-listed'
}

export interface IManager {
  id: string
  name: string
  surname: string
}
