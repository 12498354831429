export enum Topic {
  IsLive = 'isLive',
  LiveMatches = 'liveMatches',
  ForceUpdateVersion = 'forceUpdateVersion',
  BuildVersion = 'buildVersion',
  TokenExpired = 'tokenExpired',
  MatchIdWithNewAssets = 'matchIdWithNewAssets',
  MatchChanged = 'matchChanged',
  UpdateVersion = 'updateVersion',
  NewVideoAvailable = 'newVideoAvailable'
}
