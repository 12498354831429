import { FormattedMetricsConfig } from '@pages/team/models/team.models'
import { safeObjectData } from '@core/utils/object.utils'
import { ListType } from '@mediacoach-ui-library/global'
import { MetricAggregation } from '@core/enums/metric.enums'
import { MetricAggregationHeader, MetricAggregationMetadata } from '@core/models/metric.models'
import {
  AGGREGATED_METRIC_LABEL_MAP,
  AGGREGATED_METRIC_VALUE_SUFFIX_MAP,
  DEFAULT_ACCUMULATED_METRIC_AGGREGATION_HEADER,
  DEFAULT_AVERAGE_METRIC_AGGREGATION_HEADER,
  DEFAULT_MATCH_VALUE_METRIC_AGGREGATION_HEADER
} from '@core/constants/metric-aggregation.constants'
import { getLeftRightValue } from '@core/utils/team.utils'

export const mapMetrics = (config: FormattedMetricsConfig, aggregatedMetric: string = 'averageValues') => ({
  type: ListType.Metrics,
  header: { ...config },
  groups: mapGroupMetrics(config.metricsProperty, aggregatedMetric)
})

export const mapGroupMetrics = (metricList: any, aggregation: string) =>
  safeObjectData(metricList, 'groups', []).map((group: any) => ({
    title: safeObjectData(group, 'key', null),
    items: safeObjectData(group, aggregation, []).map((value: any) => ({
      text: safeObjectData(value, 'key', ''),
      key: safeObjectData(value, 'key', ''),
      keyPath: getMetricKeyPath(metricList, value),
      ...getLeftRightValue(value, [
        `left${AGGREGATED_METRIC_VALUE_SUFFIX_MAP[aggregation]}`,
        `right${AGGREGATED_METRIC_VALUE_SUFFIX_MAP[aggregation]}`
      ])
    }))
  }))

export const mapListMetrics = (config: FormattedMetricsConfig, aggregatedMetric: string = 'averageValues') => {
  const id = safeObjectData(config.metricsProperty, 'key')
  return {
    id,
    label: id,
    metrics: mapMetrics(config, aggregatedMetric)
  }
}

export const buildMetricByAggregation = (metricsProperty: any, header: any, aggregation: any) => ({
  type: ListType.Metrics,
  header,
  groups: safeObjectData(metricsProperty, 'groups', []).map((group: any) => ({
    title: safeObjectData(group, 'key', null),
    items: safeObjectData(group, aggregation, []).map((matchValue: any) => ({
      text: safeObjectData(matchValue, 'key', ''),
      key: safeObjectData(matchValue, 'key', ''),
      keyPath: getMetricKeyPath(metricsProperty, matchValue),
      ...getLeftRightValue(matchValue, [
        `left${AGGREGATED_METRIC_VALUE_SUFFIX_MAP[aggregation]}`,
        `right${AGGREGATED_METRIC_VALUE_SUFFIX_MAP[aggregation]}`
      ])
    }))
  }))
})

export const buildComparisonAggregationMode = (isComparison: boolean) =>
  isComparison ? MetricAggregation.AverageValues : MetricAggregation.AccumulatedValues

export const buildComparisonAggregationHeader = (isComparison: boolean) =>
  isComparison ? DEFAULT_AVERAGE_METRIC_AGGREGATION_HEADER : DEFAULT_ACCUMULATED_METRIC_AGGREGATION_HEADER

export const buildAggregationMetadataByContext = (
  isMatch: boolean,
  isComparison: boolean
): MetricAggregationMetadata => ({
  aggregationMode: isMatch ? MetricAggregation.MatchValues : buildComparisonAggregationMode(isComparison),
  aggregationHeader: isMatch
    ? DEFAULT_MATCH_VALUE_METRIC_AGGREGATION_HEADER
    : buildComparisonAggregationHeader(isComparison)
})

export const buildAggregationHeaderByMode = (mode: MetricAggregation): MetricAggregationHeader => ({
  text: AGGREGATED_METRIC_LABEL_MAP[mode]
})

export const getMetricKeyPath = (metrics: any, metricValue: any) =>
  `${safeObjectData(metrics, 'key', null)}.${safeObjectData(metricValue, 'key', '')}`
