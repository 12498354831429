import { environment } from '@env'
import { PlatformType } from '../models/models'
import { firstLetterToLowerCase } from '@mediacoach-ui-library/global'

const IMAGES_PATH = `${environment.STATICS_URL_LOCAL}images/`

export const AVATAR_PLACEHOLDER_IMAGE = `${IMAGES_PATH}default-avatar.svg`
export const PLAYER_PLACEHOLDER_IMAGE = `${IMAGES_PATH}default-player.svg`
export const SHIELD_PLACEHOLDER_IMAGE = `${IMAGES_PATH}default-shield.svg`

export const MC_MOBILE_LOGO = `${IMAGES_PATH}mediacoach-mobile-logo.png`

const APK_PATH = environment.STATICS_URL_CLOUD + firstLetterToLowerCase('Mobile/')

export const APK_PATH_BY_OS = {
  [PlatformType.Android]: `${APK_PATH}Android/MCMobile.apk`,
  [PlatformType.Ios]: `itms-services://?action=download-manifest&url=${APK_PATH}iOS/manifest.plist`
}
