import { ActivatedRoute } from '@angular/router'
import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core'
import { objectKeys } from '@core/utils/object.utils'

@Directive({
  selector: '[mcmDataAttr]'
})
export class DataAttributeDirective implements OnInit {
  @Input('mcmDataAttr') dataInfo: { [key: string]: string }
  @Input() dataId: string = null

  constructor(
    private _el: ElementRef,
    private _render: Renderer2,
    private _activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    objectKeys(this.dataInfo).forEach((key) =>
      this.dataInfo[key] ? this._setDataEv(this.dataId, key, this.dataInfo[key]) : ''
    )
  }

  private _setDataEv(id: string, eventParam: string, eventParamInfo: string): void {
    this._render.setAttribute(this._el.nativeElement, `data${id ? '-' + id : ''}-${eventParam}`, eventParamInfo)
  }
}
