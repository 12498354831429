import { Action, createReducer, on } from '@ngrx/store'
import { MetricDetailState } from '../state/metric-detail.state'
import { metricDetailActions } from '../actions/metric-detail.actions'

export const initialState: MetricDetailState = {
  config: null,
  metrics: null,
  key: null,
  keyPath: null
}

const reducer = createReducer(
  initialState,
  on(metricDetailActions.setConfig, (state, { config }): MetricDetailState => ({ ...state, config })),
  on(metricDetailActions.setMetrics, (state, { metrics }): MetricDetailState => ({ ...state, metrics })),
  on(
    metricDetailActions.setKeyAndKeyPath,
    (state, { key, keyPath }): MetricDetailState => ({ ...state, key, keyPath })
  ),
  on(metricDetailActions.purgeMetricDetail, (): MetricDetailState => ({ ...initialState }))
)

export const metricDetailReducer = (state: MetricDetailState | undefined, action: Action): MetricDetailState =>
  reducer(state, action)
