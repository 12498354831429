import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { RankingHeader } from '@features/rankings/models/ranking.model'

@Component({
  selector: 'mcm-ranking-header',
  templateUrl: './ranking-header.component.html',
  styleUrls: ['./ranking-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RankingHeaderComponent {
  @Input() header: RankingHeader
  @Output() closeModal = new EventEmitter<void>()
}
