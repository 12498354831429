import { Component } from '@angular/core'
import { MetricDetailType } from '../../models/metric-detail.models'
import { debounceTime, map, tap } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { PLAYER_PLACEHOLDER_IMAGE, SHIELD_PLACEHOLDER_IMAGE } from '@core/constants/asset-path'
import { parseDetailConfigByType } from '../../utils/metric-detail.utils'
import { TranslatorService } from '@mediacoach-ui-library/global'
import { Store } from '@ngrx/store'
import { selectMetricDetailKey, selectMetricDetailParsedConfig } from '../../store/selectors/metric-detail.selectors'
import { metricDetailActions } from '../../store/actions/metric-detail.actions'
import { PLAYER_DEMARCATION_FALLBACK_KEY, PLAYER_DEMARCATION_KEYS } from '@core/constants/player.constants'

@Component({
  selector: 'mcm-metric-detail',
  templateUrl: './metric-detail.component.html',
  styleUrls: ['./metric-detail.component.scss']
})
export class MetricDetailComponent {
  get currentLang() {
    return this._translator.getCurrentLang()
  }

  readonly key$: Observable<string> = this._store.select(selectMetricDetailKey)
  readonly info$: Observable<any> = this._store.pipe(
    selectMetricDetailParsedConfig,
    map((detailConfig) => parseDetailConfigByType(detailConfig)),
    debounceTime(300),
    tap(() => (this.loading = false))
  )

  loading = true
  metricDetailInfoType = MetricDetailType
  PLAYER_PLACEHOLDER_IMAGE = PLAYER_PLACEHOLDER_IMAGE
  SHIELD_PLACEHOLDER_IMAGE = SHIELD_PLACEHOLDER_IMAGE

  readonly playerDemarcationKeys = PLAYER_DEMARCATION_KEYS
  readonly playerDemarcationFallbackKey = PLAYER_DEMARCATION_FALLBACK_KEY

  constructor(
    private readonly _translator: TranslatorService,
    private readonly _store: Store
  ) {}

  onClosed() {
    this._store.dispatch(metricDetailActions.closeModal())
  }
}
