import { HubConnectionState } from '@microsoft/signalr'
import { environment } from '@env'
import { Action, createReducer, on } from '@ngrx/store'
import { Topic } from '@sockets/enums/socket.enum'
import { SocketState } from '@core/store/states/socket.state'
import { setSocketId, setSocketStatus, setTopic } from '@core/store/actions/socket.actions'

const initialState: SocketState = {
  topics: {
    [Topic.BuildVersion]: environment.appVersion,
    [Topic.IsLive]: false,
    [Topic.LiveMatches]: [],
    [Topic.ForceUpdateVersion]: false
  },
  connectionId: undefined,
  status: HubConnectionState.Disconnected
}

const reducer = createReducer(
  initialState,
  on(setTopic, (state, { topic }): SocketState => ({ ...state, topics: { ...state.topics, ...topic } })),
  on(setSocketId, (state, { id }): SocketState => ({ ...state, connectionId: id })),
  on(setSocketStatus, (state, { status }): SocketState => ({ ...state, status }))
)

export const socketReducer = (state: SocketState | undefined, action: Action): SocketState => reducer(state, action)
