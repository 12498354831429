import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { CONTEXT_MENU_METRIC_ITEMS } from '@core/constants/context-menu-config.constants'
import { MatchTab } from '@pages/match-detail/models/match-detail.models'
import { List } from '@mediacoach-ui-library/global'
import { MetricAggregation } from '@core/enums/metric.enums'
import { MetricAggregationHeader } from '@core/models/metric.models'

@Component({
  selector: 'mcm-common-list',
  templateUrl: './common-list.component.html',
  styleUrls: ['./common-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonListComponent {
  @Input() tabConfig: MatchTab
  @Input() metrics: any
  @Input() context: any
  @Input() overrideAggregationMode: boolean
  @Input() isCollapsible: boolean
  @Input() inMatchContext: boolean
  @Input() showAggregationIcon: boolean
  @Input() againstCompetition: boolean
  @Input() aggregationMode: MetricAggregation
  @Input() aggregationHeader: MetricAggregationHeader
  @Input() isVsEnabled = false

  @Output() aggregationModeChange = new EventEmitter()

  get itemContext() {
    return this.overrideAggregationMode
      ? { holder: this.context, overrideAggregationMode: this.aggregationMode }
      : this.context
  }

  CONTEXT_MENU_METRIC_ITEMS = CONTEXT_MENU_METRIC_ITEMS
  showAggregationSelector: boolean

  get listData() {
    return (((this.metrics || this.tabConfig || {})?.metrics || {})[this.aggregationMode] ?? []) as List<any>
  }

  onAggregationSelectorClose(data) {
    this.showAggregationSelector = false
    this.aggregationModeChange.emit(data.id)
  }
}
