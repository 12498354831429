<div class="mcm-layout__block-header">
  <app-title [text]="title"></app-title>
  <app-button
    [text]="'MTR_METRICS_EDIT'"
    extraClasses="mcm-button--flat mcm-edit-metric"
    [mcmAnalyticsClickEvent]="editMetricEvent"
    [routerLink]="route"></app-button>
</div>
<mcm-common-list
  [metrics]="metrics"
  [context]="context"
  [aggregationMode]="aggregationMode"
  [aggregationHeader]="aggregationHeader"
  [showAggregationIcon]="showAggregationIcon"
  [againstCompetition]="againstCompetition"
  [inMatchContext]="inMatchContext"
  [isCollapsible]="true"
  [overrideAggregationMode]="overrideAggregationMode"
  [isVsEnabled]="isVsEnabled"
  (aggregationModeChange)="aggregationModeChange.emit($event)"></mcm-common-list>
