import { Column, SortDirection } from './table.models'

export function getNewDirection(c: Column): SortDirection {
  if (!c.sortDirection || c.sortDirection === 'def') {
    return 'desc'
  } else if (c.sortDirection === 'desc') {
    return 'asc'
  } else {
    return 'def'
  }
}

export function defaultTrackBy(index: number): unknown {
  return index
}
