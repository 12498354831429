/* eslint-disable max-len */

export enum RouteUrl {
  Main = 'main',
  MainSub = 'main/.+',
  Login = 'login',
  Auth = 'auth',
  AuthMain = 'auth.+',
  AuthLogin = 'auth/login',
  AuthLogout = 'auth/logout',
  NotPermitted = 'forbidden',
  NetworkTimeout = 'network-timeout',
  Concurrency = 'concurrency',
  Home = 'main/home',
  HomeMetricFinder = 'main/home/metric-finder',
  MainMatches = 'main/matches',
  Matches = 'matches',
  MatchDetail = 'matches/.+',
  MatchCalendar = 'matches/calendar',
  MatchStandings = 'matches/tables',
  MatchDetailMetricFinder = 'matches/.+/metric-finder',
  MatchPlayerDetail = 'matches/.+/teams/.+/players/.+',
  MatchPlayerDetailMetricFinder = 'matches/.+/teams/.+/players/.+/metric-finder',
  MainMore = 'main/more',
  More = 'more',
  Profile = 'more/profile',
  TeamSelect = 'more/team-select',
  Metrics = 'more/metrics',
  MetricDetail = 'more/metrics/.*',
  MCSuite = 'more/suite',
  Info = 'more/info',
  Legal = 'more/legal',
  Notifications = 'more/notifications',
  NotificationDetails = 'more/notifications/.+/.+',
  TeamCompareDetail = 'compare/seasons/.+/competitions/.+/teams/.+',
  TeamCompareDetailMetricFinder = 'compare/seasons/.+/competitions/.+/teams/.+/metric-finder',
  Players = 'players',
  PlayerDetail = 'seasons/.+/competitions/.+/teams/.+/players/.+',
  PlayerDetailMetricFinder = 'seasons/.+/competitions/.+/teams/.+/players/.+/metric-finder',
  PlayerCompareDetail = 'seasons/.+/competitions/.+/teams/.+/players/.+/compare/seasons/.+/competitions/.+/teams/.+/players/.+',
  MatchPlayerCompareDetail = 'matches/.+/teams/.+/players/.+/compare/seasons/.+/competitions/.+/teams/.+/players/.+',
  MatchPlayerCompareDetailMetricFinder = 'matches/.+/teams/.+/players/.+/compare/seasons/.+/competitions/.+/teams/.+/players/.+/metric-finder',
  PlayerCompareDetailMetricFinder = 'seasons/.+/competitions/.+/teams/.+/players/.+/compare/seasons/.+/competitions/.+/teams/.+/players/.+/metric-finder',
  UpdateVersion = 'updateVersion',
  UserDisallowed = 'userDisallowed',
  MetricFinder = 'metric-finder'
}

export enum MetricFinderKey {
  Team = 'team',
  Player = 'player',
  Match = 'match'
}

export enum AggregationPosition {
  centered,
  right
}

export enum PlatformType {
  Android = 'android',
  Ios = 'ios'
}
