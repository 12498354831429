export enum VideoMimeType {
  m3u8 = 'm3u8',
  mp4 = 'mp4',
  mov = 'mov',
  m4v = 'm4v',
  m4a = 'm4a',
  opus = 'opus',
  ogv = 'ogv',
  mkv = 'mkv',
  mp3 = 'mp3',
  aac = 'aac',
  caf = 'caf',
  flac = 'flac',
  oga = 'oga',
  wav = 'wav',
  mpd = 'mpd'
}

export enum VideoEvent {
  Waiting = 'waiting',
  Volumechange = 'volumechange',
  Usingnativecontrols = 'usingnativecontrols',
  Usingcustomcontrols = 'usingcustomcontrols',
  Userinactive = 'userinactive',
  Useractive = 'useractive',
  Texttrackchange = 'texttrackchange',
  Textdata = 'textdata',
  Tap = 'tap',
  Suspend = 'suspend',
  Stalled = 'stalled',
  Sourceset = 'sourceset',
  Seeking = 'seeking',
  Seeked = 'seeked',
  Resize = 'resize',
  Ready = 'ready',
  Ratechange = 'ratechange',
  Posterchange = 'posterchange',
  Pluginsetup = 'pluginsetup',
  Playing = 'playing',
  Playerresize = 'playerresize',
  Playbackrateschange = 'playbackrateschange',
  Play = 'play',
  Pause = 'pause',
  Loadstart = 'loadstart',
  Loadedmetadata = 'loadedmetadata',
  Loadeddata = 'loadeddata',
  Leavepictureinpicture = 'leavepictureinpicture',
  Languagechange = 'languagechange',
  Fullscreenchange = 'fullscreenchange',
  ExitFullWindow = 'exitFullWindow',
  Error = 'error',
  Enterpictureinpicture = 'enterpictureinpicture',
  EnterFullWindow = 'enterFullWindow',
  Ended = 'ended',
  Emptied = 'emptied',
  Durationchange = 'durationchange',
  Dispose = 'dispose',
  Controlsenabled = 'controlsenabled',
  Controlsdisabled = 'controlsdisabled',
  Componentresize = 'componentresize',
  Canplaythrough = 'canplaythrough',
  Canplay = 'canplay',
  Beforepluginsetup = 'beforepluginsetup',
  Abort = 'abort',
  Timeupdate = 'timeupdate'
}

export enum Hotkey {
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Space = 'Space'
}
