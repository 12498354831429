import { PlayerPosition } from '@core/enums/player.enums'

export const METRIC_DETAIL_FEATURE_TOKEN = 'metricDetail'

export const METRIC_ITEM_LEFT_RIGHT_MAP = {
  leftAverageMetricResult: 'left',
  leftMatchMetricResult: 'left',
  leftAccumulatedMetricResult: 'left',
  homeMetricResult: 'left',
  rightAverageMetricResult: 'right',
  rightMatchMetricResult: 'right',
  rightAccumulatedMetricResult: 'right',
  awayMetricResult: 'right'
}

export const GROUPS_ITEM_KEYS = ['matchValues', 'averageValues', 'accumulatedValues']

export const METRIC_DETAIL_AVERAGE_BY_POSITION = {
  [PlayerPosition.Goalkeeper]: 'MTR_METRIC_DETAIL_GOALKEEPER_AVERAGE',
  [PlayerPosition.Defender]: 'MTR_METRIC_DETAIL_DEFENDER_AVERAGE',
  [PlayerPosition.Midfielder]: 'MTR_METRIC_DETAIL_MIDFIELDER_AVERAGE',
  [PlayerPosition.Forward]: 'MTR_METRIC_DETAIL_FORWARD_AVERAGE'
}
