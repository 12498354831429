<div class="mcm-file-upload-base">
  <div class="mcm-file-upload-base__container">
    <span
      class="mcm-file-upload-base__choose"
      [ngClass]="{
        'mcm-file-upload-base__button--focus': focus,
        'mcm-file-upload-base__button--disabled': disabled || isChooseDisabled()
      }"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (click)="choose()"
      (keydown.enter)="choose()"
      tabindex="0">
      <input
        #fileInput
        type="file"
        (change)="onFileSelect($event)"
        [multiple]="multiple"
        [accept]="accept"
        [disabled]="disabled || isChooseDisabled()"
        [attr.title]="''" />
      <span class="mcm-file-upload-base__label">{{ chooseButtonLabel }}</span>
    </span>
  </div>
  <div
    #content
    class="mcm-file-upload-base__content"
    (dragenter)="onDragEnter($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)">
    <ng-content></ng-content>
  </div>
</div>
