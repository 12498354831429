import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Item } from './dropdown.models'

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  @Output() onChange = new EventEmitter<string>()
  active = false
  selectedItem: Item
  private _items: Item[]

  get items() {
    return this._items
  }
  @Input() set items(_items) {
    this._items = _items
    this.selectedItem = _items.find(({ selected }) => selected)
  }

  toggleActive() {
    this.active = !this.active
  }

  selectOption(item) {
    this.selectedItem = item
    this.toggleActive()
    this.onChange.emit(item.id)
  }
}
