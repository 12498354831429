import { Injectable } from '@angular/core'
import {
  HttpContext,
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

import { AuthError } from '../services/auth/auth.models'
import { ToastService } from '../services/toast/toast.service'

const NO_TOAST_ON_FALIURE = new HttpContextToken<boolean>(() => false)

export function noToastOnFailure() {
  return new HttpContext().set(NO_TOAST_ON_FALIURE, true)
}

const ERROR_BLACKLIST = [AuthError.UserDisallowed, AuthError.InvalidGrant, AuthError.InvalidRequest]

@Injectable()
export class AlertsInterceptor implements HttpInterceptor {
  constructor(public toastService: ToastService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(null, (error) => {
        if (error instanceof HttpErrorResponse) {
          const errorResponse = error.error || {}
          if (
            error.status > 0 &&
            !ERROR_BLACKLIST.includes(errorResponse.error) &&
            !request.context.get(NO_TOAST_ON_FALIURE)
          ) {
            const errorResponseString = JSON.stringify(errorResponse)
            this.toastService.create({
              color: 'danger',
              message: errorResponse.Message || (errorResponseString !== '{}' ? errorResponseString : null),
              duration: 2000
            })
          }
        }
      })
    )
  }
}
