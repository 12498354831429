import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, switchMap } from 'rxjs/operators'
import { SeasonApi } from '@core/requests/api/season/season.api'
import { fetchCurrentSeason, setCurrentSeason } from '@core/store/actions/season.actions'
import { parseCompetitionStatics } from '@core/utils/season.utils'
import { Season } from '@core/models/dto/season.dto'

@Injectable()
export class SeasonEffects {

  fetchCurrent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchCurrentSeason),
      switchMap(() => this._api.fetchCurrentSeason().pipe(
          map(this._mapSeason)
        )
      ),
      map((current) => setCurrentSeason({current}))
    ))

  constructor(
    private readonly _actions$: Actions,
    private readonly _api: SeasonApi
  ) {}

  private _mapSeason = (current: Season) => (
    {
      ...current, competitions: current.competitions.map(
        (comp) => ({...comp, ...parseCompetitionStatics(comp.statics)})
      )
    }
  )
}
