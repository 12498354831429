import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core'

@Directive({
  selector: '[mcmAttachMarkers]'
})
export class AttachMarkersDirective {
  @Output() injected = new EventEmitter<void>()

  @Input('mcmAttachMarkers') set videoMarker(videoMarkerElementRef: ElementRef) {
    if (videoMarkerElementRef && this._elementRef) {
      this._cdr.detectChanges()
      const progressBar = this._elementRef.nativeElement.querySelector('.vjs-progress-control .vjs-slider')
      if (progressBar) {
        progressBar.insertBefore(videoMarkerElementRef.nativeElement, progressBar.querySelector('.vjs-mouse-display'))
        this.injected.emit()
      }
    }
  }

  constructor(
    private readonly _elementRef: ElementRef,
    private readonly _cdr: ChangeDetectorRef
  ) {}
}
