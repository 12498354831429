<div class="mcm-video-markers">
  <ng-container *ngFor="let marker of markers">
    <div
      class="mcm-video-markers__item"
      *ngIf="!marker.hideMarker"
      [ngStyle]="marker.style"
      [class.gray-area]="marker.isRange"
      [mcuiTooltip]="marker.tooltip"
      tooltipPosition="top"
      (click)="onClick(marker, $event)"></div>
  </ng-container>
</div>
