import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss']
})
export class CollapseComponent {
  @Output() isCollapsedChange = new EventEmitter<boolean>()

  private _isCollapsed = true

  get isCollapsed() {
    return this._isCollapsed
  }

  @Input() set isCollapsed(_isCollapsed) {
    this._isCollapsed = _isCollapsed
    this.isCollapsedChange.emit(this._isCollapsed)
  }
}
