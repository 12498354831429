import { ApiCompetition } from '@core/models/competition.models'
import { Competition } from '@core/models/dto/competition.dto'
import { ParseStatic, StaticOrientation } from '@mediacoach-ui-library/global'
import { ApiStatic } from '@core/models/assets.models'
import { getStaticItem } from '@core/utils/statics.utils'
import { Season } from '@core/models/dto/season.dto'

export const mapCompetition = (
  competition: ApiCompetition,
  season?: Season
): ApiCompetition & Pick<Competition, 'logo'> & Pick<Competition, 'portraitLogo'> & Pick<Competition, 'season'> => ({
  ...competition,
  logo: ParseStatic(competition?.statics, StaticOrientation.Landscape),
  portraitLogo: ParseStatic(competition?.statics, StaticOrientation.Portrait),
  ...(season ? { season, seasonId: season.id } : {})
})

export const compLogoPredicate =
  (orientation = StaticOrientation.Portrait, size = 'Medium', isColor = true) =>
  (staticItem: ApiStatic): boolean =>
    staticItem.orientation === orientation && staticItem.size === size && staticItem.isColor === isColor

export const parseCompetitionStatics = (statics: ApiStatic[]) => ({
  portraitLogo: getStaticItem(statics, compLogoPredicate()),
  portraitLogoWhite: getStaticItem(statics, compLogoPredicate(StaticOrientation.Portrait, 'High', false)),
  landscapeLogo: getStaticItem(statics, compLogoPredicate(StaticOrientation.Landscape, 'Small')),
  landscapeLogoWhite: getStaticItem(statics, compLogoPredicate(StaticOrientation.Landscape, 'High2x', false)),
  isoTypeLogo: getStaticItem(statics, compLogoPredicate(StaticOrientation.Portrait, 'Small'))
})
