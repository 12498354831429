import { Component, Input } from '@angular/core'

import { TitleAlignment, TitleSize, TitleWeight } from './title.constants'

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent {
  @Input() text: string
  @Input() size: TitleSize = TitleSize.MD
  @Input() alignment: TitleAlignment = TitleAlignment.Left
  @Input() weight: TitleWeight = TitleWeight.Bold

  constructor() {}
}
