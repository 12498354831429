import { createAction, props } from '@ngrx/store'
import { IOSBundleMetadata } from '../../models/updater.models'

export const fetchIOsBundleMetadata = createAction('[Updater] fetch ios bundle metadata', (bundleId?: string) => ({
  bundleId
}))
export const setIOSBundleMetadata = createAction(
  '[Updater] set ios bundle metadata',
  props<{ ios: IOSBundleMetadata }>()
)

export const notifyAndRedirectToAppStore = createAction(
  '[Updater] notify and redirect',
  props<{ ios: IOSBundleMetadata }>()
)
