export const FIELDS = [
  {
    key: 'played',
    label: 'MTR_HEADER_PLAYEDMATCHES_SHORT'
  },
  {
    key: 'won',
    label: 'MTR_HEADER_WONMATCHES_SHORT'
  },
  {
    key: 'drawn',
    label: 'MTR_HEADER_DRAWMATCHES_SHORT'
  },
  {
    key: 'lost',
    label: 'MTR_HEADER_LOSTMATCHES_SHORT'
  },
  {
    key: 'for',
    label: 'MTR_HEADER_GOALSFOR_SHORT'
  },
  {
    key: 'against',
    label: 'MTR_HEADER_GOALSAGAINST_SHORT'
  }
]
