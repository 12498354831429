<app-header
  class="mcm-player-selection__header"
  extraClasses="mcm-header--modal"
  [title]="title"
  [titleParams]="titleParams"
  [showBackButton]="false">
  <ng-template mcmTemplate="end-buttons">
    <app-button icon="close-outline" extraClasses="mcm-button--xxl" (onClick)="onClosed()"></app-button>
  </ng-template>
</app-header>
<div class="mcm-player-selection">
  <ng-container [ngSwitch]="selectionMode">
    <ng-container *ngSwitchCase="SelectionType.PlayerSelection">
      <ng-container
        *ngIf="((getFilteredPlayers$() | loading | async) || {loading: true, value: null}) as fullPlayersObj">
        <div class="mcm-layout__block mcm-layout__block--no-bottom">
          <ion-searchbar
            class="mcm-search-bar"
            [mcmAutofocus]="!fullPlayersObj.loading"
            [isIonic]="true"
            searchIcon="search-rounded"
            [placeholder]="'MTR_PAGES_PLAYER_COMPARE_SEARCH' | translate"
            (ionInput)="onSearch($event)"></ion-searchbar>
        </div>
        <mcm-loader
          [class.mcm-player-selection__loader]="((fullPlayersObj.value || {}).groups || []).length"
          [loading]="fullPlayersObj.loading || searching">
          <div class="mcm-layout__block">
            <ng-container *ngIf="((fullPlayersObj.value || {}).groups || []).length else defaultState;">
              <ng-container *ngIf="fullPlayersObj.value?.groups[0].items.length > 0 else noResults;">
                <app-list [data]="fullPlayersObj.value" (onItemSelected)="selection = $event"></app-list>
              </ng-container>
            </ng-container>
          </div>
        </mcm-loader>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="SelectionType.PositionSelection">
      <div class="mcm-layout__block">
        <app-list [data]="PLAYER_POSITION_MAP[positionPlayerType]" (onItemSelected)="selection = $event"></app-list>
      </div>
    </ng-container>
  </ng-container>

  <div class="mcm-layout__block mcm-player-selection__footer">
    <app-button
      text="MTR_COMMON_SELECT"
      extraClasses="mcm-button--gradient mcm-button--block mcm-button--footer"
      [disabled]="!selection"
      (onClick)="onSelected(selection)">
    </app-button>
  </div>
</div>

<ng-template #defaultState>
  <ion-icon icon="search-rounded"></ion-icon>
</ng-template>

<ng-template #noResults>
  <div class="mcm-player-selection--no-results">
    <span>{{'MTR_PAGES_PLAYER_COMPARE_NO_RESULTS' | translate}}</span>
  </div>
</ng-template>
