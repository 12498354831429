export function throttle(delay = 300): MethodDecorator {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    let throttlePause
    const original = descriptor.value

    descriptor.value = function (...args) {
      if (throttlePause) return
      throttlePause = true
      setTimeout(() => {
        original.apply(this, args)
        throttlePause = false
      }, delay)
    }

    return descriptor
  }
}
