import { Device } from '@capacitor/device'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { environment } from '@env'
import { camelToSnakeCase } from '@core/utils/string.utils'
import { UserProfile } from '../../../auth/auth.models'
import { USER_PROPERTIES } from '../constants/firebase-analytics.constants'
import { APP_VERSION } from '@core/constants/constants'
import { objectKeys } from '@core/utils/object.utils'

export async function initFirebase(): Promise<unknown> {
  return (await Device.getInfo()).platform === 'web'
    ? FirebaseAnalytics.initializeFirebase(environment.FIREBASE)
    : FirebaseAnalytics.setCollectionEnabled({ enabled: true })
}

export function mapRouteParams(
  params: { [key: string]: string },
  map: { [key: string]: string },
  isComparison?: boolean
): { [key: string]: string } {
  return objectKeys(map).reduce((paramMap, key) => {
    if (params[key]) {
      paramMap = {
        ...paramMap,
        [parseComparisonIds(map, key, isComparison)]: params[key]
      }
    }
    return paramMap
  }, {})
}

export function parseComparisonIds(map: { [key: string]: string }, key: string, isComparison: boolean): string {
  return isComparison && ['teamId', 'id', 'competitionId', 'seasonId'].includes(key)
    ? `${camelToSnakeCase(map[key])}_A`
    : map[key]
}

export function parseUserPropertiesToPromises(user: UserProfile, granted: boolean): Promise<unknown>[] {
  const promises = [
    FirebaseAnalytics.setUserProperty({ name: USER_PROPERTIES.appEnvironment, value: environment.envType }),
    FirebaseAnalytics.setUserProperty({ name: USER_PROPERTIES.userTeamId, value: user?.favouriteTeamId }),
    FirebaseAnalytics.setUserProperty({ name: USER_PROPERTIES.userCompetitionId, value: user?.favouriteCompetitionId }),
    user?.favouriteTeamName
      ? FirebaseAnalytics.setUserProperty({ name: USER_PROPERTIES.userTeamName, value: user.favouriteTeamName })
      : Promise.resolve(),
    user?.customerId
      ? FirebaseAnalytics.setUserProperty({ name: USER_PROPERTIES.customerId, value: user.customerId })
      : Promise.resolve(),
    user?.customerName
      ? FirebaseAnalytics.setUserProperty({ name: USER_PROPERTIES.customerName, value: user.customerName })
      : Promise.resolve()
  ]

  if (granted) {
    promises.push(FirebaseAnalytics.setUserId({ userId: user?.userId }))
  }

  return promises
}

export async function updateProperties(user: UserProfile, granted: boolean): Promise<unknown> {
  const promises = parseUserPropertiesToPromises(user, granted)

  const { platform } = await Device.getInfo()

  if (platform === 'web') {
    promises.push(FirebaseAnalytics.setUserProperty({ name: USER_PROPERTIES.appVersion, value: APP_VERSION })) // Only the web version
  }

  return Promise.all(promises).catch(() => true)
}
