import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { List } from '@mediacoach-ui-library/global'

@Component({
  selector: 'mcm-spider-chart-block',
  templateUrl: './spider-chart-block.component.html',
  styleUrls: ['./spider-chart-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpiderChartBlockComponent {
  @Input() spiderChartConfig: { list: List<any>; chart: any }
  @Input() data
}
