import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router'
import { NavController } from '@ionic/angular'

import { Subject } from 'rxjs'
import { tap } from 'rxjs/operators'

import { Permission } from '../auth/auth.models'
import { URL_SAVE_BLACKLIST } from '../../constants/constants'
import { trimUrl, urlDeepLength } from '../../utils/main.utils'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx'
import { PlatformType } from '@core/models/models'
import { Capacitor } from '@capacitor/core'

interface RouteHistoryItem {
  url: string
  regex: RegExp
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class RouterEventsService {
  hasNavigatedBack = false
  isDefaultRoute = true
  onNavigationStart$ = new Subject<string>()
  private _defaultRoute: Permission
  private _permissions = [] as Permission[]
  private _routeHistory = [] as RouteHistoryItem[]

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _location: Location,
    private _navController: NavController,
    private _screenOrientation: ScreenOrientation
  ) {
    this._router.events
      .pipe(
        tap((event) => {
          if (event instanceof NavigationStart) {
            this.hasNavigatedBack = event.navigationTrigger === 'popstate'
            this.onNavigationStart$.next(event.url)
            if (
              Capacitor.getPlatform() === PlatformType.Android &&
              this._screenOrientation.type === this._screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY
            ) {
              this._screenOrientation.unlock()
              this._screenOrientation
                .lock(this._screenOrientation.ORIENTATIONS.PORTRAIT)
                .catch((error) => console.log(error))
            }
          }
          if (event instanceof NavigationEnd) {
            const url = event.urlAfterRedirects
            const trimmedUrl = trimUrl(url)
            this.isDefaultRoute = !!this._defaultRoute && this._defaultRoute.regex.test(trimmedUrl)

            if (!URL_SAVE_BLACKLIST.some((regex) => regex.test(trimmedUrl))) {
              if (this._isChildFrom(url, this.url)) {
                this._routeHistory = [...this._routeHistory.slice(0, -1)]
                this.hasNavigatedBack = true
              } else {
                const urlRegexInfo =
                  this.permissions.find(
                    ({ regex, deepLength }) => regex.test(trimmedUrl) && urlDeepLength(trimmedUrl) === deepLength
                  ) || ({} as Permission)
                const urlIndex = this._routeHistory.findIndex(({ regex }) => regex === urlRegexInfo.regex)

                if (urlIndex > -1) {
                  this._routeHistory = [...this._routeHistory.slice(0, urlIndex), { url, regex: urlRegexInfo.regex }]
                  this.hasNavigatedBack = true
                } else {
                  this._routeHistory = [...this._routeHistory, { url, regex: urlRegexInfo.regex }]
                }
              }
            }
          }
        }),
        untilDestroyed(this)
      )
      .subscribe()
  }

  get previousUrl() {
    return (this._routeHistory.slice(0, -1).slice(-1)[0] || {}).url
  }
  get url() {
    return (this._routeHistory.slice(-1)[0] || {}).url
  }

  get permissions() {
    return this._permissions
  }
  set permissions(_rolePermissions) {
    this._permissions = _rolePermissions
    this._defaultRoute = this._permissions.find(({ isDefaultRoute }) => isDefaultRoute)
  }

  navigateToParent(url = this._location.path()) {
    this._routeHistory = this._routeHistory.slice(0, -1)
    const parentRoute = url.split('/').slice(0, -1).join('/')
    this._navController.navigateBack(parentRoute).then()
  }

  goBack() {
    if (!this.previousUrl) {
      this.navigateToParent()
    } else {
      void this._navController.navigateBack(this.previousUrl)
    }
  }

  private _isChildFrom(parent, child) {
    return !!(parent && child) && child.includes(parent)
  }
}
