import { Marker, RangeMarker } from '../models/video-markers.models'
import { TranslateService } from '@ngx-translate/core'
import { videoJsFormatTime } from './video.utils'

function parseMarkerStyles(
  { endTime, startTime, time, isRange }: RangeMarker,
  totalTime: number
): Partial<CSSStyleDeclaration> {
  const _leftValue = isRange
    ? ((startTime + ((endTime || totalTime) - startTime) / 2) / totalTime) * 100
    : (time / totalTime) * 100

  if (isNaN(_leftValue)) {
    return { display: 'none' }
  }

  const style: Partial<CSSStyleDeclaration> = {
    left: `${_leftValue}%`
  }

  if (isRange) {
    style.width = `${(((endTime || totalTime) - startTime) / totalTime) * 100}%`
  }
  return style
}

function parseMarker(marker: RangeMarker, totalTime: number, translate?: TranslateService): Marker {
  return {
    ...marker,
    style: parseMarkerStyles(marker, totalTime),
    tooltip: `${translate.instant(marker.label)} (${
      marker.formattedTime ? translate.instant(marker.formattedTime) : videoJsFormatTime(marker.time, totalTime)
    })`,
    hideMarker:
      marker.hideMarker ||
      (marker.isRange
        ? marker.startTime < 0 || marker.startTime > totalTime
        : typeof marker.time !== 'number' || marker.time < 0 || marker.time > totalTime)
  }
}

export function parseMarkers(markList: RangeMarker[], totalTime: number, translate: TranslateService): RangeMarker[] {
  let _grayAreas = []
  const _markers = (markList || []).map((marker) => {
    if (marker.isGrayAreaStart) {
      _grayAreas = [..._grayAreas, { startTime: marker.time }]
    }
    if (marker.isGrayAreaEnd && _grayAreas[_grayAreas.length - 1]) {
      _grayAreas[_grayAreas.length - 1].endTime = marker.time
    }
    if (marker.isGrayAreaStart || (marker.isGrayAreaEnd && _grayAreas[_grayAreas.length - 1])) {
      _grayAreas[_grayAreas.length - 1].label = marker.label
      _grayAreas[_grayAreas.length - 1].formattedTime = marker.formattedTime
      _grayAreas[_grayAreas.length - 1].hideMarker = marker.hideMarker
      _grayAreas[_grayAreas.length - 1].isRange = true
    }
    return parseMarker(marker, totalTime, translate)
  })

  return [..._markers, ..._grayAreas.map((m) => parseMarker(m, totalTime, translate))]
}
