<ion-button
  (click)="onHandleClick()"
  [disabled]="disabled"
  [color]="color"
  [expand]="expand"
  dataId="qa"
  [mcmDataAttr]="{ id: qaId }"
  [ngClass]="{'is-active': isActive}"
  class="mcm-button {{extraClasses}}">
  {{text | translate}}
  <ion-icon *ngIf="icon" [icon]="icon" [size]="iconSize"></ion-icon>
</ion-button>
