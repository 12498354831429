<div class="mcm-heat-map__container">
  <div *ngIf="title" class="mcm-heat-map__title">
    <app-title [text]="title"></app-title>
  </div>
  <app-segmented-control
    [options]="options"
    [modifiers]="[SegmentModifier.SecondLevel]"
    [value]="segmentValue"
    (onChange)="segmentValue = $event">
  </app-segmented-control>
  <div
    class="mcm-heat-map__content"
    [class.mcm-heat-map__content--empty]="!(dataDictionary[segmentValue] || []).length">
    <mcm-loader [loading]="loading">
      <mc-heat-map
        [heatMapData]="(dataDictionary[segmentValue] || [])"
        [arrowDirection]="arrowDirection"
        [heatMapConfig]="heatMapConfig"
        [isColored]="isColored">
      </mc-heat-map>
    </mcm-loader>
  </div>
</div>
