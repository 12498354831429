import { MetricAggregation } from '@core/enums/metric.enums'
import { parseMatchToRankingRequest } from '@features/rankings/parsers/match-ranking.parser'
import { RankingRequest } from '@features/rankings/models/ranking.model'
import { getMetricValueAndContext } from '@features/rankings/utils/ranking.utils'

export const resolveMatchRankingRequest = (payload, aggregationMode: MetricAggregation): RankingRequest => {
  const { context, value, mode } = getMetricValueAndContext(payload, aggregationMode)

  return parseMatchToRankingRequest(context, value, mode)
}
