import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { RankedMetric, RankingContext, RankingMenuActionPayload, RankingSourceMetric } from '../../models/ranking.model'
import { RankingMetricType } from '@features/rankings/enums/ranking.enums'

/* eslint-disable @typescript-eslint/naming-convention */

export const rankingActions = createActionGroup({
  source: 'Rankings',
  events: {
    'handle context menu': props<{ payload: any }>(),
    'open warning modal': props<{ body: string }>(),
    'perform fetch ranking': props<{ payload: any }>(),
    'open modal': props<{ payload: RankingContext }>(),
    'close modal': emptyProps(),
    'fetch ranking': props<{ payload: RankingMenuActionPayload }>(),
    'refresh ranking': props<{ payload: RankingMetricType }>(),
    'set source metric': props<{ sourceMetric: RankingSourceMetric }>(),
    'set ranking': props<{ ranking: RankedMetric[] }>(),
    'set ranking context': props<RankingContext>(),
    'set ranking match day': props<{ matchDay: number }>(),
    'purge ranking': emptyProps()
  }
})
/* eslint-enable @typescript-eslint/naming-convention */
