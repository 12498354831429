import { createFeatureSelector, createSelector, select } from '@ngrx/store'
import { StoreToken } from '@core/store/enums/store-tokens.enum'
import { pipe } from 'rxjs'
import { distinctUntilChanged, filter, map } from 'rxjs/operators'
import { DeepEqual } from '@mediacoach-ui-library/global'
import { HubConnectionState } from '@microsoft/signalr'

import { Topics } from '@sockets/models/socket.model'
import { Topic } from '@sockets/enums/socket.enum'
import { mapMatch, sortByMatchdayDescDateDesc } from '@core/utils/match.utils'
import { exists } from '@core/utils/object.utils'
import { SocketState } from '@core/store/states/socket.state'
import { selectSimplifiedLang } from '@core/store/selectors/user.selectors'
import { selectCurrentSeason } from '@core/store/selectors/season.selector'
import { APP_VERSION } from '@core/constants/constants'
import { Capacitor } from '@capacitor/core'
import { PlatformType } from '@core/models/models'
import { McmMatch } from '@core/models/match.models'

export const selectSocket = createFeatureSelector<SocketState>(StoreToken.socket)

/**
 * Gets all stored topics as a Map
 *
 * @see Topics
 * @return {Observable<Topics>}
 */
export const selectTopics = createSelector(selectSocket, ({ topics }): Topics => topics)

/**
 * Get a topic value or a set of topics as a Map
 * If a single topic is received, it will return its content. Otherwise it will return a subset of all topics as a Map
 *
 * @param topics
 * @see Topics
 * @return
 */
// export const getTopic = createSelector(
//   selectSocket, (state, topics: Topic | Topic[]): Topics | unknown => Array.isArray(topics) ?
//     topics.reduce((obj, topic) => ({
//       ...obj,
//       [topic]: exists(state.topics) ? state.topics[topic] : null
//     }), {})
//     : exists(state.topics) ? state.topics[topics] : null
// )

export const selectTopic = (topics: Topic | Topic[]) =>
  createSelector(selectSocket, (state) =>
    Array.isArray(topics)
      ? topics.reduce(
          (obj, topic) => ({
            ...obj,
            [topic]: exists(state.topics) ? state.topics[topic] : null
          }),
          {}
        )
      : exists(state.topics)
        ? state.topics[topics]
        : null
  )

export const selectDistinctTopic = (topics: Topic | Topic[]) =>
  pipe(select(selectTopic(topics)), distinctUntilChanged(DeepEqual))

export const selectSocketStatus = createSelector(selectSocket, (state) => state.status)

export const selectOnConnect = pipe(
  select(selectSocketStatus),
  filter((status) => status === HubConnectionState.Connected)
)

export const selectShouldUpdateVersion = pipe(
  select(selectTopics),
  distinctUntilChanged(DeepEqual),
  map(
    (topics) =>
      (topics[Topic.ForceUpdateVersion] || !topics[Topic.IsLive]) &&
      APP_VERSION < (topics[Topic.BuildVersion] as number) &&
      Capacitor.getPlatform() === PlatformType.Android
  )
)

export const selectTokenExpired = pipe(
  select(selectTopic(Topic.TokenExpired)),
  filter((expired) => !!expired),
  map(() => true)
)

export const selectLiveMatches = createSelector(
  selectTopic(Topic.LiveMatches),
  selectSimplifiedLang,
  selectCurrentSeason,
  (liveMatches: McmMatch[], language, currentSeason) => {
    if (!!liveMatches && !!language && !!currentSeason) {
      return sortByMatchdayDescDateDesc(liveMatches.map((match) => mapMatch(match)))
    }
  }
)
