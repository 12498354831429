import { MCMTeam } from '@pages/team/models/team.models'
import { MetricAggregation } from '@core/enums/metric.enums'
import { RankedMetric, RankingHeader, RankingRequest } from '@features/rankings/models/ranking.model'
import { RANKING_AGGREGATION_TO_METRIC_TYPE_MAP } from '@features/rankings/constants/rankings.constants'
import { LA_LIGA_LIST_NAME_CAPITALIZED } from '@core/constants/constants'
import { PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'
import { parseCommonRankingRow } from '@features/rankings/utils/ranking.utils'

export const parseTeamToRankingRequest = (team: MCMTeam, value: any, mode: MetricAggregation): RankingRequest => ({
  seasonId: team.seasonId,
  competitionId: team.competitionId,
  metricName: value.key,
  metricType: RANKING_AGGREGATION_TO_METRIC_TYPE_MAP[mode],
  teams: [team.id]
})

export const parseTeamMetricFinderToRankingRequest = (team, value, mode: MetricAggregation): RankingRequest => ({
  seasonId: team.seasonId,
  competitionId: team.competitionId,
  metricName: value.key,
  metricType: RANKING_AGGREGATION_TO_METRIC_TYPE_MAP[mode],
  teams: [team.id]
})

export const parseTeamRankingHeader = (metricKey: string, metricContext: any, matchDay: number): RankingHeader => ({
  title: metricKey,
  imageA: metricContext.portraitLogo,
  textA: metricContext.shortName,
  imageB: metricContext?.competition?.isoTypeLogo,
  textB: LA_LIGA_LIST_NAME_CAPITALIZED,
  placeholderA: PLACEHOLDER_IMAGES.TEAM,
  placeholderB: PLACEHOLDER_IMAGES.TEAM,
  matchDay
})

export const parseTeamRankingRows = (rankedMetric: RankedMetric[], context): RankedMetric[] => {
  const selectedValues = (rankedMetric || []).filter((m) => m.isSelected)
  return (rankedMetric || []).map((m) => {
    const { metric } = parseCommonRankingRow(m, context)
    const _id = context.homeTeam?.teamId || context.teamA?.id || context.id
    return {
      ...metric,
      imagePlaceholder: PLACEHOLDER_IMAGES.TEAM,
      styleClass: [
        'mcm-ranking__row',
        m.isSelected ? 'selected' : null,
        m.id === _id ? 'home' : null,
        selectedValues.length > 1 && m.id === selectedValues[0].id ? 'first-selected' : null,
        selectedValues.length > 1 && m.id === selectedValues[1].id ? 'last-selected' : null
      ]
        .filter(Boolean)
        .join(' ')
    }
  })
}
