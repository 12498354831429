import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@env'
import { ComparedTeamsData } from '@pages/team/models/team.models'
import { ApiComparedTeams, ApiTeam } from '@core/models/team.models'
import { ApiPlayers } from '@core/models/player.models'
import { ApiMetric } from '@core/models/metric.models'
import { catchObsError } from '@shared/decorators/catch-obs-error.decorator'

@Injectable()
export class TeamApi {
  constructor(private readonly _http: HttpClient) {}

  @catchObsError()
  fetchTeam(seasonId: string, competitionId: string, teamId: string) {
    return this._http.get<ApiTeam>(environment.API.TEAMS(seasonId, competitionId, teamId))
  }

  @catchObsError()
  fetchTeams(seasonId: string, competitionId: string) {
    return this._http.get<any>(environment.API.TEAMS(seasonId, competitionId))
  }

  @catchObsError()
  fetchPlayers(seasonId: string, competitionId: string, teamId: string) {
    return this._http.get<ApiPlayers>(environment.API.PLAYERS(seasonId, competitionId, teamId))
  }

  @catchObsError()
  compare(comparedTeamsData: ComparedTeamsData) {
    return this._http.post<ApiComparedTeams>(environment.API.TEAMS_COMPARE, JSON.stringify(comparedTeamsData))
  }

  @catchObsError()
  fetchTeamMetrics(seasonId: string, competitionId: string, teamId: string) {
    return this._http.get<ApiMetric>(environment.API.TEAM_METRIC_RESULTS(seasonId, competitionId, teamId))
  }
}
