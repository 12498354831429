export interface SegmentOption {
  id: string
  icon?: string
  image?: string
  label?: string
  selected?: boolean
  disabled?: boolean
  extraClasses?: string
  qaId?: string
}

export enum SegmentModifier {
  FirstLevel = 'm-component-first-level',
  SecondLevel = 'm-component-second-level',
  Header = 'm-component-header',
  Inline = 'm-component-inline',
  Circled = 'm-component-circled'
}
