import { isDevMode } from '@angular/core'

const wsLog = (message: string): void => {
  console.log(`%c[Socket] ${message}`, 'color: DodgerBlue')
}

export const wsDebug = (message: string): void => {
  if (isDevMode()) {
    wsLog(message)
  }
}
