import { EnvType, firstLetterToLowerCase, UserProfileType } from '@mediacoach-ui-library/global'

const MAIN_APP_NAME = 'mobile'
const MAIN_API_DOMAIN = 'integratedservices'
const PORTS = {
  APP: 80,
  IDENTITY_SERVER: 5050
}

export const URL_SCHEME_MAP = {
  // [EnvType.Dv]: 'dev.mediacoach.mobile://', // UNCOMMENT IF LOCAL iOS TESTING IS REQUIRED
  [EnvType.Dv]: 'com.laliga.mediacoach.mobile.dv://',
  [EnvType.Ts]: 'com.laliga.mediacoach.mobile.ts://',
  [EnvType.Qa]: 'com.laliga.mediacoach.mobile.qa://',
  [EnvType.Pr]: 'com.laliga.mediacoach.mobile://'
}
export const VARIABLES_BY_ENVIRONMENT = {
  [EnvType.Dev]: {},
  [EnvType.Dv]: {
    enableContextMenu: true,
    SMARTLOOK_API: '3df4d8f4170d79ddb97fe2ab39911626944edc75',
    bundleId: 'com.laliga.mediacoach.mobile.dv'
  },
  [EnvType.Qa]: {
    enableContextMenu: true,
    SMARTLOOK_API: '3df4d8f4170d79ddb97fe2ab39911626944edc75',
    bundleId: 'com.laliga.mediacoach.mobile.qa'
  },
  [EnvType.Ts]: {
    enableContextMenu: true,
    production: true,
    SMARTLOOK_API: '3df4d8f4170d79ddb97fe2ab39911626944edc75',
    bundleId: 'com.laliga.mediacoach.mobile.ts'
  },
  [EnvType.Pr]: {
    enableContextMenu: true,
    production: true,
    SMARTLOOK_API: 'bbfb0eb583de76b74db966ddc59b9eeac0fe0df2',
    bundleId: 'com.laliga.mediacoach.mobile'
  }
}
const AZURE_ENVS = [EnvType.Dv, EnvType.Qa, EnvType.Ts, EnvType.Pr]
const getEnvironmentInfo = (env: EnvType) => {
  const { APP_DOMAIN, IDENTITY_SERVER, API_DOMAIN } = getDomainInfo(env)
  const STATICS_URL_CLOUD = getUrlByEnv('https://festatics.mediacoach.es/', env !== EnvType.Dev ? env : EnvType.Test01)
  const STATICS_FULL_URL = (path: string) => STATICS_URL_CLOUD + (isAzureEnv(env) ? firstLetterToLowerCase(path) : path)

  return {
    production: false,
    APP_DOMAIN,
    API: getApiUrls(API_DOMAIN, env),
    STATICS_URL_LOCAL: 'assets/',
    PORTAL_DOWNLOAD_URL: getUrlByEnv('https://portal.mediacoach.es/descargas', env),
    STATICS_URL_CLOUD,
    PRIVACY_POLICY: 'https://policies.mediacoach.es/privacy',
    LEGAL_NOTICE_URL: (lang) => STATICS_FULL_URL(`Licenser/LicenseAgreement/License-Agreement_${lang}.html`),
    PROFILE_TYPE_BLACKLIST: [UserProfileType.DesktopUserProfile, UserProfileType.LiveProUserProfile],
    API_KEY_SECURE: 'Bearer',
    DEBOUNCE_TIME: {
      GENERAL: 200,
      CURSOR_CHANGES: 1000
    },
    I18N_LANGS: {
      es: { locale: 'es-ES', i18nLabel: 'MTR_COMMON_LANG_ES' },
      en: { locale: 'en-GB', i18nLabel: 'MTR_COMMON_LANG_EN' }
    },
    IDENTITY_SERVER: (isNative = false, domain = window.location.origin) => ({
      AUTHORITY_URL: IDENTITY_SERVER,
      REDIRECT_URI: `${isNative ? URL_SCHEME_MAP[env] : domain + '/'}auth`,
      END_SESSION_REDIRECT_URI: `${isNative ? URL_SCHEME_MAP[env] : domain + '/'}login`,
      API_LOGOUT: `${IDENTITY_SERVER}/connect/endsession`,
      TAG_POST_LOGOUT_REDIRECT_URI: 'post_logout_redirect_uri',
      END_SESSION_REDIRECT_CONCURRENCY_URI: `${isNative ? URL_SCHEME_MAP[env] : domain + '/'}concurrency`,
      RESPONSE_TYPE: 'code',
      CLIENT_ID: '39f4f70b-c8a2-d3c0-1e1d-d97720429e37',
      SCOPES: 'openid portal-api integratedservices-api integratednotifications-api'
    }),
    FIREBASE: {
      apiKey: 'AIzaSyAgokR9Ef-f92gsm_l5Qe8HzXEcMG4crS4',
      authDomain: 'mediacoach-mobile.firebaseapp.com',
      databaseURL: 'https://mediacoach-mobile.firebaseio.com',
      projectId: 'mediacoach-mobile',
      storageBucket: 'mediacoach-mobile.appspot.com',
      messagingSenderId: '319933323981',
      appId: '1:319933323981:web:08c917a7e1c7b4ca1ee784',
      measurementId: 'G-NMB7VLSPR0'
    }
  }
}
const isAzureEnv = (env: EnvType) => AZURE_ENVS.includes(env)

export const getDomainInfo = (env: EnvType) => {
  const isDev = env === EnvType.Dev
  return {
    APP_DOMAIN: isDev
      ? getUrlByPort('http://localhost', PORTS.APP)
      : getUrlByEnv(`https://${MAIN_APP_NAME}.mediacoach.es`, env),
    IDENTITY_SERVER: isDev
      ? getUrlByPort('http://localhost', PORTS.IDENTITY_SERVER)
      : getUrlByEnv(`https://${isAzureEnv(env) ? 'id' : 'login'}.mediacoach.es`, env),
    API_DOMAIN: getUrlByEnv(`https://${MAIN_API_DOMAIN}${isAzureEnv(env) ? '-api' : ''}.mediacoach.es`, env)
  }
}
const getUrlByPort = (domain: string, port: number) => `${domain}${port === 80 ? '' : `:${port}`}`
const getUrlByEnv = (domain: string, env: EnvType) => {
  const chunks = domain.split('.')
  chunks[0] += env === EnvType.Prod || env === EnvType.Pr ? '' : `-${env}`
  return chunks.join('.')
}

export function getEnvironment(env: EnvType) {
  return {
    envType: env,
    ...getEnvironmentInfo(env),
    ...VARIABLES_BY_ENVIRONMENT[env]
    // Keep this comment untouched. Bamboo will replace it with the following
    // environment variables:
    //
    //     BUILD_VERSION, BUILD_NUM, BUILD_DATE, BUILD_BRANCH, BUILD_COMMIT
    //
    // {{ COMPILATION_VARS }}
  }
}
export const getApiUrls = (appDomain: string, env: EnvType) => {
  const apiPath = `${appDomain}/api/`
  const SEASONS = `${apiPath}seasons`
  const MATCHES = (matchId: string) => `${apiPath}matches${matchId ? `/${matchId}` : ''}`
  const TEAMS = (seasonId: string, competitionId: string, teamId?: string) =>
    `${apiPath}seasons/${seasonId}/competitions/${competitionId}/teams${teamId ? `/${teamId}` : ''}`
  const PLAYERS = (seasonId: string, competitionId: string, teamId: string, id?: string) =>
    `${TEAMS(seasonId, competitionId, teamId)}/players${id ? `/${id}` : ''}`
  const API_TV_DOMAIN = getUrlByEnv(
    `https://${isAzureEnv(env) ? 'mobile-ws' : 'apitv'}.mediacoach.es${
      isAzureEnv(env) ? '' : '/integratednotifications/hubs/mobile'
    }`,
    env
  )
  const USER_PROFILE = `${apiPath}userprofile`
  return {
    MAIN_PATH: apiPath,
    USER_PROFILE,
    UPDATE_AVATAR: `${USER_PROFILE}/avatar`,
    SEASONS,
    SETTINGS: `${apiPath}settings`,
    TRANSLATIONS: (language: 'es-ES' | 'en-GB') => `${apiPath}translations/${language}`,
    TEAMS,
    TEAM_OVERVIEW_MATCHES: (seasonId: string, teamId: string) =>
      `${apiPath}seasons/${seasonId}/teams/${teamId}/overviewmatches`,
    TEAM_METRIC_RESULTS: (seasonId: string, competitionId: string, teamId: string) =>
      `${TEAMS(seasonId, competitionId, teamId)}/metricresults`,
    TEAMS_COMPARE: `${apiPath}teams/compare`,
    MATCH_DAYS: `${apiPath}matchdays`,
    PLAYERS,
    PLAYERS_METRIC_RESULTS: (seasonId: string, competitionId: string, teamId: string, id: string) =>
      `${PLAYERS(seasonId, competitionId, teamId, id)}/metricresults`,
    PLAYERS_SEARCH: `${apiPath}players/search`,
    PLAYERS_COMPARE: `${apiPath}players/compare`,
    STANDINGS_BY_MATCH_DAY_ID: (matchDayId: string) => `${apiPath}matchdays/${matchDayId}/standings`,
    MATCH_PLAYER_BY_ID: (matchId: string, teamId: string, id: string) =>
      `${apiPath}matches/${matchId}/teams/${teamId}/players/${id}`,
    MATCHES,
    LAST_MATCHES: (matchId: string) => `${MATCHES(matchId)}/lastnmatches`,
    MATCH_STREAMING: (matchId: string) => `${MATCHES(matchId)}/streaming`,
    MATCH_PASS_MATRIX: (matchId: string) => `${MATCHES(matchId)}/passmatrix`,
    MATCH_HEATMAP: (matchId: string) => `${MATCHES(matchId)}/heatmap`,
    MATCH_TEAM_METRICS: (matchId: string) => `${MATCHES(matchId)}/teammetricresults`,
    MATCH_PLAYER_LIST_METRICS: (matchId: string) => `${MATCHES(matchId)}/playerlistmetricresults`,
    MATCH_PLAYERS_COMPARE: (matchId: string) => `${MATCHES(matchId)}/players/compare`,
    MATCHES_IN_DATES: (seasonId: string, competitionId: string, dateFrom: string, dateTo: string) =>
      `${SEASONS}/${seasonId}/competitions/${competitionId}/matchesbetweendates?dateFrom=${dateFrom}&dateTo=${dateTo}`,
    METRIC_DEFINITIONS: `${apiPath}metricdefinitions`,
    WEB_SOCKET: (accessToken: string) => `${API_TV_DOMAIN}?access_token=${accessToken}`,
    MATCH_TIMELINE: (matchId: string) => `${MATCHES(matchId)}/streamevents?videoMetadataType=ALL`,
    MATCH_REFEREES: (matchId: string) => `${MATCHES(matchId)}/referees`,
    MATCH_KIT_SELECTOR: (matchId: string) => `${MATCHES(matchId)}/kitselector`,
    MATCH_GREEN_KEEPER: (matchId: string) => `${MATCHES(matchId)}/greenkeeper`,
    MATCH_SUNLIGHT: (matchId: string) => `${MATCHES(matchId)}/sunlight`,
    RANKINGS: `${apiPath}metrics/rank`
  }
}
