import { Component, OnDestroy } from '@angular/core'

import { Platform } from '@ionic/angular'
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx'
import { filter, take, tap } from 'rxjs/operators'

import { IonicPageVisibilityService } from '@core/services/ionic-page-visibility/ionic-page-visibility.service'
import { NotificationService } from '@core/services/notifications/notification.service'

import { StatusBar, Style } from '@capacitor/status-bar'
import { Device } from '@capacitor/device'
import { SplashScreen } from '@capacitor/splash-screen'
import { AnalyticsHandlerService } from '@core/services/analytics/analytics-handler.service'
import { UserProfile } from '@core/services/auth/auth.models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { IosUpdaterService } from '@core/services/updater/services/ios-updater.service'
import { register } from 'swiper/element/bundle'
import { CoreAuthService } from '@core/services/auth/core-auth-service'
import { Store } from '@ngrx/store'
import { selectIsoLang, selectProfile } from '@core/store/selectors/user.selectors'
import { TranslatorService } from '@mediacoach-ui-library/global'
import { App } from '@capacitor/app'
import { disconnectSocket, reconnectSocket } from '@core/store/actions/socket.actions'
import { FirebaseCrashlytics } from "@capacitor-firebase/crashlytics";

register()

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy {
  loading = true

  constructor(
    private _platform: Platform,
    private _translatorService: TranslatorService,
    private _screenOrientation: ScreenOrientation,
    private _ionicPageVisibilityService: IonicPageVisibilityService,
    private _notifications: NotificationService,
    private _ionicAuth: CoreAuthService,
    private readonly _analytics: AnalyticsHandlerService,
    private readonly _iosUpdater: IosUpdaterService,
    private readonly _store: Store
  ) {
    try {
      void this._initializeApp()
    } catch (e) {
      console.error('ERROR', e)
      void FirebaseCrashlytics.recordException(e)
    }

    // FIXME Check behaviour with UX
    this._ionicPageVisibilityService.onDidEnter$
      .pipe(
        take(1),
        tap(() => (this.loading = false))
      )
      .subscribe()
  }

  private async _initializeApp() {
    await this._platform.ready()
    await this._ionicAuth.init()
    await this._handleAppStateChange()
    this._setupAppWithProfile()
    this._handleLanguageChange()
    await this._setupDisplay()
  }

  ngOnDestroy() {
    this._iosUpdater.destroy()
  }

  private _handleAppStateChange() {
    return App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        this._store.dispatch(reconnectSocket())
      } else {
        this._store.dispatch(disconnectSocket())
      }
    })
  }

  private _setupAppWithProfile() {
    this._store
      .select(selectProfile)
      .pipe(
        filter((data) => !!data),
        take(1),
        tap(async (data) => {
          await this._analytics.requestPermissions()
          await this._setupNotifications(data)
          await this._analytics.init(data)
          this._iosUpdater.init()
        })
      )
      .subscribe()
  }

  private _handleLanguageChange() {
    this._store
      .select(selectIsoLang)
      .pipe(
        filter((lang) => !!lang),
        untilDestroyed(this)
      )
      .subscribe((lang) => {
        this._translatorService.use(lang, true)
      })
  }

  private async _setupNotifications(data: UserProfile) {
    if ((await Device.getInfo()).platform !== 'web') {
      await this._notifications.initialize(data.userId)
    }
  }

  private async _setupDisplay() {
    if ((await Device.getInfo()).platform !== 'web') {
      StatusBar.setOverlaysWebView({ overlay: true }).catch((error) => console.error(error))
      StatusBar.setStyle({ style: Style.Dark }).catch((error) => console.error(error))
    }

    this._screenOrientation.lock(this._screenOrientation.ORIENTATIONS.PORTRAIT).catch((error) => console.error(error))
    setTimeout(() => SplashScreen.hide().catch((error) => console.error(error)), 300)
  }
}
