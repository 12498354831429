import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class MenuService {
  private _updateSwiper = new Subject<void>()

  get updateSwiper() {
    return this._updateSwiper
  }

  updateAutoHeight() {
    this._updateSwiper.next()
  }
}
