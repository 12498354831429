import { UpdaterState } from '@core/services/updater/store/state/updater.state'
import { Action, createReducer, on } from '@ngrx/store'
import { setIOSBundleMetadata } from '@core/services/updater/store/actions/updater.actions'

const initialState: UpdaterState = {
  ios: undefined
}

const reducer = createReducer(
  initialState,
  on(setIOSBundleMetadata, (state, { ios }) => ({ ...state, ios }))
)

export const updaterReducer = (state: UpdaterState | undefined, action: Action): UpdaterState => reducer(state, action)
