import { EMPTY, Observable, OperatorFunction } from 'rxjs'
import { catchError } from 'rxjs/operators'

export function requestErrorHandler<T>(): OperatorFunction<T, T> {
  return function (source: Observable<T>): Observable<T> {
    return source.pipe(
      catchError((err) => {
        console.error(err)
        return EMPTY
      })
    )
  }
}
