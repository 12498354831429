import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, switchMap, tap } from 'rxjs/operators'
import { Router } from '@angular/router'
import { Location } from '@angular/common'
import { changeLocation, goToPage, navigate, navigateByUrl, navigateExternal } from '../actions/ngrx-router.actions'
import { NavController } from '@ionic/angular'
import { CoreAuthService } from '@core/services/auth/core-auth-service'
import { RouteUrl } from '@core/models/models'

@Injectable()
export class NgrxRouterEffects {
  navigate$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(navigate),
        tap(({ path, extras }) =>
          this._router.navigate(path ? [path] : [], extras ? extras : {})
        )
      ),
    { dispatch: false }
  )

  goToPage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToPage),
        switchMap(({ page}) => this._ionicAuth.isAuthenticated$.pipe(map((isAuthenticated) => ({page, isAuthenticated})))),
        tap(({ page, isAuthenticated }) => this._navController.navigateRoot(page || (isAuthenticated ? RouteUrl.Home : RouteUrl.Login)))
      ),
    { dispatch: false }
  )

  navigateExternal$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(navigateExternal),
        tap(({ path }) =>
          this._router.navigate([
            '/externalRedirect',
            { externalUrl: path, target: '_self' },
          ])
        )
      ),
    { dispatch: false }
  )

  navigateByUrl$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(navigateByUrl),
        tap(({ path }) => this._router.navigateByUrl(path))
      ),
    { dispatch: false }
  )

  changeLocation$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(changeLocation),
        tap(({ path }) => this._location.go(path))
      ),
    { dispatch: false }
  )


  constructor(
    private readonly _actions$: Actions,
    private readonly _router: Router,
    private readonly _location: Location,
    private readonly _navController: NavController,
    private readonly _ionicAuth: CoreAuthService
  ) {}
}
