import { parseMetricResults } from '@pages/team/constants/team.constants'
import { ComparedTeamsData, TeamHolder, TeamSummary } from '@pages/team/models/team.models'
import { buildMetricByAggregation, mapListMetrics } from '@core/utils/metric.utils'
import { LA_LIGA_LIST_NAME } from '@mediacoach-ui-library/global'
import { safeObjectData } from '@core/utils/object.utils'
import { MetricAggregation } from '@core/enums/metric.enums'
import { parseStatics } from '@core/utils/statics.utils'
import { StaticOrientation } from '@core/enums/statics.enum'
import { ApiComparedTeams } from '@core/models/team.models'
import { ApiMetric } from '@core/models/metric.models'
import { MATCH_DETAIL_BASE_PATH } from '@core/constants/route.constants'
import { McmTeamMatch } from '@core/models/dto/match.dto'
import { mapCompetition } from '@core/utils/season.utils'
import { Competition } from '@core/models/dto/competition.dto'
import { Season } from '@core/models/dto/season.dto'
import { AGGREGATED_METRIC_LABEL_MAP } from '@core/constants/metric-aggregation.constants'
import { McmMatch } from '@core/models/match.models'

export const getSingleTeamAverageHeader = (team: TeamHolder) => ({
  leftContent: {
    img: team?.portraitLogo,
    title: team?.abbreviation || team?.shortName,
    subtitle: AGGREGATED_METRIC_LABEL_MAP.averageValues
  },
  rightContent: {
    img: team?.competition?.isoTypeLogo,
    title: LA_LIGA_LIST_NAME,
    subtitle: AGGREGATED_METRIC_LABEL_MAP.averageValues
  }
})

export const getSingleTeamAccumulatedHeader = (team: TeamHolder) => ({
  leftContent: {
    img: team?.portraitLogo,
    title: team?.abbreviation || team?.shortName,
    subtitle: AGGREGATED_METRIC_LABEL_MAP[MetricAggregation.AverageValues]
  },
  rightContent: {
    img: team?.portraitLogo,
    title: team?.abbreviation || team?.shortName,
    subtitle: AGGREGATED_METRIC_LABEL_MAP[MetricAggregation.AccumulatedValues]
  }
})

export const getComparisonTeamHeader = (team: TeamHolder, aggregation) => ({
  leftContent: {
    img: team?.logoTeamA,
    title: team?.abbreviationTeamA || team?.nameTeamA,
    subtitle: AGGREGATED_METRIC_LABEL_MAP[aggregation]
  },
  rightContent: {
    img: team?.logoTeamB,
    title: team?.abbreviationTeamB || team?.nameTeamB,
    subtitle: AGGREGATED_METRIC_LABEL_MAP[aggregation]
  },
  fromMatch: true
})

export const buildTeamChartMetrics = (teamHolder: TeamHolder, chartMetricResults) =>
  mapListMetrics({
    leftContent: {
      img: teamHolder?.portraitLogo || teamHolder?.logoTeamA,
      title:
        teamHolder?.abbreviation || teamHolder?.shortName || teamHolder?.abbreviationTeamA || teamHolder?.nameTeamA,
      subtitle: AGGREGATED_METRIC_LABEL_MAP[MetricAggregation.AverageValues]
    },
    rightContent: {
      img:
        teamHolder?.abbreviationTeamB || teamHolder?.nameTeamB
          ? teamHolder.logoTeamB
          : teamHolder?.competition?.isoTypeLogo,
      title:
        teamHolder?.abbreviationTeamB || teamHolder?.nameTeamB
          ? teamHolder?.abbreviationTeamB || teamHolder?.nameTeamB
          : LA_LIGA_LIST_NAME,
      subtitle: AGGREGATED_METRIC_LABEL_MAP[MetricAggregation.AverageValues]
    },
    metricsProperty: parseMetricResults(chartMetricResults)
  })

export const buildTeamMetrics = (metricsProperty, teamHolder: TeamHolder) => {
  const isComparison = !!(teamHolder?.abbreviationTeamB || teamHolder?.nameTeamB)
  const id = safeObjectData(metricsProperty, 'key')
  return {
    id,
    label: id,
    metrics: {
      [MetricAggregation.MatchValues]: null,
      [MetricAggregation.AverageValues]: buildMetricByAggregation(
        metricsProperty,
        isComparison
          ? getComparisonTeamHeader(teamHolder, MetricAggregation.AverageValues)
          : getSingleTeamAverageHeader(teamHolder),
        MetricAggregation.AverageValues
      ),
      [MetricAggregation.AccumulatedValues]: buildMetricByAggregation(
        metricsProperty,
        isComparison
          ? getComparisonTeamHeader(teamHolder, MetricAggregation.AccumulatedValues)
          : getSingleTeamAccumulatedHeader(teamHolder),
        MetricAggregation.AccumulatedValues
      )
    }
  }
}

export const parseTeamMenuMetrics = (metrics: ApiMetric, teamHolder: TeamHolder) => [
  {
    ...buildTeamMetrics(metrics?.metricsOverview, teamHolder)
  },
  ...metrics?.metricCategories.map((metricCategory) => buildTeamMetrics(metricCategory, teamHolder))
]

export const mapSummary = (team: TeamSummary) => ({
  ...team,
  ...parseStatics(team.statics, [StaticOrientation.Portrait]),
  name: team.shortName || team.name,
  seasonId: team?.season?.id,
  competitionId: team?.competition?.id,
  trend: team?.lastMatchResults
})

export const mapTeamsCompareParams = ({
  season,
  competition,
  teamId,
  competitionIdB,
  teamIdB,
  seasonIdB
}): ComparedTeamsData => ({
  teamA: {
    id: teamId,
    competitionId: competition?.id,
    seasonId: season?.id
  },
  teamB: {
    id: teamIdB,
    competitionId: competitionIdB,
    seasonId: seasonIdB
  }
})

export const mapComparedTeamsToStore = (
  { teamA, teamB, ...metrics }: ApiComparedTeams,
  season: Season,
  competition: Competition
) => ({
  summary: { ...teamA, season, competition } as TeamSummary,
  teamB: { ...teamB, season, competition } as TeamSummary,
  metrics
})

export const mapTeamCardMatch = (match: McmTeamMatch, team: TeamSummary): McmTeamMatch => ({
  ...match,
  navigateUrl: MATCH_DETAIL_BASE_PATH(match.id),
  season: team?.season,
  competition: mapCompetition(team?.competition) as Competition
})

export const teamExistOnMatch = (id: string, m: McmMatch) => m?.homeTeam?.teamId === id || m?.awayTeam?.teamId === id

export const hasCurrentLiveMatchByTeamId = (id: string, liveMatches: McmMatch[]) =>
  liveMatches?.findIndex((m) => teamExistOnMatch(id, m)) !== -1
