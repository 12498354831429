import { NgModule } from '@angular/core'

import { MetricDetailComponent } from './components/metric-detail/metric-detail.component'
import { SharedModule } from '@shared/shared.module'
import { StoreModule } from '@ngrx/store'
import { METRIC_DETAIL_FEATURE_TOKEN } from './constants/metric-detail.constants'
import { metricDetailReducer } from './store/reducers/metric-detail.reducer'
import { EffectsModule } from '@ngrx/effects'
import { MetricDetailEffects } from './store/effects/metric-detail.effects'

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forFeature(METRIC_DETAIL_FEATURE_TOKEN, metricDetailReducer),
    EffectsModule.forFeature([MetricDetailEffects])
  ],
  exports: [MetricDetailComponent],
  declarations: [MetricDetailComponent],
  providers: []
})
export class MetricDetailModule {}
