<div class="mcm-team-state__container">
  <div class="mcm-team-state__basics">
    <div class="mcm-team-state__basics__position">
      {{stats?.position ? stats?.position + ((stats?.position | position) | translate) : '-'}}
    </div>
    <div class="mcm-team-state__basics__points">
      {{stats?.points !== null ? stats?.points : '-'}}
      <span class="mcm-team-state__basics__points__label"> {{'MTR_HEADER_POINTS_SHORT' | translate }} </span>
    </div>
    <img
      loading="lazy"
      *ngIf="(currentCompetition$ | async) as favCompetition"
      class="mcm-team-state__basics__competition"
      appDefaultImage
      [srcUrl]="favCompetition?.landscapeLogoWhite"
      [alt]="favCompetition.name" />
  </div>

  <div class="mcm-team-state__stats">
    <div class="mcm-team-state__stats__item">
      <div class="mcm-team-state__stats__item-container" *ngFor="let field of FIELDS">
        <div class="mcm-team-state__stats__item-label">{{field.label | translate}}</div>
        <div class="mcm-team-state__stats__item-value">
          {{(stats || {})[field.key] !== null ? (stats || {})[field.key] : '-'}}
        </div>
      </div>
    </div>
  </div>
</div>
