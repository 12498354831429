import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'mcm-video-unavailable',
  templateUrl: './video-unavailable.component.html',
  styleUrls: ['./video-unavailable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoUnavailableComponent {
  @Input() message = 'MTR_MATCH_DETAIL_VIDEO_NOT_AVAILABLE'
}
