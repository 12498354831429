import { Component, Input } from '@angular/core'

import { STATS, STATS_WITH_UNITS_DICTIONARY } from './header-player.constants'
import { PLAYER_PLACEHOLDER_IMAGE, SHIELD_PLACEHOLDER_IMAGE } from '@core/constants/asset-path'
import { PLAYER_DEMARCATION_FALLBACK_KEY, PLAYER_DEMARCATION_KEYS } from '@core/constants/player.constants'

@Component({
  selector: 'app-header-player',
  templateUrl: './header-player.component.html',
  styleUrls: ['./header-player.component.scss']
})
export class HeaderPlayerComponent {
  @Input() player
  @Input() fromMatch = false

  STATS = STATS
  STATS_WITH_UNITS_DICTIONARY = STATS_WITH_UNITS_DICTIONARY
  PLAYER_PLACEHOLDER_IMAGE = PLAYER_PLACEHOLDER_IMAGE
  SHIELD_PLACEHOLDER_IMAGE = SHIELD_PLACEHOLDER_IMAGE

  readonly playerDemarcationKeys = PLAYER_DEMARCATION_KEYS
  readonly playerDemarcationFallbackKey = PLAYER_DEMARCATION_FALLBACK_KEY

  constructor() {}
}
