<div class="mcm-team-selection__wrapper">
  <ng-container *ngIf="(competitions$ | async) as competitions">
    <ng-container *ngIf="!onlyCompetition; else competitionSelection">
      <div class="mcm-team-selection__header" *ngIf="isFavTeamMode">
        <app-segmented-control
          [options]="competitions"
          [modifiers]="['mcm-segmented-control--no-margin']"
          (onChange)="onCompetitionChanged($event)">
        </app-segmented-control>
      </div>
      <ng-container *ngIf="((teams$ | loading | async) || { loading: true, value: []}) as teamsObj">
        <mcm-loader class="mcm-team-selection__loader" [loading]="teamsObj.loading">
          <div class="mcm-team-selection__list">
            <div
              class="mcm-team-selection__list-item"
              [class.mcm-team-selection__list-item--active]="selectedTeam?.id === team.id"
              [class.mcm-team-selection__list-item--disabled]="team.id === teamId"
              *ngFor="let team of teamsObj.value"
              (click)="onTeamClick(team)">
              <app-team-badge [team]="team"></app-team-badge>
            </div>
          </div>
        </mcm-loader>
      </ng-container>
    </ng-container>

    <ng-template #competitionSelection>
      <div class="mcm-team-selection__list mcm-team-selection__list--only-competition">
        <div
          class="mcm-team-selection__list-item mcm-team-selection__list-item--competition"
          [class.mcm-team-selection__list-item--active]="selectedCompetitionId === competition.id"
          *ngFor="let competition of competitions"
          (click)="selectedCompetitionId = competition.id">
          <img
            loading="lazy"
            class="mcm-team-selection__competition-logo"
            appDefaultImage
            [srcUrl]="competition.image"
            [alt]="competition.officialName" />
        </div>
      </div>
    </ng-template>
    <div class="mcm-team-selection__footer">
      <app-button
        text="MTR_COMMON_SELECT"
        extraClasses="mcm-button--gradient mcm-button--block"
        [disabled]="onlyCompetition ? (!selectedCompetitionId || competitionId === selectedCompetitionId) : (!selectedTeam?.id || teamId === selectedTeam?.id)"
        (onClick)="onTeamSelected()">
      </app-button>
    </div>
  </ng-container>
</div>
