import { Component, Input } from '@angular/core'
import { TEAM_TREND_CLASS_MAP } from './team-trend.constants'

@Component({
  selector: 'app-team-trend',
  templateUrl: './team-trend.component.html',
  styleUrls: ['./team-trend.component.scss']
})
export class TeamTrendComponent {
  @Input() alignment = 'left'
  @Input() trendData: Array<{ resultMatchKey: string; matchdayNumber: number; resultMatch: string }>

  TEAM_TREND_CLASS_MAP = TEAM_TREND_CLASS_MAP

  constructor() {}
}
