import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { AuthError } from '../services/auth/auth.models'
import { CoreAuthService } from '@core/services/auth/core-auth-service'
import { Store } from '@ngrx/store'
import { goToPage } from '@core/ngrx-router/state/actions/ngrx-router.actions'

@Injectable({
  providedIn: 'root'
})
export class UserDisallowedGuard {
  constructor(
    private _ionicAuth: CoreAuthService,
    private readonly _store: Store
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this._ionicAuth.events$.pipe(
      tap((action) => action.error !== AuthError.UserDisallowed && this._store.dispatch(goToPage({}))),
      map((error) => !!error)
    )
  }
}
