import { Injectable } from '@angular/core'
import { Preferences } from '@capacitor/preferences'
import { Preference } from '@core/services/preferences/preferences.enum'

@Injectable({ providedIn: 'root' })
export class PreferencesService {
  async setPreference(key: Preference, value: any) {
    await Preferences.set({
      key: key as string,
      value: JSON.stringify(value)
    })
  }

  async getPreference(key: Preference): Promise<any> {
    const item = await Preferences.get({ key: key as string })
    return JSON.parse(item.value)
  }

  async getPreferences(...keys: Preference[]): Promise<any[]> {
    const items: any[] = []
    for (const key of keys) {
      const value = await this.getPreference(key)
      items.push(value)
    }
    return items
  }
}
