import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { ToastController } from '@ionic/angular'
import { ToastData } from './toast.models'

const DEFAULT_DURATION = 2000

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  showing = false

  constructor(
    private toastController: ToastController,
    private translateService: TranslateService
  ) {}

  create(toastData: ToastData) {
    if (this.showing || !toastData.message) {
      return
    }
    this.toastController
      .create({
        duration: DEFAULT_DURATION,
        ...toastData,
        message: this.translateService.instant(toastData.message, toastData.messageParams || null)
      })
      .then((toast) => void toast.present())
    this.showing = true
    setTimeout(() => {
      this.showing = false
    }, DEFAULT_DURATION)
  }
}
