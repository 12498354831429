import { createAction, props } from '@ngrx/store'

export const setSelectedCompetitionIdSetting = createAction(
  '[Settings] Set selected competitionId',
  props<{ selectedCompetitionId: string }>()
)

export const setSelectedMatchDayIdSetting = createAction(
  '[Settings] Set selected matchDayId',
  props<{ selectedMatchDayId: string }>()
)
