import { Directive, HostListener, Self } from '@angular/core'
import { VideoComponent } from '@shared/components/video-container/components/video/video.component'
import { throttle } from '@shared/decorators/throttle.decorator'

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'mcm-video' })
export class VideoUserActiveDirective {
  @HostListener('mouseenter')
  @throttle()
  onMouseEnter() {
    this._host.showControls(true)
  }

  @HostListener('mouseleave')
  @throttle()
  onMouseLeave() {
    this._host.showControls(false)
  }

  constructor(@Self() private readonly _host: VideoComponent) {}
}
