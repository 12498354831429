import { Location } from '@angular/common'
import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core'

import * as d3 from 'd3'
import {
  chartDataItemTemplate,
  closestTicks,
  DebounceTimeType,
  FlattenDeep,
  IsDOMElementVisible,
  mergeObjects
} from '@mediacoach-ui-library/global'

import { MetricConfig } from './spider-chart.models'
import { debounceTime, filter, tap } from 'rxjs/operators'
import { MetricPage } from '@pages/metric/metric.page'
import { MetricCategory } from '@pages/metric/metric.models'
import { RADIANS, XMLNS } from '@core/constants/chart.constants'
import { DEFAULT_KEY_ALIAS, DEFAULT_PARAMS } from './spider-chart.constants'
import { Axis, SVGAttrs, TextAnchor } from '@core/models/chart.models'
import { IonicPageVisibilityService } from '@core/services/ionic-page-visibility/ionic-page-visibility.service'
import { safeObjectData } from '@core/utils/object.utils'
import { McmModalControllerService } from '@core/services/mcm-modal-controller/mcm-modal-controller.service'

let componentIndex = 0

@Component({
  selector: 'app-spider-chart',
  templateUrl: './spider-chart.component.html',
  styleUrls: ['./spider-chart.component.scss']
})
export class SpiderChartComponent {
  @Input() loading = false
  @Input() keyAlias = DEFAULT_KEY_ALIAS
  // FIXME 'metricConfig' should exists in such general component, need refactor
  @Input() metricConfig: MetricConfig = {
    data: {},
    type: MetricCategory.Team
  }
  @Output() onValueSelect = new EventEmitter<{
    value: number
    itemIndex: number
    keys: string[]
    participantIndex: number
  }>()
  @ViewChild('spiderChartSvg', { static: true }) svg: ElementRef

  id = `mcm-spider-chart__id-${componentIndex++}`
  width: number
  height: number
  factor: number[]
  levels: number[]
  ticks
  isDataEmpty: boolean
  axisKeys: string[]
  polygonMatrix: {
    value: number
    participantIndex: number
    itemIndex: number
    keys: string[]
    coords: Axis
  }[][]
  polygonData: SVGAttrs[]
  legendData: SVGAttrs[]
  helperLineData: SVGAttrs[][]
  keyData: { [key: string]: SVGAttrs }
  axisData: SVGAttrs[]
  bounds: { x: [number, number]; y: [number, number] }

  slideOnPageVisible$ = this._ionicPageVisibilityService.onDidEnter$.pipe(
    debounceTime(DebounceTimeType.ForCrashes),
    filter(() => IsDOMElementVisible(this._el.nativeElement)),
    tap(() => {
      this.keyTextElems.forEach((keyTextElem) => {
        const elem = keyTextElem.nativeElement
        elem.innerHTML = ''
        this._wrapText(elem)
      })
    })
  )

  private _keyTextElems: QueryList<ElementRef<SVGTextElement>>
  private _data
  private _params = DEFAULT_PARAMS

  constructor(
    private _el: ElementRef,
    private _location: Location,
    private _modalController: McmModalControllerService,
    private _ionicPageVisibilityService: IonicPageVisibilityService
  ) {}

  get data() {
    return this._data
  }
  get params() {
    return this._params
  }
  get keyTextElems() {
    return this._keyTextElems
  }

  @ViewChildren('keyText') set keyTextElems(_keyTextElems) {
    this._keyTextElems = _keyTextElems
    _keyTextElems.forEach((keyTextElem) => this._wrapText(keyTextElem.nativeElement))
  }

  @Input() set data(_data) {
    this._data = _data && [..._data]
    this.init()
  }

  @Input() set params(_params) {
    this._params = mergeObjects(DEFAULT_PARAMS, _params || {})
    this.init()
  }

  init() {
    if (this.params) {
      this.width = this.params.radius * 2 + this.params.margin.left + this.params.margin.right
      this.height = this.params.radius * 2 + this.params.margin.top + this.params.margin.bottom
    }
    if (this.data && this.params) {
      const mappedData = this.data.length > 0 ? [this.data[0], ...this.data.slice(1).reverse()] : this.data

      this.bounds = {
        x: [-this.params.margin.left, this.params.radius * 2 + this.params.margin.right],
        y: [-this.params.margin.top, this.params.radius * 2 + this.params.margin.bottom]
      }

      // OPERATIONS FOR TICKS/LEVELS
      const minMaxYScale = this.params.normalize
        ? [0, 100]
        : d3.extent(this.data.reduce((arr, item) => [...arr, ...item.values], [0]))
      minMaxYScale[1] = minMaxYScale[1] * this.params.edgeMarginRatio
      const maxYScale = Math.ceil(minMaxYScale[1])
      const levelMaxNum = DEFAULT_PARAMS.levelMaxNum > maxYScale ? maxYScale + 1 : DEFAULT_PARAMS.levelMaxNum

      let yDomain = d3.scaleLinear().domain(minMaxYScale)
      this.ticks = closestTicks(d3.axisLeft(yDomain), levelMaxNum, true)
      const mappedDataLength = mappedData.length
      if (mappedDataLength < 3) {
        const isEmpty = mappedDataLength <= 1 && ((mappedData[0] || {}).values || []).length < 1
        this.ticks = !isEmpty ? this.ticks : Array(DEFAULT_PARAMS.levelMaxNum - 1).fill(' ')
        const chartDataItemTemp = { ...chartDataItemTemplate, ...(isEmpty ? {} : { values: Array(2).fill(0) }) }

        mappedData.push(...Array(3 - mappedDataLength).fill({ ...chartDataItemTemp, keys: [' '] }))
      }

      if (this.ticks.slice(-1)[0] < maxYScale) {
        if (this.ticks.length <= 2) {
          this.ticks[1] = maxYScale
        } else {
          minMaxYScale[1] *= 1.5
          yDomain = d3.scaleLinear().domain(minMaxYScale)
          this.ticks = closestTicks(d3.axisLeft(yDomain), levelMaxNum, true)
        }
      }

      this._setLevels(this.ticks.length - 1)
      const maxValue = this.ticks[this.levels.length]

      // PRE-CALCULATIONS
      const allAxis = mappedData.map(({ keys }) => keys)
      this.axisKeys = FlattenDeep(allAxis)
      const radiansPerChartLength = RADIANS / allAxis.length

      const maxValueLength = Math.max(...mappedData.map(({ values }) => values.length))

      this.polygonMatrix = Array(maxValueLength)
        .fill(null)
        .map((v, i) =>
          mappedData.map((a, j) => ({
            value: a.values[i],
            participantIndex: i,
            itemIndex: j,
            keys: a.keys,
            coords: {
              x: this.params.radius * (1 - (Math.max(a.values[i], 0) / maxValue) * Math.sin(j * radiansPerChartLength)),
              y: this.params.radius * (1 - (Math.max(a.values[i], 0) / maxValue) * Math.cos(j * radiansPerChartLength))
            }
          }))
        )

      this.isDataEmpty = this.polygonMatrix.length < 1 || FlattenDeep(this.polygonMatrix).every(({ value }) => !value)
      if (this.isDataEmpty) {
        this._setLevels(5)
      }

      // We replace requested keys
      this.helperLineData = this.levels.map((level, i) =>
        this.axisKeys.map((v, j) => ({
          x1: this.factor[i] * (1 - Math.sin(j * radiansPerChartLength)),
          y1: this.factor[i] * (1 - Math.cos(j * radiansPerChartLength)),
          x2: this.factor[i] * (1 - Math.sin((j + 1) * radiansPerChartLength)),
          y2: this.factor[i] * (1 - Math.cos((j + 1) * radiansPerChartLength)),
          transform:
            'translate(' + (this.params.radius - this.factor[i]) + ', ' + (this.params.radius - this.factor[i]) + ')'
        }))
      )

      this.axisData = this.axisKeys.map(
        (key, i) => ({
          x1: this.params.radius,
          y1: this.params.radius,
          x2: this.params.radius * (1 - Math.sin((i + 1) * radiansPerChartLength)),
          y2: this.params.radius * (1 - Math.cos((i + 1) * radiansPerChartLength)),
          r: 1
        }),
        {}
      )

      const { keyPadding } = this.params
      this.keyData = allAxis.reduce((obj, key, i) => {
        const x =
          this.params.radius * (1 - Math.sin(i * radiansPerChartLength)) -
          keyPadding * Math.sin(i * radiansPerChartLength)
        const y =
          this.params.radius * (1 - Math.cos(i * radiansPerChartLength)) -
          keyPadding * Math.cos(i * radiansPerChartLength)
        return {
          ...obj,
          [key]: {
            x,
            y,
            dy: '1.5em',
            textAnchor: this._getTextAnchor(x)
          }
        }
      }, {})

      this.polygonData = this.polygonMatrix.reverse().reduce(
        (arr: SVGAttrs[], v, i) => [
          ...arr,
          {
            filter: `url(${this._location.path()}#${this.id}--shadow)`,
            fill: `url(${this._location.path()}#${this.id}--grad-${i})`,
            points: this.polygonMatrix[i].map(({ coords }) => coords.x + ',' + coords.y).join(' ')
          }
        ],
        []
      )

      const getLegendDataItem = (factor, label) => {
        const translateXY = this.params.radius - factor
        return {
          x: factor * (1 - Math.sin(0)),
          y: factor * (1 - Math.cos(0)),
          transform: `translate(${translateXY},${translateXY})`,
          value: label
        }
      }

      this.legendData = [
        getLegendDataItem(0, this.ticks[0]),
        ...this.levels.map((level, i) => getLegendDataItem(this.factor[i], this.ticks[i + 1]))
      ]
    }
  }

  async openMetricModal(key) {
    const items = safeObjectData(this.metricConfig.data, 'groups.0.items', [])
    const { header } = this.metricConfig.data
    const [filterItem] = items
      .filter(({ text }) => text === key)
      .map((item) => ({
        ...item,
        text: null,
        homeValue: item.homeLabel,
        awayValue: item.awayLabel,
        homeDecorated: false,
        awayDecorated: false
      }))

    const modal = await this._modalController.create({
      component: MetricPage,
      componentProps: {
        data: {
          dualData: { ...this.metricConfig.data, groups: [{ items: [filterItem] }] },
          metricLabel: key,
          competition: this.metricConfig.type === MetricCategory.Team ? this.metricConfig.competition : null,
          imgUrl: safeObjectData(header, 'leftContent.img', ''),
          name: safeObjectData(header, 'leftContent.title', ''),
          nameWithShirtNumber: safeObjectData(header, 'leftContent.title', ''),
          team: this.metricConfig.team,
          playerPosition: this.metricConfig.playerPosition,
          playerPositionKey: this.metricConfig.playerPositionKey,
          genericPositionKey: this.metricConfig.genericPositionKey,
          specificPositionKey: this.metricConfig.specificPositionKey
        },
        type: this.metricConfig.type,
        isDual: true
      },
      cssClass: `custom-modal custom-modal--${this.metricConfig.type} custom-modal--${this.metricConfig.type}-dual`,
      backdropDismiss: false
    })
    return await modal.present()
  }

  private _setLevels(length) {
    this.levels = Array(length)
      .fill(true)
      .map((v, i) => i)
    this.factor = this.levels.map((level) => this.params.radius * ((level + 1) / this.levels.length))
  }

  private _append<K extends keyof SVGElementTagNameMap>(
    parentElem,
    elemName: K,
    attrs: { [key: string]: any }
  ): SVGElementTagNameMap[K] {
    const elem = document.createElementNS(XMLNS, elemName)
    parentElem.appendChild(elem)
    Object.entries(attrs).forEach(([key, value]) => elem.setAttribute(key, value))
    return elem
  }

  private _getTextAnchor(x) {
    const threshold = parseFloat((this.params.radius * this.params.textAnchorThreshold).toFixed(2))
    return x < this.params.radius - threshold
      ? TextAnchor.End
      : x < this.params.radius + threshold
        ? TextAnchor.Middle
        : TextAnchor.Start
  }

  private _wrapText(keyTextElem) {
    const { key, translated } = keyTextElem.dataset
    if (translated && !keyTextElem.childElementCount) {
      const { x, y } = this.keyData[key]
      const words = translated.split(/\s+/).reverse()
      const lineHeight = 1.1 // ems
      const dy = parseFloat(this.keyData[key].dy as string)
      const anchor = this.keyData[key].textAnchor
      const width =
        anchor === TextAnchor.End ? x - this.bounds.x[0] : anchor === TextAnchor.Start ? this.bounds.x[1] - x : null
      let word
      let line = []
      let lineNumber = 0
      let span = this._append(keyTextElem, 'tspan', { x, y, dy: dy + 'em' })

      // eslint-disable-next-line no-cond-assign
      while ((word = words.pop())) {
        line.push(word)
        span.textContent = line.join(' ')
        if (width && line.length > 1 && span.getComputedTextLength() > width) {
          line.pop()
          span.textContent = line.join(' ')
          line = [word]
          span = this._append(keyTextElem, 'tspan', { x, y, dy: ++lineNumber * lineHeight + dy + 'em' })
          span.textContent = word
        }
      }
    }
  }
}
