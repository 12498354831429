import { Platform } from '@ionic/angular'
import { Requestor, StorageBackend } from '@openid/appauth'
import { Browser } from 'ionic-appauth'
import { App } from '@capacitor/app'
import { NgZone } from '@angular/core'
import { environment } from '@env'
import { getIDSSettingConfig } from '@core/services/auth/auth.constants'
import { URL_SCHEME_MAP } from '../../../environments/environment.constants'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Store } from '@ngrx/store'
import { authorizationCallback, endSessionCallback, onAuthEventChange } from '@core/store/actions/auth.actions'
import { CoreAuthService } from '@core/services/auth/core-auth-service'

export const authFactory = (
  platform: Platform,
  ngZone: NgZone,
  browser: Browser,
  storage: StorageBackend,
  requestor: Requestor,
  _store: Store
) => {
  const authService = new CoreAuthService(browser, storage, requestor)
  authService.authConfig = getIDSSettingConfig(environment.IDENTITY_SERVER(platform.is('capacitor')))

  if (platform.is('capacitor')) {
    App.addListener('appUrlOpen', (data: any) => {
      if (data.url !== undefined) {
        ngZone.run(() => {
          if (data.url.indexOf(authService.authConfig.redirect_url) === 0) {
            _store.dispatch(authorizationCallback({ url: data.url }))
          } else if (data.url.indexOf(authService.authConfig.end_session_redirect_url) === 0) {
            _store.dispatch(
              endSessionCallback({ navigateUrl: data.url.replace(URL_SCHEME_MAP[environment.envType], '') })
            )
          }
        })
      }
    })
  }

  authService.events$
    .pipe(takeUntilDestroyed())
    .subscribe((authEventAction) => _store.dispatch(onAuthEventChange({ event: authEventAction })))

  return authService
}
