import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { RangeMarker } from '../../models/video-markers.models'

@Component({
  selector: 'mcm-video-markers',
  templateUrl: './video-markers.component.html',
  styleUrls: ['./video-markers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoMarkersComponent {
  @Input() markers: RangeMarker[]
  @Output() marker = new EventEmitter<RangeMarker>()

  onClick(_marker: RangeMarker, event: Event): void {
    event.preventDefault()
    event.stopPropagation()
    this.marker.emit(_marker)
  }
}
