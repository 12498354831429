import { UserProfile } from '@core/services/auth/auth.models'
import { getPermissions } from '@core/services/auth/auth.constants'

export const parseProfile = (profile: UserProfile): UserProfile => ({
  ...profile,
  permissions: getPermissions(),
  fullName: `${profile.name} ${profile.surname}`,
  favourites: {
    competitionId: profile.favouriteCompetitionId,
    teamId: profile.favouriteTeamId
  }
})
