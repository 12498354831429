import { Pipe, PipeTransform } from '@angular/core'
import { ConcatParamsPipe } from '@mediacoach/ui'
import { TranslateService } from '@ngx-translate/core'
import { MultipleTranslatePipe } from '@shared/pipes/multiple-translate.pipe'
import { PlayerPosition } from '@core/enums/player.enums'

@Pipe({
  name: 'demarcation'
})
export class DemarcationPipe<T = unknown> implements PipeTransform {
  concatParamsPipe = new ConcatParamsPipe()
  multipleTranslatePipe = new MultipleTranslatePipe(this.translate)

  constructor(private readonly translate: TranslateService) {}

  transform(
    value: T,
    params: string[],
    fallback: string | string[],
    divider: string = ' ',
    joiner: string = ' - '
  ): string {
    if (value && params && fallback) {
      const item = value as any
      return (item.playerPosition === PlayerPosition.Goalkeeper || item.playerPositionKey === 'MTR_PLAYERPOSITION_GOALKEEPER')
        ? this.translate.instant(item.playerPositionKey)
        : this.multipleTranslatePipe.transform(
            this.concatParamsPipe.transform(item, params, fallback, divider),
            divider,
            joiner
          )
    }

    return undefined
  }
}
