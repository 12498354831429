import { RankingDisplayMode } from '@features/rankings/enums/ranking.enums'
import { MetricAggregation } from '@core/enums/metric.enums'
import { RankedMetric, RankingSourceMetric } from '../models/ranking.model'
import { RANKING_PLAYER_COLUMNS, RANKING_TEAM_COLUMNS } from '../constants/rankings.constants'
import { parseTeamRankingHeader, parseTeamRankingRows } from '../parsers/team-ranking.parser'
import { parseTeamComparisonRankingHeader } from '@features/rankings/parsers/team-comparison-ranking.parser'
import { LA_LIGA_LIST_NAME_CAPITALIZED } from '@core/constants/constants'
import { exists, roundJustDecimals } from '@core/utils/number.utils'
import {
  parseMatchPlayerRankingHeader,
  parsePlayerRankingHeader,
  parsePlayerRankingRows
} from '@features/rankings/parsers/player-ranking.parser'
import { parseMatchRankingHeader } from '@features/rankings/parsers/match-ranking.parser'
import { parsePlayerComparisonRankingHeader } from '@features/rankings/parsers/player-comparison-ranking.parser'
import { MetricFinderKey } from '@core/models/models'
import { AGGREGATED_METRIC_LABEL_MAP } from '@core/constants/metric-aggregation.constants'

export const resolveRankingHeader = (
  displayMode: RankingDisplayMode,
  metricContext: any,
  metricKey: string,
  matchDay: number
) => {
  switch (displayMode) {
    case RankingDisplayMode.Team:
      return parseTeamRankingHeader(metricKey, metricContext, matchDay)

    case RankingDisplayMode.ComparedTeam:
      return parseTeamComparisonRankingHeader(metricKey, metricContext, matchDay)

    case RankingDisplayMode.Player:
      return parsePlayerRankingHeader(metricKey, metricContext, matchDay)
    case RankingDisplayMode.MatchPlayer:
      return parseMatchPlayerRankingHeader(metricKey, metricContext, matchDay)

    case RankingDisplayMode.ComparedPlayer:
    case RankingDisplayMode.MatchComparedPlayer:
      return parsePlayerComparisonRankingHeader(metricKey, metricContext, matchDay)

    case RankingDisplayMode.Match:
      return parseMatchRankingHeader(metricKey, metricContext, matchDay)
  }
}

export const resolveRankingColumns = (displayMode: RankingDisplayMode, mode: MetricAggregation) => {
  const aggregationColumn = {
    key: 'metricValue',
    styleClass: 'mcm-ranking-container__content-header last-column',
    label: AGGREGATED_METRIC_LABEL_MAP[mode],
    width: 'auto'
  }

  let columns

  switch (displayMode) {
    case RankingDisplayMode.Team:
    case RankingDisplayMode.ComparedTeam:
    case RankingDisplayMode.Match:
      columns = RANKING_TEAM_COLUMNS
      break
    case RankingDisplayMode.Player:
    case RankingDisplayMode.ComparedPlayer:
    case RankingDisplayMode.MatchPlayer:
    case RankingDisplayMode.MatchComparedPlayer:
      columns = RANKING_PLAYER_COLUMNS
      break
  }

  return [...columns, aggregationColumn]
}

export const resolveRankingRows = (
  displayMode: RankingDisplayMode,
  rankedMetric: RankedMetric[],
  { metricFinder, ...metricContext }: any,
  competition
) => {
  const context = { ...(metricContext.holder ?? metricContext), competition }
  if (rankedMetric) {
    switch (displayMode) {
      case RankingDisplayMode.Team:
      case RankingDisplayMode.ComparedTeam:
      case RankingDisplayMode.Match:
        return parseTeamRankingRows(rankedMetric, context)
      case RankingDisplayMode.Player:
      case RankingDisplayMode.MatchPlayer:
      case RankingDisplayMode.ComparedPlayer:
      case RankingDisplayMode.MatchComparedPlayer:
        return parsePlayerRankingRows(rankedMetric, context)
    }
  }

  return null
}

export const parseCommonRankingRow = (
  metric: RankedMetric,
  metricContext
): {
  metric: RankedMetric
} => {
  let photoUrl = metric.photoUrl
  let rank
  let name = metric.name
  const isCompetition = metric.name === 'competition'
  if (isCompetition) {
    rank = '#'
    name = LA_LIGA_LIST_NAME_CAPITALIZED
    photoUrl = metricContext?.competition?.isoTypeLogo
  } else {
    rank = metric.ranking
  }
  return {
    metric: {
      ...metric,
      rank,
      name,
      photoUrl,
      isCompetition,
      metricValue: roundJustDecimals(metric.metricValue as number)
    }
  }
}

export const getMetricValueAndContext = ({ value, valueContext }, aggregationMode) => {
  const { metricFinder, overrideAggregationMode, ...metricContext } = valueContext
  const context = metricFinder || overrideAggregationMode ? metricContext.holder : metricContext
  const mode = overrideAggregationMode || aggregationMode

  return {
    metricFinder,
    context,
    value,
    mode
  }
}

export const hasNoRankingAvailableAtAll = (payload: any): boolean =>
  !exists(payload?.homeValue) && !exists(payload?.awayValue)

export const hasPercentageRankingOnAccumulatedMode = (payload: RankingSourceMetric, mode: MetricAggregation): boolean =>
  payload?.homeUnit === 'MTR_UNIT_PORCENTAJE' && mode === MetricAggregation.AccumulatedValues

export const hasRankingAvailableWhenIsSingleEntity = (
  isVsEnabled: boolean,
  context: MetricFinderKey,
  mode: MetricAggregation,
  payload: RankingSourceMetric
): boolean =>
  !isVsEnabled &&
  context !== MetricFinderKey.Match &&
  ((exists(payload?.homeValue) && mode === MetricAggregation.AverageValues) ||
    (exists(payload?.awayValue) && mode === MetricAggregation.AccumulatedValues))

export const hasRankingAvailableWhenIsMultiEntity = (
  isVsEnabled: boolean,
  context: MetricFinderKey,
  mode: MetricAggregation,
  payload: any
): boolean => (isVsEnabled || context === MetricFinderKey.Match) && exists(payload?.homeValue)
