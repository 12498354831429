import { MatchState } from '@mediacoach-ui-library/global'

import { IMatch } from './match-item.model'
import { getLocalDate } from '@core/utils/main.utils'

const LIVE_STATES = [
  MatchState.LineupReady,
  MatchState.FirstHalf,
  MatchState.SecondHalf,
  MatchState.HalfTime,
  MatchState.ExtraFirstTime,
  MatchState.ExtraSecondTime
]

const parseTeamNameAndImages = (team) => ({
  ...team,
  name: team.shortName || team.name || '',
  parsedImages:
    (team.statics && {
      Portrait: { Small2x: (team.statics.find((staticAsset) => staticAsset.orientation === 'Portrait') || {}).url }
    }) ||
    {}
})

const getTeamScore = (team) => (team.score !== null ? team.score : '-')

// FIXME UI Library mapper - must fix match-item in library
export const parseMatch = (match: IMatch, lang: string) => ({
  ...match,
  home: {
    team: { ...match.homeTeam, ...parseTeamNameAndImages(match.homeTeam || {}) },
    score: { standardTimeScore: getTeamScore(match.homeTeam || {}) }
  },
  away: {
    team: { ...match.awayTeam, ...parseTeamNameAndImages(match.awayTeam || {}) },
    score: { standardTimeScore: getTeamScore(match.awayTeam || {}) }
  },
  schedule: match.schedule && {
    ...match.schedule,
    translated: getLocalDate(match.schedule.scheduledDate, lang)
  },
  isLive: match.isLive,
  state: {
    ...match.state,
    name: match.schedule && (match.schedule.toBeConfirmed ? null : match.state.name),
    minute: match.state.name !== MatchState.HalfTime ? match.state?.minute : null
  }
})
