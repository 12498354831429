<mcm-loader [loading]="(loading || !categories)" class="mcm-menu-loader">
  <div class="mcm-menu__header-wrapper">
    <div class="mcm-menu__header" #categoryWrapper>
      <div
        *ngFor="let category of categories; let i = index"
        class="mcm-menu__category"
        dataId="ev"
        [mcmDataAttr]="{action: 'click_menu_item', label: 'menuItemId_' + category.id}"
        [class.is-selected]="selectedIndex === i"
        (click)="changeCategory(i)"
        #categoryItems>
        <ion-label>{{ category.label | translate: category.labelParams }}</ion-label>
      </div>
    </div>
  </div>

  <swiper-container
    class="mcm-menu__content"
    auto-height="true"
    observe-parents="true"
    observer="true"
    (swipertap)="onSwiperTap()"
    (swiperslidechangetransitionstart)="onChangedCategory()"
    #swiper>
    <swiper-slide *ngFor="let item of categories; let i = index; trackBy: TrackByData">
      <ng-container *ngIf="(i > selectedIndex - 2 &&  i < selectedIndex + 2) || item.id === MATCH_MENU_ID">
        <ng-container
          *ngTemplateOutlet="slideTemplate[item.id] || slideTemplate.default; context: { $implicit: item}"></ng-container>
      </ng-container>
    </swiper-slide>
  </swiper-container>
</mcm-loader>
<ng-template [ngIf]="categoriesDebouncer$ | async"></ng-template>
<ng-template [ngIf]="changeCategoryDebounce$ | async"></ng-template>
<ng-template [ngIf]="slideOnPageVisible$ | async"></ng-template>
<ng-template [ngIf]="swiperUpdatedDebounce$ | async"></ng-template>
<ng-container *ngIf="updateSwiper$ | async"></ng-container>
