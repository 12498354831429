import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { TranslateService } from '@ngx-translate/core'

import { Observable, Subject } from 'rxjs'
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators'

import { Locale } from '@mediacoach-ui-library/global'

import { UserProfile } from '../auth/auth.models'
import { environment } from '@env'
import { Store } from '@ngrx/store'
import { selectProfile } from '@core/store/selectors/user.selectors'

const LANG_STANDARD_MAP = {
  [Locale.Es]: 'es-ES',
  [Locale.En]: 'en-GB'
}

@Injectable({
  providedIn: 'root'
})
export class BackEndTranslatorService {
  translations$ = {} as { [key: string]: Observable<any> }
  translationLoaded$ = new Subject<void>()

  constructor(
    private http: HttpClient,
    private readonly _store: Store,
    private _translateService: TranslateService
  ) {
    _translateService.onLangChange
      .pipe(
        switchMap(({ lang }) =>
          this._store.select(selectProfile).pipe(
            filter((profile) => !!profile),
            switchMap(() => this._getTranslations$(LANG_STANDARD_MAP[lang])),
            map(({ translations }) => ({ translations, lang }))
          )
        ),
        tap(({ translations, lang }) => _translateService.setTranslation(lang, translations, true)),
        tap(() => this.translationLoaded$.next())
      )
      .subscribe()
  }

  private _getTranslations$(language: string) {
    return (
      this.translations$[language] ||
      (this.translations$[language] = this.http
        .get<UserProfile>(environment.API.TRANSLATIONS(language))
        .pipe(shareReplay(1)))
    )
  }
}
