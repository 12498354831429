import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { ComparedPlayersData, PlayerSummary } from '@pages/player-detail/models/player-detail.models'
import { ApiMetric } from '@core/models/metric.models'
import { IMatch } from '@mediacoach-ui-library/global'
import { SelectionType } from '@shared/components/player-selection/player-selection.model'

/* eslint-disable @typescript-eslint/naming-convention */

export const playerDetailActions = createActionGroup({
  source: 'Player Detail',
  events: {
    'fetch player summary': props<{
      playerId: string
      position: string
      teamId: string
      competitionId: string
      seasonId: string
    }>(),
    'fetch player detail data': emptyProps(),
    'set player summary': props<{ summary: PlayerSummary }>(),
    'fetch player metrics': props<{ summary: PlayerSummary }>(),
    'set player metrics': props<{ metrics: ApiMetric }>(),
    'fetch match player': props<{ matchId: string; teamId: string; playerId: string; position: string }>(),
    'set match': props<{ match: IMatch }>(),
    'compare players': props<{ players: ComparedPlayersData }>(),
    'compare match players': props<{ matchId: string; comparison: ComparedPlayersData }>(),
    'set playerB': props<{ playerB: PlayerSummary }>(),
    'set player menu': props<{ menu: any }>(),
    'set player selected menu': props<{ selectedMenu: string }>(),
    'set player selected compared menu': props<{ selectedComparedMenu: any }>(),
    'open modal': props<{ showAllOptions: boolean }>(),
    'close modal': emptyProps(),
    'close modal and purge': emptyProps(),
    'handle player comparison': props<{ data: any; modalContext: SelectionType }>(),
    'handle player selection': props<{ data: any; modalContext: SelectionType }>(),
    'handle position selection': props<{ data: any; modalContext: SelectionType }>(),
    'set modal context': props<{ modalContext: SelectionType }>(),
    'set to compare player': props<{ toComparePlayer: any }>(),
    navigate: props<{ payload: any }>(),
    'purge player detail': emptyProps(),
    'purge modal': emptyProps()
  }
})
/* eslint-enable @typescript-eslint/naming-convention */
