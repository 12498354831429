<mcm-loader [loading]="loading || !data">
  <div *ngIf="isSwitchable" class="mcm-table__toggle">
    <mc-input-switch
      [isDoubleMode]="true"
      [label]="switcherValues"
      [disabled]="switcherDisabled"
      [ngModel]="switcherChecked"
      (onChange)="onSwitchChange($event)"></mc-input-switch>
  </div>

  <ng-container *ngIf="(language$ | async) as language">
    <div class="mcm-table__container" [ngSwitch]="tableMode">
      <div class="mcm-table--fixed">
        <div class="mcm-table__row mcm-table__header">
          <div *ngSwitchCase="TableMode.Standings" class="mcm-table__cell mcm-table__cell--long">
            {{ 'MTR_TITLE_POSITION' | translate }}
          </div>
          <div *ngSwitchCase="TableMode.Players" class="mcm-table__cell mcm-table__cell--long mcm-table__cell--player">
            {{ 'MTR_TITLE_PLAYER' | translate }}
          </div>
        </div>
        <ng-container *ngSwitchCase="TableMode.Standings">
          <div class="mcm-table__row" *ngFor="let item of data; let i = index">
            <div
              class="mcm-table__cell mcm-table__cell--long"
              [ngClass]="item.extraClass ? ('mcm-table__cell--' + item.extraClass) : ''"
              (click)="item.onClick && item.onClick(item)">
              <span class="mcm-row__ordinal">{{i+1}}</span>
              <span>
                <img
                  loading="lazy"
                  class="mcm-row__shield"
                  src="{{item.team.portraitLogo}}"
                  loading="lazy"
                  [alt]="item.team.shortName" />
              </span>
              <span class="mcm-row__name-team">{{item.team.shortName}}</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="TableMode.Players">
          <div class="mcm-table__row" *ngFor="let item of data">
            <div
              class="mcm-table__cell mcm-table__cell--long mcm-table__cell--player"
              dataId="ev"
              [mcmDataAttr]="{ action: 'click_player', label: 'playerIdGuid_' + (item.id || '') }"
              (click)="item.onClick && item.onClick(item)">
              <img
                loading="lazy"
                class="mcm-list__player-avatar"
                [appDefaultImage]="PLAYER_PLACEHOLDER_IMAGE"
                [srcUrl]="item.imgUrl"
                [alt]="item.name" />
              <div class="mcm-row__textbox">
                <span class="mcm-row__name-player">{{item.name}}</span>
                <span class="mcm-row__position">{{item.shirtNumber}}. {{item.position | translate}}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="mcm-table--scrollable">
        <div class="mcm-table__row mcm-table__header">
          <div
            *ngFor="let field of fields"
            class="mcm-table__cell"
            [class.mcm-table__cell--wider]="widerCells.includes(field)">
            {{field | translate}}
          </div>
        </div>
        <div class="mcm-table__row" *ngFor="let item of data; trackBy: TrackByData">
          <ng-container *ngFor="let prop of props">
            <div
              class="mcm-table__cell"
              *ngIf="((item[tableDataMode] || {})[prop] || {}) as cell"
              [ngClass]="cell.extraClass ? ('mcm-table__cell--' + cell.extraClass) : ''"
              [class.mcm-table__cell--wider]="widerCells.includes(prop)"
              (click)="item.metricAction && item.metricAction(item, prop, cell.value, cell.unit)">
              {{ cell.value === 0 ? '' : ((cell.value | number:'': language) || '-') }}{{cell.value === 0 || null ? '' :
              ((cell.unit | translate) || '')}}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</mcm-loader>
<ng-template [ngIf]="dataDebouncer$ | async"></ng-template>
