import { Injectable } from '@angular/core'
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'

import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { recordNetworkException } from '../errors/utils/error.utils'
import { Store } from '@ngrx/store'
import { unauthorizedError } from '@core/store/actions/error.actions'
import { HttpStatusCode } from '@mediacoach/ui'
import { RouteUrl } from '@core/models/models'
import { NavController } from '@ionic/angular'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly _store: Store, private readonly _navCtrl: NavController) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (error) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case HttpStatusCode.Unauthorized:
                this._store.dispatch(unauthorizedError())
                break
              default:
                void recordNetworkException(error)
                if (error?.error?.type === 'timeout') {
                  void this._navCtrl.navigateForward([RouteUrl.NetworkTimeout])
                }
                break
            }
          }
        }
      })
    )
  }
}
