import { Component, Input } from '@angular/core'
import { APP_VERSION } from '@core/constants/constants'

@Component({
  selector: 'mcm-sign-in-out',
  templateUrl: './sign-in-out.component.html',
  styleUrls: ['./sign-in-out.component.scss']
})
export class SignInOutComponent {
  @Input() text = 'MTR_COMMON_LOGGING_IN'
  version = APP_VERSION
}
