export const objectKeys = (item: any): string[] => (item ? Object.keys(item) : [])

export const safeObject = (obj: object) => ({
  access: (keyAccess) =>
    keyAccess.split('.').reduce((nestedObject: object, key: string) => {
      if (nestedObject && key in nestedObject) {
        return nestedObject[key]
      }
      return undefined
    }, obj as any)
})

export const safeObjectData = (data: any, key: string | string[], defaultReturn = {} as any) =>
  safeObject(data).access(Array.isArray(key) ? key.join('.') : key) || defaultReturn

export const toDictionary = (arr, key = 'id', isArrayMode = false) =>
  arr.reduce((obj, item) => ({ ...obj, [item[key]]: !isArrayMode ? item : [...(obj[item[key]] || []), item] }), {})

export const deepEqual = (x, y) => {
  if (x === y) {
    return true
  } else if ((x instanceof RegExp && y instanceof RegExp) || (x instanceof Function && y instanceof Function)) {
    return x.toString() === y.toString()
  } else if (typeof x === 'object' && x != null && typeof y === 'object' && y != null) {
    const [xKeys, yKeys] = [objectKeys(x), objectKeys(y)]

    if (xKeys.length !== yKeys.length) {
      return false
    }

    return Array.from(new Set([...xKeys, ...yKeys])).every((key) => deepEqual(x[key], y[key]))
  } else {
    return false
  }
}

type ValueOf<T> = T[keyof T]

export const getEnumKeyByEnumValue = <R extends string | number, T extends { [key: string]: R }>(
  myEnum: T,
  enumValue: ValueOf<T>
): string => {
  const keys = objectKeys(myEnum).filter((x) => myEnum[x] === enumValue)
  return keys.length > 0 ? keys[0] : ''
}

export const isObjectUnique = (obj, arr, compareFn) =>
  !arr.some(function (item) {
    return compareFn(item, obj)
  })

export const exists = (item: unknown): boolean =>
  item !== undefined && item !== null && !!Object.keys(item as object).length
