import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'invalidValueHandler'
})
export class InvalidValueHandlerPipe implements PipeTransform {
  transform(value: unknown, empty?: boolean): unknown | string {
    const placeholder = empty ? '' : '-'
    return typeof value === 'object' || value === 'undefined' || value === '' ? placeholder : value
  }
}
