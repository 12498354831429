<div *ngIf="options?.length" class="mcm-segmented-control" [ngClass]="modifiers">
  <ion-segment (ionChange)="onSegmentValueChanged($event)" mode="ios" [value]="value" [disabled]="disabled">
    <ion-segment-button
      *ngFor="let option of options"
      [value]="option.id"
      [disabled]="option.disabled"
      [ngClass]="option.extraClasses"
      dataId="qa"
      [mcmDataAttr]="{ id: option.qaId }">
      <span class="mcm-segmented-control__content">
        <ion-img *ngIf="option.image" [src]="option.image"></ion-img>
        <ion-icon *ngIf="option.icon" [icon]="option.icon"></ion-icon>
        <ion-label *ngIf="option.label">{{option.label | translate}}</ion-label>
      </span>
    </ion-segment-button>
  </ion-segment>
</div>
