export const STATS: { [key: string]: { key: string; valueKey: string; unit?: string }[] } = {
  NO_MATCH: [
    {
      key: 'MTR_PLAYER_AGE',
      valueKey: 'age'
    },
    {
      key: 'MTR_PLAYER_NATIONALITY',
      valueKey: 'nationalityKey'
    },
    {
      key: 'MTR_PLAYER_HEIGHT',
      valueKey: 'height',
      unit: 'cm'
    },
    {
      key: 'MTR_PLAYER_FAVOURITELEG',
      valueKey: 'preferredFootKey'
    }
  ],
  MATCH: [
    {
      key: 'MTR_TITLE_PARTICIPATION',
      valueKey: 'participationKey'
    }
  ]
}

export const STATS_WITH_UNITS_DICTIONARY = {
  MTR_METRIC_PLAYERMINUTESPLAYED_HEADER: 'MTR_COMMON_MATCH_MINUTE_SHORT',
  MTR_METRIC_PLAYERPHYSICALDISTANCEKM_HEADER: 'MTR_COMMON_KM_SHORT'
}
