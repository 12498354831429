import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'mcm-heatmap-block',
  templateUrl: './heat-map-block.component.html',
  styleUrls: ['./heat-map-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeatMapBlockComponent {
  @Input() data: any
  @Input() loading: boolean
}
