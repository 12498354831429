import { ErrorHandler, Injectable } from '@angular/core'
import { recordClientException } from './utils/error.utils'

@Injectable()
export class MCMErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    console.error('[ERROR]', error)
    recordClientException(typeof error === 'string' ? new Error(error) : error)
  }
}
