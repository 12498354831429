import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'

@Directive({
  selector: '[mcmVar]'
})
export class VarDirective {
  context: any = {}

  constructor(
    private readonly vcRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<unknown>
  ) {}

  @Input()
  set mcmVar(context: unknown) {
    this.context.$implicit = this.context.mcmVar = context
    this.updateView()
  }

  updateView(): void {
    this.vcRef.clear()
    this.vcRef.createEmbeddedView(this.templateRef, this.context)
  }
}
