<mcui-calendar
  class="mcm-calendar-inline"
  containerStyleClass="mcm-calendar-inline__content"
  [(ngModel)]="value"
  [disableRipple]="true"
  [showHeader]="true"
  [showOtherMonths]="false"
  [minDate]="minDate"
  [maxDate]="maxDate"
  [i18n]="'MTR_CALENDAR_ITEMS' | translate"
  [inline]="true"
  (calendarSelect)="onSelected($event)"
  (calendarMonthChange)="onMonthChanged($event)">
  <ng-template mcuiTemplate="date" let-date="date">
    <span
      class="mcm-calendar__cell"
      [class.mcm-calendar__cell--active]="dayHasMatches(date.day)"
      [class.mcm-calendar__cell--today]="date.today">
      <span class="mcm-calendar__cell__number">{{date.day}}</span>
      <div class="mcm-calendar__cell__shields-container" *ngIf="favTeamHasMatch(date.day)">
        <ion-img [src]="favTeamHasMatch(date.day)?.home" class="mcm-calendar__cell__shield"></ion-img>
        <ion-img [src]="favTeamHasMatch(date.day)?.away" class="mcm-calendar__cell__shield"></ion-img>
      </div>
    </span>
  </ng-template>
</mcui-calendar>
