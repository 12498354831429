import { Directive, ElementRef, Input, Renderer2 } from '@angular/core'
import { Column, TemplateData } from '../table.models'
import { DEFAULT_HEADER_HEIGHT, DEFAULT_ROW_HEIGHT } from '../table.constants'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[gridTpl]'
})
export class GridTemplateDirective<T = unknown> {
  private _columns: Column[]
  private _rows: T[]
  private _headerHeight: string
  private _rowHeight: string
  private _firstRun = true

  constructor(
    private readonly _host: ElementRef,
    private readonly _renderer: Renderer2
  ) {}

  @Input('gridTpl')
  set data({ columns, rows, headerHeight, rowHeight }: TemplateData) {
    this._columns = columns
    this._rows = rows as T[]
    this._rowHeight = rowHeight || DEFAULT_ROW_HEIGHT
    this._headerHeight = headerHeight || DEFAULT_HEADER_HEIGHT

    if (columns && rows && columns.length > 0 && rows.length > 0 && this._firstRun) {
      this.handleTemplate()
    }
  }

  handleTemplate(): void {
    this._renderer.setStyle(
      this._host.nativeElement,
      'grid-template-columns',
      `${this._columns.reduce((acc, c) => {
        acc += ` ${c.width || '1fr'}`
        return acc
      }, '')}`
    )
    this._renderer.setStyle(
      this._host.nativeElement,
      'grid-template-rows',
      `${this._headerHeight} repeat(${this._rows.length}, ${
        this._rowHeight !== DEFAULT_ROW_HEIGHT ? this._rowHeight : `minmax(${this._rowHeight}, 1fr))`
      }`
    )
    this._firstRun = false
  }
}
