import { Injectable } from '@angular/core'
import { environment } from '@env'
import { Smartlook } from '@awesome-cordova-plugins/smartlook/ngx'
import { UserProfile } from '@core/services/auth/auth.models'

@Injectable({ providedIn: 'root' })
export class SmartlookService {
  constructor(private readonly _smartlook: Smartlook) {
    this._smartlook.setProjectKey({ key: environment.SMARTLOOK_API })
  }

  init(user: UserProfile, granted: boolean) {
    this._smartlook.setUserIdentifier({ identifier: user.userId })
    if (granted) {
      this._smartlook.setUserEmail({ email: user.email })
    }
    this._smartlook.start()
  }
}
