export enum TeamMatchType {
  LastMatch = 'lastMatch',
  CurrentMatch = 'currentMatch',
  NextMatch = 'nextMatch'
}

export enum Competition {
  CompetitionA = '39df9ec8-be91-4be5-1925-4b670a4cbed9',
  CompetitionB = '39df9ec8-becb-86ea-b5e8-600c1b47968d'
}

export enum MetricMode {
  Match = 'matchMetricResult',
  Accumulated = 'accumulatedMetricResult',
  Acumulated = 'acumulatedMetricResult', // KEEP: BE send this typo. Don't delete it
  Average = 'averageMetricResult'
}
