<app-header
  class="mcm-metric-detail__header"
  extraClasses="mcm-header--modal"
  [title]="key$ | async"
  [showBackButton]="false">
  <ng-template mcmTemplate="end-buttons">
    <app-button icon="close-outline" extraClasses="mcm-button--xxl" (onClick)="onClosed()"></app-button>
  </ng-template>
</app-header>
<ion-content>
  <mcm-loader [loading]="loading">
    <ng-container *ngIf="info$ | async as info">
      <div class="mcm-metric-detail__data">
        <ng-container
          *ngIf="[metricDetailInfoType.Player,
        metricDetailInfoType.PlayerCompetition,
        metricDetailInfoType.TeamCompetition].includes(info.type); else defaultCase">
          <ng-container
            *ngTemplateOutlet="singleInfo; context: { data: info.extraData, items: info?.data || [] }"></ng-container>
        </ng-container>
        <ng-template #defaultCase>
          <app-list [data]="info?.data"></app-list>
        </ng-template>
      </div>
    </ng-container>
  </mcm-loader>
</ion-content>

<ng-template #singleInfo let-data="data" let-items="items">
  <div class="mcm-metric-detail__single-info-container">
    <div class="mcm-metric-detail__single-info-header">
      <img
        loading="lazy"
        class="mcm-metric-detail__single-info-header-logo"
        [class.mcm-metric-detail__single-info-header-logo--is-player]="data?.isPlayer"
        slot="start"
        [appDefaultImage]="data?.isTeam ? SHIELD_PLACEHOLDER_IMAGE : PLAYER_PLACEHOLDER_IMAGE"
        [srcUrl]="data?.portraitLogo"
        [alt]="data?.formattedName" />
      <img
        loading="lazy"
        *ngIf="data?.team"
        class="mcm-metric-detail__single-info-header-team"
        [appDefaultImage]="SHIELD_PLACEHOLDER_IMAGE"
        [srcUrl]="data?.team?.portraitLogo"
        [alt]="data?.team?.name" />
      <div class="mcm-metric-detail__single-info-header-info">
        <div *ngIf="data?.shirtNumber" class="mcm-metric-detail__single-info-header-info__number">
          <span> {{data?.shirtNumber}} </span>
        </div>
        <div class="mcm-metric-detail__single-info-header-info__name-position">
          <span
            class="mcm-metric-detail__single-info-header-info__name"
            [class.mcm-metric-detail__single-info-header-info__name--is-team]="data?.isTeam">
            {{data?.formattedName}}
          </span>
          <span *ngIf="data?.playerPosition" class="mcm-metric-detail__single-info-header-info__position">
            {{(data | demarcation: playerDemarcationKeys : playerDemarcationFallbackKey : ' ')}}
          </span>
        </div>
      </div>
    </div>
    <div class="mcm-metric-detail__single-info-content">
      <div class="mcm-metric-detail__single-info-item" *ngFor="let item of items">
        <span class="mcm-metric-detail__single-info-item--text"> {{item.text | translate}} </span>
        <span class="mcm-metric-detail__single-info-item--value">
          {{ item?.value !== null ? ((item?.value | number:'': currentLang) + ((item?.unit | translate) || '')) : '-' }}
        </span>
      </div>
    </div>
  </div>
</ng-template>
