<div
  *ngIf="competition"
  class="mcm-competition-selector"
  dataId="qa"
  [mcmDataAttr]="{ id: qaId }"
  (click)="showModal = !showModal">
  <img
    loading="lazy"
    class="mcm-competition-selector__logo"
    slot="start"
    appDefaultImage
    [srcUrl]="competition?.landscapeLogoWhite"
    [alt]="competition.officialName" />

  <app-button icon="loop"></app-button>
</div>

<app-modal
  *ngIf="showModal"
  extraClasses="no-padding"
  title="MTR_SELECT_COMPETITION_TITLE"
  (onClose)="showModal = false">
  <app-team-selection
    [competitionId]="competition.id"
    [onlyCompetition]="true"
    (onTeamSelect)="onTeamSelected($event)"></app-team-selection>
</app-modal>
