import { Pipe, PipeTransform } from '@angular/core'

import { isObservable, Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { exists } from '@core/utils/number.utils'

export interface Loadable<T> {
  value: T
  loading: boolean
}

@Pipe({
  name: 'loading'
})
export class LoadingPipe implements PipeTransform {
  transform(val): Observable<Loadable<any>> {
    return !isObservable(val)
      ? val
      : val.pipe(
          map((value: any) => ({ loading: !exists(value), value })),
          catchError((error) => of({ loading: false, error }))
        )
  }
}
