<div class="mcm-collapse">
  <ng-container *ngIf="!isCollapsed">
    <ng-content></ng-content>
  </ng-container>
  <div class="mcm-collapse__show-more" [class.is-collapse]="isCollapsed">
    <app-button
      [text]="isCollapsed? 'MTR_COMMON_LIST_SHOW_ALL' : 'MTR_COMMON_LIST_SHOW_LESS'"
      icon="arrow-down-rounded"
      extraClasses="mcm-button--flat"
      (onClick)="isCollapsed = !isCollapsed">
    </app-button>
  </div>
</div>
