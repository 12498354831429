import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { ContextMenuAction } from '@core/models/context-menu.models'
import { clickContextMenuItem } from '@core/store/actions/context-menu.actions'
import { rankingActions } from '@features/rankings/store/actions/ranking.actions'
import { metricDetailActions } from '@features/metric-detail/store/actions/metric-detail.actions'

@Injectable()
export class ContextMenuEffects {

  clickContextMenuItem$ = createEffect(() => this._actions$.pipe(
    ofType(clickContextMenuItem),
    map((
      {menuItem: {action}, value, valueContext}
    ) => this._getActionToDispatchByContextMenuAction(action)({payload: {value, valueContext}}))
  ))

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store) {
  }

  private _getActionToDispatchByContextMenuAction = (action) => ({
    [ContextMenuAction.MetricDetail]: metricDetailActions.openModal,
    [ContextMenuAction.Rankings]: rankingActions.fetchRanking
  }[action])
}
