import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { FirebaseAnalyticsEvent } from '@core/services/analytics/firebase/enums/firebase-analytics.enums'
import { MetricAggregation } from '@core/enums/metric.enums'
import { MetricAggregationHeader } from '@core/models/metric.models'

@Component({
  selector: 'mcm-edit-metrics-block',
  templateUrl: './edit-metrics-block.component.html',
  styleUrls: ['./edit-metrics-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMetricsBlockComponent {
  @Input() metrics: any
  @Input() context: any
  @Input() title: string
  @Input() aggregationMode: MetricAggregation
  @Input() aggregationHeader: MetricAggregationHeader
  @Input() showAggregationIcon: boolean
  @Input() againstCompetition: boolean
  @Input() route: string
  @Input() inMatchContext: boolean
  @Input() overrideAggregationMode: boolean
  @Input() isVsEnabled = false

  @Output() aggregationModeChange = new EventEmitter()

  readonly editMetricEvent = FirebaseAnalyticsEvent.editMetrics
}
