import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient, HttpResponse } from '@angular/common/http'
import { environment } from '@env'
import { UserProfile } from '@core/services/auth/auth.models'

@Injectable()
export class ProfileApi {
  constructor(private readonly _http: HttpClient) {}

  fetchUserProfile(): Observable<HttpResponse<UserProfile>> {
    return this._http.get<UserProfile>(environment.API.USER_PROFILE, { observe: 'response' })
  }

  saveUserAvatar(avatar: { encodedImage: string }): Observable<any> {
    return this._http.put(environment.API.UPDATE_AVATAR, JSON.stringify(avatar))
  }

  saveUserProfile(profile: UserProfile): Observable<void> {
    return this._http.put<void>(environment.API.USER_PROFILE, profile)
  }
}
