export enum MetricType {
  TeamOverview = 'TeamOverview',
  MatchTeamPrevious = 'MatchTeamPrevious',
  MatchTeamOverview = 'MatchTeamOverview',
  Goalkeeper = 'Goalkeeper',
  Defender = 'Defender',
  Midfielder = 'Midfielder',
  Forward = 'Forward'
}

export enum MetricAggregation {
  AccumulatedValues = 'accumulatedValues',
  AverageValues = 'averageValues',
  MatchValues = 'matchValues'
}
