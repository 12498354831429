<div class="mcm-kit-selector">
  <div
    class="mcm-kit-selector__item"
    [class.mcm-kit-selector__item--back]="i !== selectedIndex"
    [class.mcm-kit-selector__item--front]="i === selectedIndex"
    [class.mcm-kit-selector__item--only-one]="data?.length === 1"
    [class.mcm-kit-selector__item--reverse]="isReverse"
    *ngFor="let kit of data; let i = index">
    <div class="mcm-kit-selector__asset-container">
      <div
        class="mcm-kit-selector__asset"
        [ngClass]="'mcm-kit-selector__asset--'+ asset.model"
        *ngFor="let asset of kit.assets">
        <img loading="lazy" [appDefaultImage]="''" [srcUrl]="asset.image" [alt]="asset.alt" />
      </div>
    </div>
  </div>
  <div
    *ngIf="data?.length > 1"
    class="mcm-kit-selector__switcher"
    [class.mcm-kit-selector__switcher--reverse]="isReverse"
    (click)="onKitSelectorSwitch(selectedIndex === 0 ? 1 : 0)">
    <ion-icon icon="loop"></ion-icon>
  </div>
  <app-title
    [class.mcm-kit-selector__item--only-one]="data?.length === 1"
    *ngIf="data[selectedIndex]"
    [text]="data[selectedIndex].key | translate"></app-title>
</div>
