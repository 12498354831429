import { ConnectionCoordinateType } from '@mediacoach-ui-library/global'
import { SegmentOption } from '../segmented-control/segmented-control.models'

export const FIELD_DIAGRAM_DEFAULT_OPTIONS: SegmentOption[] = [
  {
    id: ConnectionCoordinateType.Target,
    label: 'MTR_FIELD_DIAGRAM_TARGET'
  },
  {
    id: ConnectionCoordinateType.Origin,
    label: 'MTR_FIELD_DIAGRAM_ORIGIN'
  }
]
