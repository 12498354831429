import { Component } from '@angular/core'

import { TranslatorService } from '@mediacoach-ui-library/global'

import { IonicPage } from '@core/components/ionic-page/ionic-page.component'
import { IPlayer, IPlayerDual, ITeam, ITeamDual, MetricCategory } from './metric.models'
import { IonicPageVisibilityService } from '@core/services/ionic-page-visibility/ionic-page-visibility.service'
import { PLAYER_PLACEHOLDER_IMAGE, SHIELD_PLACEHOLDER_IMAGE } from '@core/constants/asset-path'
import { McmModalControllerService } from '@core/services/mcm-modal-controller/mcm-modal-controller.service'
import { PLAYER_DEMARCATION_FALLBACK_KEY, PLAYER_DEMARCATION_KEYS } from '@core/constants/player.constants'

@Component({
  selector: 'app-metric',
  templateUrl: './metric.page.html',
  styleUrls: ['./metric.page.scss']
})
export class MetricPage extends IonicPage {
  data: IPlayer | ITeam | IPlayerDual | ITeamDual
  type: MetricCategory = MetricCategory.Player
  isDual: boolean
  language$ = this._translatorService.onLangChange

  MetricCategory = MetricCategory
  PLAYER_PLACEHOLDER_IMAGE = PLAYER_PLACEHOLDER_IMAGE
  SHIELD_PLACEHOLDER_IMAGE = SHIELD_PLACEHOLDER_IMAGE

  readonly playerDemarcationKeys = PLAYER_DEMARCATION_KEYS
  readonly playerDemarcationFallbackKey = PLAYER_DEMARCATION_FALLBACK_KEY

  constructor(
    private _modalController: McmModalControllerService,
    private _translatorService: TranslatorService,
    _ionicPageVisibilityService: IonicPageVisibilityService
  ) {
    super(_ionicPageVisibilityService)
  }

  onAccept() {
    this._modalController.dismiss()
  }
}
