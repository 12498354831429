<div class="mcm-dropdown">
  <div class="mcm-dropdown__selector" [class.mcm-dropdown__selector__active]="active" (click)="toggleActive()">
    <span [class.mcm-dropdown__selector__placeholder]="!selectedItem">
      {{selectedItem?.label || 'MTR_COMMON_MATCHDAY_SELECT' | translate: selectedItem?.labelParams }}
    </span>
    <i *ngIf="!active" class="mcm-dropdown__selector__icon u-icon--sm u-icon--arrow-down"></i>
    <i *ngIf="active" class="mcm-dropdown__selector__icon u-icon--sm u-icon--arrow-up"></i>
  </div>

  <div class="mcm-dropdown__container" [ngClass]="active ? 'mcm-dropdown__container':'mcm-dropdown__container__hidden'">
    <div
      *ngFor="let item of items"
      class="mcm-dropdown__option"
      [class.mcm-dropdown__option__selected]="selectedItem === item"
      (click)="selectOption(item)">
      {{item.label | translate: item.labelParams }}
    </div>
  </div>
</div>
