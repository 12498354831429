import { Directive, Input, TemplateRef } from '@angular/core'

@Directive({
  selector: '[mcmTemplate]'
})
export class TemplateDirective {
  @Input('mcmTemplate') type: string

  constructor(public template: TemplateRef<any>) {}

  getType(): string {
    return this.type
  }
}
