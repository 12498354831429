/* eslint-disable max-len, @typescript-eslint/naming-convention */
import { LanguageTranslations } from '@shared/components/video-container/models/video.models'

export const VIDEOJS_LOCALES: { [code: string]: LanguageTranslations } = {
  es: {
    Play: 'Reproducir',
    'Play Video': 'Reproducir Vídeo',
    Pause: 'Pausa',
    'Current Time': 'Tiempo reproducido',
    Duration: 'Duración total',
    'Remaining Time': 'Tiempo restante',
    'Stream Type': 'Tipo de secuencia',
    LIVE: 'DIRECTO',
    Loaded: 'Cargado',
    Progress: 'Progreso',
    Fullscreen: 'Pantalla completa',
    'Non-Fullscreen': 'Pantalla no completa',
    Mute: 'Silenciar',
    Unmute: 'No silenciado',
    'Playback Rate': 'Velocidad de reproducción',
    Subtitles: 'Subtítulos',
    'subtitles off': 'subtítulos desactivados',
    Captions: 'Subtítulos especiales',
    'captions off': 'subtítulos especiales desactivados',
    Chapters: 'Capítulos',
    'You aborted the media playback': 'Ha interrumpido la reproducción del vídeo.',
    'A network error caused the media download to fail part-way.':
      'Un error de red ha interrumpido la descarga del vídeo.',
    'The media could not be loaded, either because the server or network failed or because the format is not supported.':
      'No se ha podido cargar el vídeo debido a un fallo de red o del servidor.',
    'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.':
      // eslint-disable-next-line max-len
      'La reproducción de vídeo se ha interrumpido por un problema de corrupción de datos o porque el vídeo precisa funciones que su navegador no ofrece.',
    'No compatible source was found for this media.': 'No se ha encontrado ninguna fuente compatible con este vídeo.',
    'Audio Player': 'Reproductor de audio',
    'Video Player': 'Reproductor de video',
    Replay: 'Volver a reproducir',
    'Seek to live, currently behind live': 'Buscar en vivo, actualmente demorado con respecto a la transmisión en vivo',
    'Seek to live, currently playing live': 'Buscar en vivo, actualmente reproduciendo en vivo',
    'Progress Bar': 'Barra de progreso',
    'progress bar timing: currentTime={1} duration={2}': '{1} de {2}',
    Descriptions: 'Descripciones',
    'descriptions off': 'descripciones desactivadas',
    'Audio Track': 'Pista de audio',
    'Volume Level': 'Nivel de volumen',
    'The media is encrypted and we do not have the keys to decrypt it.':
      'El material audiovisual está cifrado y no tenemos las claves para descifrarlo.',
    Close: 'Cerrar',
    'Modal Window': 'Ventana modal',
    'This is a modal window': 'Esta es una ventana modal',
    'This modal can be closed by pressing the Escape key or activating the close button.':
      'Esta ventana modal puede cerrarse presionando la tecla Escape o activando el botón de cierre.',
    ', opens captions settings dialog': ', abre el diálogo de configuración de leyendas',
    ', opens subtitles settings dialog': ', abre el diálogo de configuración de subtítulos',
    ', selected': ', seleccionado',
    'Close Modal Dialog': 'Cierra cuadro de diálogo modal',
    ', opens descriptions settings dialog': ', abre el diálogo de configuración de las descripciones',
    'captions settings': 'configuración de leyendas',
    'subtitles settings': 'configuración de subtítulos',
    'descriptions settings': 'configuración de descripciones',
    Text: 'Texto',
    White: 'Blanco',
    Black: 'Negro',
    Red: 'Rojo',
    Green: 'Verde',
    Blue: 'Azul',
    Yellow: 'Amarillo',
    Magenta: 'Magenta',
    Cyan: 'Cian',
    Background: 'Fondo',
    Window: 'Ventana',
    Transparent: 'Transparente',
    'Semi-Transparent': 'Semitransparente',
    Opaque: 'Opaca',
    'Font Size': 'Tamaño de fuente',
    'Text Edge Style': 'Estilo de borde del texto',
    None: 'Ninguno',
    Raised: 'En relieve',
    Depressed: 'Hundido',
    Uniform: 'Uniforme',
    Dropshadow: 'Sombra paralela',
    'Font Family': 'Familia de fuente',
    'Proportional Sans-Serif': 'Sans-Serif proporcional',
    'Monospace Sans-Serif': 'Sans-Serif monoespacio',
    'Proportional Serif': 'Serif proporcional',
    'Monospace Serif': 'Serif monoespacio',
    Casual: 'Informal',
    Script: 'Cursiva',
    'Small Caps': 'Minúsculas',
    Reset: 'Restablecer',
    'restore all settings to the default values': 'restablece todas las configuraciones a los valores predeterminados',
    Done: 'Listo',
    'Caption Settings Dialog': 'Diálogo de configuración de leyendas',
    'Beginning of dialog window. Escape will cancel and close the window.':
      'Comienzo de la ventana de diálogo. La tecla Escape cancelará la operación y cerrará la ventana.',
    'End of dialog window.': 'Final de la ventana de diálogo.',
    '{1} is loading.': '{1} se está cargando.',
    'Exit Picture-in-Picture': 'Salir del modo imagen en imagen',
    'Picture-in-Picture': 'Modo imagen en imagen'
  },
  en: {
    Play: 'Play',
    'Play Video': 'Play Video',
    Pause: 'Pause',
    'Current Time': 'Current Time',
    Duration: 'Duration',
    'Remaining Time': 'Remaining Time',
    'Stream Type': 'Stream Type',
    LIVE: 'LIVE',
    Loaded: 'Loaded',
    Progress: 'Progress',
    Fullscreen: 'Fullscreen',
    'Non-Fullscreen': 'Non-Fullscreen',
    Mute: 'Mute',
    Unmute: 'Unmute',
    'Playback Rate': 'Playback Rate',
    Subtitles: 'Subtitles',
    'subtitles off': 'subtitles off',
    Captions: 'Captions',
    'captions off': 'captions off',
    Chapters: 'Chapters',
    'You aborted the media playback': 'You aborted the media playback',
    'A network error caused the media download to fail part-way.':
      'A network error caused the media download to fail part-way.',
    'The media could not be loaded, either because the server or network failed or because the format is not supported.':
      'The media could not be loaded, either because the server or network failed.',
    'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.':
      'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.',
    'No compatible source was found for this media.': 'No compatible source was found for this media.',
    'Audio Player': 'Audio Player',
    'Video Player': 'Video Player',
    Replay: 'Replay',
    'Seek to live, currently behind live': 'Seek to live, currently behind live',
    'Seek to live, currently playing live': 'Seek to live, currently playing live',
    'Progress Bar': 'Progress Bar',
    'progress bar timing: currentTime={1} duration={2}': 'progress bar timing: currentTime={1} duration={2}',
    Descriptions: 'Descriptions',
    'descriptions off': 'descriptions off',
    'Audio Track': 'Audio Track',
    'Volume Level': 'Volume Level',
    'The media is encrypted and we do not have the keys to decrypt it.':
      'The media is encrypted and we do not have the keys to decrypt it.',
    Close: 'Close',
    'Modal Window': 'Modal Window',
    'This is a modal window': 'This is a modal window',
    'This modal can be closed by pressing the Escape key or activating the close button.':
      'This modal can be closed by pressing the Escape key or activating the close button.',
    ', opens captions settings dialog': ', opens captions settings dialog',
    ', opens subtitles settings dialog': ', opens subtitles settings dialog',
    ', selected': ', selected',
    'Close Modal Dialog': 'Close Modal Dialog',
    ', opens descriptions settings dialog': ', opens descriptions settings dialog',
    'captions settings': 'captions settings',
    'subtitles settings': 'subtitles settings',
    'descriptions settings': 'descriptions settings',
    Text: 'Text',
    White: 'White',
    Black: 'Black',
    Red: 'Red',
    Green: 'Green',
    Blue: 'Blue',
    Yellow: 'Yellow',
    Magenta: 'Magenta',
    Cyan: 'Cyan',
    Background: 'Background',
    Window: 'Window',
    Transparent: 'Transparent',
    'Semi-Transparent': 'Semi-Transparent',
    Opaque: 'Opaque',
    'Font Size': 'Font Size',
    'Text Edge Style': 'Text Edge Style',
    None: 'None',
    Raised: 'Raised',
    Depressed: 'Depressed',
    Uniform: 'Uniform',
    Dropshadow: 'Dropshadow',
    'Font Family': 'Font Family',
    'Proportional Sans-Serif': 'Proportional Sans-Serif',
    'Monospace Sans-Serif': 'Monospace Sans-Serif',
    'Proportional Serif': 'Proportional Serif',
    'Monospace Serif': 'Monospace Serif',
    Casual: 'Casual',
    Script: 'Script',
    'Small Caps': 'Small Caps',
    Reset: 'Reset',
    'restore all settings to the default values': 'restore all settings to the default values',
    Done: 'Done',
    'Caption Settings Dialog': 'Caption Settings Dialog',
    'Beginning of dialog window. Escape will cancel and close the window.':
      'Beginning of dialog window. Escape will cancel and close the window.',
    'End of dialog window.': 'End of dialog window.',
    '{1} is loading.': '{1} is loading.'
  }
}
/* eslint-enable max-len, @typescript-eslint/naming-convention */
