import { Inject, Injectable } from '@angular/core'
import { from, Observable, of, Subject } from 'rxjs'
import { HubConnection, HubConnectionState } from '@microsoft/signalr'
import { map, switchMap, takeUntil, tap } from 'rxjs/operators'
import { FACTORY_TOKEN } from '../constants/signalr.constants'
import { SocketMessage, SocketStatus } from '../models/signalr.model'
import { UntilDestroy } from '@ngneat/until-destroy'
import { wsDebug } from '@core/websockets/utils/websocket-logger.utils'

@UntilDestroy()
@Injectable()
export class SignalrService {
  private readonly destroy$$: Subject<void> = new Subject()
  private readonly message$$: Subject<SocketMessage> = new Subject<SocketMessage>()
  private readonly statusChange$$: Subject<SocketStatus> = new Subject<SocketStatus>()

  readonly message$ = this.message$$.asObservable()
  readonly statusChange$ = this.statusChange$$.asObservable()

  constructor(@Inject(FACTORY_TOKEN) private readonly _hub: Observable<HubConnection>) {}

  private async _start(hub: HubConnection): Promise<void> {
    try {
      return await hub.start()
    } catch (e) {
      return await Promise.resolve()
    }
  }

  get hub() {
    return this._hub
  }

  updateStatus(status: HubConnectionState, payload: unknown): void {
    this.statusChange$$.next({ status, payload })
  }

  setupRoom(room: string): Observable<HubConnection> {
    return this._hub.pipe(
      takeUntil(this.destroy$$),
      switchMap((hub) => {
        hub.on(room, (topic: string, message: string) => {
          wsDebug(`${new Date().toLocaleTimeString()} - ${topic} updated`)
          this.message$$.next({ topic, message })
        })

        hub.onreconnecting((error) => {
          wsDebug(`${new Date().toLocaleTimeString()} - Connection lost due to error "${error}". Reconnecting.`)
          this.updateStatus(HubConnectionState.Reconnecting, error)
        })
        hub.onreconnected((connectionId) => {
          wsDebug(`${new Date().toLocaleTimeString()} - Connection reestablished.`)
          this.updateStatus(HubConnectionState.Connected, connectionId)
        })

        hub.onclose((error) => {
          wsDebug(`${new Date().toLocaleTimeString()} - Connection closed due to "${error || 'manually close'}".`)
          this.updateStatus(HubConnectionState.Disconnected, error)
        })
        return of(hub)
      })
    )
  }

  startConnection(): Observable<HubConnection> {
    return this._hub.pipe(
      switchMap((hub) => from(this._start(hub)).pipe(map(() => hub))),
      tap((hub) => wsDebug(`startConnection called. Connection state: ${hub.state}`)),
      takeUntil(this.destroy$$)
    )
  }

  stopConnection(): Observable<HubConnection> {
    return this._hub.pipe(
      switchMap((hub) => from(hub.stop()).pipe(map(() => hub))),
      tap((hub) => wsDebug(`stopConnection called. Connection state: ${hub.state}`)),
      takeUntil(this.destroy$$)
    )
  }

  dispose(): void {
    this.destroy$$.next()
    this.destroy$$.complete()
  }
}
