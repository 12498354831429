/* eslint-disable @typescript-eslint/member-ordering */
import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core'

import { debounce, DebounceTimeType, IsDOMElementVisible } from '@mediacoach-ui-library/global'

import { Subject } from 'rxjs'
import { debounceTime, filter, tap } from 'rxjs/operators'

import { MATCH_MENU_ID, OPTIONS } from './menu.constants'
import { TemplateDirective } from '../../directives'
import { Category, MenuOptionsConfig } from './menu.models'
import { IonicPageVisibilityService } from '@core/services/ionic-page-visibility/ionic-page-visibility.service'
import { trackByData } from '@core/utils/main.utils'
import { FirebaseAnalyticsService } from '@core/services/analytics/firebase/firebase-analytics.service'
import {
  FirebaseAnalyticsCategory,
  FirebaseAnalyticsEvent,
  FirebaseAnalyticsParam
} from '@core/services/analytics/firebase/enums/firebase-analytics.enums'
import { Swiper } from 'swiper/types'
import { SwiperContainer } from 'swiper/swiper-element'
import { MenuService } from '@core/services/menu/menu.service'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements AfterContentInit, OnInit {
  private _hasInit = false
  private _omitOnChange = true
  private _categoryItems: QueryList<ElementRef>
  private _categoryWrapper: ElementRef
  private _itemsWidth: number[]
  private _itemsAccWidth: number[]
  private _wrapperWidth: number
  private _selectedIndex = 0 // FIXME Fix to wait for categories selection instead of initializing it to 0 by default
  private _categories: Category[]
  private _changeCategory$ = new Subject<number>()
  private _categories$ = new Subject<Category[]>()
  private _swiperUpdated$ = new Subject<void>()

  MATCH_MENU_ID = MATCH_MENU_ID
  loading = false
  slideTemplate = {}
  swiperInstance: Swiper
  options: MenuOptionsConfig = { ...OPTIONS }
  categoriesDebouncer$ = this._categories$.pipe(
    tap(() => (this.loading = true)),
    debounceTime(DebounceTimeType.InputChanges),
    tap((_categories) => {
      this.loading = false
      this._categories = JSON.parse(JSON.stringify(_categories || []))
      if (this._categories.length > 0) {
        let selectedIndex = this._categories.findIndex(({ selected }) => selected)
        if (selectedIndex < 0) {
          selectedIndex = 0
          this.categories[selectedIndex].selected = true
        }
        if (this.swiperInstance) {
          this.changeCategory(selectedIndex)
        }
      }
    })
  )
  swiperUpdatedDebounce$ = this._swiperUpdated$.pipe(
    debounceTime(DebounceTimeType.ForCrashes),
    tap(() => {
      this.swiperInstance.updateAutoHeight(0)
    })
  )
  changeCategoryDebounce$ = this._changeCategory$.pipe(
    debounceTime(DebounceTimeType.ForCrashes),
    tap((index) => {
      this.swiperInstance?.slideTo(index)
      this._setSelectedIndex(index)
    })
  )
  slideOnPageVisible$ = this._ionicPageVisibilityService.onDidEnter$.pipe(
    debounceTime(DebounceTimeType.ForCrashes),
    filter(() => IsDOMElementVisible(this.el.nativeElement)),
    tap(() => {
      this.changeCategory()
    })
  )

  readonly updateSwiper$ = this._menuService.updateSwiper.pipe(
    debounceTime(1000),
    tap(() => {
      this.swiperInstance?.updateAutoHeight(500)
    })
  )

  TrackByData = trackByData

  get selectedIndex() {
    return this._selectedIndex
  }
  @Input() set selectedIndex(_selectedIndex) {
    if (this._selectedIndex !== _selectedIndex) {
      this.options = { ...this.options, initialSlide: _selectedIndex }
      this._selectedIndex = _selectedIndex
      const categories = this.categories || ([] as Category[])
      categories.forEach((category, index) => (category.selected = this._selectedIndex === index))
    }
  }

  get categories() {
    return this._categories
  }
  @Input() set categories(_categories) {
    const categoryIds = (categories) => (categories || []).map(({ id }) => id).join('_')
    if (this._hasInit && categoryIds(this._categories) !== categoryIds(_categories)) {
      this._categories$.next(_categories)
    } else {
      this._categories = _categories
    }
  }

  @ViewChild('swiper')
  set swiper(swiperRef: ElementRef<SwiperContainer>) {
    this._setSwiperInstance(swiperRef)
  }

  get categoryWrapper() {
    return this._categoryWrapper
  }
  @ViewChild('categoryWrapper') set categoryWrapper(_categoryWrapper) {
    this._categoryWrapper = _categoryWrapper
    this._wrapperWidth = null
  }
  get categoryItems() {
    return this._categoryItems
  }
  @ViewChildren('categoryItems') set categoryItems(_categoryItems) {
    this._categoryItems = _categoryItems
    this._itemsWidth = null
    this._itemsAccWidth = null
  }
  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>

  @Output() onChange = new EventEmitter<{ selectedIndex: number; id: string }>()

  constructor(
    private el: ElementRef,
    private _analytics: FirebaseAnalyticsService,
    private _ionicPageVisibilityService: IonicPageVisibilityService,
    private _menuService: MenuService
  ) {}

  private get itemsWidth() {
    return (this._itemsWidth =
      this._itemsWidth || this.categoryItems.map((element) => element.nativeElement.getBoundingClientRect().width))
  }

  private get itemsAccWidth() {
    return (this._itemsAccWidth =
      this._itemsAccWidth || this.itemsWidth.reduce((arr, width, i) => [...arr, width + (arr[i - 1] || 0)], []))
  }

  private get wrapperWidth() {
    return (this._wrapperWidth = this._wrapperWidth || this.categoryWrapper.nativeElement.getBoundingClientRect().width)
  }

  @debounce(0)
  private _setSwiperInstance(swiperRef: ElementRef<SwiperContainer>) {
    if (this._hasInit && !this.swiperInstance) {
      this.swiperInstance = swiperRef?.nativeElement.swiper
    }
  }

  private _getPosition(index) {
    const prevWidth = index && this.itemsAccWidth[index - 1]
    return prevWidth + (this.itemsWidth[index] - this.wrapperWidth) / 2
  }

  private _setSelectedIndex(index) {
    this.categoryWrapper.nativeElement.scrollLeft = this._getPosition(index)
    this.selectedIndex = index
  }

  changeCategory(index = this.selectedIndex) {
    this._changeCategory$.next(index)
  }

  onChangedCategory() {
    Promise.resolve(this.swiperInstance.activeIndex)
      .then((index) => this._setSelectedIndex(index))
      .then(() => {
        if (this.categories?.length) {
          this._analytics.logEvent(FirebaseAnalyticsEvent.menuItem, {
            [FirebaseAnalyticsParam.category]: FirebaseAnalyticsCategory.navigation,
            [FirebaseAnalyticsParam.menuItemId]: this.categories[this.selectedIndex].id
          })
          if (!this._omitOnChange) {
            this.onChange.emit({ selectedIndex: this.selectedIndex, id: this.categories[this.selectedIndex].id })
          }
          this._omitOnChange = false
        }
      })
  }

  ngOnInit() {
    this._hasInit = true
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => (this.slideTemplate[item.getType() || 'default'] = item.template))
  }

  onSwiperTap() {
    this._swiperUpdated$.next()
  }
}
