import { Injectable } from '@angular/core'
import { Actions,  createEffect, ofType } from '@ngrx/effects'
import { concatLatestFrom } from '@ngrx/operators'
import { initializeMetricAggregation, initializeMetricAggregationSuccess } from '@core/store/actions/metric-aggregation.actions'
import { map } from 'rxjs/operators'
import { MetricAggregationState } from '@core/store/states/metric-aggregation.state'
import { Store } from '@ngrx/store'
import { selectAggregationContext, selectAggregationMode } from '@core/store/selectors/metric-aggregation.selectors'
import { buildAggregationMetadataByContext } from '@core/utils/metric.utils'
import { MetricFinderKey } from '@core/models/models'
import { MetricAggregation } from '@core/enums/metric.enums'


@Injectable()
export class MetricAggregationEffects {
  initializeMetricAggregation$ = createEffect(() => this._actions$.pipe(
    ofType(initializeMetricAggregation),
    concatLatestFrom(() => [
      this._store.select(selectAggregationMode),
      this._store.select(selectAggregationContext)
    ]),
    map(([initialState, mode, context]) => {
        const {type, ...newState} = {...initialState}

        if (context !== newState.context) {
          mode = null
        }

        if (
          newState.context === MetricFinderKey.Player &&
          newState.isMatch &&
          !newState.isVsEnabled &&
          (mode === MetricAggregation.MatchValues || mode === null)
        ) {
          mode = newState.aggregationMode
        }

        if (!mode) {
          const {
            aggregationMode
          } = buildAggregationMetadataByContext(initialState.isMatch, initialState.isVsEnabled)
          newState.aggregationMode = aggregationMode
        } else {
          newState.aggregationMode = mode
        }
        return initializeMetricAggregationSuccess({initialState: newState as MetricAggregationState})
      }
    )))

  constructor(
    private readonly _store: Store,
    private readonly _actions$: Actions,
  ) {
  }
}
