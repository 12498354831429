import { RootState } from '../states/root.state'
import { createSelector } from '@ngrx/store'
import { MetricAggregationState } from '../states/metric-aggregation.state'
import { MetricAggregationContextMetadata } from '@core/models/metric.models'
import { AGGREGATED_METRIC_LABEL_MAP } from '@core/constants/metric-aggregation.constants'

export const selectMetricAggregationState = (state: RootState) => state.metricAggregation

export const selectIsVSEnabled = createSelector(
  selectMetricAggregationState,
  (state: MetricAggregationState) => state.isVsEnabled
)

export const selectIsMatch = createSelector(
  selectMetricAggregationState,
  (state: MetricAggregationState) => state.isMatch
)

export const selectMetricAggregationMetadata = createSelector(
  selectIsVSEnabled,
  selectIsMatch,
  (isVsEnabled, isMatch): MetricAggregationContextMetadata => ({ isVsEnabled, isMatch })
)

export const selectAggregationMode = createSelector(
  selectMetricAggregationState,
  (state: MetricAggregationState) => state.aggregationMode
)

export const selectAggregationContext = createSelector(
  selectMetricAggregationState,
  (state: MetricAggregationState) => state.context
)

export const selectAggregationLabel = createSelector(selectAggregationMode, (mode) => AGGREGATED_METRIC_LABEL_MAP[mode])

export const selectAggregationHeader = createSelector(selectAggregationLabel, (text) => ({ text }))

export const selectPlayerDemarcation = createSelector(selectMetricAggregationState, (state) => state.demarcation)

export const selectMetricAggregationModes = createSelector(
  selectMetricAggregationState,
  (state) => state.aggregationModes
)
