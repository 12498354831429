import { createActionGroup, emptyProps, props } from '@ngrx/store'

/* eslint-disable @typescript-eslint/naming-convention */

export const metricDetailActions = createActionGroup({
  source: 'Metric Detail',
  events: {
    'manage config': props<{ payload: any }>(),
    'set config': props<{ config: any }>(),
    'set metrics': props<{ metrics: any }>(),
    'open modal': props<{ payload: any }>(),
    'close modal': emptyProps(),
    'set key and key path': props<{ key: string; keyPath: string }>(),
    'purge metric detail': emptyProps()
  }
})
/* eslint-enable @typescript-eslint/naming-convention */
