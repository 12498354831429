import { Pipe, PipeTransform } from '@angular/core'

const POSITION_MAP = [
  '-',
  'MTR_COMMON_POSITION_SUFFIX_FIRST',
  'MTR_COMMON_POSITION_SUFFIX_SECOND',
  'MTR_COMMON_POSITION_SUFFIX_THIRD'
]

@Pipe({
  name: 'position'
})
export class PositionPipe implements PipeTransform {
  transform(position: number): any {
    const _position = position ?? 0
    return POSITION_MAP[_position] ?? 'MTR_COMMON_POSITION_SUFFIX'
  }
}
