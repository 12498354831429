import { Injectable } from '@angular/core'
import { Actions,  createEffect, ofType } from '@ngrx/effects'
import { concatLatestFrom } from '@ngrx/operators'
import { map, tap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { setSelectedCompetitionIdSetting, setSelectedMatchDayIdSetting } from '@core/store/actions/settings.actions'
import { FirebaseAnalyticsService } from '@core/services/analytics/firebase/firebase-analytics.service'
import {
  FirebaseAnalyticsCategory,
  FirebaseAnalyticsEvent,
  FirebaseAnalyticsParam
} from '@core/services/analytics/firebase/enums/firebase-analytics.enums'
import { selectCurrentSeason } from '@core/store/selectors/season.selector'
import { Competition } from '@core/models/dto/competition.dto'

@Injectable()
export class SettingsEffects {

  setSelectedCompetitionIdSetting$ = createEffect(() =>
    this._actions$.pipe(
      ofType(setSelectedCompetitionIdSetting),
      concatLatestFrom(() => this._store.select(selectCurrentSeason)),
      tap(([{selectedCompetitionId}]) => {
        this._analytics.logEvent(FirebaseAnalyticsEvent.changeCompetition, {
          [FirebaseAnalyticsParam.competitionId]: selectedCompetitionId,
          [FirebaseAnalyticsParam.category]: FirebaseAnalyticsCategory.settings,
        })
      }),
      map(([{selectedCompetitionId}, currentSeason]) => {
        const competition = currentSeason.competitions.find((comp) => comp.id === selectedCompetitionId) as Competition
        return competition?.matchdays?.items?.find((matchDay) => matchDay.selected)?.id
      }),
      map((selectedMatchDayId) => setSelectedMatchDayIdSetting({selectedMatchDayId}))
    ))

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
    private _analytics: FirebaseAnalyticsService) {
  }
}
