import { Column, SortDirection } from '../../table/table.models'
import { DEFAULT_STICKY_COLUMN_STYLE } from '../../table/table.constants'
import { TeamPlayer } from '../models/team-players-grid.models'
import { SafeObjectData } from '@mediacoach-ui-library/global'
import { MetricMode } from '@pages/matches/enums/matches.enum'
import { MetricAggregation } from '@core/enums/metric.enums'

const metricPlayerSortFn = (a: TeamPlayer, b: TeamPlayer, sortDirection: SortDirection, sortableKey: string) => {
  const _a = +SafeObjectData(a, sortableKey) || 0
  const _b = +SafeObjectData(b, sortableKey) || 0
  return sortDirection === 'desc'
    ? b.groupWeight - a.groupWeight || b.starterNumeric - a.starterNumeric || _b - _a
    : b.groupWeight - a.groupWeight || b.starterNumeric - a.starterNumeric || _a - _b
}

export const COLUMNS: Column[] = [
  {
    label: 'MTR_TITLE_PLAYER',
    sticky: true,
    sortDirection: 'asc',
    sortFn: (a: TeamPlayer, b: TeamPlayer, sortDirection: SortDirection) =>
      sortDirection === 'desc'
        ? b.groupWeight - a.groupWeight ||
          b.starterNumeric - a.starterNumeric ||
          b.positionOrder - a.positionOrder ||
          a.shirtNumber - b.shirtNumber
        : b.groupWeight - a.groupWeight ||
          b.starterNumeric - a.starterNumeric ||
          a.positionOrder - b.positionOrder ||
          a.shirtNumber - b.shirtNumber,
    ...DEFAULT_STICKY_COLUMN_STYLE
  },
  {
    label: 'MTR_METRIC_PLAYERMINUTESPLAYED_SHORT',
    sortableKey: 'MTR_METRIC_PLAYERMINUTESPLAYED_SHORT.value',
    sortFn: metricPlayerSortFn
  },
  {
    label: 'MTR_METRIC_PLAYERGOALS_SHORT',
    sortableKey: 'MTR_METRIC_PLAYERGOALS_SHORT.value',
    sortFn: metricPlayerSortFn
  },
  {
    label: 'MTR_METRIC_PLAYERPASSESASSIST_SHORT',
    sortableKey: 'MTR_METRIC_PLAYERPASSESASSIST_SHORT.value',
    sortFn: metricPlayerSortFn
  },
  {
    label: 'MTR_METRIC_PLAYERPASSES_SHORT',
    sortableKey: 'MTR_METRIC_PLAYERPASSES_SHORT.value',
    sortFn: metricPlayerSortFn
  },
  {
    label: 'MTR_METRIC_PLAYERPASSESFORWARD_SHORT',
    sortableKey: 'MTR_METRIC_PLAYERPASSESFORWARD_SHORT.value',
    sortFn: metricPlayerSortFn
  },
  {
    label: 'MTR_METRIC_PLAYERPASSESATTACK_SHORT',
    sortableKey: 'MTR_METRIC_PLAYERPASSESATTACK_SHORT.value',
    sortFn: metricPlayerSortFn
  },
  {
    label: 'MTR_METRIC_PLAYERPASSESKEYPASSASSIST_SHORT',
    sortableKey: 'MTR_METRIC_PLAYERPASSESKEYPASSASSIST_SHORT.value',
    sortFn: metricPlayerSortFn
  },
  {
    label: 'MTR_METRIC_PLAYERSHOTS_SHORT',
    sortableKey: 'MTR_METRIC_PLAYERSHOTS_SHORT.value',
    sortFn: metricPlayerSortFn
  },
  {
    label: 'MTR_METRIC_PLAYERSHOTSGOAL_SHORT',
    sortableKey: 'MTR_METRIC_PLAYERSHOTSGOAL_SHORT.value',
    sortFn: metricPlayerSortFn
  },
  {
    label: 'MTR_METRIC_PLAYERAERIAL_SHORT',
    sortableKey: 'MTR_METRIC_PLAYERAERIAL_SHORT.value',
    sortFn: metricPlayerSortFn
  },
  {
    label: 'MTR_METRIC_PLAYERAERIALSUCCESSP_SHORT',
    sortableKey: 'MTR_METRIC_PLAYERAERIALSUCCESSP_SHORT.value',
    sortFn: metricPlayerSortFn
  },
  {
    label: 'MTR_METRIC_PLAYERRECOVERIES_SHORT',
    sortableKey: 'MTR_METRIC_PLAYERRECOVERIES_SHORT.value',
    sortFn: metricPlayerSortFn
  },
  {
    label: 'MTR_METRIC_PLAYERRECOVERIESOPPOSITEFIELD_SHORT',
    sortableKey: 'MTR_METRIC_PLAYERRECOVERIESOPPOSITEFIELD_SHORT.value',
    sortFn: metricPlayerSortFn
  },
  {
    label: 'MTR_METRIC_PLAYERCLEARENCE_SHORT',
    sortableKey: 'MTR_METRIC_PLAYERCLEARENCE_SHORT.value',
    sortFn: metricPlayerSortFn
  },
  {
    label: 'MTR_METRIC_PLAYERPHYSICALDISTANCEKM_SHORT',
    sortableKey: 'MTR_METRIC_PLAYERPHYSICALDISTANCEKM_SHORT.value',
    sortFn: metricPlayerSortFn
  },
  {
    label: 'MTR_METRIC_PLAYERDISTANCESPRINT_SHORT',
    sortableKey: 'MTR_METRIC_PLAYERDISTANCESPRINT_SHORT.value',
    sortFn: metricPlayerSortFn
  }
]

export const METRIC_MODE_MAP = {
  [MetricAggregation.AccumulatedValues]: MetricMode.Acumulated,
  [MetricAggregation.AverageValues]: MetricMode.Average,
  [MetricAggregation.MatchValues]: MetricMode.Match
}
