import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from '@env'

if (environment.production) {
  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err))

// workaround to prevent long press blocking on web browser
window.oncontextmenu = function (event) {
  if (event.pointerType === 'touch') {
    // context menu was not triggered by long press
    return false
  }

  // just to show that pointerEvent.pointerType has another value 'mouse' aka right click
  if (event.pointerType === 'mouse') {
    // context menu was triggered by right click
    return true
  }

  // returning true will show a context menu for other cases
  return true
}
