/* eslint-disable id-blacklist */
import { ListType, MatchState, TEAMS, TeamType } from '@mediacoach-ui-library/global'
import { TeamMatchType } from '../enums/matches.enum'
import { getTeamProp } from '@core/constants/constants'
import { objectKeys, safeObjectData } from '@core/utils/object.utils'
import { TableDataMode } from '@shared/components/stat-table/stat-table.models'
import { parseStatics } from '@core/utils/statics.utils'
import { StaticOrientation } from '@core/enums/statics.enum'
import { DemarcationPipe } from '@shared/pipes/demarcation.pipe'
import { PLAYER_DEMARCATION_FALLBACK_KEY, PLAYER_DEMARCATION_KEYS } from '@core/constants/player.constants'

export const MATCHES_FEATURE_TOKEN = 'matches'

export enum MatchTabs {
  Previous = 'MTR_METRICCATEGORY_PREVIA',
  LineUp = 'MTR_METRICCATEGORY_LINEUP',
  Overview = 'MTR_METRICCATEGORY_PARTIDO'
}

export const TEAM_MATCH_TYPES = Object.values(TeamMatchType)

export const getMatchCompetitionIdsByTeam = (team) =>
  Array.from(new Set(TEAM_MATCH_TYPES.filter((matchType) => (team[matchType] || {}).competitionId))).map(
    (matchType) => (team[matchType] || {}).competitionId
  )

export const eachTeamMatchTypeObj = (team) => (reduce) =>
  TEAM_MATCH_TYPES.filter((matchType) => team[matchType]).reduce(
    (obj, matchType) => ({
      ...obj,
      [matchType]: reduce(matchType)
    }),
    {}
  )

export const CATEGORY_BY_MATCH_STATE = {
  [MatchState.LineupReady]: MatchTabs.LineUp,
  [MatchState.FullTime]: MatchTabs.Overview,
  [MatchState.Pending]: MatchTabs.Previous,
  isLive: MatchTabs.Overview
}

export const PLAYER_DETAIL_BASE_PATH = (seasonId, competitionId, teamId, playerId) =>
  `seasons/${seasonId}/competitions/${competitionId}/teams/${teamId}/players/${playerId}`
export const MATCH_PLAYER_DETAIL_BASE_PATH = (matchId, teamId, playerId) =>
  `matches/${matchId}/teams/${teamId}/players/${playerId}`

export const MATCH_LIST_FORMATTED_PLAYERS = (match, isStarter: boolean, _demarcationPipe: DemarcationPipe) =>
  TEAMS.reduce((obj, teamKey) => {
    const matchTeam = match[getTeamProp(teamKey)]
    return {
      ...obj,
      [teamKey]: {
        type: ListType.Players,
        groups: [
          {
            items: ((matchTeam.lineup || matchTeam.squad || {}).players || [])
              .filter((player) => !!player.starter === isStarter)
              .map((player) => ({
                id: player.id,
                teamId: matchTeam.id,
                competitionId: matchTeam.competitionId,
                seasonId: matchTeam.seasonId,
                imgUrl: (
                  safeObjectData(player, 'statics', []).find(({ orientation }) => orientation === 'Portrait') || {}
                ).url,
                shirtNumber: safeObjectData(player, 'shirtNumber', '-'),
                name: safeObjectData(player, 'formattedName', '-'),
                position: _demarcationPipe.transform(
                  player,
                  PLAYER_DEMARCATION_KEYS,
                  PLAYER_DEMARCATION_FALLBACK_KEY,
                  ' '
                ),
                navigateUrl: matchTeam.lineup
                  ? MATCH_PLAYER_DETAIL_BASE_PATH(match.id, matchTeam.teamId, player.id)
                  : PLAYER_DETAIL_BASE_PATH(match.seasonId, match.competitionId, matchTeam.teamId, player.id)
              }))
          }
        ]
      }
    }
  }, {})

export const MATCH_FORMATTED_PLAYERS = (match, _demarcationPipe: DemarcationPipe) =>
  ['starters', 'substitutes'].reduce(
    (obj, squadType) => ({
      ...obj,
      [squadType]: MATCH_LIST_FORMATTED_PLAYERS(match, squadType === 'starters', _demarcationPipe)
    }),
    {}
  )

export const GET_TEAM_TREND = (trend, team) => {
  const lastMatchResults = safeObjectData(trend, 'lastMatchResults', [])

  return {
    img: (team.statics.find((res) => res.orientation === 'Portrait') || {}).url,
    name: safeObjectData(team, 'shortName', null),
    data: lastMatchResults,
    position: safeObjectData(trend, 'standings.position', null)
  }
}

export function mapPlayerMetric(player) {
  return objectKeys(safeObjectData(player, 'listMetricResults.0'))
    .filter((value) => value !== 'key')
    .reduce(
      (prev, mKey) => ({
        ...prev,
        [mKey]: safeObjectData(player, 'listMetricResults', []).reduce(
          (prevMetric, currentMetric) => ({
            ...prevMetric,
            [currentMetric.key]: {
              value: (currentMetric[mKey] || {}).value,
              unit: (currentMetric[mKey] || {}).unit
            }
          }),
          {}
        )
      }),
      {}
    )
}

export const getFieldsAndProps = (data, accessDataKey: string) => {
  const metricKeys = safeObjectData(data || {}, accessDataKey, []).map(({ key }) => key)
  return {
    fields: [...metricKeys],
    props: [...metricKeys]
  }
}

export const GET_PLAYERS_FORMATTED_CONFIG_TO_STAT_TABLE = (
  home,
  away,
  returnData: TeamType[] = [],
  isSwitchable: boolean,
  switcherOptions: TableDataMode[] = []
) => {
  const switcher = [
    { id: TableDataMode.Match, text: 'MTR_COMMON_MATCH_VALUE' },
    { id: TableDataMode.Average, text: 'MTR_COMMON_AVERAGE' },
    { id: TableDataMode.Accumulated, text: 'MTR_COMMON_ACCUMULATED' }
  ]

  const DATA_TO_PROCESS = [
    { id: TeamType.Home, value: home.lineup || home.squad },
    { id: TeamType.Away, value: away.lineup || away.squad }
  ]

  return {
    isSwitchable,
    switcherValues: isSwitchable ? switcher.filter(({ id }) => switcherOptions.includes(id)) : [],
    ...DATA_TO_PROCESS.filter((team) => returnData.includes(team.id)).reduce(
      (prev, current) => ({
        ...prev,
        [current.id]: getFieldsAndProps(current.value, 'players.0.listMetricResults')
      }),
      {}
    )
  }
}

export const parseComparedPlayer = (comparedPlayerData, player) => ({
  ...comparedPlayerData,
  playerId: player.id,
  name: comparedPlayerData.formattedName,
  metricPlayerName: `${comparedPlayerData.shirtNumber}. ${comparedPlayerData.formattedName}`,
  teamId: player.teamId,
  team: { ...player.team, teamId: player.teamId },
  competitionId: player.competitionId,
  seasonId: player.seasonId,
  ...parseStatics(comparedPlayerData.statics, [StaticOrientation.Portrait])
})

export const parseComparedPlayers = (comparedData, playerA, playerB) => ({
  ...comparedData,
  playerA: parseComparedPlayer(comparedData.playerA, playerA),
  playerB: parseComparedPlayer(comparedData.playerB, playerB)
})
