import { Injectable } from '@angular/core'
import { SmartlookService } from './smartlook/smartlook.service'
import { FirebaseAnalyticsService } from './firebase/firebase-analytics.service'
import { UserProfile } from '@core/services/auth/auth.models'
import { AppTrackingTransparencyService } from './app-tracking-transparency/app-tracking-transparency.service'
import { AppTrackingStatus } from 'capacitor-plugin-app-tracking-transparency'

@Injectable({ providedIn: 'root' })
export class AnalyticsHandlerService {
  isGranted: boolean
  status: AppTrackingStatus

  constructor(
    private readonly _att: AppTrackingTransparencyService,
    private readonly _smartlook: SmartlookService,
    private readonly _firebase: FirebaseAnalyticsService
  ) {}

  async requestPermissions() {
    this.isGranted = await this._att.isGranted()
    this.status = await this._att.getStatus()
  }

  async init(user: UserProfile) {
    this._firebase.registerUser(this.isGranted)
    this._smartlook.init(user, this.status === 'authorized')
  }
}
