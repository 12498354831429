import { ModalType, ViewType } from '../models/team.models'

export const TEAM_FEATURE_TOKEN = 'team'

export const TITLE_BY_MODAL = {
  [ModalType.FavTeam]: 'MTR_SELECT_TEAM_TITLE',
  [ModalType.Vs]: 'MTR_TITLE_TEAMS_VS'
}

export const VIEWS = [
  {
    id: ViewType.Teams,
    label: 'MTR_TITLE_TEAM',
    qaId: 'mcm-home__segment-button--team'
  },
  {
    id: ViewType.Players,
    label: 'MTR_TITLE_PLAYERS',
    qaId: 'mcm-home__segment-button--players'
  }
]

export const parseMetricResults = (values) => ({
  groups: [{ averageValues: values }],
  key: 'MTR_CHART_METRIC_RESULTS'
})
