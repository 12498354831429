import { MetricType } from '../enums/metric.enums'
import { PlayerPosition } from '../enums/player.enums'

export const PLAYER_POSITION_KEYS = {
  [PlayerPosition.Goalkeeper]: 'MTR_PLAYERPOSITION_GOALKEEPER',
  [PlayerPosition.Defender]: 'MTR_PLAYERPOSITION_DEFENDER',
  [PlayerPosition.Midfielder]: 'MTR_PLAYERPOSITION_MIDFIELDER',
  [PlayerPosition.Forward]: 'MTR_PLAYERPOSITION_FORWARD'
}

export const PLAYER_AVERAGE_BY_POSITION = {
  [PlayerPosition.Goalkeeper]: 'MTR_COMMON_GOALKEEPER_AVERAGE',
  [PlayerPosition.Defender]: 'MTR_COMMON_DEFENDER_AVERAGE',
  [PlayerPosition.Midfielder]: 'MTR_COMMON_MIDFIELDER_AVERAGE',
  [PlayerPosition.Forward]: 'MTR_COMMON_FORWARD_AVERAGE'
}

export const SORTED_POSITIONS = [
  PLAYER_POSITION_KEYS[MetricType.Goalkeeper],
  PLAYER_POSITION_KEYS[MetricType.Defender],
  PLAYER_POSITION_KEYS[MetricType.Midfielder],
  PLAYER_POSITION_KEYS[MetricType.Forward]
]

export const PLAYER_DEMARCATION_GENERIC = 'genericPositionKey'
export const PLAYER_DEMARCATION_SPECIFIC = 'specificPositionKey'
export const PLAYER_DEMARCATION_FALLBACK_KEY = 'playerPositionKey'
export const PLAYER_DEMARCATION_KEYS = [PLAYER_DEMARCATION_GENERIC, PLAYER_DEMARCATION_SPECIFIC]
