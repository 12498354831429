import { Directive } from '@angular/core'
import { CalendarComponent } from '@mediacoach/ui'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mcui-calendar'
})
export class CalendarNavigatorDirective {
  private readonly _emptyFn = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
  }

  constructor(private readonly _calendar: CalendarComponent) {
    _calendar.switchToView = this._emptyFn
  }
}
