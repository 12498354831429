export const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const toLowerCaseNoAccent = (str) =>
  str
    .toLowerCase()
    .replace(
      /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
      (_str, a, c, e, i, n, o, s, u, y, ae) =>
        (a && 'a') ||
        (c && 'c') ||
        (e && 'e') ||
        (i && 'i') ||
        (n && 'n') ||
        (o && 'o') ||
        (s && 's') ||
        (u && 'u') ||
        (y && 'y') ||
        (ae && 'ae')
    )
