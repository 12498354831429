import { RootState } from '@core/store/states/root.state'
import { createSelector, select } from '@ngrx/store'
import { SeasonState } from '@core/store/states/season.state'
import { pipe, takeWhile } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { selectFavCompetitionId, selectFavTeamId } from '@core/store/selectors/user.selectors'
import { Competition } from '@core/models/dto/competition.dto'
import { mapCompetition } from '@core/utils/season.utils'

export const selectSeason = (state: RootState) => state.season

export const selectCurrentSeason = createSelector(selectSeason, (state: SeasonState) => state.current)

export const selectCompetitions = pipe(
  select(selectCurrentSeason),
  filter((season) => !!season),
  map((season) => season.competitions.map((competition) => mapCompetition(competition, season)) as Competition[])
)

export const selectCurrentCompetition = pipe(
  select(
    createSelector(selectCurrentSeason, selectFavCompetitionId, (season, competitionId) => ({ season, competitionId }))
  ),
  filter(({ season }) => !!season),
  map(
    ({ season, competitionId }) =>
      (season.competitions.find((comp) => comp.id === competitionId) || season.competitions[0]) as Competition
  )
)

export const selectCurrentSeasonAndCompetition = createSelector(
  selectCurrentSeason,
  selectFavCompetitionId,
  (season, competitionId) => {
    if (season) {
      return {
        season,
        competition: (season.competitions.find((comp) => comp.id === competitionId) ||
          season.competitions[0]) as Competition
      }
    }
  }
)

export const selectCompetitionById = (competitionId: string) =>
  pipe(
    select(createSelector(selectCurrentSeason, (season) => ({ season }))),
    filter(({ season }) => !!season),
    map(
      ({ season }) =>
        (season.competitions.find((comp) => comp.id === competitionId) || season.competitions[0]) as Competition
    )
  )

export const selectFavCurrentSeasonCompetitionWithFavTeamId = () =>
  pipe(
    select(
      createSelector(selectCurrentSeasonAndCompetition, selectFavTeamId, (seasonCompetition, teamId) => ({
        seasonCompetition,
        teamId
      }))
    ),
    map(({ seasonCompetition, teamId }) => ({ ...seasonCompetition, teamId })),
    takeWhile((fav) => !fav || (!!fav && (!fav.competition || !fav.season || !fav.teamId)), true),
    filter((fav) => !!fav && !!fav.competition && !!fav.season)
  )

export const selectCurrentSeasonCompetitionAndTeamIds = () =>
  pipe(
    selectFavCurrentSeasonCompetitionWithFavTeamId(),
    map(({ season, competition, teamId }): { seasonId: string; competitionId: string; teamId: string } => ({
      seasonId: season.id,
      competitionId: competition.id,
      teamId
    }))
  )
